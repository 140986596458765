const errors = {
  "en": {},
  "pl": {
    0: "Błąd systemu, spróbuj później lub skontaktuj się z administratorem",
    401: "Brak autoryzacji",
    403: "Brak dostępu",
    404: "Strony nie znaleziono lub nie istnieje",
    500: "Wewnętrzny błąd serwera",
    502: "Bad Gateway",
    503: "Serwis niedostępny",
    504: "Gateway Timeout",
    1000: "Niepoprawny login lub hasło",
    1002: "Imię jest wymagne",
    1003: "Imię jest za krótkie",
    1004: "Imię jest za długie",
    1005: "Nazwisko jest wymagane",
    1006: "Nazwisko jest za krótkie",
    1007: "Nazwisko jest za długie",
    1008: "Email jest wymagany",
    1009: "Email jest niepoprawny",
    1010: "Email jest za krótki",
    1011: "Email jest za długi",
    1012: "Email jest zajęty",
    1013: "Hasło jest wymagane",
    1014: "Hasło jest za krótkie",
    1015: "Hasło jest za długie",
    1016: "Kod regionu jest wymagany",
    1017: "Kod regionu jest nieznany",
    1018: "Kod regionu został już zajęty",
    1019: "Niepoprawne hasło",
    1020: "Niepoprawny kod regionu",
    1021: "Problem z autoryzacją",
    1022: "Konto nie jest aktywowane",
    1023: "Hasła się nie zgadzają",
    1024: "Konto zostało zablokowane",
    1025: "Email jest wymagany",
    1026: "Email jest niepoprawny",
    1027: "Email jest za krótki",
    1028: "Email jest za długi",
    1029: "Numer telefonu jest wymagany",
    1030: "Numer telefonu jest za krótki",
    1031: "Numer telefonu jest za długi",
    1032: "Pozycja jest wymagana",
    1033: "Pozycja jest za krótka",
    1034: "Pozycja jest za długa",
    1100: "Nazwa kosztu jest wymagana",
    1101: "Nazwa kosztu jest za krótka",
    1102: "Nazwa kosztu jest za długa",
    1103: "Maksymalny koszt jest wymagany",
    1104: "Maksymalny koszt ma zły format",
    1105: "Kategoria jest wymagana",
    1106: "Kategoria nie jest rozpoznana",
    1107: "Koszt jest wymagany",
    1108: "Koszt ma zły format",
    1109: "Liczba jest wymagana",
    1110: "Liczba ma zły format",
    1200: "Imię jest wymagane",
    1201: "Imię jest za krótkie",
    1202: "Imię jest za długie",
    1203: "Nazwisko jest wymagane",
    1204: "Nazwisko jest za krótkie",
    1205: "Nazwisko jest za długie",
    1206: "Email jest wymagany",
    1207: "Email jest niepoprawny",
    1208: "Email jest za długi",
    1209: "Email jest zajęty",
    1210: "Hasło jest wymagane",
    1211: "Hasło jest za krótkie",
    1212: "Hasło jest za długie",
    1213: "Wymagane jest potwierdzenie hasła",
    1214: "Hasła nie są jednakowe",
    1215: "Stare hasło jest wymagane",
    1216: "Stare hasło jest za krótkie",
    1217: "Stare hasło jest za długie",
    1218: "Błąd przy blokowaniu użytkownika",
    1300: "Nieznany bład przy zapisywaniu aplikacji",
    1301: "Nieznany bład przy zapisywaniu pól aplikacji",
    1302: "Nieznany bład przy zapisywaniu plików aplikacji",
    1303: "Pola są wymagane",
    1304: "Pola nie są tablicą",
    1305: "Nazwa pola jest wymagana",
    1306: "Nazwa pola jest nieznana",
    1307: "Wartość pola jest za krótka",
    1308: "Wartość pola jest za długa",
    1309: "Koszty są wymagane",
    1310: "Koszty nie są tablicą",
    1311: "ID kosztu jest wymagane",
    1312: "ID kosztu jest nieznane",
    1313: "Nazwa kosztu jest wymagana",
    1314: "Nazwa kosztu jest za krótka.",
    1315: "Nazwa kosztu jest za długa.",
    1316: "Uwaga jest za krótka",
    1317: "Uwaga jest za długa",
    1318: "Koszt jest wymagany",
    1319: "Koszt nie jest liczbą",
    1320: "Wniosek nie może być aktualizowany",
    1321: "Błąd przy zapisie kosztów ",
    1322: "Status jest wymagany",
    1323: "Nieznany status",
    1324: "Uwaga jest zbyt krótka",
    1325: "Uwaga jest zbyt długa",
    1326: "Uwaga jest zbyt krótka",
    1327: "Uwaga jest zbyt długa",
    1328: "Uwaga jest zbyt krótka",
    1329: "Uwaga jest zbyt długa",
    1330: "Niezane pole",
    1331: "Nieznany koszt",
    1332: "Liczba jest wymagana",
    1333: "Liczba nie jest liczbą",
    1334: "Suma kontrolna jest wymagana",
    1335: "Suma kontrolna się nie zgadza",
    1336: "Aplikacja nie może być zduplikowana",
    1337: "Błąd przy duplikowaniu pól",
    1338: "Błąd przy duplikowaniu kosztów",
    1339: "Nie można wysłać aplikacji, skończył się czas.",
    1340: "Wymagany koszt Diagnoza cyberbezpieczeństwa",
    1341: "Wymagana koncepcja wniosku",
    1342: "Wniosek z uwagami można odrzucić tylko raz. Ten wniosek został już wcześniej odrzucony.",
    1343: "Aplikacja została już akceptowana",
    1344: "Uwaga do kosztu jest wymagana",
    1345: "Pola się powtarzają",
    1346: "Pole Uwaga w koszcie jest wymagana",
    1347: "Opinia KOWR jest wymagana",
    1348: "Plik wniosku jest wymagany",
    1349: "Plik musi być w formacie PDF",
    1350: "Plik musi mieć mniej niż 5MB",
    1351: "Bład przy przypisaniu moderatora",
    1352: "Bład przy przypisaniu moderatora",
    1353: "Bład przy przypisaniu moderatora",
    1354: "Bład przy przypisaniu moderatora",
    1355: "Bład przy przypisaniu moderatora",
    1356: "Bład przy przypisaniu moderatora",
    1357: "Bład przy przypisaniu moderatora",
    1358: "Wymagane kryteria",
    1359: "Koszt nie może być usunięty",
    1360: "Numer konta jest wymagany",
    1361: "Pole opłacone jest wymagane",
    1362: "Załącz pliki",
    1363: "Wymagana jest data podpisania umowy",
    1364: "Niepoprawny format daty",
    1365: "Plik rezygnacji jest wymagany",
    1366: "Zły format pliku",
    1367: "Plik jest za duży",
    1368: "Plik rezygnacji jest wymagany",
    1369: "Zły format pliku",
    1380: "Plik jest za duży",
    1381: "Wniosek jest zamknięty",
    1400: "Nie masz uprawnień do historii",
    1500: "Nazwa jest wymagana",
    1501: "Nazwa jest za krótka.",
    1502: "Nazwa jest za długa.",
    1503: "Skrócona nazwa jest wymagana",
    1504: "Skrócona nazwa jest za krótka",
    1505: "Skrócona nazwa jest za długa",
    1506: "Województwo jest wymagane",
    1507: "Województwo jest za krótkie",
    1508: "Województwo jest za długie",
    1509: "Powiat jest wymagany",
    1510: "Powiat jest za krótki",
    1511: "Powiat jest za długi",
    1512: "Miasto jest wymagane",
    1513: "Miasto jest za krótkie",
    1514: "Miasto jest za długie",
    1515: "Nazwa ulicy jest wymagana",
    1516: "Nazwa ulicy jest za krótka",
    1517: "Nazwa ulicy jest za długa",
    1518: "Numer budunku jest wymagany",
    1519: "Numer budunku jest za krótki",
    1520: "Numer budunku jest za długi",
    1521: "Numer mieszkania jest wymagany",
    1522: "Numer mieszkania jest za krótki",
    1523: "Numer mieszkania jest za długi",
    1524: "Kod pocztowy jest wymagany",
    1525: "Kod pocztowy jest za krótki",
    1526: "Kod pocztowy jest za długi",
    1527: "Email jest wymagany",
    1528: "Email jest za krótki",
    1529: "Email jest za długi",
    1530: "Adres epuap jest wymagany",
    1531: "Adres epuap jest za krótki",
    1532: "Adres epuap jest za długi",
    1533: "Numer telefonu jest wymagany",
    1534: "Numer telefonu jest za krótki",
    1535: "Numer telefonu jest za długi",
    1536: "Numer NIP jest wymagany",
    1537: "Numer NIP jest niepoprawny",
    1538: "Numer REGON jest wymagany",
    1539: "Numer REGON jest niepoprawny",
    1600: "Nieznany plik szablonu",
    1601: "Nie ma plików do spakowania",
    1700: "Data “od” jest wymagana",
    1701: "Data “od” ma zły format",
    1702: "Data “do” jest wymagana",
    1703: "Data “do” ma zły format",
    1800: "Status jest wymagany",
    1801: "Typ jest wymagany",
    1802: "Wiadomość jest wymagany",
    1803: "Tytuł jest wymagany",
    1901: "Miasto jest wymagane",
    1902: "Miasto jest za krótkie",
    1903: "Miasto jest za długie",
    1911: "Miasto jest wymagane",
    1912: "Miasto jest za krótkie",
    1913: "Miasto jest za długie",
    1921: "Nazwa ulicy jest wymagana",
    1922: "Nazwa ulicy jest za krótka",
    1923: "Nazwa ulicy jest za długa",
    1931: "Numer budunku jest wymagany",
    1932: "Numer budunku jest za krótki",
    1933: "Numer budunku jest za długi",
    1941: "Kod pocztowy jest wymagany",
    1942: "Kod pocztowy jest za krótki",
    1943: "Kod pocztowy jest za długi",
    1951: "Błędny NIP",
    1952: "Błędny NIP",
    1961: "REGON jest wymagany",
    1962: "Błędny REGON",
    2000: "Imię rodzica jest za krótkie",
    2001: "Imię rodzica jest za długie",
    2002: "Nazwisko rodzica jest za krótkie",
    2003: "Nazwisko rodzica jest za długie",
    2004: "Imię dziecka jest za krótkie",
    2005: "Imię dziecka jest za długie",
    2006: "Nazwisko dziecka jest za krótkie",
    2007: "Nazwisko dziecka jest za długie",
    2008: "Województwo jest za krótkie",
    2009: "Województwo  jest za długie",
    2010: "Powiat jest za krótki",
    2011: "Powiat jest za długi",
    2012: "Miasto jest za krótkie",
    2013: "Miasto jest za długie",
    2014: "Nazwa ulicy jest za krótka",
    2015: "Nazwa ulicy jest za długa",
    2017: "Numer budynku jest za długi",
    2018: "Kod pocztowy jest za krótki",
    2019: "Kod pocztowy jest za długi",
    2020: "Numer mieszkania jest za krótki",
    2021: "Numer mieszkania jest za długi",
    2022: "Nazwa sprzętu jest za krótka",
    2023: "Nazwa sprzętu jest za długa",
    2024: "Nazwa sprzętu jest wymagana",
    2025: "Email jest niepoprawny",
    2026: "Email jest za długi",
    2027: "Numer telefonu jest za krótki",
    2028: "Numer telefonu jest za długi",
    2029: "Nazwa szkoły jest za krótka",
    2030: "Nazwa szkoły jest za długa",
    2040: "Nieznana kategoria",
    2041: "Rozliczenie już istnieje",
    2042: "Błąd przy tworzeniu rozliczenia",
    2043: "Nieznany typ ",
    2044: "Brakujący plik",
    2045: "Błąd przy weryfikacji",
    2046: "Błąd przy tworzeniu kosztu",
    2047: "Błąd przy zapisywaniu kosztu",
    2048: "Błąd przy usuwaniu kosztu",
    2049: "Błąd przy zapisywaniu pliku",
    2050: "Nieprawidłowa wartość",
    2051: "Błąd przy generowaniu pliku",
    2052: "Próba usunięcia zablokowanego pliku",
    2055: "Błąd przy odrzucaniu",
    2058: "Zły status wniosku",
    2059: "Suma dodanych kosztów przekracza wartość przyznanego grantu",

    3000: "Błąd w kontroli",
    3001: "Wniosek powinien być w statusie 'Skierowany do Kontroli'",
    3003: "Przypisywany użytkownik musi być kontrolerem",
    3004: "Kontrolerzy muszą się od siebie różnić",
    3010: "Brak dostepu do tego czatu",
    3012: "Brak dostepu do tego wątku",
    3013: "Brak uprawnień do dodania wiadomości do tego wątku",
    3015: "Brak uprawnień do edycji tej wiadomości",
    3016: "Wiadomość powinna być szkicem lub być usunięta",
  }
}

export default errors;