import SuperFetch from '../helpers/superFetch';
import TlnString from '../components/Tln/TlnString';

const Auth = {
  login: ({ email, password }) =>
    SuperFetch.post('auth/login', { email, password })
      .then(response => response),

  signUp: data => {
    if(window.location.hostname.includes('cyfrowa-gmina')) {
      data.d = "12"
    }
    return SuperFetch.post('auth/register', data, TlnString("AUTH_SIGNUP_SUCCESS"))
      .then(response => response)
    },

  forgotPassword: email =>
    SuperFetch.post('auth/forgot-password', { email }, TlnString("AUTH_FORGOT_PASSWORD_SUCCESS"))
      .then(response => response),

  resetPassword: data =>
    SuperFetch.post('auth/reset-password', data, TlnString("AUTH_RESET_PASSWORD_SUCCESS"))
      .then(response => response),

  activateAccount: ({ token }) =>
    SuperFetch.post('auth/activate-account', { token }, TlnString("AUTH_ACTIVATE_SUCCESS"))
      .then(response => response),

  currentUser: () =>
    SuperFetch.get('who-am-i')
      .then(response => response),

};

export default Auth;
