import SuperFetch from '../helpers/superFetch';

const Regions = {
  sendRegionForm: data =>
    SuperFetch.put(`my-account/details`, data)
      .then(response => response.data),

  getTerytData: teryt =>
    SuperFetch.get(`region?search=${teryt}`)
      .then(response => response.data),

}

export default Regions;
