import { Collapse } from "antd";
import styled from "styled-components";

const AppStyleHolder = styled.div`
  .site-layout {
    transition: margin-left 0.2s;
    margin-left: 80px;
  }

.note-date {
  opacity: 0.5;
  font-size: 80%;
}
`;

export const CustomCollapse = styled(Collapse)`
 > .ant-collapse-item > .ant-collapse-header {
    /* padding-left: 0; */
    font-size: ${props => props.fontSize || "1.5rem"};

    svg {
      height: ${props => props.fontSize || "1rem"};
      width: ${props => props.fontSize || "1rem"};
    }
  }
  
  ${props => props.showSwitch && `
    .ant-collapse-header {
      display: flex;
      align-items: center;
      .header-switch-wrapper {
        display: flex;
        width: 100%;
        justify-content: space-between;
        .switch-wrapper {
          .ant-switch {
            margin-left: 1rem;
          }
        }
      }
    }
  `
  }
  /* display: "flex", alignItems: "center" */

  /* &.ant-collapse-ghost > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box {
    padding-left: 0;
  } */
`

export default AppStyleHolder;
