const getCurrentUserRoles = state => state.Auth.currentUser ? state.Auth.currentUser.roles : [];
const getCurrentUser = state => state.Auth.currentUser;
const getLoading = state => state.Auth.loading;
const getCurrentUserEmail = state => state.Auth.currentUser?.email;
const getCurrentUserDetails = state => state.Auth.currentUser?.details;
const getCurrentUserLoaded = state => state.Auth.currentUserLoaded;
const getCurrentUserLoading = state => state.Auth.currentUserLoading;
const getCurrentUserId = state => state.Auth.currentUser ? state.Auth.currentUser.id : null;
const getUnreadMsg = state => state.Auth.msgcount;
const getDate = state => state.Auth.pgrDate;

export {
  getLoading,
  getCurrentUser,
  getCurrentUserRoles,
  getCurrentUserLoaded,
  getCurrentUserId,
  getCurrentUserEmail,
  getCurrentUserDetails,
  getCurrentUserLoading,
  getUnreadMsg,
  getDate
}
