import asyncComponent from '../helpers/AsyncFunc';
import { ROUTER_URLS } from '../helpers/enumsUrls';

export const beneficiaryRoutes = [
  {
    path: ROUTER_URLS.APP,
    component: asyncComponent(() => import('../components/DashboardSwitcher')),
  },
  {
    path: ROUTER_URLS.USER_NEW_APPLICATION,
    component: asyncComponent(() => import('../pages/Beneficiary/CommonApplicationEdit')),
  },
  {
    path: ROUTER_URLS.USER_EDIT_APPLICATION,
    component: asyncComponent(() => import('../pages/Beneficiary/CommonApplicationEdit')),
  },
  {
    path: ROUTER_URLS.USER_APPLICATION,
    component: asyncComponent(() => import('../pages/Beneficiary/Application')),
  },
  {
    path: ROUTER_URLS.USER_APPLICATIONS,
    component: asyncComponent(() => import('../pages/Beneficiary/Applications')),
  },
  {
    path: ROUTER_URLS.USER_MY_EMAILS,
    component: asyncComponent(() => import('../components/Emails')),
  },
  {
    path: ROUTER_URLS.USER_NEW_EMAIL,
    component: asyncComponent(() => import('../pages/Beneficiary/NewEmail')),
  },
  {
    path: ROUTER_URLS.USER_REGION_FORM,
    component: asyncComponent(() => import('../pages/Beneficiary/RegionForm')),
  },
  {
    path: ROUTER_URLS.MONITORING,
    component: asyncComponent(() => import('../pages//Beneficiary/Monitoring')),
  },
  {
    path: ROUTER_URLS.USER_STATEMENTS,
    component: asyncComponent(() => import('../pages/Beneficiary/Statements')),
  },
  {
    path: ROUTER_URLS.USER_STATEMENT,
    component: asyncComponent(() => import('../pages/Beneficiary/Statement')),
  },
  {
    path: ROUTER_URLS.USER_STATEMENT,
    component: asyncComponent(() => import('../pages/Beneficiary/Statement')),
  },
  {
    path: ROUTER_URLS.USER_EDIT_STATEMENT,
    component: asyncComponent(() => import('../pages/Beneficiary/EditStatement')),
  },
];

export const adminRoutes = [
  {
    path: ROUTER_URLS.APP,
    component: asyncComponent(() => import('../components/DashboardSwitcher')),
  },
  {
    path: ROUTER_URLS.ADMIN_USERS,
    component: asyncComponent(() => import('../pages/Admin/Users')),
  },
  {
    path: ROUTER_URLS.ADMIN_USERS_DETAILS,
    component: asyncComponent(() => import('../pages/Admin/UserDetails')),
  },
  {
    path: ROUTER_URLS.ADMIN_ELIGIBLE_COSTS,
    component: asyncComponent(() => import('../pages/Admin/EligibleCosts')),
  },
  {
    path: ROUTER_URLS.ADMIN_MY_EMAILS,
    component: asyncComponent(() => import('../components/Emails')),
  },
  {
    path: ROUTER_URLS.ADMIN_USER_APPLICATION,
    component: asyncComponent(() => import('../pages/Admin/Application')),
  },
  {
    path: ROUTER_URLS.ADMIN_USER_APPLICATIONS,
    component: asyncComponent(() => import('../pages/Admin/Applications')),
  },
  {
    path: ROUTER_URLS.APPLICATION_BACKUP,
    component: asyncComponent(() => import('../pages/Admin/ApplicationBackup')),
  },
  {
    path: ROUTER_URLS.MODERATOR_APPLICATION,
    component: asyncComponent(() => import('../pages/Moderator/Application')),
  },
  {
    path: ROUTER_URLS.ADMIN_NEW_EMAIL,
    component: asyncComponent(() => import('../pages/Admin/NewEmail')),
  },
  {
    path: ROUTER_URLS.EMAIL_CHAT_USER,
    component: asyncComponent(() => import('../components/EmailChat')),
  },
  {
    path: ROUTER_URLS.MONITORING,
    component: asyncComponent(() => import('../pages//Admin/Monitoring')),
  },
  {
    path: ROUTER_URLS.ADMIN_STATEMENTS,
    component: asyncComponent(() => import('../pages/Admin/Statements')),
  },
  {
    path: ROUTER_URLS.ADMIN_STATEMENT,
    component: asyncComponent(() => import('../pages/Admin/Statement')),
  },
  {
    path: ROUTER_URLS.STATEMENT_BACKUP,
    component: asyncComponent(() => import('../pages/Admin/StatementBackup')),
  },
];

export const statementVerifierRoutes = [
  {
    path: ROUTER_URLS.APP,
    component: asyncComponent(() => import('../components/DashboardSwitcher')),
  },
  {
    path: ROUTER_URLS.VERIFIER_STATEMENTS,
    component: asyncComponent(() => import('../pages/StatementVerifier/Statements')),
  },
  {
    path: ROUTER_URLS.VERIFIER_MY_EMAILS,
    component: asyncComponent(() => import('../components/Emails')),
  },
  {
    path: ROUTER_URLS.VERIFIER_STATEMENT,
    component: asyncComponent(() => import('../pages/StatementVerifier/Statement')),
  },
];

export const statementApproverRoutes = [
  {
    path: ROUTER_URLS.APP,
    component: asyncComponent(() => import('../components/DashboardSwitcher')),
  },
  {
    path: ROUTER_URLS.APPROVER_STATEMENTS,
    component: asyncComponent(() => import('../pages/StatementApprover/Statements')),
  },
  {
    path: ROUTER_URLS.APPROVER_STATEMENT,
    component: asyncComponent(() => import('../pages/StatementApprover/Statement')),
  },
  {
    path: ROUTER_URLS.APPROVER_MY_EMAILS,
    component: asyncComponent(() => import('../components/Emails')),
  },
];

export const inspectorRoutes = [
  {
    path: ROUTER_URLS.APP,
    component: asyncComponent(() => import('../components/DashboardSwitcher')),
  },
  {
    path: ROUTER_URLS.INSPECTOR_STATEMENTS,
    component: asyncComponent(() => import('../pages/Inspector/Statements')),
  },
  {
    path: ROUTER_URLS.INSPECTOR_STATEMENT,
    component: asyncComponent(() => import('../pages/Inspector/Statement')),
  },
  {
    path: ROUTER_URLS.INSPECTOR_EDIT_STATEMENT,
    component: asyncComponent(() => import('../pages/Inspector/Statement')),
  },
  {
    path: ROUTER_URLS.INSPECTOR_MY_EMAILS,
    component: asyncComponent(() => import('../components/Emails')),
  },
];

export const moderatorRoutes = [
  {
    path: ROUTER_URLS.APP,
    component: asyncComponent(() => import('../components/DashboardSwitcher')),
  },
  {
    path: ROUTER_URLS.MODERATOR_APPLICATIONS_LIST,
    component: asyncComponent(() => import('../pages/Moderator/Applications')),
  },
  {
    path: ROUTER_URLS.MODERATOR_APPLICATION,
    component: asyncComponent(() => import('../pages/Moderator/Application')),
  },
];

export const verifierRoutes = [
  {
    path: ROUTER_URLS.APP,
    component: asyncComponent(() => import('../components/DashboardSwitcher')),
  },
  {
    path: ROUTER_URLS.VERIFIER_APPLICATIONS_LIST,
    component: asyncComponent(() => import('../pages/Verifier/Applications')),
  },
  {
    path: ROUTER_URLS.VERIFIER_APPLICATION,
    component: asyncComponent(() => import('../pages/Verifier/Application')),
  },
  {
    path: ROUTER_URLS.VERIFIER_STATEMENTS,
    component: asyncComponent(() => import('../pages/Verifier/Statements')),
  },
  {
    path: ROUTER_URLS.VERIFIER_STATEMENT,
    component: asyncComponent(() => import('../pages/Verifier/Statement')),
  },
];

export const controllerRoutes = [
  {
    path: ROUTER_URLS.APP,
    component: asyncComponent(() => import('../components/DashboardSwitcher')),
  },
  {
    path: ROUTER_URLS.CONTROLLER_STATEMENTS_LIST,
    component: asyncComponent(() => import('../pages/Controller/Statements')),
  },
  {
    path: ROUTER_URLS.CONTROLLER_STATEMENT,
    component: asyncComponent(() => import('../pages/Controller/Statement')),
  },
];

export const commonRoutes = [
  {
    path: ROUTER_URLS.MY_LOGS,
    component: asyncComponent(() => import('../components/Logs')),
  },
  {
    path: ROUTER_URLS.MY_ACCOUNT,
    component: asyncComponent(() => import('../components/Account')),
  },
  {
    path: ROUTER_URLS.EMAIL_CHAT,
    component: asyncComponent(() => import('../components/EmailChat')),
  },
];
