import actions from './actions';

const initState = {
  isLoading: false,
  isLoadingSingleApplication: false,
  isSaving: false,
  isSending: false,
  isUploading: false,
  applications: [],
  application: {},
  total: null,
  filters: {}
};

export default function reducer(state = initState, { type, payload }) {
  switch (type) {
    case actions.LOAD_APPLICATIONS:
      return {
        ...state,
        isLoading: true,
      };
    case actions.LOAD_APPLICATIONS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        applications: payload.data.data,
        total: payload.data.total,
        signed: payload.data.all,
        allowedApplications: payload.data.allowed_applications
      };
    case actions.LOAD_APPLICATIONS_ERROR:
      return {
        ...state,
        isLoading: false,
      };
    case actions.LOAD_APPLICATION:
      return {
        ...state,
        isLoadingSingleApplication: true,
      };
    case actions.LOAD_APPLICATION_SUCCESS:
      return {
        ...state,
        isLoadingSingleApplication: false,
        application: { ...payload.data },
      };
    case actions.LOAD_APPLICATION_ERROR:
      return {
        ...state,
        isLoadingSingleApplication: false,
      };
    case actions.LOAD_USER_APPLICATION:
      return {
        ...state,
        isLoadingSingleApplication: true,
      };
    case actions.SAVE_APPLICATION:
      return {
        ...state,
        isSaving: true,
      };
    case actions.SAVE_APPLICATION_SUCCESS:
      return {
        ...state,
        isSaving: false,
      };
    case actions.SAVE_APPLICATION_ERROR:
      return {
        ...state,
        isSaving: false,
      };

    case actions.SEND_APPLICATION:
      return {
        ...state,
        isSending: true,
      };
    case actions.SEND_APPLICATION_SUCCESS:
      return {
        ...state,
        isSending: false,
      };
    case actions.SEND_APPLICATION_ERROR:
      return {
        ...state,
        isSending: false,
      };
    case actions.SAVE_APPLICATION_BY_MODERATOR:
      return {
        ...state,
        isSending: true,
      };
    case actions.SAVE_APPLICATION_FILE:
      return {
        ...state,
        isUploading: true,
      };
    case actions.SAVE_APPLICATION_FILE_SUCCESS:
      return {
        ...state,
        isUploading: false,
      };
    case actions.SAVE_APPLICATION_FILE_ERROR:
      return {
        ...state,
        isUploading: false,
      };

    case actions.SAVE_APPLICATION_FILE_VERIFIER:
      return {
        ...state,
        isUploading: true
      };
    case actions.SAVE_APPLICATION_FILE_VERIFIER_SUCCESS:
      return {
        ...state,
        isUploading: false,
      };
    case actions.SAVE_APPLICATION_FILE_VERIFIER_ERROR:
      return {
        ...state,
        isUploading: false,
      };
    case actions.SAVE_ADMIN_EDIT:
      return {
        ...state,
        isSaving: true,
      };
    case actions.SAVE_ADMIN_EDIT_SUCCESS:
      return {
        ...state,
        isSaving: false,
      };
    case actions.SAVE_ADMIN_EDIT_ERROR:
      return {
        ...state,
        isSaving: false,
      };
    case actions.CLEAR_APPLICATION:
      return {
        ...state,
        application: undefined,
      };
    case actions.SAVE_FILTERS:
      return {
        ...state,
        filters: payload.filters,
      };
    case actions.CORRECT_SIGNED_FILES:
      return {
        ...state,
        isUploading: true,
      };
    case actions.REJECT_APPLICATION_FILES:
      return {
        ...state,
        isSaving: true,
      };
    case actions.REJECT_APPLICATION_FILES_SUCCESS:
      return {
        ...state,
        isSaving: false,
      };
    case actions.REJECT_APPLICATION_FILES_ERROR:
      return {
        ...state,
        isSaving: false,
      };
    default:
      return state;
  }
}
