const pl = {
  YES: 'Tak',
  NO: 'Nie',
  DELETE: 'Usuń',
  CANCEL: 'Anuluj',
  ID: 'Id',
  ARE_YOU_SURE: 'Czy na pewno?',
  SUBMIT: 'Zatwierdź',
  NO_DATA: 'brak danych',
  EMPTY: 'brak',
  UPLOAD_FILE: 'Wybierz pliki',

  MENU_DASHBOARD: 'Użytkownicy',
  MENU_LANGUAGES: 'Języki',
  MENU_SETTINGS: 'Ustawienia',
  MENU_USERS: 'Użytkownicy',
  MENU_ELIGIBLE_COSTS: 'Koszty kwalifikowane',
  MENU_EMAILS: 'Wiadomości',
  MENU_LOGS: 'Logi',
  MENU_ACCOUNT: 'Ustawienia konta',
  MENU_APPLICATIONS: 'Wnioski',
  MENU_STATEMENTS: 'Rozliczenia',

  TABLE_SHOWING: 'Pokazane',
  TABLE_OF: 'z',
  TABLE_RESULTS: 'wyników',
  TABLE_NO_DATA: 'Brak Danych',
  TABLE_SURE_TO_CANCEL: 'Na pewno chcesz anulować?',
  TABLE_SEARCH: 'Szukaj',
  TABLE_RESET: 'Reset',

  CRUD_ADD_NEW: 'Dodaj nowy rekord',
  CRUD_SAVE: 'Zapisz',
  CRUD_UPDATE: 'Aktualizuj',
  CRUD_CANCEL: 'Anuluj',
  CRUD_CLOSE: 'Zamknij',
  CRUD_EDIT: 'Edycja',
  CRUD_ADD: 'Dodaj nowy',
  CRUD_ARE_YOU_SURE: 'Czy na pewno chcesz usunąć wybrany wiersz?',
  CRUD_ARE_YOU_SURE_BLOCK: 'Czy na pewno chcesz zablokować tego użytkownika?',
  CRUD_ACTIONS: 'Akcje',
  CRUD_ADD_RECORD_SUCCESS: 'Pomyślnie dodano nowy rekord',
  CRUD_EDIT_RECORD_SUCCESS: 'Edycja zakończona pomyślnie',
  CRUD_REMOVE_RECORD_SUCCESS: 'Pomyślnie usunięto rekord',
  CRUD_REMOVE_CHAT_THREAD_SUCCESS: 'Pomyślnie usunięto wątek',

  CRUD_ADD_FILE_SUCCESS: 'Pomyślnie dodano plik',
  CRUD_ADD_FILE_ERROR: 'Plik nie został dodany',
  CRUD_REMOVE_FILE_SUCCESS: 'Pomyślnie usunięto plik',
  CRUD_SEND_SETTLEMENT_SUCCESS: 'Rozliczenie zostało przesłane',

  MODERATORS_SAVE: 'Przypisano moderatorów do wniosków',

  FORMS_EMAIL: 'E-mail',
  FORMS_FIRST_NAME: 'Imię',
  FORMS_LAST_NAME: 'Nazwisko',
  FORMS_OLD_PASSWORD: 'Stare hasło',
  FORMS_PASSWORD: 'Hasło',
  FORMS_CONFIRM_PASSWORD: 'Potwierdź hasło',
  FORMS_NAME: 'Nazwa',
  FORMS_NAME_TOO_SHORT: 'Nazwa musi mieć 3 znaki',
  FORMS_TEXT_TOO_SHORT: 'Pole musi mieć 3 znaki',
  FORMS_NAME_TOO_LONG: 'Nazwa nie może mieć więcej niż 180 znaków',
  FORMS_PHONE_NUMBER: 'Numer telefonu (np. 42 123 1234, 466 799 799)',
  FORMS_TERMS_AND_CONDITIONS: 'Akceptuję warunki korzystania z serwisu',
  FORMS_FIELD_WRONG_NAME: 'Ta nazwa jest zarezerwowana',
  FORMS_FIELD_REQUIRED: 'To pole jest wymagane',
  FORMS_FIELD_POSITIVE: 'To pole jest musi mieć dodatnią wartość',
  FORMS_TERYT_NOT_CORRECT: 'Podany TERYT jest niepoprawny',
  FORMS_EMAIL_NOT_CORRECT: 'Podany e-mail jest niepoprawny',
  FORMS_PHONE_NOT_CORRECT: 'Numer telefonu jest niepoprawny',
  FORMS_POSTAL_CODE_NOT_CORRECT: 'Kod pocztowy jest niepoprawny',
  FORMS_PASSWORD_NOT_CORRECT: 'Hasło musi zawierać co najmniej jedną dużą, małą literę i jedną cyfrę',
  FORMS_NOT_SAME_PASSWORD: 'Hasła muszą być takie same',
  FORMS_ACCOUNT_INFO_TITLE: 'Dane konta',
  FORMS_CHANGE_PASSWORD_TITLE: 'Zmiana hasła',
  FORMS_NEW_PASSWORD: 'Nowe hasło',
  FORMS_CONFIRM_NEW_PASSWORD: 'Potwierdź nowe hasło',
  FORMS_CURRENT_PASSWORD: 'Obecne hasło',
  FORMS_CHANGE_PASSWORD: 'Zmień hasło',
  FORMS_MAX_COST: 'Sugerowany koszt',
  FORMS_CATEGORY: 'Kategoria',
  FORMS_NOT_NUMBER: 'Wartość musi być liczbą',
  FORMS_COST: 'Koszt (w PLN)',
  FORMS_QTY: 'Liczba sztuk',
  FORMS_NOTE: 'Uwagi',
  FORMS_SINGLE_NOTE: 'Uwaga',
  FORMS_REGION_NAME: 'Nazwa',
  FORMS_REGION_STREET: 'Nazwa ulicy',
  FORMS_REGION_STREET_NO: 'Numer budynku',
  FORMS_REGION_CITY: 'Miasto',
  FORMS_REGION_ZIP_CODE: 'Kod pocztowy',
  FORMS_FILE: 'Dodaj załączniki',
  FORMS_ELIGIBLE_COSTS_ID: 'Koszt kwalifikowany',
  FORMS_ACTIVATION_CODE: 'TERYT',
  FORMS_APPLICANT_NAME: 'Nazwa Wnioskodawcy',
  FORMS_APPLICANT_SHORTCUT: 'Skrócona nazwa Wnioskodawcy',
  FORMS_COUNTRY: 'Kraj',
  FORMS_VOIVODESHIP: 'Województwo',
  FORMS_DISTRICT: 'Powiat',
  FORMS_MUNICIPALITY: 'Gmina',
  FORMS_ENTITY: 'Jednostka',
  FORMS_CITY: 'Miejscowość',
  FORMS_STREET: 'Ulica',
  FORMS_STREET_NUMBER: 'Nr budynku',
  FORMS_FLAT_NUMBER: 'Nr lokalu',
  FORMS_POSTAL_CODE: 'Kod pocztowy',
  FORMS_EMAIL_ADDRESS: 'Adres e-mail',
  FORMS_EPUAP_ADDRESS: 'Adres ePUAP',
  'FORMS_REGION.VOIVODESHIP': 'Województwo',
  'FORMS_REGION.DISTRICT': 'Powiat',
  'FORMS_REGION.MUNICIPALITY': 'Gmina',
  'FORMS_REGION.ENTITY': 'Jednostka',
  'FORMS_REGION.CITY': 'Miejscowość',
  'FORMS_REGION.NAME': 'Gmina',
  'FORMS_REGION.STREET': 'Ulica',
  'FORMS_REGION.STREET_NUMBER': 'Nr budynku',
  'FORMS_REGION.FLAT_NUMBER': 'Nr lokalu',
  'FORMS_REGION.POSTAL_CODE': 'Kod pocztowy',
  'FORMS_DETAILS.EPUAP_ADDRESS': 'Adres ePUAP',
  'FORMS_DETAILS.PHONE_NUMBER': 'Numer telefonu',
  'FORMS_DETAILS.NIP': 'NIP',
  'FORMS_DETAILS.REGON': 'REGON',
  FORMS_SEARCH: 'Wyszukaj',
  FORMS_STAGE: 'Etap',
  FORMS_VERIFIER_ROLE: 'Rola',
  FORMS_STATUS: 'Status',
  FORMS_CONTACT_FIRST_NAME: 'Imię',
  FORMS_CONTACT_LAST_NAME: 'Nazwisko',
  FORMS_CONTACT_EMAIL: 'Adres e-mail',
  FORMS_CONTACT_PHONE: 'Numer telefonu (np. 42 123 1234, 466 799 799)',
  FORMS_CONTACT_POSITION: 'Stanowisko',
  'FORMS_DETAILS.CONTACT_FIRST_NAME': 'Imię',
  'FORMS_DETAILS.CONTACT_LAST_NAME': 'Nazwisko',
  'FORMS_DETAILS.CONTACT_EMAIL': 'Adres e-mail',
  'FORMS_DETAILS.CONTACT_PHONE': 'Numer telefonu',
  'FORMS_DETAILS.CONTACT_POSITION': 'Stanowisko',
  FORMS_ELIGIBLE_COST_ID: 'Koszt kwalifikowany',
  FORMS_DESCRIPTION: 'Koncepcja realizacji grantu',
  FORMS_DESCRIPTION_NOTE: 'Odpowiedź do uwagi na koncepcję',
  FORMS_SIGN_FILE: 'Wgraj podpisane dokumenty',
  FORMS_NIP: 'NIP (np. 0123456789)',
  FORMS_REGON: 'REGON (np. 123456789)',
  FORMS_NIP_NOT_CORRECT: 'NIP jest niepoprawny',
  FORMS_REGON_NOT_CORRECT: 'REGON jest niepoprawny',
  FORMS_ACCOUNT_NUMBER_NOT_CORRECT: 'Numer konta jest niepoprawny',
  FORMS_ACCOUNT_NUMBER: 'Numer konta bankowego',
  FORMS_BANK_ACCOUNT: 'Numer konta bankowego',
  FORMS_ACCOUNT_NUMBER_NOTE: 'Odpowiedź do pola numer konta bankowego',
  FORMS_TYPE: 'Typ',
  FORMS_TITLE: 'Tytuł',
  FORMS_QTY_FEMALE: 'Liczba kobiet',
  FORMS_QTY_MALE: 'Liczba mężczyzn',
  'FORMS_REGION.EMAIL_ADDRESS': 'Email (z bazy teleadresowej)',
  FORMS_REPRESENTATIVE_FIRST_NAME1: 'Imię',
  FORMS_REPRESENTATIVE_LAST_NAME1: 'Nazwisko',
  FORMS_REPRESENTATIVE_POSITION1: 'Pozycja',
  FORMS_REPRESENTATIVE_FIRST_NAME_RESPONSE: 'Odpowiedź do pola Imię',
  FORMS_REPRESENTATIVE_LAST_NAME_RESPONSE: 'Odpowiedź do pola Nazwisko',
  FORMS_REPRESENTATIVE_POSITION_RESPONSE: 'Odpowiedź do pola Pozycja',
  FORMS_REPRESENTATIVE_FIRST_NAME1_NOTE: 'Uwagi do pola Imię',
  FORMS_REPRESENTATIVE_LAST_NAME1_NOTE: 'Uwagi do pola Nazwisko',
  FORMS_REPRESENTATIVE_POSITION1_NOTE: 'Uwagi do pola Pozycja',

  FORMS_REPRESENTATIVE_FIRST_NAME2: 'Imię',
  FORMS_REPRESENTATIVE_LAST_NAME2: 'Nazwisko',
  FORMS_REPRESENTATIVE_POSITION2: 'Pozycja',
  FORMS_REPRESENTATIVE_FIRST_NAME2_NOTE: 'Uwagi do pola Imię',
  FORMS_REPRESENTATIVE_LAST_NAME2_NOTE: 'Uwagi do pola Nazwisko',
  FORMS_REPRESENTATIVE_POSITION2_NOTE: 'Uwagi do pola Pozycja',

  FORMS_REPRESENTATIVE_FIRST_NAME3: 'Imię',
  FORMS_REPRESENTATIVE_LAST_NAME3: 'Nazwisko',
  FORMS_REPRESENTATIVE_POSITION3: 'Pozycja',
  FORMS_REPRESENTATIVE_FIRST_NAME3_NOTE: 'Uwagi do pola Imię',
  FORMS_REPRESENTATIVE_LAST_NAME3_NOTE: 'Uwagi do pola Nazwisko',
  FORMS_REPRESENTATIVE_POSITION3_NOTE: 'Uwagi do pola Pozycja',

  FORMS_MODERATOR: 'Moderator',
  FORMS_MODERATOR_NO_ONE: 'Nieprzypisane',
  FORMS_FROM: 'Od',
  FORMS_TO: 'Do',
  FORMS_POSITIVE: 'Wartość musi być większa od zera',
  ADMIN_EDITED_VALUE: 'Wartość zmieniona przez weryfikatora',

  NO_DAYS_LEFT: 'termin upływa dzisiaj',
  AFTER_DEADLINE: 'po terminie:',
  TODAY: 'dziś',
  APPLICATION_USER_DAYS_LEFT: 'Termin rozliczenia wniosku o grant w konkursie "Granty PPGR" kończy się',

  FORMS_ID: 'Id',
  FORMS_START_DATE: 'Data od',
  FORMS_END_DATE: 'Data do',
  FORMS_PAID: 'Opłacone',
  FORMS_PAID_YES: 'Opłacone',
  FORMS_PAID_NO: 'Nie opłacone',
  FORMS_ROUND: 'Runda',
  FORMS_INTERNAL_NOTE: 'Nota wewnętrzna',
  FORMS_INTERNAL_NOTE_YES: 'Występuje nota wewnętrzna',
  FORMS_INTERNAL_NOTE_NO: 'Brak noty wewnętrznej',
  FORMS_CONFIRM_INTERNAL_NOTE: 'Czy na pewno chcesz odznaczyć pole noty wewnętrznej?',

  ADD_NEW_REPRESENATTIVE: 'Dodaj nową osobę upoważnioną do reprezentacji',

  AUTH_SIGNIN: 'Zaloguj',
  AUTH_LOGOUT: 'Wyloguj',
  AUTH_SIGNUP: 'Zarejestruj',
  AUTH_SIGNUP_SUCCESS: 'Na podany adres e-mail został wysłany link aktywacyjny',
  AUTH_ACTIVATE_SUCCESS: 'Pomyślnie aktywowano konto. Możesz zalogować się',
  AUTH_CONFIRM: 'Potwierdź',
  AUTH_FORGOT_PASSWORD: 'Zapomniałem hasło',
  AUTH_FORGOT_PASSWORD_SUCCESS: 'Link do resetu hasła został wysłany na podany adres e-mail',
  AUTH_CREATE_ACCOUNT: 'Załóż konto',
  AUTH_HAVE_ACCOUNT_ALREADY: 'Posiadasz już konto? Zaloguj się',
  AUTH_FORGOT_PASSWORD_SUBTITLE: 'Zapomniałeś hasła?',
  AUTH_FORGOT_PASSWORD_DESCRIPTION: 'Wprowadź adres e-mail na który zostało zarejestrowane konto.',
  AUTH_BACK_TO_SIGNIN: 'Powrót do strony logowania',
  AUTH_RESET_PASSWORD_SUBTITLE: 'Ustal nowe hasło',
  AUTH_RESET_PASSWORD_DESCRIPTION: 'Wpisz hasło i je potwierdź',
  AUTH_RESET_PASSWORD_SUCCESS: 'Hasło zostało ustawione. Możesz zalogować się',
  AUTH_LOGGED: 'Jesteś zalogowany jako: ',

  USERS_TITLE: 'Zarządzanie użytkownikami',
  USERS_ADD_USER: 'Dodaj użytkownika',
  USERS_EDIT_USER: 'Edytuj użytkownika',
  USERS_DELETE_USER: 'Usuń wiersz',
  USERS_DETAILS_USER: 'Szczegóły użytkownika',
  USERS_NAME: 'Imię',
  USERS_LAST_NAME: 'Nazwisko',
  USERS_EMAIL: 'E-mail',
  USERS_PHONE_NUMBER: 'Numer telefonu',
  USERS_REGON: 'REGON',
  USERS_NIP: 'NIP',
  USERS_EMAIL_VERIFIED_AT: 'E-mail zweryfikowany dn.',
  USERS_BLOCKED: 'Zablokowany',
  USERS_LICENSE_NAME: 'Nazwa licencji',
  USERS_ROLE: 'Rola',
  USERS_LANGUAGE: 'Język',
  USERS_ADMIN_ROLE: 'Admin',
  USERS_CLIENT_ROLE: 'Klient',
  USERS_DISTRIBUTOR_ROLE: 'Dystrybutor',
  USERS_SERVICE_ROLE: 'Serwisant',
  USERS_USER_BLOCKED: 'Użytkownik zablokowany',
  USERS_USER_UNBLOCKED: 'Użytkownik odblokowany',
  USERS_ACTIVATION_CODE: 'Region',
  USERS_REGION: 'Region',
  USERS_TERYT: 'TERYT',
  USERS_BLOCK: 'Zablokuj użytkownika',
  USERS_BLOCK_SUCCESS: 'Użytkownik został zablokowany',
  USERS_UNBLOCK_SUCCESS: 'Użytkownik został odblokowany',
  USERS_ACTIVATE: 'Aktywuj użytkownika',
  USERS_ACTIVATE_SUCCESS: 'Użytkownik został aktywowany',
  USERS_INDEX: 'Lp.',

  USER_DETAILS_TITLE: 'Dane o użytkowniku',
  USER_DETAILS_DETAILS: 'Dane',
  USER_DETAILS_LOGS: 'Logi',
  USER_DETAILS_REQUESTS: 'Wnioski',
  USER_DETAILS_EMAILS: 'Wiadomości',

  USER_DETAILS_DETAILS_EMAIL: 'Adres e-mail',
  USER_DETAILS_DETAILS_ROLE: 'Rola',
  USER_DETAILS_DETAILS_IS_BLOCKED: 'Czy zablokowany',
  USER_DETAILS_DETAILS_ACTIVATION_DATE: 'Data aktywacji',
  USER_DETAILS_DETAILS_ACTIVATION_DATE_NO_DATE: 'Brak aktywacji',

  USER_DETAILS_TITLE_GENERAL: 'Dane ogólne',
  USER_DETAILS_TITLE_DETAILED: 'Dane szczegółowe',

  USER_PAYMENT_HISTORY: 'Historia płatności:',
  USER_VEHICLES: 'Pojazdy i maszyny:',

  ELIGIBLE_COSTS_TITLE: 'Koszty kwalifikowane',
  ELIGIBLE_COSTS_MAX_COST: 'Maksymalny koszt',
  ELIGIBLE_COSTS_CATEGORY: 'Kategoria',
  ELIGIBLE_COSTS_ADD: 'Dodaj nowy koszt kwalifikowany',

  APPLICATION_NEW: 'Nowy wniosek',
  APPLICATION_EDIT: 'Edytuj wniosek',
  APPLICATION_TITLE: 'Szczegóły wniosku',
  APPLICATION_NAME: 'Nazwa',
  APPLICATION_STREET: 'Nazwa ulicy',
  APPLICATION_STREET_NO: 'Numer budynku',
  APPLICATION_CITY: 'Miasto',
  APPLICATION_ZIP_CODE: 'Kod pocztowy',
  APPLICATION_FILE: 'Dodaj załączniki',
  APPLICATION_NO_COST: 'Brak kosztów w tej kategorii',
  APPLICATION_ADD_NEW_COST: 'Dodaj nowy koszt',
  APPLICATION_ELIGIBLE_COST: 'Koszt kwalifikowany',
  APPLICATION_COST: 'Koszt',
  APPLICATION_SAVE: 'Zapisz wniosek',
  APPLICATION_USED_TOO_MANY: 'Kwota dofinansowania jest mniejsza',
  APPLICATION_ELIGIBLE_COST_MAX_COST: 'Sugerowany koszt',
  APPLICATION_ELIGIBLE_COST_MAX_SUM: 'Wykorzystano',
  APPLICATION_ELIGIBLE_COST_VAT: 'w tym VAT',
  APPLICATION_ADVISE_SUM: 'Suma usług doradczych',
  APPLICATION_ADVISE_MAX_SUM: 'Maks.',

  APPLICATION_QUANTITY: 'Liczba',
  APPLICATION_NOTE: 'Uwagi',
  APPLICATION_NOTE_MODERATOR: 'Uwagi weryfikatora',
  APPLICATION_NOTE_MODERATOR_ALL: 'Uwagi weryfikatora do całego wniosku',
  APPLICATION_NOTE_INSPECTOR_ALL: 'Uwagi kontrolera do przesłanych dokumentów',
  APPLICATION_NOTE_MODERATOR_FOR_MODERATOR: 'Uwagi wewnętrzne dla innego weryfikatora',
  APPLICATION_MAX_COST: 'Dofinansowanie',
  APPLICATION_USE_ALL_MONEY: 'Kwota dofinansowania jest większa',
  APPLICATION_DONATION: 'Maks. wartość grantu dla gminy:',
  APPLICATION_DONATION_VOIVODESHIP: 'Maks. wartość grantu dla województwa:',
  APPLICATION_SUMMARY: 'Podsumowanie',
  APPLICATION_TOO_LITTLE_MONEY: 'Masz jeszcze dostępne środki z dofinansowania. Postaraj się je wydać.',
  APPLICATION_TO_SPEND: 'Do wykorzystania zostało:',
  APPLICATION_SPENT_ABOVE: 'Kwota dofinansowania została przekroczona o:',
  APPLICATION_SENT: 'Wyślij wniosek',
  APPLICATION_SENT_ACCEPTED: 'Zaakceptuj wniosek',
  APPLICATION_SENT_REJECTED: 'Odrzuć wniosek z uwagami',
  APPLICATION_SENT_CANCELED: 'Odrzuć definitywnie wniosek',
  APPLICATION_UPLOAD_FILE: 'Twój wniosek został zaakceptowany',
  APPLICATION_SEND_FILES: 'Wyślij pliki',
  APPLICATION_SENDING_FILES: 'Wyślij pliki',
  APPLICATION_USER_ROUND: 'Jesteś w rundzie numer',
  APPLICATION_USER_ROUND_DESC: 'Wniosek możesz składać w terminie:',
  APPLICATION_USER_NEXT_ROUND: 'Zostałeś zakwalifikowany do jednej z następnych rund',
  APPLICATION_USER_NEXT_ROUND_DESC: 'Informacje o następnych rundach podamy wkrótce',
  APPLICATION_OWNER: 'Jednostka',
  APPLICATION_OWNER_NAME: 'Autor',
  APPLICATION_REJECTED_INFO_TITLE: 'Wniosek oczekuje na aktualizację',
  APPLICATION_REJECTED_INFO_TXT:
    'Wniosek zawiera notatki od weryfikatora, przejdź do edycji wniosku, by go zaktualizować i wysłać ponownie.',
  APPLICATION_CONTACT_PERSON: 'Osoba upoważniona do kontaktu',
  APPLICATION_CONCEPTION: 'Przedstaw koncepcję realizacji grantu',
  APPLICATION_CONFIRM: 'Jesteś pewny?',
  APPLICATION_CONFIRM_TXT: 'Po wysłaniu wniosku, nie będzie można już go edytować. Zostanie przesłany do weryfikacji.',
  APPLICATION_RESPONSE: 'Odpowiedź: ',
  APPLICATION_CONFIRM_SEND: 'Czy na pewno chcesz wysłać wniosek?',
  APPLICATION_CONFIRM_ACCEPTED: 'Czy na pewno chcesz zaakceptować wniosek?',
  APPLICATION_CONFIRM_REJECTED: 'Czy na pewno chcesz odrzucić wniosek?',
  APPLICATION_CONFIRM_CANCELED: 'Czy na pewno chcesz odrzucić definitywnie wniosek?',
  APPLICATION_CONFIRM_INSPECTION: 'Czy na pewno chcesz przekazać wniosek do kontroli?',
  APPLICATION_TO_VERIFY_TITLE: 'Wniosek wymaga weryfikacji',
  APPLICATION_TO_VERIFY_TXT: 'Pobierz dokumenty mianowania',
  APPLICATION_VERIFY_BUTTON: 'Oznacz jako zweryfikowane',
  APPLICATION_DOWNLOAD_APPLICATION: 'Pobierz wniosek',
  APPLICATION_DOWNLOAD_APPLICATIONS: 'Pobierz wnioski',
  APPLICATION_DOWNLOAD_REPORT: 'Pobierz raport',
  APPLICATION_DOWNLOAD_GENERAL_REPORT: 'Pobierz raport zaakceptowanych wniosków',
  APPLICATION_DOWNLOAD_PGR_REPORT: 'Wszystkie wnioski PGR',
  APPLICATION_DOWNLOAD_CG_REPORT: 'Wszystkie wnioski CG',
  APPLICATION_DOWNLOAD_RANKING_PGR: 'Lista rankingowa PGR',
  APPLICATION_DOWNLOAD_RANKING_CG: 'Lista rankingowa CG',
  APPLICATION_DOWNLOAD_CG_ACTIONS: 'Raport działań CG',
  APPLICATION_DOWNLOAD_NIP_REPORT: 'Lista NIPów do kwestury',
  APPLICATION_DOWNLOAD_PAYMENT_CG_CSV: 'Polecenie wypłaty (CG, CSV)',
  APPLICATION_DOWNLOAD_PAYMENT_CP_CSV: 'Polecenie wypłaty (CP, CSV)',
  APPLICATION_DOWNLOAD_PAYMENT_CG_DOC: 'Polecenie wypłaty (CG/CP, DOC)',
  APPLICATION_DOWNLOAD_PAYMENT_PGR_CSV: 'Polecenie wypłaty (PGR, CSV)',
  APPLICATION_DOWNLOAD_PAYMENT_PGR_DOC: 'Polecenie wypłaty (PGR, DOC)',
  APPLICATION_UPLOAD_FILE_VERIFIER: 'Aby zaakceptować wniosek, wgraj podpisane pliki umowy:',
  APPLICATION_REJECT_VERIFIER: 'Jeśli masz uwagi do tych dokumentów wpisz uwagi powyżej i odrzuć wniosek:',
  APPLICATION_REPRESENTATIVE1: 'Osoba upoważniona do reprezentacji (podpisania umowy)',
  APPLICATION_REPRESENTATIVE2: 'Druga osoba upoważniona do reprezentacji (podpisania umowy)',
  APPLICATION_REPRESENTATIVE3: 'Trzecia osoba upoważniona do reprezentacji (podpisania umowy)',
  APPLICATION_DOWNLOAD_AGREEMENT: 'Pobierz umowę',
  APPLICATION_DOWNLOAD_APPLICATION_FORM: 'Pobierz wniosek',
  APPLICATION_DOWNLOAD_REGISTRATION_FORM: 'Pobierz formularz rejestracyjny',
  APPLICATION_FILES: 'Pliki zostały przesłane, oczekują na zweryfikowanie i podpisanie przez Grantodawcę',
  APPLICATION_TO_VERIFY_CONFIRMATION_DOWNLOAD: 'Pobierz i zweryfikuj pliki pełnomocnictwa:',
  APPLICATION_TO_VERIFY_SIGNED_DOWNLOAD: 'Pobierz i zweryfikuj pliki podpisanej umowy:',
  APPLICATION_TO_SIGNED_DOWNLOAD: 'Pliki przesłane przez gminę:',
  APPLICATION_TO_CORRECT_DOWNLOAD: 'Przesłane pliki:',
  COUNT_APPLICATIONS: 'Wniosków w sumie',
  APPLICATION_ACCOUNT_NUMBER: 'Numer konta bankowego',
  APPLICATION_CONSENT:
    'Pouczony(-a) o odpowiedzialności za składanie oświadczeń niezgodnych z prawdą, w tym o konieczności zwrotu przyznanego w ramach projektu „Cyfrowa gmina” wsparcia',
  APPLICATION_DISTRICT_CONSENT:
    'Pouczony(-a) o odpowiedzialności za składanie oświadczeń niezgodnych z prawdą, w tym o konieczności zwrotu przyznanego w ramach projektu „Cyfrowy Powiat” wsparcia',
  APPLICATION_VOIVODESHIP_CONSENT:
    'Pouczony(-a) o odpowiedzialności za składanie oświadczeń niezgodnych z prawdą, w tym o konieczności zwrotu przyznanego w ramach konkursu „Cyfrowe Województwo” wsparcia',
  APPLICATION_FREE_AUDIT:
    'Oświadczam, że posiadam zasoby własne, uprawnione do wykonania diagnozy cyberbepieczeństwa, dlatego nie wnioskuję o dofinansowanie kosztu usługi zewnętrznej.',

  APPLICATION_CORRECT_SIGNED_DOCUMENTS: 'Dodaj nowe pliki, jeśli jest taka potrzeba odpowiedz na uwagi i wyślij pliki ponownie:',
  APPLICATION_WITHDRAW: 'Wycofaj wniosek',
  APPLICATION_WITHDRAW_TEXT: 'Załącz pismo podpisane elektronicznie (w PDF), które rozpatrzymy w 5 dni roboczych',
  APPLICATION_TO_WITHDRAW_STATUS: 'Wniosek został zgłoszony do wycofania',
  APPLICATION_TO_WITHDRAW_STATUS_TEXT: 'Pobierz plik i zatwierdź wniosek o wycofanie:',
  APPLICATION_TO_WITHDRAW_STATUS_BUTTON: 'Wycofaj wniosek',

  APPLICATION_TERMINATION: 'Odstąpienie od umowy',
  APPLICATION_TERMINATION_TEXT: 'Załącz pismo podpisane elektronicznie (w PDF), które rozpatrzymy w 5 dni roboczych',
  APPLICATION_TO_TERMINATION_STATUS: 'Wniosek został zgłoszony do odstępienia od umowy',
  APPLICATION_TO_TERMINATION_STATUS_TEXT: 'Pobierz plik i zatwierdź wniosek o odstępienie od umowy:',
  APPLICATION_TO_TERMINATION_STATUS_BUTTON: 'Odstąpienie od umowy',

  APPLICATION_TO_RESIGN_STATUS: 'Wniosek został zgłoszony do rezygnacji',
  APPLICATION_TO_RESIGN_STATUS_TEXT: 'Pobierz plik i zatwierdź wniosek o rezygnację:',
  APPLICATION_TO_RESIGN_STATUS_BUTTON: 'Zaakceptuj rezygnację z wniosku',

  APPLICATION_DUPLICATE: 'Duplikowanie wniosku',
  APPLICATION_DETAILS: 'Podgląd wniosku',
  APPLICATION_DOWNLOAD: 'Pobierz wszystkie dokumenty',

  APPLICATION_SIGNED: 'Wniosek został zaakceptowany i podpisany',
  APPLICATION_SIGNED_BENEFICIARY_CONFIRMATION_DOWNLOAD: 'Pełnomocnictwa wysłane przez grantobiorcę',
  APPLICATION_SIGNED_BENEFICIARY_SIGNED_DOWNLOAD: 'Podpisane dokumenty',
  APPLICATION_SIGNED_CPPC_DOWNLOAD: 'Dokumenty przesłane przez grantodawcę',

  APPLICATION_ASSIGN_MODERATOR: 'Przypisz moderatora',
  APPLICATION_REPORT: 'Wygeneruj raport',

  APPLICATION_CHANGE_STATUS: 'AWARYJNIE Zmień status wniosku',
  APPLICATION_CHANGE_FIELD: 'AWARYJNIE Zmień pole wniosku',
  APPLICATION_CHANGE_COST: 'AWARYJNIE Zmień koszt',
  APPLICATION_CHANGE_STATUS_TXT:
    'Tutaj można awaryjnie zmienić status wniosku na dowolny - np cofnąć do poprzedniego statusu przy pomyłce',

  MARK_AS_DELETED: 'Oznacz jako usunięte',
  COST_DELETED_BY_ADMIN: 'Koszt usunięty przez weryfikatora',
  SHOW_HISTORY: 'Pokaż historię',
  HIDE_HISTORY: 'Ukryj historię',

  NEW_APPLICATION_ALERT_TITLE: 'Uwaga',
  NEW_APPLICATION_ALERT_ERRORS_MSG: 'Formularz zawiera błędy, napraw je przed zapisaniem.',
  NEW_APPLICATION_ALERT_ERRORS_FILES_MSG: 'Pamiętaj, aby załączyć opinię KOWR powyżej',

  NEW_APPLICATION_MAX_COST: 'Maksymalna kwota dofinansowania została przekroczona',
  NEW_APPLICATION_ALERT_MAX_COST: 'Zmodyfikuj wniosek, aby móc go zapisać',

  NEW_APPLICATION_ALERT_SENDING: 'Po wysłaniu wniosku zostanie on przekazany do weryfikacji',

  NEW_APPLICATION_CONFIRMATION_ALERT_TITLE: 'Uwaga',
  NEW_APPLICATION_CONFIRMATION_ALERT_ERRORS_FILES_MSG:
    'Pamiętaj, aby załączyć oświadczenie potwierdzające możliwość ubiegania się o wsparcie',

  NOTIFICATION_VERIFIER_FILE_SIZE_ERROR: 'Plik musi być mniejszy niż 5MB',
  NOTIFICATION_CHAT_FILE_SIZE_ERROR: 'Plik musi być mniejszy niż 54MB',
  NOTIFICATION_VERIFIER_FILES_MAX_COUNT_ERROR: 'Maksymalna ilość plików: 5',
  NOTIFICATION_VERIFIER_FILE_TYPE_ERROR: 'Plik musi mieć odpowiednie rozszerzenie',

  MOB_AUTH_SIGNIN: 'Zaloguj się',
  MOB_AUTH_SIGNIN_QUESTION: 'Posiadasz już konto?',
  MOB_AUTH_SIGNUP: 'Zarejestruj się',
  MOB_AUTH_REGISTER: 'Rejestracja',
  MOB_AUTH_SIGNUP_QUESTION: 'Nie masz jeszcze konta?',
  MOB_AUTH_LOGOUT: 'Wyloguj się',

  ABOUT_APPLICATION: 'Dotyczy wniosku:',

  '404_TITLE': '404',
  '404_SUBTITLE': 'Nie ma takiej strony',
  '404_DESC': 'Strona, której szukasz prawdopodobnie nie istnieje lub została przeniesiona.',
  '404_BACK': 'Powrót do strony głównej',

  ERROR: 'Błąd',
  SMS: 'SMS',
  OTHER: 'Pozostałe',
  MORE: 'Więcej',
  LOGS_TITLE: 'Moje logi',
  LOGS_ID: 'ID',
  LOGS_DESCRIPTION: 'Wykonana akcja',
  LOGS_CAUSER: 'Wykonawca',
  LOGS_SUBJECT: 'Zmodyf. obiekt',
  LOGS_DATE: 'Data',
  LOGS_PROPERTIES: 'Zmienione wartości',
  LOGS_PROPERTIES_OLD: 'Stare wartości',
  LOGS_PROPERTIES_NEW: 'Nowe wartości',

  LOGS_ACTION_CREATED: 'Utworzenie',
  LOGS_ACTION_UPDATED: 'Edycja',
  LOGS_ACTION_DELETED: 'Usunięcie',

  LOG_CAUSER_ME: 'Ja',
  LOG_CAUSER_ADMIN: 'Administrator',

  LOG_OBJECT_APPLICATION: 'Wnioski',
  LOG_OBJECT_APPLICATION_ELIGIBLE_COST: 'Koszta kwalifikowane we wniosku',
  LOG_OBJECT_APPLICATION_FIELD: 'Pole wniosku',
  LOG_OBJECT_APPLICATION_FIELD_FILE: 'Załącznik wniosku',
  LOG_OBJECT_ELIGIBLE_COST: 'Koszty kwalifikowane',
  LOG_OBJECT_FILES: 'Pliki',
  LOG_OBJECT_ROUNDS: 'Rundy',
  LOG_OBJECT_USER: 'Użytkownik',

  EMAILS_TITLE: 'Moje wiadomości',
  EMAILS_SUBJECT: 'Tytuł',
  EMAILS_RECEIVER: 'Odbiorca',
  EMAILS_SENDER: 'Nadawca',
  EMAILS_DATE: 'Data',
  EMAILS_TEXT: 'Treść wiadomości',
  EMAILS_NEW_APPLICATION: 'Wyślij wiadomość',
  EMAILS_SENT: 'Wysłano',
  EMAILS_READ: 'Odczytano',
  EMAILS_UNREAD: 'Nie odczytano',
  EMAILS_TOPIC: 'Kategoria',
  EMAIL_ADMIN: 'Zespół Partnera Projektu',
  EMAIL_ME: 'Ty',
  EMAIL_BENEFICIARY: 'Gmina',
  EMAIL_SYSTEM: 'Wiadomość systemowa',

  APPLICATIONS_TITLE: 'Wnioski',
  APPLICATIONS_ID: 'Id',
  APPLICATIONS_STATUS: 'Status',
  APPLICATIONS_UPDATE_DATE: 'Data modyfikacji',

  APPLICATION_STATUS_DRAFT: 'Szkic',
  APPLICATION_STATUS_NEW: 'Nowy',
  APPLICATION_STATUS_ACCEPTED: 'Zaakceptowany',
  APPLICATION_STATUS_UPDATED: 'Zaktualizowany',
  APPLICATION_STATUS_REJECTED: 'Do poprawy',
  APPLICATION_STATUS_CANCELED: 'Odrzucony',
  APPLICATION_STATUS_TO_VERIFY: 'Do zweryfikowania',
  APPLICATION_STATUS_VERIFIED: 'Kompletny',
  APPLICATION_STATUS_SIGNED: 'Podpisany',
  APPLICATION_STATUS_TO_WITHDRAW: 'Zgłoszony do wycofania',
  APPLICATION_STATUS_WITHDRAWN: 'Wycofany',
  APPLICATION_STATUS_TO_RESIGN: 'Zgłoszony do rezygnacji',
  APPLICATION_STATUS_RESIGNED: 'Rezygnacja',
  APPLICATION_STATUS_TO_TERMINATION: 'Zgłoszony do odstąpienia od umowy',
  APPLICATION_STATUS_TERMINATED: 'Odstąpiono od umowy',
  APPLICATION_STATUS_TO_BE_CORRECTED: 'Dokumenty do poprawy',
  APPLICATION_STATUS_CORRECTED: 'Poprawione dokumenty',
  APPLICATION_STATUS_TO_AMEND: 'Do aneksu',
  APPLICATION_STATUS_AMENDED: 'Aneksowany',
  APPLICATION_STATUS_AMENDMENT_TO_BE_CORRECTED: 'Aneks do poprawy',
  APPLICATION_STATUS_AMENDMENT_CORRECTED: 'Aneks poprawiony',
  APPLICATION_STATUS_AMENDMENT_SIGNED: 'Aneks zaakceptowany',

  STATUS_STATEMENT_DRAFT: 'Szkic rozliczenia',
  STATUS_STATEMENT_DRAFT_SIGNING: 'Szkic rozliczenia - do podpisania',
  STATUS_STATEMENT_NEW: 'Nowe rozliczenie',
  STATUS_STATEMENT_REJECTED: 'Rozliczenie odrzucone do poprawy',
  STATUS_STATEMENT_REJECTED_SIGNING: 'Rozliczenie odrzucone do poprawy - do podpisania',
  STATUS_STATEMENT_INSPECTION_REJECTED_BENEFICIARY: 'W trakcie kontroli - do uzupełnienia',
  STATUS_STATEMENT_INSPECTION_REJECTED_INSPECTOR: 'W trakcie kontroli - oczekiwanie na odpowiedź',
  STATUS_STATEMENT_SAVED: 'Zapisane rozliczenie',
  STATUS_STATEMENT_UPDATED: 'Zaktualizowane rozliczenie',
  STATUS_STATEMENT_INSPECTION_UPDATED_INSPECTOR: 'W trakcie kontroli - odpowiedź przesłana przez Beneficjenta',
  STATUS_STATEMENT_ACCEPTED: 'Zaakceptowane rozliczenie',
  STATUS_STATEMENT_VERIFICATION: 'Wniosek w trakcie weryfikacji',
  STATUS_STATEMENT_VERIFIED: 'Wniosek zweryfikowany',
  STATUS_STATEMENT_APPROVEMENT: 'Wniosek w trakcie zatwierdzania',
  STATUS_STATEMENT_APPROVED: 'Wniosek zatwierdzony',
  STATUS_STATEMENT_INSPECTION_APPROVED_BENEFICIARY: 'Wniosek zatwierdzony',
  STATUS_STATEMENT_TO_VERIFICATION: 'Wniosek w trakcie weryfikacji',
  STATUS_STATEMENT_TO_INSPECTION: 'Skierowany do kontroli',
  STATUS_STATEMENT_WAITING_TO_INSPECTION: 'Zawiadomienie o kontroli',
  STATUS_STATEMENT_INSPECTION_IN_PROGRESS: 'Do kontroli',
  STATUS_STATEMENT_INSPECTION_IN_PROGRESS_BENEFICIARY: 'W trakcie kontroli',
  STATUS_STATEMENT_APPROVED_TO_CORRECTION: 'Wniosek zatwierdzony z korektą',
  STATUS_STATEMENT_VERIFIED_TO_CORRECTION: 'Wniosek zweryfikowany z korektą',
  STATUS_STATEMENT_REJECTED_TO_VERIFICATION: 'Wniosek do ponownej weryfikacji',
  STATUS_STATEMENT_INSPECTION_PREPARING_THE_CALL: 'W trakcie kontroli - przygotowywanie wezwania do wyjaśnień',
  STATUS_STATEMENT_INSPECTION_CALL_FOR_CLARIFICATION: 'W trakcie kontroli - oczekiwanie na wyjaśnienia',
  STATUS_STATEMENT_INSPECTION_CALL_FOR_CLARIFICATION_BENEFICIARY: 'W trakcie kontroli - do wyjaśnienia',
  STATUS_STATEMENT_INSPECTION_CALL_FOR_CLARIFICATION_ANSWERED: 'W trakcie kontroli - wyjaśnienia złożone',
  STATUS_STATEMENT_INSPECTION_END: 'Przygotowywanie informacji pokontrolnej',
  STATUS_STATEMENT_INSPECTION_INFORMATION_POST_INSPECTION: 'Oczekiwanie na akceptację informacji pokontrolnej',
  STATUS_STATEMENT_INSPECTION_INFORMATION_POST_INSPECTION_BENEFICIARY: 'Informacja pokontrolna - do akceptacji',
  STATUS_STATEMENT_INSPECTION_INFORMATION_POST_INSPECTION_ANSWERED:
    'Informacja pokontrolna - odpowiedź przesłana przez Beneficjenta',
  STATUS_STATEMENT_INSPECTION_FINAL_INFORMATION_POST_INSPECTION: 'Oczekiwanie na akceptację ostatecznej informacji pokontrolnej',
  STATUS_STATEMENT_INSPECTION_FINAL_INFORMATION_POST_INSPECTION_BENEFICIARY: 'Ostateczna informacja pokontrolna - do akceptacji',
  STATUS_STATEMENT_INSPECTION_FINAL_INFORMATION_POST_INSPECTION_ANSWERED:
    'Ostateczna informacja pokontrolna - odpowiedź przesłana przez Beneficjenta',
  STATUS_STATEMENT_AFTER_INSPECTION_WITHOUT_RESERVATIONS: 'Kontrola zakończona - bez zastrzeżeń',
  STATUS_STATEMENT_AFTER_INSPECTION_WITH_SIGNIFICANT_RESERVATIONS: 'Kontrola zakończona - z istotnymi zastrzeżeniami',
  STATUS_STATEMENT_AFTER_INSPECTION_WITH_IRRELEVANT_RESERVATIONS: 'Kontrola zakończona - z nieistotnymi zastrzeżeniami',
  STATUS_STATEMENT_TO_CHECK: 'Rozliczenie w kontroli',
  STATUS_STATEMENT_DOCUMENTS_SENT: 'Dokumenty rozliczenia wysłane',
  STATUS_STATEMENT_DOCUMENTS_REJECTED: 'Dokumenty rozliczenia odrzucone',
  STATUS_STATEMENT_DOCUMENTS_UPDATED: 'Dokumenty poprawione',
  STATUS_STATEMENT_CONTROL_ENDED: 'Kontrola zakończona',

  CHAT_INSPECTORS_NOTE: 'Uwagi kontrolera',
  CHAT_NO_NOTE: 'Brak uwag',
  CHAT_THREAD: 'Tytuł uwagi:',
  CHAT_NEW_THREAD: 'Nowa uwaga',
  CHAT_NEW_MESSAGE: 'Uwaga do Rozliczającego:',
  CHAT_NEW_MESSAGE_RESPONSE: 'Odpowiedź Rozliczającego:',
  CHAT_REQUIRED_FILES: 'Oczekuj na załączanie pliku poprzez czat',

  CHAT_ROLE_NAMES_INSPECTOR: 'Kontroler:',
  CHAT_ROLE_NAMES_USER: 'Rozliczający:',

  CHAT_TITLE_PLACEHOLDER: 'Krótko nazwij problem',
  CHAT_MESSAGES_PLACEHOLDER_INSPECTOR: 'Opisz oczekiwania względem Rozliczającego',
  CHAT_MESSAGES_PLACEHOLDER_BENEFICIARY: 'Napisz odpowiedź',
  CHAT_MESSAGES_FILE_REQUIRED: 'Należy załączyć dokument',

  CHAT_ADD_NOTE: 'Dodaj nową uwagę',
  CHAT_SAVE_AND_SEND: 'Zapisz i wyślij',
  CHAT_SAVE_DRAFT: 'Zapisz szkic',
  CHAT_DELETE_DRAFT: 'Usuń uwagę',
  CHAT_DELETE_DRAFT_CONFIRM: 'Czy na pewno chcesz usunąć uwagę?',

  NORMAL_INSPECTION: 'Normalna',
  RANDOM_INSPECTION: 'Z losowania',
  AD_HOC_INSPECTION: 'Doraźna',
  SEND_INSPECTION_NOTICE: 'Wyślij zawiadomienie',
  INSPECTION_ADD_FILE: 'Dodaj podpisany dokument',
  INSPECTION_INFORMATION: 'Kontrola rozpocznie się 5 dni po wysłaniu zawiadomienia. Do tego czasu wniosek zostanie zablokowany',
  INSPECTION_INFORMATION_AD_HOC:
    'Kontrola rozpocznie się 1 dzień po wysłania zawiadomienia. Do tego czasu wniosek zostanie zablokowany',

  APPLICATION_BENEFICIARY_STATUS_NEW: 'Oczekuje na zaakceptowanie przez weryfikatora',
  APPLICATION_BENEFICIARY_STATUS_ACCEPTED: 'Oczekuje na dodatkowe dokumenty',
  APPLICATION_BENEFICIARY_STATUS_UPDATED: 'Zaktualizowany, oczekuje na zaakceptowanie',
  APPLICATION_BENEFICIARY_STATUS_REJECTED: 'Oczekuje na aktualizacje',

  ACCOUNT_TITLE: 'Moje konto',
  ACCOUNT_EDIT_DATA: 'Edytuj dane',
  ACCOUNT_EDIT_PASSWORD: 'Zmień hasło',

  ROLE_NAMES_ADMIN: 'Administrator',
  ROLE_NAMES_MODERATOR: 'Moderator',
  ROLE_NAMES_VERIFIER: 'Weryfikator',
  ROLE_NAMES_APPROVER: 'Weryfikator generalny',
  ROLE_NAMES_INSPECTOR: 'Kontroler',
  ROLE_NAMES_USER: 'Użytkownik',

  REGION_FORM_TITLE: 'Informacje o wnioskodawcy',
  REGION_FORM_ADDRESS_DATA: 'Adres siedziby',
  REGION_FORM_CONTACT_DATA: 'Dane teleadresowe',
  REGION_FORM_APPROVALS_DATA:
    'Pouczony(-a) o odpowiedzialności za składanie oświadczeń niezgodnych z prawdą, w tym o konieczności zwrotu przyznanego w ramach projektu „Cyfrowa Gmina” wsparcia',
  REGION_FORM_APPROVALS_DATA_DISTRICT:
    'Pouczony(-a) o odpowiedzialności za składanie oświadczeń niezgodnych z prawdą, w tym o konieczności zwrotu przyznanego w ramach projektu „Cyfrowy Powiat” wsparcia',
  REGION_FORM_APPROVALS_DATA_VOIVODESHIP:
    'Pouczony(-a) o odpowiedzialności za składanie oświadczeń niezgodnych z prawdą, w tym o konieczności zwrotu przyznanego w ramach projektu „Cyfrowe Województwo” wsparcia',
  REGION_FORM_ALERT_TITLE: 'Uwaga!',
  REGION_FORM_ALERT_MSG: 'Sprawdź dokładnie czy zostały ustawione prawidłowe dane gminy - będzie je potem trudno zmienić.',
  REGION_FORM_DISTRICT_ALERT_MSG:
    'Sprawdź dokładnie czy zostały ustawione prawidłowe dane powiatu - będzie je potem trudno zmienić.',
  REGION_FORM_VOIVODESHIP_ALERT_MSG:
    'Sprawdź dokładnie czy zostały ustawione prawidłowe dane województwa - będzie je potem trudno zmienić.',
  REGION_FORM_ALERT_TOP_MSG: 'Uzupełnij dane, aby przejść dalej.',
  REGION_FORM_ALERT_ERRORS_MSG: 'Formularz zawiera błędy, napraw je przed zapisaniem.',

  APPLICATION_PGR_EQUIPMENT: 'Sprzęt',
  APPLICATION_PGR_BUDGET: 'Budżet',
  APPLICATION_PGR_SUPPORT_NUMBER: 'Proszę o wskazanie szacowanej liczby osób objętych wsparciem',
  APPLICATION_PGR_SCHOOLS: 'Szkoły',
  APPLICATION_PGR_STUDENTS: 'Uczniowie',
  APPLICATION_PGR_FROM: 'od',
  APPLICATION_PGR_TO: 'do',
  APPLICATION_PGR_PC: 'Komputer stacjonarny',
  APPLICATION_PGR_LAPTOP: 'Laptop',
  APPLICATION_PGR_TABLET: 'Tablet',
  APPLICATION_PGR_SOFTWARE: 'Oprogramowanie',
  APPLICATION_PGR_INSURANCE: 'Ubezpieczenie sprzętu',
  APPLICATION_PGR_INTERNET: 'Dostęp do internetu',
  APPLICATION_PGR_ACCESSORIES: 'Akcesoria',
  APPLICATION_PGR_INCLUDES: 'W tym (beneficjenci ostateczni)',
  APPLICATION_PGR_FILE_TITLE: 'Opinia KOWR w zakresie występowania zlikwidowanego PPGR na obszarze danej gminy',
  APPLICATION_PGR_FILE_TXT: 'Załącz plik poniżej. Przyjmujemy tylko PDF.',
  APPLICATION_PGR_FILE_TXT_REPLACE: 'Aby dodać nowe pliki użyj przycisku poniżej. Przyjmujemy tylko PDF.',
  APPLICATION_PGR_CONFIRMATION_FILE_TITLE: 'Oświadczenie gmin potwierdzające możliwość ubiegania się o wsparcie',
  APPLICATION_PGR_CONFIRMATION_FILE_TXT: 'Załącz plik poniżej. Przyjmujemy tylko format XLS (Excel) i PDF.',
  APPLICATION_PGR_CONFIRMATION_FILE_TXT_REPLACE:
    'Aby dodać nowe pliki użyj przycisku poniżej. Przyjmujemy tylko format XLS (Excel) i PDF.',
  FORMS_PGR_PRICE: 'Cena jednostkowa',
  FORMS_PGR_QTY: 'Liczba sztuk',
  FORMS_PGR_SUM: 'Kwota ogółem',
  FORMS_PGR_PARAM: 'Parametry sprzętu',
  APPLICATION_PGR_SUM_LABEL: 'Łącznie',
  APPLICATION_PGR_SUM_TO_SPEND: 'Do rozdysponowania',
  APPLICATION_PGR_VAT_LABEL: 'W tym VAT',
  APPLICATION_NEW_PGR: 'Nowy wniosek',
  FORMS_PGR_MAX_PRICE: 'Kwota maksymalna została przekroczona',
  APPLICATION_TYPE: 'Typ wniosku',

  BACKUP_SHOW: 'Pokaż starsze wersje',
  BACKUP_INFO: 'Wersja z dnia: ',

  ATTACH_CONFIRMATION: 'Dołącz pełnomocnictwo osoby upoważnionej do reprezentacji',
  ATTACH_SIGNED:
    'Proszę sprawdzić poprawność danych w pobranych dokumentach. Proszę do pobranego pliku dołączyć dokument potwierdzający prawo do reprezentacji gminy (pełnomocnictwo/upoważnienie do zawarcia Umowy – osoby wymienione we wniosku), podpisać podpisem kwalifikowanym umowę i wszystkie załączniki, spakować katalog do formatu „.zip” i załączyć poniżej',
  ATTACH_SIGNED_VOIVODESHIP:
    'Proszę sprawdzić poprawność danych w pobranych dokumentach. Proszę do pobranego pliku dołączyć dokument potwierdzający prawo do reprezentacji województwa (pełnomocnictwo/upoważnienie do zawarcia Umowy – osoby wymienione we wniosku), podpisać podpisem kwalifikowanym umowę i wszystkie załączniki, spakować katalog do formatu „.zip” i załączyć poniżej',
  ATTACH_SIGNED_DISTRICT:
    'Proszę sprawdzić poprawność danych w pobranych dokumentach. Proszę do pobranego pliku dołączyć dokument potwierdzający prawo do reprezentacji powiatu (pełnomocnictwo/upoważnienie do zawarcia Umowy – osoby wymienione we wniosku), podpisać podpisem kwalifikowanym umowę i wszystkie załączniki, spakować katalog do formatu „.zip” i załączyć poniżej',
  ATTACH_SIGNED_CORRECTED: 'Dołącz poprawione pliki',
  GENERATE_AGREEMENT_INFO:
    'Proszę sprawdzić poprawność numeru konta bankowego wskazanego w aplikacji grantowej. Gmina musi posiadać indywidualny nr rachunku bankowego dla rozliczania otrzymanego grantu, dlatego proszę o potwierdzenie czy przekazany numer konta jest unikalny dla planowanego do realizacji grantu.',
  GENERATE_AGREEMENT_INFO_DISTRICT:
    'Proszę sprawdzić poprawność numeru konta bankowego wskazanego w aplikacji grantowej. Powiat musi posiadać indywidualny nr rachunku bankowego dla rozliczania otrzymanego grantu, dlatego proszę o potwierdzenie czy przekazany numer konta jest unikalny dla planowanego do realizacji grantu.',
  GENERATE_AGREEMENT_INFO_VOIVODESHIP:
    'Proszę sprawdzić poprawność numeru konta bankowego wskazanego w aplikacji grantowej. Województwo musi posiadać indywidualny nr rachunku bankowego dla rozliczania otrzymanego grantu, dlatego proszę o potwierdzenie czy przekazany numer konta jest unikalny dla planowanego do realizacji grantu.',

  CG_APPLICATION_TITLE: 'Rejestracja wniosków w programie Cyfrowa Gmina jest jeszcze zamknięta',
  CG_APPLICATION_CLOSED: 'Rejestracja wniosków zostanie otwarta 18.10.2021',

  APPLICATION_USER_ROUND_ENDED_TITLE: 'Rejestracja wniosków jest zamknięta',
  APPLICATION_USER_ROUND_ENDED_DESC: 'Rejestracja wniosków dla Twojej rundy została zamknięta',

  CW_APPLICATION_TITLE: 'Rejestracja wniosków w konkursie Cyfrowe Województwo jest już zamknięta',

  CG_APPLICATION_PGR_TITLE: 'Rejestracja wniosków w programie Granty PPGR jest już zamknięta',
  CG_APPLICATION_PGR_TITLE_OPEN:
    'Rejestracja wniosków w grancie PPGR została otwarta. Możliwość składania wniosków zostanie zablokowana 5.11.2021 o godzinie 16:00',

  CRITERIA_1_PGR:
    'Weryfikacji podlega czy Wnioskodawcą jest jednostka samorządu terytorialnego, zgodnie z Regulaminem konkursu grantowego.',
  CRITERIA_1_PGR_TITLE: 'Kwalifikowalność Wnioskodawcy',
  CRITERIA_1_PGR_DISTRICT:
    'Weryfikacji podlega czy Wnioskodawcą jest jednostka samorządu terytorialnego, zgodnie z Regulaminem konkursu grantowego.',
  CRITERIA_2_PGR:
    'Weryfikacji podlega czy Wnioskodawca nie został wykluczony z możliwości otrzymania dofinansowania ze środków UE - kryterium weryfikowane na podstawie oświadczenia zawartego we Wniosku o przyznanie Grantu.',
  CRITERIA_2_PGR_TITLE: 'Niepodleganie wykluczeniu z możliwości otrzymania dofinansowania ze środków Unii Europejskie',

  CRITERIA_3_PGR_TITLE: 'Okres realizacji projektu',
  CRITERIA_3_PGR: 'Okres realizacji projektu nie przekracza terminu wskazanego w Regulaminie konkursu grantowego.',

  CRITERIA_4_PGR:
    'Weryfikacji podlega czy wskazane we wniosku wydatki są kwalifikowalne i zgodne z Regulaminem konkursu grantowego.',
  CRITERIA_4_PGR_TITLE: 'Kwalifikowalność wydatków',

  CRITERIA_5_PGR:
    'Weryfikacji podlega czy efekty projektu zostaną utrzymane przez min. 2 lata od zakończenia projektu. Ocena na podstawie oświadczenia Wnioskodawcy, o zapoznaniu się z Regulaminem konkursu grantowego i akceptacji jego zasad, zawartych we Wniosku o przyznanie Grantu.',
  CRITERIA_5_PGR_TITLE: 'Zapewnienie utrzymania efektów projektu ',

  CRITERIA_1_CG_TITLE: 'Kwalifikowalność Wnioskodawcy',
  CRITERIA_1_CG:
    'Weryfikacji podlega czy Wnioskodawcą jest jednostka samorządu terytorialnego, zgodnie z załącznikiem nr 2 do Regulaminu konkursu oraz czy złożył wniosek w prawidłowej rundzie naboru.',
  CRITERIA_1_CG_DISTRICT:
    'Weryfikacji podlega czy Wnioskodawcą jest jednostka samorządu terytorialnego - powiat, zgodnie z załącznikiem nr 2 do Regulaminu konkursu oraz czy wniosek został złożony w trakcie prowadzonego naboru.',
  CRITERIA_1_CG_VOIVODESHIP:
    'Weryfikacji podlega czy Wnioskodawcą jest jednostka samorządu terytorialnego - województwo, zgodnie z załącznikiem nr 2 do Regulaminu konkursu oraz czy wniosek został złożony w trakcie prowadzonego naboru.',

  CRITERIA_2_CG_TITLE: 'Niepodleganie wykluczeniu z możliwości otrzymania dofinansowania ze środków Unii Europejskie',
  CRITERIA_2_CG:
    'Weryfikacji podlega czy Wnioskodawca nie został wykluczony z możliwości otrzymania dofinansowania ze środków UE - kryterium weryfikowane na podstawie oświadczenia zawartego we Wniosku o przyznanie Grantu.',

  CRITERIA_3_CG_TITLE: 'Wysokość wnioskowanej kwoty',
  CRITERIA_3_CG:
    'Weryfikacji podlega czy wnioskowana kwota nie przekracza maksymalnej kwoty wskazanej w regulaminie konkursu zgodnie z załącznikiem nr 2.',

  CRITERIA_4_CG_TITLE: 'Okres realizacji projektu',
  CRITERIA_4_CG: 'Okres realizacji projektu nie przekracza terminu wskazanego w Regulaminie konkursu.',

  CRITERIA_5_CG_TITLE: 'Kwalifikowalność wydatków',
  CRITERIA_5_CG:
    'Weryfikacji podlega czy wskazane we wniosku wydatki są kwalifikowalne i zgodne z Regulaminem konkursu grantowego.',

  CRITERIA_6_CG_TITLE: 'Zasadność kosztów w Projekcie',
  CRITERIA_6_CG:
    'Weryfikacji podlega czy Wnioskodawca wystarczająco uzasadnił potrzebę wskazanych wydatków oraz ich racjonalność w kontekście celu projektu oraz potrzeb Grantobiorcy.',

  CRITERIA_7_CG_TITLE: 'Zapewnienie utrzymania efektów projektu',
  CRITERIA_7_CG:
    'Weryfikacji podlega czy efekty projektu zostaną utrzymane przez min. 2 lata od zakończenia projektu. Ocena na podstawie oświadczeń Wnioskodawcy o zapoznaniu się i akceptacji zapisów regulaminu konkursu i schematu grantowego, zawartych we Wniosku o przyznanie Grantu.',

  CRITERIA_8_CG_TITLE: 'Opis koncepcji projektu',
  CRITERIA_8_CG:
    'Weryfikacji podlega czy Wnioskodawca przedstawił opis koncepcji projektu zawierający informacje o: potrzebach Wnioskodawcy w zakresie cyfryzacji urzędu, a także jednostek podległych i nadzorowanych (jeśli dotyczy), celach i efektach projektu, w tym w odniesieniu do celów Programu Operacyjnego Polska Cyfrowa, V oś priorytetowa.',

  MAINTENANCE_TITLE: 'Strona w przebudowie',
  MAINTENANCE_SUBTITLE: 'Prowadzimy prace konserwacyjne',
  MAINTENANCE_DESC: 'Zapraszamy wkrótce',

  ADMIN_EDIT_DATA: 'Awaryjna edycja danych',
  ADMIN_EDIT_OWN_RESPONSIBILITY: 'Uwaga! edytujesz na własną odpowiedzialność',
  ADMIN_EDIT_FIELD: 'Edytuj pole wniosku',
  ADMIN_EDIT_ELIGIBLE_COST: 'Edytuj koszt we wniosku',
  FORMS_VALUE: 'Wartość',
  FORMS_ADMIN_NOTE: 'Notatka admina',

  UPLOAD_CONTRACTOR: 'Edycja kontrahentów',
  CONTRACTORS: 'Kontrahenci',

  APPLICATION_SHOW_AMENDMENT: 'Odblokuj aneks',
  APPLICATION_CONFIRM_SHOW_AMENDMENT: 'Czy na pewno chcesz odblokować aneks dla tego wniosku?',
  AMENDMENT_GENERATE:
    'Aby zaktualizować numer konta bankowego lub adres, wygeneruj aneks do umowy, podpisz elektronicznie i załącz poniżej.',
  AMENDMENT_SENT: 'Aneks do umowy został podpisany i przesłany przez grantobiorcę',
  AMENDMENT_WAITING_FOR_SIGNATURE: 'Aneks czeka na weryfikację i podpis grantodawcy',
  AMENDMENT_TO_BE_CORRECTED: 'Aneks został zwrócony do poprawy',
  AMENDMENT_SIGNED: 'Aneks został podpisany przez grantodawcę',
  AMENDMENT_UPLOAD_FILE_VERIFIER: 'Aby zaakceptować aneks, wgraj podpisane pliki aneksu:',
  APPLICATION_DOWNLOAD_AMENDMENT: 'Pobierz aneks',
  AMENDMENT_CHANGE_BANK_ACCOUNT: 'Zaktualizuj numer rachunku',
  AMENDMENT_CHANGE_ADDRESS: 'Zaktualizuj adres',
  GENERATE_AGREEMENT_INFO_ADDRESS:
    'Proszę sprawdzić poprawność adresu wskazanego w aplikacji grantowej. Zaktualizować go, wygenerować aneks, podpisać elektronicznie i przesłać.',
  FORMS_AGREEMENT_DATE: 'Data zawarcia umowy',
  AMENDMENT_GENERATE_2: 'Aneks został wygenerowany',
  APPLICATION_RESIGN: 'Rezygnacja',
  APPLICATION_RESIGN_TEXT: 'Załącz pismo podpisane elektronicznie (w PDF), które rozpatrzymy w 5 dni roboczych',
  ERROR_QTY: 'Liczba musi być całkowita i większa lub równa 0',

  MONOTORING_LINK: 'Lista beneficjentów ostatecznych',
  FORMS_PARENT_FIRST_NAME: 'Imię',
  FORMS_PARENT_LAST_NAME: 'Nazwisko',
  FORMS_PHONE: 'Telefon',
  FORMS_KID_FIRST_NAME: 'Imię',
  FORMS_KID_LAST_NAME: 'Nazwisko',
  FORMS_ADDRESS: 'Adres',
  FORMS_PARENT: 'Opiekun',
  FORMS_CHILD: 'Dziecko',
  FORMS_COMMUNITY: 'Gmina',
  FORMS_SCHOOL_NAME: 'Szkoła',
  MONITORING_REPORT: 'Raport beneficjentów ostatecznych',
  HARDWARE_DELETE_TOOLTIP: 'Czy na pewno chcesz usunąć ten wiersz?',
  HARDWARE_DELETE_: 'Usuń wpis',
  ADD_NEW_DATA: 'Dodaj nowy wiersz',

  UNLOCK_AGREEMENT: 'Odblokuj umowę',
  LOCK_AGREEMENT: 'Zablokuj umowę',

  FORMS_AGREEMENT_LOCKED: 'Um. zabl.',
  FORMS_AGREEMENT_LOCKED_YES: 'Zablokowana',
  FORMS_AGREEMENT_LOCKED_NO: 'Odblokowana',
  STATEMENTS_TITLE: 'Rozliczenia',
  FORMS_PRIMARY_VERIFIER: 'Pierwszy weryfikator',
  FORMS_SECONDARY_VERIFIER: 'Drugi weryfikator',
  FORMS_VERIFIERS: 'Weryfikatorzy',
  ASSIGN_VERIFIER: 'Przypisz weryfikatora',
  VERIFIERS_SAVE: 'Przypisano weryfikatorów',
  SELECT_INSPECTOR: 'Wybierz kontrolera',
  ASSIGN_INSPECTORS: 'Przypisywanie zespołu kontrolnego',
  FORMS_MEMBER_INSPECTOR: 'Członek zespołu kontrolującego',
  FORMS_MANAGER_INSPECTOR: 'Kierownik kontroli',
  FORMS_INSPECTORS: 'Zespół kontrolny',
  ASSIGN_INSPECTOR: 'Przypisz kontrolera',
  INSPECTORS_SAVE: 'Przypisano kontrolerów',
  CREATE_STATEMENT: 'Złóż wniosek rozliczający',
  STATEMENT_NEW: 'Nowe rozliczenie',
  APPLICATION_STATEMENT_CONCEPTION: ' Postęp rzeczowy realizacji projektu',
  FORMS_STATEMENT_DESCRIPTION:
    'Należy krótko opisać przebieg realizacji projektu grantowego (min. 500 znaków, max. 2000 liczone bez spacji)',
  FORMS_STATEMENT_DESCRIPTION_NOTE: 'Odpowiedź',
  APPLICATION_STATEMENT_CHANGES: 'Zmiany w projekcie grantowym',
  FORMS_STATEMENT_CHANGES:
    'W przypadku zmian w zakresie realizacji projektu przedstawionym we Wniosku grantowym w stosunku do wykazanych we Wniosku rozliczającym, należy wykazać dokonane zmiany i krótko opisać uzasadnienie każdej z wprowadzonych modyfikacji.',
  FORMS_STATEMENT_CHANGES_NOTE: 'Odpowiedź',
  STATEMENT_TITLE: 'Rozliczenie',
  STATEMENT_EDIT: 'Edycja rozliczenia',
  STATEMENT_INSPECTION: 'Kontrola',
  STATEMENT_SENT: 'Wyślij rozliczenie',
  STATEMENT_CONFIRM_TXT: 'Po wysłaniu rozliczenia, nie będzie można już go edytować. Zostanie przesłany do weryfikacji.',
  APPLICATION_SUM: 'Wysokość przyznanego grantu:',
  STATEMENT_TO_RETURN: 'Kwota podlegająca zwrotowi',
  STATEMENT_FILE_USER: 'Pliki przesłane przez Grantobiorcę',
  FORMS_TYPE_STATEMENT: 'Rodzaj zamówienia',
  FORMS_MODE_STATEMENT: 'Tryb udzielenia zamówienia',
  FORMS_FILE_FIELD_NOTE_STATEMENT: 'Uwagi do załączonych plików (np. pozycja na fakturze)',
  STATEMENT_CONTROL_SEND_FILES: 'Proszę załączyć dokumenty dotyczące tego kosztu',
  STATEMENT_BANK_FIELDS: 'Rozliczenie przyznanego grantu',
  FORMS_APPLICATION_VALUE: 'Wysokość przyznanego grantu',
  FORMS_STATEMENT_VALUE: 'Wysokość rozliczonego grantu',
  FORMS_VALUE_TO_RETURN: 'Kwota grantu podlegająca zwrotowi',
  FORMS_VALUE_TO_RETURNED: 'W tym kwota zwrócona na dzień złożenia wniosku rozliczającego',
  STATEMENT_BANK_FIELDS_FILES: 'Załącz wyciągi bankowe potwierdzające podane wyżej kwoty',
  STATEMENT_CONSENT:
    'Pouczony(-a) o odpowiedzialności karnej za składanie oświadczeń niezgodnych z prawdą, w tym o konieczności zwrotu przyznanego w ramach projektu „Cyfrowa gmina” wsparcia Ja niżej podpisany oświadczam, iż zgodnie z moja wiedzą:',
  STATEMENT_SAVE: 'Zapisz rozliczenie',
  STATEMENT_CONFIRM_SEND: 'Czy na pewno chcesz przekazać dokumenty do rozliczenia?',
  GENERATE_STATEMENT_INFO:
    'Sprawdź poprawność podanych wyżej danych. Po wysłaniu rozliczenia zostanie ono przekazane do weryfikacji. Jeśli wszystko się zgadza podpisz dokument elektronicznie, załącz poniżej i wyślij. Dołącz również uchwałę powołującą włodarza gminy (podpisującego wniosek rozliczający) i skarbnika (podpisującego wniosek rozliczający).',
  DOWNLOAD_STATEMENT: 'Pobierz rozliczenie do podpisania',
  STATEMENT_DOWNLOAD_PGR_REPORT: 'Wszystkie rozliczenia PGR',
  STATEMENT_DOWNLOAD_CG_REPORT: 'Wszystkie rozliczenia CG',
  STATEMENT_QUALIFIED_COST: 'Kwota kwalifikowana',
  FORMS_LEGAL_MODE: 'Podstawa prawna',
  FORMS_FEMALES: 'Liczba kobiet biorąca udział w szkoleniu',
  FORMS_MALES: 'Liczba mężczyzn biorących udział w szkoleniu',
  STATEMENT_APPLICATION_QUANTITY: 'Wnioskowana liczba',
  STATEMENT_QUANTITY: 'Zrealizowana liczba',
  STATEMENT_APPLICATION_COST: 'Wnioskowana kwota',
  STATEMENT_COST: 'Zrealizowana kwota',
  STATEMENT_FILES_REQUIRED: 'Dołącz wymagane pliki',
  FORMS_STORAGE: 'Oświadczam, że dokumentacja związana z projektem przechowywana jest w',
  STATEMENT_MISSING_FILES: 'Brakuje dokumentów. Wejdź w edycję kosztu i dodaj brakujące pliki.',
  APPLICATION_STATEMENT_VAT: 'Oświadczenie Grantobiorcy o kwalifikowalności podatku VAT',
  APPLICATION_STATEMENT_VAT_DESC: 'Należy załączyć dokument w formacie PDF z podpisem kwalifikowalnym osoby uprawnionej',

  DOCS_FINANCE:
    'Dokumenty finansowe potwierdzające poniesienie wydatków (faktury lub równoważne dowody księgowe wraz z potwierdzeniem dowodu zapłaty)',
  DOCS_FINANCE_TEXT: 'Faktura opis do niej i potwierdzenie płatności skanujemy jako jeden dokument',
  DOCS_PROTOCOLS: 'Protokół/ protokoły odbioru sprzętu/ usługi',
  DOCS_PROTOCOLS_TEXT: 'Z wyszczególnionymi ilościami oraz specyfikacją.',
  DOCS_INSURANCE: 'Ubezpieczenie sprzętu',
  DOCS_INSURANCE_TEXT: 'Polisę i potwierdzenie płatności skanujemy jako jeden dokument',
  PEOPLE_DOCS_TITLE: 'Lista podmiotów, którym przekazano sprzęt/ oprogramowanie/ usługę',
  PEOPLE_DOCS_NOTE: 'Zestawienie w formie tabeli w Excel oraz PDF z podpisem uprawnionego przedstawiciela JST',
  FORMS_PGR_STATEMENT_SUM: 'Wartość sprzętu',
  FORMS_PGR_STATEMENT_CHILDREN_COUNT: 'Liczba dzieci',
  BUDGET_ROW_APPLICATION: 'Wniosek o grant',
  BUDGET_ROW_STATEMENT: 'Wniosek rozlczający grant',
  STATEMENT_PGR_CHILDREN_COUNT: 'Liczba dzieci objętych wsparciem',
  STATEMENT_PGR_CHILDREN_SAVED: 'Liczba beneficjentów ostatecznych zadeklarowana w aplikacji',
  NOT_APPLICABLE: 'Nie dotyczy',
  FINANCE_DOCS_TITLE:
    'Dokumenty finansowe potwierdzające poniesienie wydatków (faktury lub równoważne dowody księgowe wraz z potwierdzeniem dowodu zapłaty)',
  FINANCE_DOCS_NOTE: 'Faktura opis do niej i potwierdzenie płatności skanujemy jako 1 dokument',
  PROTOCOLS_DOCS_TITLE: 'Protokół/ protokoły odbioru sprzętu/ usługi',
  PROTOCOLS_DOCS_NOTE: 'Polisa i potwierdzenie płatności skanujemy jako 1 dokument',
  INSURANCE_DOCS_TITLE: 'Polisa ubezpieczeniowa',
  INSURANCE_DOCS_NOTE: 'Polisa i potwierdzenie płatności skanujemy jako 1 dokument',
  APPLICATION_COST_VALUE: 'Wniosek o grant',
  STATEMENT_COST_VALUE: 'Rozliczenie grantu',
  PAYMENT_CONFIRMATION_FIELDS_FILES: 'Załącz potwierdzenia przelewu kwoty podlegającej zwrotowi',
  STATEMENT_PGR_BUDGET: 'Postęp rzeczowo-finansowy realizacji projektu',
  STATEMENT_NOTE_MODERATOR_DONE: 'Uwaga zrealizowana',
  STATEMENT_NOTE_MODERATOR_UNDONE: 'Uwaga niezrealizowana',
  STATEMENT_CRITERIA_PREVERIFICATION: 'Weryfikacja wstępna',
  STATEMENT_CRITERIA_GENERAL: 'Weryfikacja ogólna',
  STATEMENT_CRITERIA_DETAILS: 'Weryfikacja szczegółowa',
  STATEMENT_CRITERIA_CONCLUSIONS: 'Wnioski z weryfikacji wniosku rozliczającego',
  STATEMENT_CRITERIA_DATE_OF_STATEMENT_UPDATE: 'Data złożenia poprawionego/uzupełnionego wniosku',
  STATEMENT_CRITERIA_SEND_DATE: 'Data przesłania',
  STATEMENT_CRITERIA_FILE_NAME: 'Nazwa pliku',
  STATEMENT_CRITERIA_DATE_OF_CALL: 'Data wezwania',
  STATEMENT_CONFIRM_TO_VERIFIACTION: 'Czy na pewno chcesz wysłać wniosek do weryfikacji?',
  STATEMENT_SEND_TO_VERIFIACTION: 'Wyślij wniosek do weryfikacji',
  STATEMENT_CONFIRM_CONTROL: 'Czy na pewno chcesz wysłać wniosek do kontroli?',
  STATEMENT_SEND_CONTROL: 'Wyślij wniosek do kontroli',
  STATEMENT_CONFIRM_TO_INSPECTION: 'Czy na pewno chcesz wysłać wniosek do kontroli?',
  STATEMENT_SEND_TO_INSPECTION: 'Wyślij wniosek do kontroli',
  STATEMENT_INSPECTION_BEGIN: 'Rozpoczynanie kontroli',
  STATEMENT_INSPECTION_TYPE: 'Rodzaj kontroli:',
  STATEMENT_INSPECTION_FILE: 'Zawiadomienie o kontroli:',
  STATEMENT_INSPECTION_LOCKED: 'Kontrola zablokowana - poczekaj, aż upłynie czas zawiadomienia',
  STATEMENT_INSPECTION_LOCKED_ONLY_ONCE: 'Kontrola zablokowana - została już przeprowadzona',
  STATEMENT_CONFIRM_TO_CORRECTION: 'Czy na pewno chcesz zaakceptować wniosek z korektą?',
  STATEMENT_SEND_TO_CORRECTION: 'Zaakceptuj wniosek z korektą',
  STATEMENT_ADDITIONAL_FILES: 'Dodatkowe pliki',
  STATEMENT_VERIFIER_CRITERIA: 'Lista sprawdzająca do wniosku',
  APPLICATION_PGR_INELIGIBLE_LABEL: 'Koszty niekwalifikowane',
  STATEMENT_DAYS_LEFT: 'Pozostało dni',
  STATEMENT_DAYS_LEFT_FOR_VERIFICATION: 'Na weryfikację',
  STATEMENT_DAYS_LEFT_FOR_CORRECTION: 'Na poprawę',
  STATEMENT_TIME_FOR_CORRECTION_IS_UP: 'Czas na poprawę minął',
  PGR_ELIGIBLE_COSTS: 'Dodatkowe koszty (inne niż zostały przedstawione we Wniosku o Grant)',
  FORMS_AGREEMENT_EXTEND: 'Liczba miesięcy do wydłużenia umowy',
  AMENDMENT_CHANGE_DATE: 'Wydłużenie terminu umowy',
  GENERATE_AGREEMENT_TEXT_DATE: 'Pobierz aneks, aby wydłużyć termin składania wniosku rozliczającego: ',
  GENERATE_AGREEMENT_INFO_DATE: 'Podpisz elektronicznie pobrany wyżej aneks i prześlij za pomocą przycisku poniżej: ',
  STATEMENT_CONFIRM_SENT: 'Czy na pewno chcesz wysłać rozliczenie do weryfikacji?',

  // CG FORM
  STATEMENT_CG_MAIN_TITLE: 'Szczegóły rozliczenia',
  STATEMENT_CG_TITLE_PROJECT_PROGRESS: 'Przebieg realizacji projektu',
  STATEMENT_CG_SUBTITLE_PHYSICAL_PROGRESS: 'Postęp rzeczowy realizacji projektu',
  STATEMENT_CG_LABEL_BRIEF_PROJECT_REALIZATION: 'Krótki opis przebiegu realizacji projektu grantowego',
  STATEMENT_CG_VALIDATION_BRIEF_PROJECT_REALIZATION: 'Minimalnie 500 znaków, maksymalnie 2000.',
  STATEMENT_CG_LABEL_BRIEF_PROJECT_REALIZATION_ERROR_TOO_SHORT: 'Za krótki opis przebiegu realizacji projektu grantowego',
  STATEMENT_CG_LABEL_BRIEF_PROJECT_REALIZATION_ERROR_TOO_LONG: 'Za długi opis przebiegu realizacji projektu grantowego',
  STATEMENT_CG_SUBTITLE_CHANGES_GRANT_PROCESS: 'Zmiany w projekcie grantowym',
  STATEMENT_CG_DESCRIPTION_CHANGES_GRANT_PROCESS:
    'W przypadku zmian w zakresie realizacji projektu przedstawionym we Wniosku grantowym w stosunku do wykazanych we Wniosku rozliczającym, należy wykazać dokonane zmiany i krótko opisać uzasadnienie każdej z wprowadzonych modyfikacji.',
  STATEMENT_CG_LABEL_CHANGES_GRANT_IS_CHANGED: 'Czy wprowadzono zmiany?',
  STATEMENT_CG_LABEL_LIST_OF_CHANGES: 'Wykaz każdej zmiany z uzasadnieniem',
  STATEMENT_CG_ERROR_TEXT_TOO_SHORT: 'Pole musi mieć minimum 3 znaki',

  STATEMENT_CG_ACCOUNT_NUMBER_TITLE: 'Konto Bankowe',
  STATEMENT_CG_ACCOUNT_NUMBER: 'Numer konta bankowego',
  STATEMENT_CG_REPRESENTATIVE_POSITION: 'Stanowisko',
  STATEMENT_CG_PERSONAL_DATA: 'Dane Grantobiorcy',
  STATEMENT_CG_DETAILS_TITLE: 'Dane szczegółowe',
  STATEMENT_CG_USER_NAME: 'Imię',
  STATEMENT_CG_USER_LAST_NAME: 'Nazwisko',
  STATEMENT_CG_USER_EMAIL: 'E-mail',
  STATEMENT_CG_USER_POSITION: 'Pozycja',
  STATEMENT_CG_USER_NUMBER: 'Numer telefonu',
  STATEMENT_CG_USER_NUMBER_ERROR: 'Numer telefonu jest nieprawidłowy',
  STATEMENT_CG_USER_REGON: 'REGON',
  STATEMENT_CG_USER_NIP: 'NIP',
  STATEMENT_CG_REPRESENATTIVE_PERSON: 'Osoba upoważniona do reprezentacji (podpisania wniosku rozliczeniowego)',
  STATEMENT_CG_ADD_REPRESENATTIVE: 'Dodaj osobę upoważnioną',
  STATEMENT_CG_REPRESENATTIVE_DOCUMENT_TITLE: 'Dokument mianujący na stanowisko:',
  STATEMENT_CG_REPRESENATTIVE_DOCUMENT_BUTTON_TITLE: 'Dodaj dokument mianowania',
  STATEMENT_CG_REPRESENATTIVE_DOCUMENT_INFO:
    'Załącz dokument umocowania podpisany elektronicznie przez obie osoby wskazane do reprezentacji.',

  STATEMENT_CG_TITLE_DECLARATIONS: 'Oświadczenia Grantobiorcy',
  STATEMENT_CG_TITLE_STORAGE: 'Miejsce przechowywania dokumentacji',
  STATEMENT_CG_TITLE_STORAGE_DECLARATION: 'Oświadczam, że dokumentacja związana z projektem przechowywana jest w',
  STATEMENT_CG_TITLE_STORAGE_DECLARATION_PLACEHOLDER: 'Miejsce lub miejsca przechowywania dokumentacji projektowej',
  STATEMENT_CG_TITLE_ELIGIBLE_VAT: 'Oświadczenie Grantobiorcy o kwalifikowalności podatku VAT',
  STATEMENT_CG_TITLE_DECLARATIONS_INSIDE: 'Oświadczenia',

  STATEMENT_CG_TITLE_FINANCIAL_PROGRESS: 'Postęp rzeczowo-finansowy realizacji projektu',
  STATEMENT_CG_ADD_COST: 'Dodaj koszt',
  STATEMENT_CG_TITLE_COST_VAT: 'Informacje o podatku VAT dla wszystkich kosztów',
  STATEMENT_CG_SUBTITLE_COST_VAT: 'Kwalifikowalność podatku VAT',
  STATEMENT_CG_DESCRIPTION_COST_VAT:
    'Jeżeli Grantobiorca będzie mógł w jakiejś części projektu posiadać możliwość odliczenia podatku VAT, powinien uznać go za niekwalifikowalny w całości.',
  STATEMENT_CG_COST_PLACEHOLDER: 'Wpisz nazwę kosztu',
  STATEMENT_CG_DELETE_COST: 'Usuń koszt',
  STATEMENT_CG_DELETE_COST_CONFIRM: 'Czy na pewno chcesz usunąć koszt?',
  STATEMENT_CG_SETTLE_COST: 'Rozlicz koszt',
  STATEMENT_CG_NOT_SETTLE_COST: 'Zrezygnuj z kosztu',
  STATEMENT_CG_NOT_SETTLE_COST_CONFIRM: 'Czy na pewno chcesz zrezygnować z kosztu?',
  STATEMENT_CG_COST_SUMMARY: 'Podsumowanie kosztów w tej kategorii',
  STATEMENT_CG_LABEL_GRANT_APPLICATION_COST: 'Wniosek o Grant',
  STATEMENT_CG_LABEL_GRANT_SETTLEMENT_COST: 'Rozliczenie Grantu',
  STATEMENT_CG_LABEL_CONSULTANCY: 'Czy koszt jest usługą doradczą?',
  STATEMENT_CG_LABEL_VAT_ELIGIBLITY: 'Czy podatek VAT jest kwalifikowany dla całego projektu?',
  STATEMENT_CG_LABEL_GRANT_APPLICATION: 'Wniosek o Grant',
  STATEMENT_CG_LABEL_GRANT_SETTLEMENT_GROSS: 'Rozliczenie Grantu (brutto)',
  STATEMENT_CG_LABEL_GRANT_SETTLEMENT_NET: 'Rozliczenie Grantu (netto)',
  STATEMENT_CG_LABEL_UNIT_COST: 'Koszt jednostkowy',
  STATEMENT_CG_LABEL_UNITS_NUMBER: 'Liczba sztuk',
  STATEMENT_CG_LABEL_TOTAL_COST: 'Koszt całkowity',
  STATEMENT_CG_LABEL_VAT_COST: 'Rozliczenie Grantu (VAT)',
  STATEMENT_CG_LABEL_PEOPLE_COVERED: 'Osoby objęte szkoleniem',
  STATEMENT_CG_LABEL_COVERED_MALE: 'Mężczyźni',
  STATEMENT_CG_LABEL_COVERED_FEMALE: 'Kobiety',
  STATEMENT_CG_LABEL_COVERED_TOTAL: 'Łącznie',
  STATEMENT_CG_SUM_ERROR: 'Koszt musi być większy od 0',
  STATEMENT_CG_NETTO_QTY_ERROR:
    'Liczba sztuk musi być większa niż 0. Jeżeli koszt nie został zrealizowany, kliknij przycisk "Zrezygnuj z kosztu" lub "Usuń koszt".',
  STATEMENT_CG_SUM_NETTO_ERROR: 'Całkowity koszt netto musi być mniejszy od całkowitego kosztu brutto.',
  STATEMENT_CG_COLLABS_TITLE_PROTOCOLS: 'Protokoły odbioru sprzętu lub usługi',
  STATEMENT_CG_COLLABS_TITLE_PROTOCOLS_CYBER_DIAGNOSIS: 'Potwierdzenie przesłania diagnozy',
  STATEMENT_CG_VALIDATION_BRIEF_PROTOCOLS: 'Należy załączyć określone pliki',
  STATEMENT_CG_COLLABS_TITLE_DOCUMENTS: 'Dokumenty finansowe potwierdzające poniesienie wydatków',
  STATEMENT_CG_VALIDATION_BRIEF_DOCUMENTS: 'Należy załączyć określone pliki',
  STATEMENT_CG_COLLABS_TITLE_FINAL_BENEFICIARIES: 'Lista placówek, którym przekazano sprzęt, oprogramowanie, usługę',
  STATEMENT_CG_LABEL_FINAL_BENEFICIARY: 'Beneficjent ostateczny',
  STATEMENT_CG_LABEL_TRANSFERRED_PIECES: 'Przekazanych sztuk',
  STATEMENT_CG_ADD_BENEFICIARY: 'Dodaj beneficjenta',
  STATEMENT_CG_DELETE_BENEFICIARY: 'Usuń beneficjenta',
  STATEMENT_CG_BENEFICIARY_ERROR: 'Musisz dodać przynajmniej jednego beneficjenta',
  STATEMENT_CG_LABEL_BENEFICIARY_PROTOCOLS: 'Protokoły przekazania sprzętu lub usługi',
  STATEMENT_CG_NO_FILE_ERROR: 'Należy załączyć określone pliki',
  STATEMENT_CG_ADD_FILE: 'Dodaj pliki',
  STATEMENT_CG_VALIDATION_BRIEF_CYBER_DIAGNOSIS:
    'Brak wykonania obligatoryjnego zadania wynikającego z § 4 pkt. 8 Regulaminu konkursu - w kolumnie “Liczba sztuk” wpisano 0.',
  STATEMENT_CG_LABEL_CYBER_DIAGNOSIS:
    'Oświadczam, że diagnoza cyberbezpieczeństwa została wykonana z wykorzystaniem zasobów własnych, posiądających odpowiednie uprawnienia, dlatego nie rozliczam kosztu usługi zewnętrznej.',

  STATEMENT_CG_SUMMARY_TITLE: 'Podsumowanie rozliczenia',
  STATEMENT_CG_SUMMARY_BILLINGS_TITLE: 'Rozliczenie przyznanego Grantu',
  STATEMENT_CG_SUMMARY_BILLINGS_APPLICATION_VALUE: 'Wysokość przyznanego grantu',
  STATEMENT_CG_SUMMARY_BILLINGS_SETTLEMENT_VALUE: 'Wysokość rozliczonego grantu',
  STATEMENT_CG_SUMMARY_BILLINGS_AMOUNT_TO_RETURN: 'Różnica do zwrotu',
  STATEMENT_CG_SUMMARY_BILLINGS_VALUE_RETURNED: 'Kwota zwrócona na dzień złożenia rozliczenia',
  STATEMENT_CG_SUMMARY_BILLINGS_BANK_STATEMENT_TITLE:
    'Wyciąg bankowy za okres od dnia przekazania środków do dnia złożenia wniosku rozliczającego',
  STATEMENT_CG_SUMMARY_BILLINGS_BANK_STATEMENT: 'Należy załączyć określone pliki.',
  STATEMENT_CG_SUMMARY_IDENTITY_TITLE: 'Identyfikacja wniosku',

  STATEMENT_CG_TITLE_PROCEDURE_DOCUMENTATION: 'Dokumentacja z postępowania o udzielenie zamówienia',
  STATEMENT_CG_SUBTITLE_PROCEDURE_DOCUMENTATION: 'Podsumowanie dokumentacji postępowań',
  STATEMENT_CG_LABEL_UNUSED_COSTS: 'Koszty nieprzypisane do żadnego postępowania przetargowego',
  STATEMENT_CG_ADD_PROCEDURE: 'Dodaj postępowanie',
  STATEMENT_CG_PROCEDURE_PLACEHOLDER: 'Wpisz nazwę postępowania',
  STATEMENT_CG_DELETE_PROCEDURE: 'Usuń postępowanie',
  STATEMENT_CG_DELETE_PROCEDURE_CONFIRM: 'Czy na pewno chcesz usunąć postępowanie?',
  STATEMENT_CG_NO_RELATED_COSTS_PROCEDURE_ERROR: 'Konieczne przypisanie przynajmniej jednego kosztu',
  STATEMENT_CG_LEGAL_BASIS: 'Podstawa prawna',
  STATEMENT_CG_COLLABS_TITLE_RELATED_COSTS: 'Powiązane koszty',
  STATEMENT_CG_LABEL_RELATED_COSTS: 'Koszty, których dotyczy postępowanie',
  STATEMENT_CG_ADD_RELATED_COST: 'Dodaj koszt powiązany',
  STATEMENT_CG_DELETE_RELATED_COST: 'Usuń powiązanie',
  STATEMENT_CG_PLACEHOLDER_RELATED_COSTS: 'Wybierz koszt do dodania',
  STATEMENT_CG_COLLABS_TITLE_ATTACHMENTS: 'Załączniki',
  STATEMENT_CG_DESCRIPTION_NO_ATTACHMENTS: 'Wybierz podstawę prawną, aby wyświetlić załączniki',

  STATEMENT_CG_SIGN_SETTLEMENT: 'Przejdź do etapu podpisywania rozliczenia',
  STATEMENT_CG_SUBMIT_FAIL: 'Rozliczenie zawiera błędy, które należy poprawić przed podpisaniem',
  STATEMENT_CG_SUBMIT_SUM_FAIL: 'Suma dodanych kosztów przekracza wartość przyznanego grantu',
  STATEMENT_CG_TITLE_SETTLEMENT_SENDING: 'Podpisywanie i przesyłanie rozliczenia',
  STATEMENT_CG_SIGNED_SETTLEMENT: 'Podpisane rozliczenie:',
  STATEMENT_CG_SEND_SETTLEMENT: 'Wyślij podpisane rozliczenie',
  STATEMENT_CG_ADD_SETTLEMENT: 'Dodaj podpisany dokument',
  STATEMENT_CG_EDIT_SETTLEMENT: 'Edytuj rozliczenie',

  STATEMENT_CG_TITLE_CORRECTION: 'Korekta finansowa',
  STATEMENT_CG_SUBTITLE_TRANSFER_CONFIRMATION: 'Potwierdzenie otrzymania przelewu',
  STATEMENT_CG_SUBTITLE_DOCUMENTATION_CALL_FOR_CORRECTION: 'Dokumentacja wezwania do korekty',
  STATEMENT_CG_LABEL_TRANSFER_FILES: 'Wyciągi bankowe potwierdzające otrzymanie przelewu',
  STATEMENT_CG_LABEL_CORRECT_COST: 'Czy kwota przelewu jest zgodna z należnością?',
  STATEMENT_CG_BUTTON_COST_CORRECT: 'Zatwierdź otrzymanie prawidłowej kwoty',
  STATEMENT_CG_BUTTON_COST_INCORRECT: 'Zgłoś nieprawidłową kwotę',
  STATEMENT_CG_BUTTON_DOCUMENTATION_CORRECT: 'Zatwierdź dokumentację',
  STATEMENT_CG_LABEL_CALL_FOR_CORRECTION_STATUS: 'Status dokumentacji do wezwania',
  STATEMENT_CG_LABEL_DOCUMENTATION_OUTSIDE: 'Dokumentacja wezwania w systemie zewnętrznym',
  STATEMENT_CG_DOCUMENTATION_FOR_CORRECTION_STATUS_UNCONFIRMED: 'Niezatwierdzona',
  STATEMENT_CG_DOCUMENTATION_FOR_CORRECTION_STATUS_CONFIRMED: 'Zatwierdzona',
  STATEMENT_CG_DOCUMENTATION_FOR_CORRECTION_STATUS_UNCONFIRMED_DESCRIPTION:
    'Dokumentacja nie została jeszcze zatwierdzona przez CPPC.',
  STATEMENT_CG_DOCUMENTATION_FOR_CORRECTION_STATUS_CONFIRMED_DESCRIPTION:
    'Dokumentacja została zatwierdzona. Proces korekty zakończy się po potwierdzeniu otrzymania właściwej kwoty przelewu przez Operatora Projektu.',
  STATEMENT_CG_SUBTITLE_TRANSFER_INFO: 'Potwierdzenie otrzymania przelewu',
  STATEMENT_CG_LABEL_TRANSFER_STATUS: 'Status przelewu',
  STATEMENT_CG_TRANSFER_STATUS_CONFIRMED: 'Zatwierdzony',
  STATEMENT_CG_TRANSFER_STATUS_UNCONFIRMED: 'Niepotwierdzony',
  STATEMENT_CG_TRANSFER_STATUS_REJECTED: 'Odrzucony',
  STATEMENT_CG_TRANSFER_STATUS_CONFIRMED_DESCRIPTION:
    'Kwota przelewu została zatwierdzona. Proces korekty zakończy się po zmieszczeniu i zatwierdzeniu dokumentacji wezwania do korekty przez CPPC.',
  STATEMENT_CG_TRANSFER_STATUS_UNCONFIRMED_DESCRIPTION:
    'Otrzymanie przelewu nie zostało jeszcze potwierdzone przez Operatora Projektu.',
  STATEMENT_CG_TRANSFER_STATUS_REJECTED_DESCRIPTION: 'Kwota otrzymanego przelewu nie jest zgodna z oczekiwaną.',
  STATEMENT_CG_LABEL_TRANSFER_INFO_FILES: 'Wyciągi bankowe potwierdzające otrzymanie przelewu',
  STATEMENT_CG_LABEL_BACK_TO_EDIT: 'Wróć do edycji',
  STATEMENT_CG_LABEL_GENERATE_FILE: 'Wygeneruj wezwanie',

  STATEMENT_CG_VERIFICATION_BUTTON_TITLE: 'Przeprowadź weryfikację',
  STATEMENT_CG_STATEMENT_NO_CHANGES: 'Brak zmian',
  STATEMENT_CG_INELIGIBLE_COSTS_LABEL: 'Koszty niekwalifikowane',
  STATEMENT_CG_EXTERNAL_COST_LABEL: 'koszt spoza wniosku o grant',

  STATEMENT_CG_INFO_SENT_TITLE: 'Informacje o przesłaniu wniosku rozliczającego',
  STATEMENT_CG_INFO_SENT_SUBTITLE: 'Przesłane wnioski rozliczające',
  STATEMENT_CG_INFO_SENT_LAST: 'Ostatnia wersja wniosku:',
  STATEMENT_CG_NO_LAST_FILE: 'Wniosek nie został jeszcze przesłany',
  STATEMENT_CG_FILES_LAST_DELETED: 'Usunięte ostatnio:',
  STATEMENT_CG_FILE_LIST: 'Lista plików:',
  STATEMENT_CG_NO_FILE: 'Brak plików',
  STATEMENT_CG_INFO_SENT_ARCHIVE: 'Archiwum przesłanych wniosków:',
  STATEMENT_CG_NO_FILE_IN_ARCHIVE: 'Brak plików w archiwum',
  STATEMENT_CG_INFO_SENT_DATE: 'Wniosek wysłany',

  // Answers
  STATEMENT_CG_ERROR_LIST_TITLE_CONTACTANSWER: 'Brak odpowiedzi na uwagę - Osoba upoważniona do kontaktu:',
  STATEMENT_CG_ERROR_LIST_TITLE_REPRESENTATIONANSWER: 'Brak odpowiedzi na uwagę - Osoba upoważniona do reprezentacji:',
  STATEMENT_CG_ERROR_LIST_TITLE_BANKANSWER: 'Brak odpowiedzi na uwagę - Konto Bankowe',
  STATEMENT_CG_ERROR_LIST_TITLE_STATEMENTCHANGESANSWER: 'Brak odpowiedzi na uwagę - Zmiany w projekcie grantowym:',
  STATEMENT_CG_ERROR_LIST_TITLE_STATEMENTCONCEPTIONANSWER: 'Brak odpowiedzi na uwagę - Postęp rzeczowy realizacji projektu:',
  STATEMENT_CG_ERROR_LIST_TITLE_COSTANSWER: 'Brak odpowiedzi na uwagę - Postęp rzeczowo-finansowy realizacji projektu:',
  STATEMENT_CG_ERROR_LIST_TITLE_PROCEDURESANSWER:
    'Brak odpowiedzi na uwagę - Dokumentacja z postępowania o udzielenie zamówienia:',
  STATEMENT_CG_ERROR_LIST_TITLE_PROCEDURESUMMARY: 'Występują koszty nieprzypisane do żadnego postępowania',
  STATEMENT_CG_ERROR_LIST_TITLE_DECLARATIONANSWER: 'Brak odpowiedzi na uwagę - Oświadczenia Grantobiorcy:',
  STATEMENT_CG_ERROR_LIST_TITLE_DECLARATIONVATANSWER:
    'Brak odpowiedzi na uwagę - Oświadczenie Grantobiorcy o kwalifikowalności podatku VAT:',
  STATEMENT_CG_ERROR_LIST_TITLE_SUMMARYANSWER: 'Brak odpowiedzi na uwagę - Podsumowanie rozliczenia',
  STATEMENT_CG_ERROR_LIST_TITLE_STORAGEANSWER: 'Brak odpowiedzi na uwagę - Miejsce przechowywania dokumentacji:',
  STATEMENT_CG_ERROR_LIST_TITLE_GENERALNOTESANSWER: 'Brak odpowiedzi na uwagę do całego wniosku',
  STATEMENT_CG_ERROR_LIST_TITLE_GENERALFILESANSWER: 'Brak pliku w odpowiedzi na uwagę do całego wniosku',
  STATEMENT_CG_ERROR_LIST_TABLE_NAME: 'Nazwa sekcji',
  STATEMENT_CG_ERROR_LIST_TABLE_QTY: 'Ilość błędów',
  STATEMENT_CG_ERROR_LIST_TITLE_CONTACT: 'Osoba upoważniona do kontaktu:',
  STATEMENT_CG_ERROR_LIST_TITLE_REPRESENTATION: 'Osoba upoważniona do reprezentacji:',
  STATEMENT_CG_ERROR_LIST_TITLE_STATEMENTCHANGES: 'Zmiany w projekcie grantowym:',
  STATEMENT_CG_ERROR_LIST_TITLE_VAT: 'Kwalifikowalność podatku VAT dla całego projektu:',
  STATEMENT_CG_ERROR_LIST_TITLE_STATEMENTCONCEPTION: 'Postęp rzeczowy realizacji projektu:',
  STATEMENT_CG_ERROR_LIST_TITLE_COST: 'Postęp rzeczowo-finansowy realizacji projektu:',
  STATEMENT_CG_ERROR_LIST_TITLE_PROCEDURES: 'Dokumentacja z postępowania o udzielenie zamówienia:',
  STATEMENT_CG_ERROR_LIST_TITLE_DECLARATION: 'Oświadczenia Grantobiorcy:',
  STATEMENT_CG_ERROR_LIST_TITLE_DECLARATIONVAT: 'Oświadczenie Grantobiorcy o kwalifikowalności podatku VAT:',
  STATEMENT_CG_ERROR_LIST_TITLE_STORAGE: 'Miejsce przechowywania dokumentacji:',
  STATEMENT_CG_ERROR_LIST_TITLE_SUMMARY: 'Podsumowanie:',

  STATEMENT_CG_VERIFIER: 'Weryfikator',
  STATEMENT_CG_APPROVER: 'Weryfikator Nadrzędny',
  STATEMENT_CG_VERIFIER_NOTES: 'Uwagi Weryfikatora:',
  STATEMENT_CG_VERIFIER_NOTE_PLACEHOLDER: 'Napisz uwagę do Granatobiorcy',
  STATEMENT_CG_VERIFIER_NOTE_TITLE: 'Uwagi do wniosku:',
  STATEMENT_CG_GENERAL_VERIFIER_NOTE: 'Uwagi do całego wniosku',
  STATEMENT_CG_GENERAL_VERIFIER_NOTE_FILES_HISTORY: 'Historia plików dołączonych do uwag do całego wniosku',
  STATEMENT_CG_INTERNAL_VERIFIER_NOTE: 'Uwagi wewnętrzne Weryfikatora',
  STATEMENT_CG_INTERNAL_VERIFIER_NOTE_TITLE: 'Uwagi wewnętrzne do wniosku:',
  STATEMENT_CG_INTERNAL_VERIFIER_NOTE_SUBTITLE: 'Uwagi wewnętrzne do innego Weryfikatora:',
  STATEMENT_CG_VERIFIER_NOTE_ALL: 'Uwagi Weryfikatora do całego wniosku',
  STATEMENT_CG_VERIFIER_NOTE: 'Uwaga Weryfikatora',
  STATEMENT_CG_BENEFICIARY_ANSWER_SUBTITLE: 'Odpowiedź Grantobiorcy',
  STATEMENT_CG_VERIFIER_ANSWER_SUBTITLE: 'Odpowiedź Weryfikatora',
  STATEMENT_CG_BENEFICIARY_ANSWER: 'Odpowiedź Grantobiorcy:',
  STATEMENT_CG_BENEFICIARY_ANSWER_PLACEHOLDER: 'Ustosunkuj się do zgłoszonych uwag',
  STATEMENT_CG_VERIFIER_NOTE_DONE: 'Zrealizowana',
  STATEMENT_CG_VERIFIER_ADDITIONAL_FILES: 'Oczekuj na załączenie pliku w odpowiedzi',
  STATEMENT_CG_VERIFIER_DOCUMENTS_SAMPLE: 'Dokumenty wybrane do próby:',
  STATEMENT_CG_VERIFIER_COSTS_ELIGIBLE: 'Koszty kwalifikowane:',
  STATEMENT_CG_VERIFIER_COSTS_INELIGIBLE: 'Koszty niekwalifikowane:',
  STATEMENT_CG_VERIFIER_CRITERIA_PLACEHOLDER: 'Ta uwaga nie będzie widoczna dla Grantobiorcy',
  STATEMENT_CG_VERIFICATION_SAVE_NOTE: 'Zapisz uwagę',
  STATEMENT_CG_VERIFICATION_SAVE_ANSWER: 'Zapisz odpowiedź',
  STATEMENT_CG_VERIFICATION_EDIT_NOTE: 'Edytuj uwagę',
  STATEMENT_CG_VERIFICATION_DELETE_NOTE: 'Usuń uwagę',
  STATEMENT_CG_VERIFICATION_DELETE_NOTE_CONFIRM: 'Czy na pewno chcesz usunąć uwagę?',

  STATEMENT_CG_CHECK_LIST_INITIAL_TITLE: 'Lista sprawdzająca - weryfikacja wstępna',
  STATEMENT_CG_CHECK_LIST_GENERAL_TITLE: 'Lista sprawdzająca - weryfikacja ogólna',
  STATEMENT_CG_CHECK_LIST_DETAILS_TITLE: 'Lista sprawdzająca - weryfikacja szczegółowa',
  STATEMENT_CG_CHECK_LIST_DETAILS_TITLE_PZP: 'Lista sprawdzająca - weryfikacja szczegółowa PZP',
  STATEMENT_CG_CHECK_LIST_SUMMARY_TITLE: 'Lista sprawdzająca - wnioski z weryfikacji wniosku rozliczeniowego',

  STATEMENT_CG_VERIFICATION_DECISION_TITLE: 'Weryfikacja wniosku',
  STATEMENT_CG_VERIFICATION_DECISION_SUBTITLE: 'Decyzja weryfikacyjna',
  STATEMENT_CG_VERIFICATION_REJECTING: 'Zwracanie wniosku rozliczeniowego do Grantobiorcy:',
  STATEMENT_CG_VERIFICATION_REREJECTING: 'Zwracanie wniosku rozliczeniowego do Weryfikatora:',
  STATEMENT_CG_VERIFICATION_DECISION: 'Decyzja ostateczna:',
  STATEMENT_CG_VERIFICATION_DECISION_ACCEPT: 'Zaakceptuj',
  STATEMENT_CG_VERIFICATION_DECISION_REJECT: 'Odrzuć do poprawy',
  STATEMENT_CG_VERIFICATION_DECISION_TO_CORRECTION: 'Zaakceptuj z korektą',
  STATEMENT_CG_VERIFICATION_DECISION_TO_INSPECTION: 'Przekaż do kontroli',
  STATEMENT_CG_VERIFICATION_DECISION_TO_VERIFICATION: 'Przekaż do ponownej weryfikacji',

  STATEMENT_CG_VERIFICATION_SUMMARY_HISTORY_TITLE: 'Historia przekazywania wniosku do poprawy',
  STATEMENT_CG_VERIFICATION_SUMMARY_HISTORY_CALL_DATE: 'Wezwanie do poprawy',
  STATEMENT_CG_VERIFICATION_SUMMARY_HISTORY_UPDATED_AT: 'Złożenie poprawionego wniosku:',
  STATEMENT_CG_VERIFICATION_SUMMARY_HISTORY_NOT_UPDATED_YET: 'Jeszcze nie przesłano',
  STATEMENT_CG_VERIFIER_DOCUMENTS_SAMPLE_ERROR_TOO_SHORT: 'Za krótki opis Próby dokumentów',

  TABLE_COLUMN_AFTER_INSPECTION: 'Przeprowadzono kontrolę',
  TABLE_COLUMN_AFTER_CORRECTION: 'Nałożono korektę',
};

export default pl;
