import actions from './actions';

const initState = {
  costs: []
};

export default function reducer(state = initState, { type, payload }) {
  switch (type) {
    case actions.LOAD_STATEMENT_COSTS_SUCCESS:
      return {
        ...state,
        costs: payload.costs,
      };
      case actions.CLEAR_COSTS:
      return {
        ...state,
        costs: initState.costs,
      };
    default:
      return state;
    }
  }
  