import actions from './actions';
import { defaultActiveLanguage } from '../../settings';

const initState = {
  activeLanguage: defaultActiveLanguage
};

export default function languagesReducer(state = initState, { type, payload }) {
  switch (type) {
    case actions.SET_LANGUAGE:
      return { ...state, activeLanguage: payload.language };

    default:
      return state;
  }
}
