import { call, all, takeEvery, put, fork } from 'redux-saga/effects';
import actions from './actions';
import Users from '../../services/users';

export function* loadUsers() {
  yield takeEvery(actions.LOAD_USERS, function*({ payload }) {
    try {
      const result = yield call(Users.getUsers, payload.currentPage, payload.teryt, payload.email);
      yield put(actions.loadUsersSuccess(result));
    } catch (error) {
      yield put(actions.loadUsersError(error));
    }
  });
}

export function* loadUser() {
  yield takeEvery(actions.LOAD_USER, function*({ payload }) {
    const { userId } = payload;
    try {
      const result = yield call(Users.getUser, userId);
      yield put(actions.loadUserSuccess(result));
    } catch (error) {
      yield put(actions.loadUserError(error));
    }
  });
}

export function* storeUser() {
  yield takeEvery(actions.SAVE_USER, function*({ payload }) {
    const { data, actionName } = payload;
    try {
      switch (actionName) {
        case 'delete':
          yield call(Users.deleteUser, data);
          break;
        case 'update':
          yield call(Users.updateUser, data);
          break;
        case 'block':
          yield call(Users.blockUser, data);
          break;
        case 'unblock':
          yield call(Users.unblockUser, data);
          break;
        case 'activate':
          yield call(Users.activateUser, data);
          break;
        default:
          yield call(Users.saveUser, data);
          break;
      }
      yield put(actions.loadUsers(1));
    } catch (error) {
      yield put(actions.saveUserError(error));
    }
  });
}

export function* loadModerators() {
  yield takeEvery(actions.LOAD_MODERATORS, function*() {
    try {
      const result = yield call(Users.loadModerators);
      yield put(actions.loadModeratorsSuccess(result));
    } catch (error) {
      yield put(actions.loadUsersError(error));
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(loadUsers),
    fork(loadUser),
    fork(storeUser),
    fork(loadModerators)
  ]);
}