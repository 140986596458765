import React from 'react';
import ReactDOM from 'react-dom';
import App from './DashApp';

import 'antd/dist/antd.less';
import { ConfigProvider } from 'antd';
import pl_PL from 'antd/es/locale/pl_PL';

ReactDOM.render(
  <React.StrictMode>
    <ConfigProvider locale={pl_PL}>
      <App />
    </ConfigProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
