import { call, all, takeEvery, put, fork } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import actions from './actions';
import Regions from '../../services/regions';

import { URLS } from '../../helpers/enumsUrls';

export function* sendRegionForm() {
  yield takeEvery(actions.SEND_REGION_FORM, function*({ payload }) {
    const { data } = payload;
    try {
      yield call(Regions.sendRegionForm, data);
      yield put(actions.sendRegionFormSuccess());
      yield put(push(URLS.APP));
    } catch (error) {
      yield put(actions.sendRegionFormError(error));
    }
  });
}

export function* loadTerytData() {
  yield takeEvery(actions.LOAD_TERYT_DATA, function*({ payload }) {
    const { teryt } = payload;
    try {
      const result = yield call(Regions.getTerytData, teryt);
      yield put(actions.loadTerytDataSuccess(result));
    } catch (error) {
      yield put(actions.loadTerytDataSuccess(error));
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(sendRegionForm),
    fork(loadTerytData)
  ]);
}