import { call, all, takeEvery, put, fork } from 'redux-saga/effects';
import actions from './actions';
import StatementCosts from '../../services/statementCosts';
import notification from '../../components/notification';

export function* loadCosts() {
  yield takeEvery(actions.LOAD_STATEMENT_COSTS, function*({ payload }) {
    const { statementId } = payload;
    try {
      const data = yield call(StatementCosts.loadCosts, statementId);
      yield put(actions.loadCostsSuccess(data));
    } catch (error) {
      notification('error', "Wystąpił błąd, spróbuj ponownie później");
    }
  });
}

export function* saveCost() {
  yield takeEvery(actions.SAVE_STATEMENT_COST, function*({ payload }) {
    const { cost, statementId } = payload;
    try {
      if(cost.id) {
        yield call(StatementCosts.saveCost, cost);
      } else {
        yield call(StatementCosts.addCost, statementId, cost);
      }
      yield put(actions.loadCosts(statementId));
    } catch (error) {
      notification('error', "Wystąpił błąd, spróbuj ponownie później");
    }
  });
}

export function* deleteCost() {
  yield takeEvery(actions.DELETE_STATEMENT_COST, function*({ payload }) {
    const { costId, statementId } = payload;
    try {
      yield call(StatementCosts.deleteCost, costId);
      yield put(actions.loadCosts(statementId));
    } catch (error) {
      notification('error', "Wystąpił błąd, spróbuj ponownie później");
    }
  });
}


export default function* rootSaga() {
  yield all([
    fork(loadCosts),
    fork(saveCost),
    fork(deleteCost)
  ]);
}