import React, { Component } from 'react';
import { connect } from 'react-redux';
import actions from '../redux/auth/actions';
import { getCurrentUser, getCurrentUserRoles, getCurrentUserDetails, getCurrentUserLoaded, getCurrentUserLoading, getDate } from '../redux/auth/selectors';

function withUserProvider(Cmp) {
  class Controller extends Component {
    componentDidMount() {
       if(!this.props.currentUserLoaded && !this.props.currentUserLoading) {
        this.props.getCurrentUser();
      }
    }

    render() {
      if(!this.props.currentUserLoaded || !this.props.user) {
        return null;
      }

      return (
        <Cmp {...this.props} />
      )
    }
  }

  return connect(
    mapStateToProps,
    mapDispatchToProps
  )(Controller);
}


const mapStateToProps = state => {
  return {
    user: getCurrentUser(state),
    userRoles: getCurrentUserRoles(state),
    userDetails: getCurrentUserDetails(state),
    currentUserLoaded: getCurrentUserLoaded(state),
    currentUserLoading: getCurrentUserLoading(state),
    pgrDate: getDate(state)
  }
}

const mapDispatchToProps = { getCurrentUser: actions.getCurrentUser };

export default withUserProvider;