import styled from "styled-components";


const LogoStyleWrapper = styled.div`
  img {
    width: 100%;
    max-width: 500px;
  }

  .collapsed {
    width: auto;
    max-height: 50px;
    display: block;
    margin: 0 auto;
  }
`;

export { LogoStyleWrapper };