import SuperFetch from '../helpers/superFetch';
import TlnString from '../components/Tln/TlnString';

const Account = {
  getUserAccount: () =>
    SuperFetch.get(`who-am-i`)
      .then(response => response.data),

  updateUserAccount: data =>
    SuperFetch.put(`my-account`, data, TlnString("CRUD_EDIT_RECORD_SUCCESS"))
      .then(response => response.data),

  updateUserPassword: data =>
   SuperFetch.put(`my-account/update-password`, data, TlnString("CRUD_EDIT_RECORD_SUCCESS"))
      .then(response => response.data),
}

export default Account;

