import styled from 'styled-components';

const UserAvatarWrapper = styled.article`
  display: flex;
  flex-direction: column;
  align-items: center;
  line-height: 2;
  margin-right: 16px;

  @media only screen and (max-width: 767px) {
    .userData {
      display: none;
    }

    .ant-avatar-sm {
      height: 36px;
      width: 36px;
      line-height: 36px;
    }

    .ant-avatar-icon {
      font-size: 24px;
    }
  }
`;

export {
  UserAvatarWrapper
}