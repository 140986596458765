import { call, all, takeEvery, put, fork } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import actions from './actions';
import Applications from '../../services/applications';
import { URLS } from '../../helpers/enumsUrls';
import { APPLICATION_STATUS } from '../../helpers/enums';

export function* loadApplications() {
  yield takeEvery(actions.LOAD_APPLICATIONS, function*({ payload }) {
    const { currentPage, userId, query } = payload;
    try {
      const result = userId ?
        yield call(Applications.getApplicationsById, currentPage, userId)
        : 
        yield call(Applications.getApplicationsWithStatus, currentPage, query);
      yield put(actions.loadApplicationsSuccess(result));
    } catch (error) {
      yield put(actions.loadApplicationsError(error));
    }
  });
}

export function* loadApplication() {
  yield takeEvery(actions.LOAD_APPLICATION, function*({ payload }) {
    const { applicationId, userId } = payload;
    try {
      const result = userId ? yield call(Applications.getApplicationById, applicationId, userId) : yield call(Applications.getApplication, applicationId);
      yield put(actions.loadApplicationSuccess(result));
    } catch (error) {
      yield put(actions.loadApplicationError(error));
    }
  });
}

export function* saveApplicationByModerator() {
  yield takeEvery(actions.SAVE_APPLICATION_BY_MODERATOR, function*({ payload }) {
    const { application, applicationId } = payload;
    try {
      yield call(Applications.saveApplicationByModerator, {application, applicationId});
      yield put(actions.sendApplicationSuccess());
      yield put(push(URLS.MODERATOR_APPLICATIONS_LIST));
    } catch (error) {
      yield put(actions.loadApplicationError(error));
    }
  });
}


export function* storeApplication() {
  yield takeEvery(actions.SAVE_APPLICATION, function*({ payload }) {
    const { data, actionName } = payload;
    let result;

    let id = data.id;
    if (!id && data.get) {
      id = data.get('id')
    }
    try {
      switch (actionName) {
        case 'update':
          result = yield call(Applications.updateApplication, id, data);
          yield put(actions.saveApplicationSuccess());
          yield put(push(URLS.USER_APPLICATION + id));
          break;
        default:
          result = yield call(Applications.saveApplication, data);
          yield put(actions.saveApplicationSuccess());
          yield put(push(URLS.USER_APPLICATION + result.id));
          break;
      }
    } catch (error) {
      yield put(actions.saveApplicationError(error));
    }
  });
}


export function* storeApplicationFile() {
  yield takeEvery(actions.SAVE_APPLICATION_FILE, function*({ payload }) {
    const { application } = payload;
    try {
      yield call(Applications.saveApplicationFile, application);
      yield put(actions.saveApplicationFileSuccess());
      yield put(push(URLS.USER_APPLICATIONS));
    } catch (error) {
      yield put(actions.saveApplicationFileError(error));
    }
  });
}

export function* storeWithdrawFile() {
  yield takeEvery(actions.SAVE_WITHDRAW_FILE, function*({ payload }) {
    const { application } = payload;
    try {
      yield call(Applications.saveWithdrawFile, application);
      yield put(actions.saveApplicationFileSuccess());
      yield put(push(URLS.USER_APPLICATIONS));
    } catch (error) {
      yield put(actions.saveApplicationFileError(error));
    }
  });
}

export function* storeResignFile() {
  yield takeEvery(actions.SAVE_RESIGN_FILE, function*({ payload }) {
    const { application } = payload;
    try {
      yield call(Applications.saveResignFile, application);
      yield put(actions.saveApplicationFileSuccess());
      yield put(push(URLS.USER_APPLICATIONS));
    } catch (error) {
      yield put(actions.saveApplicationFileError(error));
    }
  });
}

export function* storeTerminationFile() {
  yield takeEvery(actions.SAVE_TERMINATION_FILE, function*({ payload }) {
    const { application } = payload;
    try {
      yield call(Applications.saveTerminationFile, application);
      yield put(actions.saveApplicationFileSuccess());
      yield put(push(URLS.USER_APPLICATIONS));
    } catch (error) {
      yield put(actions.saveApplicationFileError(error));
    }
  });
}

export function* storeApplicationFileVerifier() {
  yield takeEvery(actions.SAVE_APPLICATION_FILE_VERIFIER, function*({ payload }) {
    const { application } = payload;
    try {
      yield call(Applications.saveApplicationFileVerifier, application);
      yield put(actions.saveApplicationFileVerifierSuccess());
      yield put(push(URLS.VERIFIER_APPLICATIONS_LIST));
    } catch (error) {
      yield put(actions.saveApplicationFileVerifierError(error));
    }
  });
}

export function* loadUserApplication() {
  yield takeEvery(actions.LOAD_USER_APPLICATION, function*({ payload }) {
    const { applicationId } = payload;
    try {
      const result = yield call(Applications.getUserApplication, applicationId);

      yield put(actions.loadApplicationSuccess(result));
    } catch (error) {
      yield put(actions.loadApplicationError(error));
    }
  });
}

export function* loadVerifierApplication() {
  yield takeEvery(actions.LOAD_VERIFIER_APPLICATION, function*({ payload }) {
    const { applicationId } = payload;
    try {
      const result = yield call(Applications.getVerifierApplication, applicationId);

      yield put(actions.loadApplicationSuccess(result));
    } catch (error) {
      yield put(actions.loadApplicationError(error));
    }
  });
}

export function* loadBackupApplication() {
  yield takeEvery(actions.LOAD_APPLICATION_BACKUP, function*({ payload }) {
    const { applicationId, backupId } = payload;
    try {
      const result = yield call(Applications.getApplicationBackup, applicationId, backupId);

      yield put(actions.loadApplicationSuccess(result));
    } catch (error) {
      yield put(actions.loadApplicationError(error));
    }
  });
}

export function* loadUserApplications() {
  yield takeEvery(actions.LOAD_USER_APPLICATIONS, function*({ payload }) {
    const { currentPage } = payload;
    try {
      const result = yield call(Applications.getUserApplications, currentPage);

      yield put(actions.loadApplicationsSuccess(result));
    } catch (error) {
      yield put(actions.loadApplicationsError(error));
    }
  });
}

export function* loadVerifierApplications() {
  yield takeEvery(actions.LOAD_VERIFIER_APPLICATIONS, function*({ payload }) {
    const { currentPage, query } = payload;
    try {
      const result = yield call(Applications.getVerifierApplications, {currentPage, query});

      yield put(actions.loadApplicationsSuccess(result));
    } catch (error) {
      yield put(actions.loadApplicationsError(error));
    }
  });
}

export function* loadAdminApplications() {
  yield takeEvery(actions.LOAD_ADMIN_APPLICATIONS, function*({ payload }) {
    const { currentPage, query } = payload;
    try {
      const result = yield call(Applications.getAdminApplications, {currentPage, query});

      yield put(actions.loadApplicationsSuccess(result));
    } catch (error) {
      yield put(actions.loadApplicationsError(error));
    }
  });
}

export function* sendApplication() {
  yield takeEvery(actions.SEND_APPLICATION, function*({ payload }) {
    const { applicationId, controlSum } = payload;
    try {
      yield call(Applications.sendApplication, {applicationId, controlSum});
      yield put(actions.sendApplicationSuccess());
      yield put(push(URLS.USER_APPLICATIONS));
    } catch (error) {
      yield put(actions.loadApplicationError(error));
      yield put(actions.sendApplicationError(error));
    }
  });
}

export function* duplicateApplication() {
  yield takeEvery(actions.DUPLICATE_APPLICATION, function*({ payload }) {
    const { id } = payload;
    try {
      yield call(Applications.duplicateApplication, {id});

      yield put(actions.loadUserApplications());
    } catch (error) {
      yield put(actions.loadApplicationError(error));
    }
  });
}

export function* sendStatus() {
  yield takeEvery(actions.SEND_STATUS, function*({ payload }) {
    const { applicationId, status } = payload;
    try {
      if(status === APPLICATION_STATUS.STATUS_CANCELED) {
        yield call(Applications.setApplicationCanceled, {applicationId});
      } else if(status === APPLICATION_STATUS.STATUS_VERIFIED) {
        yield call(Applications.setApplicationVerified, {applicationId});
      }
      yield put(push(URLS.MODERATOR_APPLICATIONS_LIST));
    } catch (error) {
      yield put(actions.loadApplicationError(error));
    }
  });
}

export function* changeAdminStatus() {
  yield takeEvery(actions.CHANGE_ADMIN_STATUS, function*({ payload }) {
    const { applicationId, status } = payload;
    try {
      yield call(Applications.changeAdminStatus, {applicationId, status});
      yield put(push(URLS.MODERATOR_APPLICATIONS_LIST));
    } catch (error) {
      yield put(actions.loadApplicationError(error));
    }
  });
}

export function* showFile() {
  yield takeEvery(actions.SHOW_FILE, function*({ payload }) {
    const { url } = payload;
    try {
      yield call(Applications.showFile, { url });
    } catch (error) {
      yield put(actions.loadApplicationError(error));
    }
  });
}

export function* acceptWithdrawal() {
  yield takeEvery(actions.ACCEPT_WITHDRAWAL, function*({ payload }) {
    const { id } = payload;
    try {
      yield call(Applications.acceptWithdrawal, { id });
      yield put(push(URLS.MODERATOR_APPLICATIONS_LIST));
    } catch (error) {
      yield put(actions.loadApplicationError(error));
    }
  });
}

export function* acceptResign() {
  yield takeEvery(actions.ACCEPT_RESIGN, function*({ payload }) {
    const { id } = payload;
    try {
      yield call(Applications.acceptResign, { id });
      yield put(push(URLS.MODERATOR_APPLICATIONS_LIST));
    } catch (error) {
      yield put(actions.loadApplicationError(error));
    }
  });
}


export function* acceptTermination() {
  yield takeEvery(actions.ACCEPT_TERMINATION, function*({ payload }) {
    const { id } = payload;
    try {
      yield call(Applications.acceptTermination, { id });
      yield put(push(URLS.MODERATOR_APPLICATIONS_LIST));
    } catch (error) {
      yield put(actions.loadApplicationError(error));
    }
  });
}

export function* saveAssignModerators() {
  yield takeEvery(actions.SAVE_ASSIGN_MODERATOR, function*({ payload }) {
    const { moderator, applications } = payload;
    try {
      yield call(Applications.saveAssignModerators, {moderator, applications});
      yield put(actions.loadAdminApplications());
    } catch (error) {
      yield put(actions.loadApplicationError(error));
    }
  });
}

export function* rejectApplicationFiles() {
  yield takeEvery(actions.REJECT_APPLICATION_FILES, function*({ payload }) {
    const { id, note_confirmation_files, note_signed_files } = payload;
    try {
      yield call(Applications.rejectApplicationFiles, { id, note_confirmation_files, note_signed_files });
      yield put(actions.rejectApplicationFilesSuccess());
      yield put(push(URLS.VERIFIER_APPLICATIONS_LIST));
    } catch (error) {
      yield put(actions.loadApplicationError(error));
      yield put(actions.rejectApplicationFilesError(error));
    }
  });
}

export function* correctSignedFiles() {
  yield takeEvery(actions.CORRECT_SIGNED_FILES, function*({ payload }) {
    try {
      yield call(Applications.correctSignedFiles, payload);
      yield put(actions.saveApplicationFileSuccess());
      yield put(push(URLS.USER_APPLICATIONS));
    } catch (error) {
      yield put(actions.loadApplicationError(error));
      yield put(actions.saveApplicationFileError(error));
    }
  });
}

export function* markAsPaid() {
  yield takeEvery(actions.MARK_AS_PAID, function*({ payload }) {
    const { id, paid, state } = payload;
    try {
      yield call(Applications.markAsPaid, {id, paid});
      yield put(actions.loadAdminApplications(state.currentPage, undefined, state));
    } catch (error) {
      yield put(actions.loadApplicationError(error));
    }
  });
}

export function* toggleAgreement() {
  yield takeEvery(actions.TOGGLE_AGREEMENT, function*({ payload }) {
    const { id, locked, state } = payload;
    try {
      yield call(Applications.toggleAgreement, {id, locked});
      yield put(actions.loadAdminApplications(state.currentPage, undefined, state));
    } catch (error) {
      yield put(actions.loadApplicationError(error));
    }
  });
}

export function* saveAdminEdit() {
  yield takeEvery(actions.SAVE_ADMIN_EDIT, function*({ payload }) {
    const { applicationId, type, values } = payload;
    try {
      if(type === 'cost') {
        yield call(Applications.saveAdminCost, {values});
      } else {
        yield call(Applications.saveAdminField, {values});
      }
      yield put(actions.saveAdminEditSuccess());
      yield put(actions.loadApplication(applicationId));
    } catch (error) {
      yield put(actions.saveAdminEditError());
      yield put(actions.loadApplicationError(error));
    }
  });
}

export function* saveContractors() {
  yield takeEvery(actions.SAVE_CONTRACTORS, function*({ payload }) {
    const { contractors } = payload;
    try {
        yield call(Applications.saveContractors, {contractors});
    } catch (error) {
      yield put(actions.loadApplicationError(error));
    }
  });
}

export function* showAmendment() {
  yield takeEvery(actions.SHOW_AMENDMENT, function*({ payload }) {
    const { applicationId, agreementDate, agreementExtend } = payload;
    try {
      yield call(Applications.showAmendment, {applicationId, agreementDate, agreementExtend});
    } catch (error) {
      yield put(actions.loadApplicationError(error));
    }
  });
}

export function* sendAmendment() {
  yield takeEvery(actions.SEND_AMENDMENT, function*({ payload }) {
    const { data } = payload;

    const id = data.get('id')
    try {
      yield call(Applications.updateApplication, id, data);
      yield put(actions.loadUserApplication(id));
    } catch (error) {
      yield put(actions.saveApplicationError(error));
    }
  });
}

export function* verifyAmendmentFiles() {
  yield takeEvery(actions.VERIFY_AMENDMENT_FILES, function*({ payload }) {
    const { id, note_signed_files, file_annex_signed } = payload;
    try {
      if(file_annex_signed) {
        yield call(Applications.signAmendmentFiles, { id, data: file_annex_signed });
      } else {
        yield call(Applications.rejectAmendmentFiles, { id, note_signed_files });
      }
      yield put(push(URLS.VERIFIER_APPLICATIONS_LIST));
    } catch (error) {
      yield put(actions.loadApplicationError(error));
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(loadApplications),
    fork(loadApplication),
    fork(storeApplication),
    fork(loadUserApplication),
    fork(loadUserApplications),
    fork(loadVerifierApplications),
    fork(loadVerifierApplication),
    fork(loadAdminApplications),
    fork(sendApplication),
    fork(saveApplicationByModerator),
    fork(duplicateApplication),
    fork(storeApplicationFile),
    fork(storeWithdrawFile),
    fork(storeTerminationFile),
    fork(storeApplicationFileVerifier),
    fork(sendStatus),
    fork(showFile),
    fork(acceptWithdrawal),
    fork(saveAssignModerators),
    fork(changeAdminStatus),
    fork(rejectApplicationFiles),
    fork(correctSignedFiles),
    fork(markAsPaid),
    fork(saveAdminEdit),
    fork(loadBackupApplication),
    fork(saveContractors),
    fork(showAmendment),
    fork(sendAmendment),
    fork(verifyAmendmentFiles),
    fork(acceptResign),
    fork(acceptTermination),
    fork(storeResignFile),
    fork(toggleAgreement)
  ]);
}