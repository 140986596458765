import Tln from '../components/Tln';

const DATE_FORMATS = {
  DEFAULT_FORMAT: 'DD-MM-YYYY HH:mm:ss',
};

const ROLE_NAMES = {
  ROLE_ADMIN: 'admin',
  ROLE_MODERATOR: 'moderator',
  ROLE_VERIFIER: 'cppc',
  ROLE_USER: 'beneficiary',
  ROLE_CONTROLLER: 'controller', //TODO: check is that role really exists
  ROLE_INSPECTOR: 'inspector',
  ROLE_INSPECTOR_MASTER: 'master',
  ROLE_STATEMENT_VERIFIER: 'verifier',
  ROLE_PUBLIC_PROCUREMENT_VERIFIER: 'public_procurement_verifier',
  ROLE_APPROVER: 'approver',
};

export const VERIFIER_ROLE = {
  ROLE_PRIMARY: 'primary',
  ROLE_SECONDARY: 'secondary',
  ROLE_APPROVER: 'approver',
};

const ROLE_NAMES_TLN = [
  {
    id: ROLE_NAMES.ROLE_ADMIN,
    name: <Tln id='ROLE_NAMES_ADMIN' />,
  },
  {
    id: ROLE_NAMES.ROLE_MODERATOR,
    name: <Tln id='ROLE_NAMES_MODERATOR' />,
  },
  {
    id: ROLE_NAMES.ROLE_VERIFIER,
    name: <Tln id='ROLE_NAMES_VERIFIER' />,
  },
  {
    id: ROLE_NAMES.ROLE_USER,
    name: <Tln id='ROLE_NAMES_USER' />,
  },
  {
    id: ROLE_NAMES.ROLE_APPROVER,
    name: <Tln id='ROLE_NAMES_APPROVER' />,
  },
  {
    id: ROLE_NAMES.ROLE_INSPECTOR,
    name: <Tln id='ROLE_NAMES_INSPECTOR' />,
  },
];

const LOGS_ACTION = {
  LOG_CREATED: 'created',
  LOG_UPDATED: 'updated',
  LOG_DELETED: 'deleted',
};

const LOGS_ACTION_TLN = [
  {
    id: LOGS_ACTION.LOG_CREATED,
    name: <Tln id='LOGS_ACTION_CREATED' />,
  },
  {
    id: LOGS_ACTION.LOG_UPDATED,
    name: <Tln id='LOGS_ACTION_UPDATED' />,
  },
  {
    id: LOGS_ACTION.LOG_DELETED,
    name: <Tln id='LOGS_ACTION_DELETED' />,
  },
];

const LOGS_OBJECTS = {
  LOG_APPLICATION: String.raw`App\Models\Application`,
  LOG_APPLICATION_ELIGIBLE_COST: String.raw`App\Models\ApplicationEligibleCost`,
  LOG_APPLICATION_FIELD: String.raw`App\Models\ApplicationField`,
  LOG_APPLICATION_FIELD_FILE: String.raw`App\Models\ApplicationFieldFile`,
  LOG_ELIGIBLE_COST: String.raw`App\Models\EligibleCosts`,
  LOG_FILES: String.raw`App\Models\Files`,
  LOG_ROUNDS: String.raw`App\Models\Rounds`,
  LOG_USER: String.raw`App\Models\User`,
};

const LOGS_OBJECTS_TLN = [
  {
    id: LOGS_OBJECTS.LOG_APPLICATION,
    name: <Tln id='LOG_OBJECT_APPLICATION' />,
  },
  {
    id: LOGS_OBJECTS.LOG_APPLICATION_ELIGIBLE_COST,
    name: <Tln id='LOG_OBJECT_APPLICATION_ELIGIBLE_COST' />,
  },
  {
    id: LOGS_OBJECTS.LOG_APPLICATION_FIELD,
    name: <Tln id='LOG_OBJECT_APPLICATION_FIELD' />,
  },
  {
    id: LOGS_OBJECTS.LOG_APPLICATION_FIELD_FILE,
    name: <Tln id='LOG_OBJECT_APPLICATION_FIELD_FILE' />,
  },
  {
    id: LOGS_OBJECTS.LOG_ELIGIBLE_COST,
    name: <Tln id='LOG_OBJECT_ELIGIBLE_COST' />,
  },
  {
    id: LOGS_OBJECTS.LOG_FILES,
    name: <Tln id='LOG_OBJECT_FILES' />,
  },
  {
    id: LOGS_OBJECTS.LOG_ROUNDS,
    name: <Tln id='LOG_OBJECT_ROUNDS' />,
  },
  {
    id: LOGS_OBJECTS.LOG_USER,
    name: <Tln id='LOG_OBJECT_USER' />,
  },
];

const APPLICATION_STATUS = {
  STATUS_DRAFT: 'draft',
  STATUS_NEW: 'new',
  STATUS_ACCEPTED: 'accepted',
  STATUS_UPDATED: 'updated',
  STATUS_REJECTED: 'rejected',
  STATUS_CANCELED: 'canceled',
  STATUS_TO_VERIFY: 'to_verify',
  STATUS_VERIFIED: 'verified',
  STATUS_SIGNED: 'signed',
  STATUS_TO_WITHDRAW: 'to_withdraw',
  STATUS_WITHDRAWN: 'withdrawn',
  STATUS_TO_RESIGN: 'to_resign',
  STATUS_RESIGNED: 'resigned',
  STATUS_TO_TERMINATION: 'to_renouncement',
  STATUS_TERMINATED: 'reneouncemented',
  STATUS_TO_BE_CORRECTED: 'to_be_corrected',
  STATUS_CORRECTED: 'corrected',
  STATUS_TO_AMEND: 'to_annex',
  STATUS_AMENDED: 'annex_sent',
  STATUS_AMENDMENT_TO_BE_CORRECTED: 'annex_to_correct',
  STATUS_AMENDMENT_CORRECTED: 'annex_corrected',
  STATUS_AMENDMENT_SIGNED: 'annex_signed',
};

const APPLICATION_STATUS_TLN = [];
const APPLICATION_BENEFICIARY_STATUS_TLN = [];

Object.keys(APPLICATION_STATUS).forEach(status => {
  APPLICATION_STATUS_TLN.push({ id: APPLICATION_STATUS[status], name: <Tln id={`APPLICATION_${status}`} /> });

  if (['STATUS_NEW', 'STATUS_ACCEPTED', 'STATUS_UPDATED', 'STATUS_REJECTED'].includes(status)) {
    APPLICATION_BENEFICIARY_STATUS_TLN.push({
      id: APPLICATION_STATUS[status],
      name: <Tln id={`APPLICATION_BENEFICIARY_${status}`} />,
    });
  } else {
    APPLICATION_BENEFICIARY_STATUS_TLN.push({ id: APPLICATION_STATUS[status], name: <Tln id={`APPLICATION_${status}`} /> });
  }
});

const STATEMENT_STATUS = {
  STATUS_STATEMENT_DRAFT: 'draft',
  STATUS_STATEMENT_DRAFT_SIGNING: 'draft_signing',
  STATUS_STATEMENT_NEW: 'settlement_new',
  STATUS_STATEMENT_REJECTED: 'rejected',
  STATUS_STATEMENT_REJECTED_SIGNING: 'rejected_signing',
  // STATUS_STATEMENT_SAVED: 'saved',
  STATUS_STATEMENT_UPDATED: 'updated',
  STATUS_STATEMENT_ACCEPTED: 'accepted',
  STATUS_STATEMENT_VERIFICATION: 'verification',
  STATUS_STATEMENT_VERIFIED: 'verified',
  STATUS_STATEMENT_APPROVEMENT: 'approvement',
  STATUS_STATEMENT_APPROVED: 'approved',
  STATUS_STATEMENT_APPROVED_TO_CORRECTION: 'approved_to_correction',
  STATUS_STATEMENT_VERIFIED_TO_CORRECTION: 'verified_to_correction',
  STATUS_STATEMENT_REJECTED_TO_VERIFICATION: 'rejected_to_verification',

  STATUS_STATEMENT_TO_INSPECTION: 'to_inspection',
  STATUS_STATEMENT_WAITING_TO_INSPECTION: 'waiting_to_inspection',
  STATUS_STATEMENT_INSPECTION_IN_PROGRESS: 'inspection_in_progress',
  STATUS_STATEMENT_INSPECTION_PREPARING_THE_CALL: 'preparing_the_call',
  STATUS_STATEMENT_INSPECTION_CALL_FOR_CLARIFICATION: 'call_for_clarification',
  STATUS_STATEMENT_INSPECTION_CALL_FOR_CLARIFICATION_ANSWERED: 'call_for_clarification_answered',
  STATUS_STATEMENT_INSPECTION_END: 'inspection_end',
  STATUS_STATEMENT_INSPECTION_INFORMATION_POST_INSPECTION: 'information_post_inspection',
  STATUS_STATEMENT_INSPECTION_INFORMATION_POST_INSPECTION_ANSWERED: 'information_post_inspection_answered',
  STATUS_STATEMENT_INSPECTION_FINAL_INFORMATION_POST_INSPECTION: 'final_information_post_inspection',
  STATUS_STATEMENT_INSPECTION_FINAL_INFORMATION_POST_INSPECTION_ANSWERED: 'final_information_post_inspection_answered',
  STATUS_STATEMENT_AFTER_INSPECTION_WITHOUT_RESERVATIONS: 'after_inspection_without_reservations',
  STATUS_STATEMENT_AFTER_INSPECTION_WITH_SIGNIFICANT_RESERVATIONS: 'after_inspection_with_significant_reservations',
  STATUS_STATEMENT_AFTER_INSPECTION_WITH_IRRELEVANT_RESERVATIONS: 'after_inspection_with_irrelevant_reservations',

  // STATUS_STATEMENT_TO_CHECK: 'settlement_to_check',
  // STATUS_STATEMENT_DOCUMENTS_SENT: 'settlement_documents_sent',
  // STATUS_STATEMENT_DOCUMENTS_REJECTED: 'settlement_documents_rejected',
  // STATUS_STATEMENT_DOCUMENTS_UPDATED: 'settlement_documents_updated',
  // STATUS_STATEMENT_CONTROL_ENDED: 'settlement_control_ended',
};
const STATEMENT_STATUS_TLN = [];

Object.keys(STATEMENT_STATUS).forEach(status => {
  STATEMENT_STATUS_TLN.push({ id: STATEMENT_STATUS[status], name: <Tln id={status} /> });
});

const VERIFICATION_STATEMENT_STATUS = {
  SUCCESSFULLY: 'successfully',
  FAILURE: 'failure',
  TO_INSPECTION: 'inspection',
  WITH_CORRECTION: 'correction',
  TO_VERIFICATION: 'verification',
};

const INSPECTION_STATEMENT_STATUS = {
  NORMAL: 'normal',
  RANDOM: 'random',
  AD_HOC: 'ad_hoc',
};

const NOTIFICATION_TYPES = {
  ERROR: 'error',
};

const UPLOAD_FILE = {
  VERIFIER_FILE_SIZE: 5,
  CHAT_RESPONSE_FILE_SIZE: 54,
  VERIFIER_FILES_MAX_COUNT: 5,
};

const APPLICATION_TYPE = {
  PGR: 'pgr',
  CG: 'cg',
};

const CYBERCOST = 'Diagnoza cyberbezpieczeństwa';
const CYBEROBJ = {
  category: 'Zapewnienie cyberbezpieczeństwa samorządowych systemów informatycznych',
  cost: {
    eligible_costs_id: 19,
    qty: 1,
    cost: 100,
    note: '',
    name: '',
  },
};

const ADVISE_NAME = 'Usługi doradcze';

const PAGINATION_SIZE = 100;

const EMAILS_PARAMS = ['page', 'search', 'status', 'type', 'read', 'from', 'to'];
const ADMIN_PARAMS = [
  'page',
  'search',
  'status',
  'type',
  'moderator',
  'id',
  'startDate',
  'endDate',
  'paid',
  'round',
  'agreement_locked',
];
const REACT_APP_DISTRICT_HOST = process.env.REACT_APP_DISTRICT_HOST ?? 'cyfrowy-powiat.p.lodz.pl';
const REACT_APP_VOIVODESHIP_HOST = process.env.REACT_APP_VOIVODESHIP_HOST ?? 'cyfrowe-wojewodztwo.p.lodz.pl';
const STATEMENT_PGR_NAMES = ['pc', 'laptop', 'tablet', 'software', 'insurance', 'internet'];

const VOIVODESHIPS = {
  dolnośląskie: [
    'powiat bolesławiecki',
    'powiat dzierżoniowski',
    'powiat głogowski',
    'powiat górowski',
    'powiat jaworski',
    'Jelenia Góra',
    'powiat kamiennogórski',
    'powiat karkonoski[d]',
    'powiat kłodzki',
    'Legnica',
    'powiat legnicki',
    'powiat lubański',
    'powiat lubiński',
    'powiat lwówecki',
    'powiat milicki',
    'powiat oleśnicki',
    'powiat oławski',
    'powiat polkowicki',
    'powiat strzeliński',
    'powiat średzki',
    'powiat świdnicki',
    'powiat trzebnicki',
    'Wałbrzych',
    'powiat wałbrzyski',
    'powiat wołowski',
    'Wrocław',
    'powiat wrocławski',
    'powiat ząbkowicki',
    'powiat zgorzelecki',
    'powiat złotoryjski',
  ],
  'kujawsko-pomorskie': [
    'powiat aleksandrowski',
    'powiat brodnicki',
    'powiat bydgoski',
    'Bydgoszcz',
    'powiat chełmiński',
    'powiat golubsko-dobrzyński',
    'Grudziądz',
    'powiat grudziądzki',
    'powiat inowrocławski',
    'powiat lipnowski',
    'powiat mogileński',
    'powiat nakielski',
    'powiat radziejowski',
    'powiat rypiński',
    'powiat sępoleński',
    'powiat świecki',
    'Toruń',
    'powiat toruński',
    'powiat tucholski',
    'powiat wąbrzeski',
    'Włocławek',
    'powiat włocławski',
    'powiat żniński',
  ],
  lubelskie: [
    'powiat bialski',
    'Biała Podlaska',
    'powiat biłgorajski',
    'Chełm',
    'powiat chełmski',
    'powiat hrubieszowski',
    'powiat janowski',
    'powiat krasnostawski',
    'powiat kraśnicki',
    'powiat lubartowski',
    'powiat lubelski',
    'Lublin',
    'powiat łęczyński',
    'powiat łukowski',
    'powiat opolski',
    'powiat parczewski',
    'powiat puławski',
    'powiat radzyński',
    'powiat rycki',
    'powiat świdnicki',
    'powiat tomaszowski',
    'powiat włodawski',
    'powiat zamojski',
    'Zamość',
  ],
  lubuskie: [
    'powiat gorzowski',
    'Gorzów Wielkopolski',
    'powiat krośnieński',
    'powiat międzyrzecki',
    'powiat nowosolski',
    'powiat słubicki',
    'powiat strzelecko-drezdenecki',
    'powiat sulęciński',
    'powiat świebodziński',
    'powiat wschowski',
    'Zielona Góra',
    'powiat zielonogórski',
    'powiat żagański',
    'powiat żarski',
  ],
  łódzkie: [
    'powiat bełchatowski',
    'powiat brzeziński',
    'powiat kutnowski',
    'powiat łaski',
    'powiat łęczycki',
    'powiat łowicki',
    'powiat łódzki wschodni',
    'Łódź',
    'powiat opoczyński',
    'powiat pabianicki',
    'powiat pajęczański',
    'powiat piotrkowski',
    'Piotrków Trybunalski',
    'powiat poddębicki',
    'powiat radomszczański',
    'powiat rawski',
    'powiat sieradzki',
    'Skierniewice',
    'powiat skierniewicki',
    'powiat tomaszowski',
    'powiat wieluński',
    'powiat wieruszowski',
    'powiat zduńskowolski',
    'powiat zgierski',
  ],
  małopolskie: [
    'powiat bocheński',
    'powiat brzeski',
    'powiat chrzanowski',
    'powiat dąbrowski',
    'powiat gorlicki',
    'powiat krakowski',
    'Kraków',
    'powiat limanowski',
    'powiat miechowski',
    'powiat myślenicki',
    'powiat nowosądecki',
    'powiat nowotarski',
    'Nowy Sącz',
    'powiat olkuski',
    'powiat oświęcimski',
    'powiat proszowicki',
    'powiat suski',
    'powiat tarnowski',
    'Tarnów',
    'powiat tatrzański',
    'powiat wadowicki',
    'powiat wielicki',
  ],
  mazowieckie: [
    'powiat białobrzeski',
    'powiat ciechanowski',
    'powiat garwoliński',
    'powiat gostyniński',
    'powiat grodziski',
    'powiat grójecki',
    'powiat kozienicki',
    'powiat legionowski',
    'powiat lipski',
    'powiat łosicki',
    'powiat makowski',
    'powiat miński',
    'powiat mławski',
    'powiat nowodworski',
    'powiat ostrołęcki',
    'Ostrołęka',
    'powiat ostrowski',
    'powiat otwocki',
    'powiat piaseczyński',
    'Płock',
    'powiat płocki',
    'powiat płoński',
    'powiat pruszkowski',
    'powiat przasnyski',
    'powiat przysuski',
    'powiat pułtuski',
    'Radom',
    'powiat radomski',
    'Siedlce',
    'powiat siedlecki',
    'powiat sierpecki',
    'powiat sochaczewski',
    'powiat sokołowski',
    'powiat szydłowiecki',
    'Warszawa',
    'powiat warszawski zachodni',
    'powiat węgrowski',
    'powiat wołomiński',
    'powiat wyszkowski',
    'powiat zwoleński',
    'powiat żuromiński',
    'powiat żyrardowski',
  ],
  opolskie: [
    'powiat brzeski',
    'powiat głubczycki',
    'powiat kędzierzyńsko-kozielski',
    'powiat kluczborski',
    'powiat krapkowicki',
    'powiat namysłowski',
    'powiat nyski',
    'powiat oleski',
    'Opole',
    'powiat opolski',
    'powiat prudnicki',
    'powiat strzelecki',
  ],
  podkarpackie: [
    'powiat bieszczadzki',
    'powiat brzozowski',
    'powiat dębicki',
    'powiat jarosławski',
    'powiat jasielski',
    'powiat kolbuszowski',
    'Krosno',
    'powiat krośnieński',
    'powiat leski',
    'powiat leżajski',
    'powiat lubaczowski',
    'powiat łańcucki',
    'powiat mielecki',
    'powiat niżański',
    'powiat przemyski',
    'Przemyśl',
    'powiat przeworski',
    'powiat ropczycko-sędziszowski',
    'powiat rzeszowski',
    'Rzeszów',
    'powiat sanocki',
    'powiat stalowowolski',
    'powiat strzyżowski',
    'Tarnobrzeg',
    'powiat tarnobrzeski',
  ],
  podlaskie: [
    'powiat augustowski',
    'powiat białostocki',
    'Białystok',
    'powiat bielski',
    'powiat grajewski',
    'powiat hajnowski',
    'powiat kolneński',
    'Łomża',
    'powiat łomżyński',
    'powiat moniecki',
    'powiat sejneński',
    'powiat siemiatycki',
    'powiat sokólski',
    'powiat suwalski',
    'Suwałki',
    'powiat wysokomazowiecki',
    'powiat zambrowski',
  ],
  pomorskie: [
    'powiat bytowski',
    'powiat chojnicki',
    'powiat człuchowski',
    'Gdańsk',
    'powiat gdański',
    'Gdynia',
    'powiat kartuski',
    'powiat kościerski',
    'powiat kwidzyński',
    'powiat lęborski',
    'powiat malborski',
    'powiat nowodworski',
    'powiat pucki',
    'Słupsk',
    'powiat słupski',
    'Sopot',
    'powiat starogardzki',
    'powiat sztumski',
    'powiat tczewski',
    'powiat wejherowski',
  ],
  śląskie: [
    'powiat będziński',
    'powiat bielski',
    'Bielsko-Biała',
    'powiat bieruńsko-lędziński',
    'Bytom',
    'Chorzów',
    'powiat cieszyński',
    'Częstochowa',
    'powiat częstochowski',
    'Dąbrowa Górnicza',
    'Gliwice',
    'powiat gliwicki',
    'Jastrzębie-Zdrój',
    'Jaworzno',
    'Katowice',
    'powiat kłobucki',
    'powiat lubliniecki',
    'powiat mikołowski',
    'Mysłowice',
    'powiat myszkowski',
    'Piekary Śląskie',
    'powiat pszczyński',
    'powiat raciborski',
    'Ruda Śląska',
    'powiat rybnicki',
    'Rybnik',
    'Siemianowice Śląskie',
    'Sosnowiec',
    'Świętochłowice',
    'powiat tarnogórski',
    'Tychy',
    'powiat wodzisławski',
    'Zabrze',
    'powiat zawierciański',
    'Żory',
    'powiat żywiecki',
  ],
  świętokrzyskie: [
    'powiat buski',
    'powiat jędrzejowski',
    'powiat kazimierski',
    'Kielce',
    'powiat kielecki',
    'powiat konecki',
    'powiat opatowski',
    'powiat ostrowiecki',
    'powiat pińczowski',
    'powiat sandomierski',
    'powiat skarżyski',
    'powiat starachowicki',
    'powiat staszowski',
    'powiat włoszczowski',
  ],
  'warmińsko-mazurskie': [
    'powiat bartoszycki',
    'powiat braniewski',
    'powiat działdowski',
    'Elbląg',
    'powiat elbląski',
    'powiat ełcki',
    'powiat giżycki',
    'powiat gołdapski',
    'powiat iławski',
    'powiat kętrzyński',
    'powiat lidzbarski',
    'powiat mrągowski',
    'powiat nidzicki',
    'powiat nowomiejski',
    'powiat olecki',
    'Olsztyn',
    'powiat olsztyński',
    'powiat ostródzki',
    'powiat piski',
    'powiat szczycieński',
    'powiat węgorzewski',
  ],
  wielkopolskie: [
    'powiat chodzieski',
    'powiat czarnkowsko-trzcianecki',
    'powiat gnieźnieński',
    'powiat gostyński',
    'powiat grodziski',
    'powiat jarociński',
    'powiat kaliski',
    'Kalisz',
    'powiat kępiński',
    'powiat kolski',
    'Konin',
    'powiat koniński',
    'powiat kościański',
    'powiat krotoszyński',
    'powiat leszczyński',
    'Leszno',
    'powiat międzychodzki',
    'powiat nowotomyski',
    'powiat obornicki',
    'powiat ostrowski',
    'powiat ostrzeszowski',
    'powiat pilski',
    'powiat pleszewski',
    'Poznań',
    'powiat poznański',
    'powiat rawicki',
    'powiat słupecki',
    'powiat szamotulski',
    'powiat średzki',
    'powiat śremski',
    'powiat turecki',
    'powiat wągrowiecki',
    'powiat wolsztyński',
    'powiat wrzesiński',
    'powiat złotowski',
  ],
  zachodniopomorskie: [
    'powiat białogardzki',
    'powiat choszczeński',
    'powiat drawski',
    'powiat goleniowski',
    'powiat gryficki',
    'powiat gryfiński',
    'powiat kamieński',
    'powiat kołobrzeski',
    'Koszalin',
    'powiat koszaliński',
    'powiat łobeski',
    'powiat myśliborski',
    'powiat policki',
    'powiat pyrzycki',
    'powiat sławieński',
    'powiat stargardzki',
    'Szczecin',
    'powiat szczecinecki',
    'powiat świdwiński',
    'Świnoujście',
    'powiat wałecki',
  ],
};

const STATEMENT_PARAMS = [
  'page',
  'search',
  'status',
  'type',
  'moderator',
  'id',
  'startDate',
  'endDate',
  'round',
  'stage',
  'verifier_role',
  'internal_note',
  'after_inspection',
  'after_correction',
];

const EQIPMENT_NAME = ['Komputer stacjonarny', 'Laptop', 'Tablet'];

export {
  DATE_FORMATS,
  LOGS_ACTION_TLN,
  LOGS_OBJECTS_TLN,
  APPLICATION_STATUS_TLN,
  APPLICATION_BENEFICIARY_STATUS_TLN,
  APPLICATION_STATUS,
  ROLE_NAMES_TLN,
  ROLE_NAMES,
  VERIFICATION_STATEMENT_STATUS,
  INSPECTION_STATEMENT_STATUS,
  NOTIFICATION_TYPES,
  UPLOAD_FILE,
  APPLICATION_TYPE,
  CYBERCOST,
  CYBEROBJ,
  PAGINATION_SIZE,
  ADVISE_NAME,
  EMAILS_PARAMS,
  ADMIN_PARAMS,
  REACT_APP_DISTRICT_HOST,
  REACT_APP_VOIVODESHIP_HOST,
  VOIVODESHIPS,
  STATEMENT_STATUS_TLN,
  STATEMENT_STATUS,
  STATEMENT_PARAMS,
  STATEMENT_PGR_NAMES,
  EQIPMENT_NAME,
};
