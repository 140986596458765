import SuperFetch from '../helpers/superFetch';
import TlnString from '../components/Tln/TlnString';
import { STATEMENT_PARAMS } from '../helpers/enums';

const searchParams = query => {
  const qs = new URLSearchParams();

  STATEMENT_PARAMS.forEach(param => {
    if (query[param]) {
      qs.append(param, query[param]);
    }
  });

  return qs.toString();
};

const Statements = {
  getAdminStatements: (query = {}) => {
    return SuperFetch.get(`admin/settlement?${searchParams(query)}`).then(response => response.data);
  },

  getVerifierStatements: (query = {}, type) => {
    return SuperFetch.get(`verify/list?${searchParams(query)}`).then(response => response.data);
  },

  getApproverStatements: (query = {}, type) =>
    SuperFetch.get(`approve/list?${searchParams(query)}`).then(response => response.data),

  getInspectorStatements: (query = {}, type) =>
    SuperFetch.get(`inspect/list?${searchParams(query)}`).then(response => response.data),

  getVerifierCppcStatements: (query = {}, type) =>
    SuperFetch.get(`operator/settlements/list?${searchParams(query)}`).then(response => response.data),

  getUserStatements: (currentPage = 1) => SuperFetch.get(`settlement?page=${currentPage}`).then(response => response.data),

  getUserByIdStatements: userId => SuperFetch.get(`admin/users/${userId}/statements`).then(response => response.data),

  getControllerStatements: (query = {}) =>
    SuperFetch.get(`controller/settlement?${searchParams(query)}`).then(response => response.data),

  loadVerifiers: () => SuperFetch.get(`admin/users/verifiers/list`).then(response => response.data),

  saveVerifiers: data =>
    SuperFetch.post(`admin/users/verifiers/assign`, data, TlnString('VERIFIERS_SAVE')).then(response => response.data),

  loadInspectors: () => SuperFetch.get(`inspect/master/inspectors`).then(response => response.data),

  saveInspectors: data =>
    SuperFetch.post(`inspect/master/assign`, data, TlnString('INSPECTORS_SAVE')).then(response => response.data),

  saveStatement: data => {
    return SuperFetch.put(`settlement/${data.id}`, data, TlnString('CRUD_EDIT_RECORD_SUCCESS')).then(response => response.data);
  },

  saveStatementFiles: (id, files) =>
    SuperFetch.post(`settlement/file/${id}`, files, TlnString('CRUD_EDIT_RECORD_SUCCESS')).then(response => response.data),

  getStatement: statementId => SuperFetch.get(`settlement/${statementId}`).then(response => response.data),

  getAdminStatement: (statementId, type) => SuperFetch.get(`admin/settlement/${statementId}`).then(response => response.data),

  getVerifierStatement: (statementId, type) => SuperFetch.get(`verify/settlement/${statementId}`).then(response => response.data),

  getVerifierCppcStatement: statementId =>
    SuperFetch.get(`operator/settlements/settlement/${statementId}`).then(response => response.data),

  getApproverStatement: statementId => SuperFetch.get(`approve/settlement/${statementId}`).then(response => response.data),

  getInspectorStatement: statementId => SuperFetch.get(`inspect/settlement/${statementId}`).then(response => response.data),

  createStatement: applicationId => SuperFetch.post(`settlement/create/${applicationId}`).then(response => response.data),

  saveStatementByAdmin: (id, data) =>
    SuperFetch.post(`verify/check-list/update/${id}`, data, TlnString('CRUD_EDIT_RECORD_SUCCESS')).then(
      response => response.data,
    ),

  saveStatementByApprover: (id, data) =>
    SuperFetch.post(`approve/fields/store/${id}`, data, TlnString('CRUD_EDIT_RECORD_SUCCESS')).then(response => response.data),

  sendStatement: data =>
    SuperFetch.post(`settlement/send/${data.get('id')}`, data, TlnString('CRUD_SEND_SETTLEMENT_SUCCESS')).then(
      response => response.data,
    ),

  changeAdminStatus: ({ statementId, status }) =>
    SuperFetch.put(`admin/settlement/status/${statementId}`, { status }).then(response => response.data),

  saveAdminField: ({ values }) =>
    SuperFetch.put(`admin/settlement/save-admin-field/${values.id}`, { ...values }, TlnString('CRUD_EDIT_RECORD_SUCCESS')).then(
      response => response.data,
    ),

  saveAdminCost: ({ values }) =>
    SuperFetch.put(`admin/settlement/save-admin-cost/${values.id}`, { ...values }, TlnString('CRUD_EDIT_RECORD_SUCCESS')).then(
      response => response.data,
    ),

  saveCost: ({ id, cost }) => SuperFetch.post(`settlement/control/${id}`, cost).then(response => response.data),

  loadStatementBackup: (statementId, backupId) =>
    SuperFetch.get(`admin/settlement/${statementId}/${backupId}`).then(response => response.data),

  saveControllerStatement: (status, id, data) =>
    SuperFetch.put(`controller/settlement/${status}/${id}`, { ...data }, TlnString('CRUD_EDIT_RECORD_SUCCESS')).then(
      response => response.data,
    ),

  removeDocumentFile: (costId, categoryId, file) =>
    SuperFetch.put(`settlement/remove-file/${costId}/${categoryId}/${file}`).then(response => response.data),

  removeDocumentFilePGR: (statement, budgetType, category, file) =>
    SuperFetch.delete(`settlement/${statement}/field/${budgetType}/document/${category}/${file}`).then(response => response.data),

  removeDocumentFileCost: (costId, file) =>
    SuperFetch.delete(`settlement/cost/${costId}/document/${file}`).then(response => response.data),

  saveSingleCost: (statementId, cost) =>
    SuperFetch.put(`settlement/save-cost/${statementId}`, cost, TlnString('CRUD_EDIT_RECORD_SUCCESS')).then(
      response => response.data,
    ),

  saveSingleAdminNote: (statementId, note) =>
    SuperFetch.post(`verify/note/store/${statementId}`, note, TlnString('CRUD_EDIT_RECORD_SUCCESS')).then(
      response => response.data,
    ),

  saveVerifiersResponseNote: ({ id, response }) =>
    SuperFetch.post(`verify/answers/store/${id}`, { answer: response }, TlnString('CRUD_EDIT_RECORD_SUCCESS')).then(
      response => response.data,
    ),

  sendToInspection: id =>
    SuperFetch.post(`inspect/action/inspect/${id}`, {}, TlnString('CRUD_EDIT_RECORD_SUCCESS')).then(response => response.data),

  startInspection: data =>
    SuperFetch.post(`inspect/action/begin/${data.get('id')}`, data, TlnString('CRUD_EDIT_RECORD_SUCCESS')).then(
      response => response.data,
    ),

  manageInspection: ({ statementId, action, data }) =>
    SuperFetch.post(
      `inspect/action/${action}/${statementId}`,
      data,
      TlnString(action === 'update' ? 'Odpowiedź została przesłana' : 'CRUD_EDIT_RECORD_SUCCESS'),
    ).then(response => response.data),

  approveInspection: ({ statementId, status }) =>
    SuperFetch.post(`inspect/action/approved/${statementId}`, { status: status }, TlnString('CRUD_EDIT_RECORD_SUCCESS')).then(
      response => response.data,
    ),

  toggleAdminNoteStatus: noteId =>
    SuperFetch.post(`verify/note/change-status/${noteId}`, {}, TlnString('CRUD_EDIT_RECORD_SUCCESS')).then(
      response => response.data,
    ),

  saveResponseNote: ({ id, response }) =>
    SuperFetch.post(`settlement/answers/store/${id}`, { answer: response }, TlnString('CRUD_EDIT_RECORD_SUCCESS')).then(
      response => response.data,
    ),

  statusChangeVerifier: ({ id, status }) =>
    SuperFetch.post(`verify/${status}/${id}`, {}, TlnString('CRUD_EDIT_RECORD_SUCCESS')).then(response => response.data),

  statusChangeApprover: ({ id, status }) =>
    SuperFetch.post(`approve/${status}/${id}`, {}, TlnString('CRUD_EDIT_RECORD_SUCCESS')).then(response => response.data),

  saveIneligibleCost: ({ model, fieldId, cost, sum }) =>
    SuperFetch.put(
      `verify/ineligible-costs/store/${model}/${fieldId}`,
      { cost, sum },
      TlnString('CRUD_EDIT_RECORD_SUCCESS'),
    ).then(response => response.data),

  toggleInternalNote: ({ id }) =>
    SuperFetch.put(`admin/settlement/internal-note/${id}`, {}, TlnString('CRUD_EDIT_RECORD_SUCCESS')).then(
      response => response.data,
    ),

  sendAllThreads: ({ statementId, action, data }) =>
    SuperFetch.post(`inspect/action/${action}/${statementId}`, data, TlnString('Uwagi zostały przesłane')),
  sendAllThreadsToDraft: ({ chatId, data }) => SuperFetch.post(`chat/${chatId}/draft`, data, TlnString('Szkic został zapisany')),

  addThread: ({ chatId, data, url }) =>
    SuperFetch.post(`chat/thread/${chatId}/${url}`, data, TlnString('CRUD_EDIT_RECORD_SUCCESS')),
  updateThread: ({ threadId, data, url }) =>
    SuperFetch.put(`chat/thread/${threadId}/${url}`, data, TlnString('CRUD_EDIT_RECORD_SUCCESS')),
  deleteThread: ({ threadId }) => SuperFetch.delete(`chat/thread/${threadId}`, {}, TlnString('CRUD_REMOVE_CHAT_THREAD_SUCCESS')),

  removeChatDocumentFile: ({ msgId, fileId }) =>
    SuperFetch.delete(`chat/thread/msg/${msgId}/${fileId}`, {}, TlnString('CRUD_REMOVE_FILE_SUCCESS')),

  addChatResponse: ({ threadId, data, updateUrl }) =>
    SuperFetch.post(`chat/thread/msg/${threadId}${updateUrl}`, data, TlnString('CRUD_EDIT_RECORD_SUCCESS')),

  addChatResponseFile: ({ data }) => SuperFetch.post(`chat/thread/attachment`, data, TlnString('CRUD_ADD_FILE_SUCCESS')),
};

export default Statements;
