import React from 'react';

import eu from '../../image/eu.jpg';
import { EuStyleWrapper } from './style';

const Footer = () => (
  <EuStyleWrapper>
    <img src={eu} alt="Logotypy unijne"/>
  </EuStyleWrapper>
);
export default Footer;