import actions from './actions';

const initState = {
  isLoading: false,
  data: [],
  settlement: {},
};

export default function reducer(state = initState, { type, payload }) {
  switch (type) {
    case actions.LOAD_DATA:
      return {
        ...state,
        isLoading: true,
      };
    case actions.LOAD_DATA_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: payload.data,
        settlement: payload.settlement,
      };
    case actions.LOAD_DATA_ERROR:
      return {
        ...state,
        isLoading: false,
      };
    case actions.SAVE_DATA_SUCCESS: {
      for(let i = 0; i < state.data.length; i++) {
        if(state.data[i].id === payload.data.id) {
          state.data[i] = payload.data;
        }
      }

      return {
        ...state,
        isLoading: false, 
        data: [...state.data]
      };
    }

    case actions.REMOVE_RECORD_SAVE: {
      for(let i = 0; i < state.data.length; i++) {
        if(state.data[i].id === payload.dataId) {
          state.data.splice(i, 1)
        }
      }

      return {
        ...state,
        isLoading: false, 
        data: [...state.data]
      };
    }

    case actions.ADD_DATA_SUCCESS: {
      state.data.push(payload.record);

      return {
        ...state,
        isLoading: false, 
        data: [...state.data]
      };
    }
    default:
      return state;
  }
}
