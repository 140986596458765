import React, { Component } from 'react';
import { connect } from 'react-redux';
import actions from '../../redux/auth/actions';
const { logout } = actions;

const withLoggedUserContainer = WrappedComponent => {

  class withLoggedUser extends Component {

    render() {
      return (
        <WrappedComponent
          logout={logout}
          {...this.props}
        />
      )
    }
  }

  return connect(
    null,
    mapDispatchToProps
  )(withLoggedUser);
};

const mapDispatchToProps = { logout };

export default withLoggedUserContainer;
