export const settlementsActions = {
  RESET_SETTLEMENT: 'RESET_SETTLEMENT',
  resetSettlement: () => ({
    type: settlementsActions.RESET_SETTLEMENT,
  }),

  LOAD_PROJECT_PROGRESS: 'LOAD_PROJECT_PROGRESS',
  loadProjectProgress: (id, prefix, user, signal) => ({
    type: settlementsActions.LOAD_PROJECT_PROGRESS,
    payload: { id, prefix, user, signal },
  }),

  LOAD_PROJECT_PROGRESS_SUCCESS: 'LOAD_PROJECT_PROGRESS_SUCCESS',
  loadProjectProgressSuccess: projectProgress => ({
    type: settlementsActions.LOAD_PROJECT_PROGRESS_SUCCESS,
    payload: { projectProgress },
  }),

  LOAD_PROJECT_PROGRESS_ERROR: 'LOAD_PROJECT_PROGRESS_ERROR',
  loadProjectProgressError: () => ({
    type: settlementsActions.LOAD_PROJECT_PROGRESS_ERROR,
  }),

  UPDATE_STATEMENT_CHANGES: 'UPDATE_STATEMENT_CHANGES',
  updateStatementChanges: (settlementId, statementChanges) => ({
    type: settlementsActions.UPDATE_STATEMENT_CHANGES,
    payload: { settlementId, statementChanges },
  }),

  UPDATE_STATEMENT_CHANGES_SUCCESS: 'UPDATE_STATEMENT_CHANGES_SUCCESS',
  updateStatementChangesSuccess: () => ({
    type: settlementsActions.UPDATE_STATEMENT_CHANGES_SUCCESS,
  }),

  UPDATE_STATEMENT_CHANGES_ERROR: 'UPDATE_STATEMENT_CHANGES_ERROR',
  updateStatementChangesError: () => ({
    type: settlementsActions.UPDATE_STATEMENT_CHANGES_ERROR,
  }),

  UPDATE_STATEMENT_CONCEPTION: 'UPDATE_STATEMENT_CONCEPTION',
  updateStatementConception: (settlementId, statementConception) => ({
    type: settlementsActions.UPDATE_STATEMENT_CONCEPTION,
    payload: { settlementId, statementConception },
  }),

  UPDATE_STATEMENT_CONCEPTION_SUCCESS: 'UPDATE_STATEMENT_CONCEPTION_SUCCESS',
  updateStatementConceptionSuccess: () => ({
    type: settlementsActions.UPDATE_STATEMENT_CONCEPTION_SUCCESS,
  }),

  UPDATE_STATEMENT_CONCEPTION_ERROR: 'UPDATE_STATEMENT_CONCEPTION_ERROR',
  updateStatementConceptionError: () => ({
    type: settlementsActions.UPDATE_STATEMENT_CONCEPTION_ERROR,
  }),

  LOAD_FINANCIAL_PROGRESS: 'LOAD_FINANCIAL_PROGRESS',
  loadFinancialProgress: (id, prefix, user, signal) => ({
    type: settlementsActions.LOAD_FINANCIAL_PROGRESS,
    payload: { id, prefix, user, signal },
  }),

  LOAD_FINANCIAL_PROGRESS_SUCCESS: 'LOAD_FINANCIAL_PROGRESS_SUCCESS',
  loadFinancialProgressSuccess: financialProgress => ({
    type: settlementsActions.LOAD_FINANCIAL_PROGRESS_SUCCESS,
    payload: { financialProgress },
  }),

  LOAD_FINANCIAL_PROGRESS_ERROR: 'LOAD_FINANCIAL_PROGRESS_ERROR',
  loadFinancialProgressError: () => ({
    type: settlementsActions.LOAD_FINANCIAL_PROGRESS_ERROR,
  }),

  LOAD_FINANCIAL_PROGRESS_CATEGORY_DETAILS: 'LOAD_FINANCIAL_PROGRESS_CATEGORY_DETAILS',
  loadFinancialProgressCategoryDetails: (settlementId, prefix, user, signal) => ({
    type: settlementsActions.LOAD_FINANCIAL_PROGRESS_CATEGORY_DETAILS,
    payload: { settlementId, prefix, user, signal },
  }),

  LOAD_FINANCIAL_PROGRESS_CATEGORY_DETAILS_SUCCESS: 'LOAD_FINANCIAL_PROGRESS_CATEGORY_DETAILS_SUCCESS',
  loadFinancialProgressCategoryDetailsSuccess: financialProgressCategoryDetails => ({
    type: settlementsActions.LOAD_FINANCIAL_PROGRESS_CATEGORY_DETAILS_SUCCESS,
    payload: { financialProgressCategoryDetails },
  }),

  LOAD_FINANCIAL_PROGRESS_CATEGORY_DETAILS_ERROR: 'LOAD_FINANCIAL_PROGRESS_CATEGORY_DETAILS_ERROR',
  loadFinancialProgressCategoryDetailsError: () => ({
    type: settlementsActions.LOAD_FINANCIAL_PROGRESS_CATEGORY_DETAILS_ERROR,
  }),

  UPDATE_COST_SETTLEMENT: 'UPDATE_COST_SETTLEMENT',
  updateCost: (cost, settlementId, needProcedureDocumentationReload) => ({
    type: settlementsActions.UPDATE_COST_SETTLEMENT,
    payload: { cost, settlementId, needProcedureDocumentationReload },
  }),

  UPDATE_COST_SETTLEMENT_SUCCESS: 'UPDATE_COST_SETTLEMENT_SUCCESS',
  updateCostSuccess: () => ({
    type: settlementsActions.UPDATE_COST_SETTLEMENT_SUCCESS,
  }),

  UPDATE_COST_SETTLEMENT_ERROR: 'UPDATE_COST_SETTLEMENT_ERROR',
  updateCostError: () => ({
    type: settlementsActions.UPDATE_COST_SETTLEMENT_ERROR,
  }),

  DELETE_COST_SETTLEMENT: 'DELETE_COST_SETTLEMENT',
  deleteCost: (costId, settlementId) => ({
    type: settlementsActions.DELETE_COST_SETTLEMENT,
    payload: { costId, settlementId },
  }),

  DELETE_COST_SETTLEMENT_SUCCESS: 'DELETE_COST_SETTLEMENT_SUCCESS',
  deleteCostSuccess: () => ({
    type: settlementsActions.DELETE_COST_SETTLEMENT_SUCCESS,
  }),

  DELETE_COST_SETTLEMENT_ERROR: 'DELETE_COST_SETTLEMENT_ERROR',
  deleteCostError: () => ({
    type: settlementsActions.DELETE_COST_SETTLEMENT_ERROR,
  }),

  DETACH_FILE: 'DETACH_FILE',
  detachFile: (costId, fileId) => ({
    type: settlementsActions.DETACH_FILE,
    payload: { costId, fileId },
  }),

  DETACH_ATTACHMENT_FILE: 'DETACH_ATTACHMENT_FILE',
  detachAttachmentFile: (attachmentId, fileId) => ({
    type: settlementsActions.DETACH_ATTACHMENT_FILE,
    payload: { attachmentId, fileId },
  }),

  DETACH_FIELD_FILE: 'DETACH_FIELD_FILE',
  detachFieldFile: (settlementId, budgetType, category, fileId) => ({
    type: settlementsActions.DETACH_FIELD_FILE,
    payload: { settlementId, budgetType, category, fileId },
  }),

  LOAD_PERSONAL_INFO: 'LOAD_PERSONAL_INFO',
  loadPersonalInfo: (id, prefix, user, signal) => ({
    type: settlementsActions.LOAD_PERSONAL_INFO,
    payload: { id, prefix, user, signal },
  }),

  LOAD_PERSONAL_INFO_SUCCESS: 'LOAD_PERSONAL_INFO_SUCCESS',
  loadPersonalInfoSuccess: personalInfo => ({
    type: settlementsActions.LOAD_PERSONAL_INFO_SUCCESS,
    payload: { personalInfo },
  }),

  LOAD_PERSONAL_INFO_ERROR: 'LOAD_PERSONAL_INFO_ERROR',
  loadPersonalInfoError: () => ({
    type: settlementsActions.LOAD_PERSONAL_INFO_ERROR,
  }),

  UPDATE_CONTACT_INFO: 'UPDATE_CONTACT_INFO',
  updateContactInfo: (id, data) => ({
    type: settlementsActions.UPDATE_CONTACT_INFO,
    payload: { id, data },
  }),

  ADD_REPRESENTATIVE_INFO: 'ADD_REPRESENTATIVE_INFO',
  addRepresentativeInfo: id => ({
    type: settlementsActions.ADD_REPRESENTATIVE_INFO,
    payload: { id },
  }),

  ADD_REPRESENTATIVE_INFO_SUCCESS: 'ADD_REPRESENTATIVE_INFO_SUCCESS',
  addRepresentativeInfoSuccess: representativeInfo => ({
    type: settlementsActions.ADD_REPRESENTATIVE_INFO_SUCCESS,
    payload: { representativeInfo },
  }),

  ADD_REPRESENTATIVE_INFO_ERROR: 'ADD_REPRESENTATIVE_INFO_ERROR',
  addRepresentativeInfoError: () => ({
    type: settlementsActions.ADD_REPRESENTATIVE_INFO_ERROR,
  }),

  UPDATE_REPRESENTATIVE_INFO: 'UPDATE_REPRESENTATIVE_INFO',
  updateRepresentativeInfo: (id, data) => ({
    type: settlementsActions.UPDATE_REPRESENTATIVE_INFO,
    payload: { id, data },
  }),

  DELETE_REPRESENTATIVE_INFO: 'DELETE_REPRESENTATIVE_INFO',
  deleteRepresentativeInfo: id => ({
    type: settlementsActions.DELETE_REPRESENTATIVE_INFO,
    payload: { id },
  }),

  DETACH_REPRESENTATIVE_FILE: 'DETACH_REPRESENTATIVE_FILE',
  detachRepresentativeFile: (representativeId, fileId) => ({
    type: settlementsActions.DETACH_REPRESENTATIVE_FILE,
    payload: { representativeId, fileId },
  }),

  LOAD_DECLARATIONS: 'LOAD_DECLARATIONS',
  loadDeclarations: (id, prefix, user, signal) => ({
    type: settlementsActions.LOAD_DECLARATIONS,
    payload: { id, prefix, user, signal },
  }),

  LOAD_DECLARATIONS_SUCCESS: 'LOAD_DECLARATIONS_SUCCESS',
  loadDeclarationsSuccess: declarations => ({
    type: settlementsActions.LOAD_DECLARATIONS_SUCCESS,
    payload: { declarations },
  }),

  LOAD_DECLARATIONS_ERROR: 'LOAD_DECLARATIONS_ERROR',
  loadDeclarationsError: () => ({
    type: settlementsActions.LOAD_DECLARATIONS_ERROR,
  }),

  UPDATE_DECLARATIONS: 'UPDATE_DECLARATIONS',
  updateDeclarations: (id, data) => ({
    type: settlementsActions.UPDATE_DECLARATIONS,
    payload: { id, data },
  }),

  UPDATE_DECLARATIONS_SUCCESS: 'UPDATE_DECLARATIONS_SUCCESS',
  updateDeclarationsSuccess: () => ({
    type: settlementsActions.UPDATE_DECLARATIONS_SUCCESS,
  }),

  UPDATE_DECLARATIONS_ERROR: 'UPDATE_DECLARATIONS_ERROR',
  updateDeclarationsError: () => ({
    type: settlementsActions.UPDATE_DECLARATIONS_ERROR,
  }),

  LOAD_SUMMARY: 'LOAD_SUMMARY',
  loadSummary: (id, prefix, user, signal) => ({
    type: settlementsActions.LOAD_SUMMARY,
    payload: { id, prefix, user, signal },
  }),

  LOAD_SUMMARY_SUCCESS: 'LOAD_SUMMARY_SUCCESS',
  loadSummarySuccess: summary => ({
    type: settlementsActions.LOAD_SUMMARY_SUCCESS,
    payload: { summary },
  }),

  LOAD_SUMMARY_ERROR: 'LOAD_SUMMARY_ERROR',
  loadSummaryError: () => ({
    type: settlementsActions.LOAD_SUMMARY_ERROR,
  }),

  LOAD_PROCEDURE_DOCUMENTATION: 'LOAD_PROCEDURE_DOCUMENTATION',
  loadProcedureDocumentation: (settlementId, prefix, user, signal) => ({
    type: settlementsActions.LOAD_PROCEDURE_DOCUMENTATION,
    payload: { settlementId, prefix, user, signal },
  }),

  LOAD_PROCEDURE_DOCUMENTATION_SUCCESS: 'LOAD_PROCEDURE_DOCUMENTATION_SUCCESS',
  loadProcedureDocumentationSuccess: procedureDocumentation => ({
    type: settlementsActions.LOAD_PROCEDURE_DOCUMENTATION_SUCCESS,
    payload: { procedureDocumentation },
  }),

  LOAD_PROCEDURE_DOCUMENTATION_ERROR: 'LOAD_PROCEDURE_DOCUMENTATION_ERROR',
  loadProcedureDocumentationError: () => ({
    type: settlementsActions.LOAD_PROCEDURE_DOCUMENTATION_ERROR,
  }),

  UPDATE_PROCEDURE: 'UPDATE_PROCEDURE',
  updateProcedure: (procedure, settlementId, needProcedureDocumentationReload) => ({
    type: settlementsActions.UPDATE_PROCEDURE,
    payload: { procedure, settlementId, needProcedureDocumentationReload },
  }),

  UPDATE_PROCEDURE_SUCCESS: 'UPDATE_PROCEDURE_SUCCESS',
  updateProcedureSuccess: () => ({
    type: settlementsActions.UPDATE_PROCEDURE_SUCCESS,
  }),

  UPDATE_PROCEDURE_ERROR: 'UPDATE_PROCEDURE_ERROR',
  updateProcedureError: () => ({
    type: settlementsActions.UPDATE_PROCEDURE_ERROR,
  }),

  DELETE_PROCEDURE: 'DELETE_PROCEDURE',
  deleteProcedure: (procedureId, settlementId) => ({
    type: settlementsActions.DELETE_PROCEDURE,
    payload: { procedureId, settlementId },
  }),

  DELETE_PROCEDURE_SUCCESS: 'DELETE_PROCEDURE_SUCCESS',
  deleteProcedureSuccess: () => ({
    type: settlementsActions.DELETE_PROCEDURE_SUCCESS,
  }),

  DELETE_PROCEDURE_ERROR: 'DELETE_PROCEDURE_ERROR',
  deleteProcedureError: () => ({
    type: settlementsActions.DELETE_PROCEDURE_ERROR,
  }),

  UPDATE_SUMMARY: 'UPDATE_SUMMARY',
  updateSummary: (id, data) => ({
    type: settlementsActions.UPDATE_SUMMARY,
    payload: { id, data },
  }),

  UPDATE_VALIDATION_ERRORS: 'UPDATE_VALIDATION_ERRORS',
  updateValidationErrors: (settlementId, data) => ({
    type: settlementsActions.UPDATE_VALIDATION_ERRORS,
    payload: { settlementId, data },
  }),

  SIGN_SETTLEMENT: 'SIGN_SETTLEMENT',
  signSettlement: settlementId => ({
    type: settlementsActions.SIGN_SETTLEMENT,
    payload: { settlementId },
  }),

  SIGN_SETTLEMENT_SUCCESS: 'SIGN_SETTLEMENT_SUCCESS',
  signSettlementSuccess: () => ({
    type: settlementsActions.SIGN_SETTLEMENT_SUCCESS,
  }),

  SIGN_SETTLEMENT_ERROR: 'SIGN_SETTLEMENT_ERROR',
  signSettlementError: () => ({
    type: settlementsActions.SIGN_SETTLEMENT_ERROR,
  }),

  LOAD_SETTLEMENT_DETAILS: 'LOAD_SETTLEMENT_DETAILS',
  loadSettlementDetails: (settlementId, prefix, user, signal) => ({
    type: settlementsActions.LOAD_SETTLEMENT_DETAILS,
    payload: { settlementId, prefix, user, signal },
  }),

  LOAD_SETTLEMENT_DETAILS_SUCCESS: 'LOAD_SETTLEMENT_DETAILS_SUCCESS',
  loadSettlementDetailsSuccess: settlementDetails => ({
    type: settlementsActions.LOAD_SETTLEMENT_DETAILS_SUCCESS,
    payload: { settlementDetails },
  }),

  LOAD_SETTLEMENT_DETAILS_ERROR: 'LOAD_SETTLEMENT_DETAILS_ERROR',
  loadSettlementDetailsError: () => ({
    type: settlementsActions.LOAD_SETTLEMENT_DETAILS_ERROR,
  }),

  ADD_SETTLEMENT_VERIFIER_NOTE: 'ADD_SETTLEMENT_VERIFIER_NOTE',
  addSettlementVerifierNote: (settlementId, type, id, note) => ({
    type: settlementsActions.ADD_SETTLEMENT_VERIFIER_NOTE,
    payload: { settlementId, type, id, note },
  }),

  ADD_SETTLEMENT_VERIFIER_NOTE_SUCCESS: 'ADD_SETTLEMENT_VERIFIER_NOTE_SUCCESS',
  addSettlementVerifierNoteSuccess: noteData => ({
    type: settlementsActions.ADD_SETTLEMENT_VERIFIER_NOTE_SUCCESS,
    payload: { noteData },
  }),

  ADD_SETTLEMENT_VERIFIER_NOTE_ERROR: 'ADD_SETTLEMENT_VERIFIER_NOTE_ERROR',
  addSettlementVerifierNoteError: () => ({
    type: settlementsActions.ADD_SETTLEMENT_VERIFIER_NOTE_ERROR,
  }),

  ADD_SETTLEMENT_VERIFIER_FIELD_NOTE: 'ADD_SETTLEMENT_VERIFIER_FIELD_NOTE',
  addSettlementVerifierFieldNote: (settlementId, note) => ({
    type: settlementsActions.ADD_SETTLEMENT_VERIFIER_FIELD_NOTE,
    payload: { settlementId, note },
  }),

  UPDATE_SETTLEMENT_VERIFIER_NOTE: 'UPDATE_SETTLEMENT_VERIFIER_NOTE',
  updateSettlementVerifierNote: (noteId, note) => ({
    type: settlementsActions.UPDATE_SETTLEMENT_VERIFIER_NOTE,
    payload: { noteId, note },
  }),

  UPDATE_SETTLEMENT_VERIFIER_NOTE_SUCCESS: 'UPDATE_SETTLEMENT_VERIFIER_NOTE_SUCCESS',
  updateSettlementVerifierNoteSuccess: noteId => ({
    type: settlementsActions.UPDATE_SETTLEMENT_VERIFIER_NOTE_SUCCESS,
    payload: { noteId },
  }),

  UPDATE_SETTLEMENT_VERIFIER_NOTE_ERROR: 'UPDATE_SETTLEMENT_VERIFIER_NOTE_ERROR',
  updateSettlementVerifierNoteError: () => ({
    type: settlementsActions.UPDATE_SETTLEMENT_VERIFIER_NOTE_ERROR,
  }),

  DELETE_SETTLEMENT_VERIFIER_NOTE: 'DELETE_SETTLEMENT_VERIFIER_NOTE',
  deleteSettlementVerifierNote: noteId => ({
    type: settlementsActions.DELETE_SETTLEMENT_VERIFIER_NOTE,
    payload: { noteId },
  }),

  DELETE_SETTLEMENT_VERIFIER_NOTE_SUCCESS: 'DELETE_SETTLEMENT_VERIFIER_NOTE_SUCCESS',
  deleteSettlementVerifierNoteSuccess: noteId => ({
    type: settlementsActions.DELETE_SETTLEMENT_VERIFIER_NOTE_SUCCESS,
    payload: { noteId },
  }),

  DELETE_SETTLEMENT_VERIFIER_NOTE_ERROR: 'DELETE_SETTLEMENT_VERIFIER_NOTE_ERROR',
  deleteSettlementVerifierNoteError: () => ({
    type: settlementsActions.DELETE_SETTLEMENT_VERIFIER_NOTE_ERROR,
  }),

  ADD_SETTLEMENT_VERIFIER_NOTE_ANSWER: 'ADD_SETTLEMENT_VERIFIER_NOTE_ANSWER',
  addSettlementVerifierNoteAnswer: (noteId, note) => ({
    type: settlementsActions.ADD_SETTLEMENT_VERIFIER_NOTE_ANSWER,
    payload: { noteId, note },
  }),

  ADD_SETTLEMENT_VERIFIER_NOTE_ANSWER_SUCCESS: 'ADD_SETTLEMENT_VERIFIER_NOTE_ANSWER_SUCCESS',
  addSettlementVerifierNoteAnswerSuccess: answerId => ({
    type: settlementsActions.ADD_SETTLEMENT_VERIFIER_NOTE_ANSWER_SUCCESS,
    payload: { answerId },
  }),

  ADD_SETTLEMENT_VERIFIER_NOTE_ANSWER_ERROR: 'ADD_SETTLEMENT_VERIFIER_NOTE_ANSWER_ERROR',
  addSettlementVerifierNoteAnswerError: () => ({
    type: settlementsActions.ADD_SETTLEMENT_VERIFIER_NOTE_ANSWER_ERROR,
  }),

  UPDATE_SETTLEMENT_VERIFIER_NOTE_ANSWER: 'UPDATE_SETTLEMENT_VERIFIER_NOTE_ANSWER',
  updateSettlementVerifierNoteAnswer: (answerId, note) => ({
    type: settlementsActions.UPDATE_SETTLEMENT_VERIFIER_NOTE_ANSWER,
    payload: { answerId, note },
  }),

  UPDATE_SETTLEMENT_VERIFIER_NOTE_ANSWER_SUCCESS: 'UPDATE_SETTLEMENT_VERIFIER_NOTE_ANSWER_SUCCESS',
  updateSettlementVerifierNoteAnswerSuccess: answerId => ({
    type: settlementsActions.UPDATE_SETTLEMENT_VERIFIER_NOTE_ANSWER_SUCCESS,
    payload: { answerId },
  }),

  UPDATE_SETTLEMENT_VERIFIER_NOTE_ANSWER_ERROR: 'UPDATE_SETTLEMENT_VERIFIER_NOTE_ANSWER_ERROR',
  updateSettlementVerifierNoteAnswerError: () => ({
    type: settlementsActions.UPDATE_SETTLEMENT_VERIFIER_NOTE_ANSWER_ERROR,
  }),

  SWITCH_SETTLEMENT_VERIFIER_NOTE_STATUS: 'SWITCH_SETTLEMENT_VERIFIER_NOTE_STATUS',
  switchSettlementVerifierNoteStatus: noteId => ({
    type: settlementsActions.SWITCH_SETTLEMENT_VERIFIER_NOTE_STATUS,
    payload: { noteId },
  }),

  UPDATE_SETTLEMENT_VERIFIER_INELIGIBLE_COST: 'UPDATE_SETTLEMENT_VERIFIER_INELIGIBLE_COST',
  updateSettlementVerifierIneligibleCost: (settlementId, costId, sum) => ({
    type: settlementsActions.UPDATE_SETTLEMENT_VERIFIER_INELIGIBLE_COST,
    payload: { settlementId, costId, sum },
  }),

  RESET_VERIFICATION_CHECKLIST: 'RESET_VERIFICATION_CHECKLIST',
  resetVerificationCheckList: () => ({
    type: settlementsActions.RESET_VERIFICATION_CHECKLIST,
  }),

  LOAD_VERIFICATION_CHECKLIST: 'LOAD_VERIFICATION_CHECKLIST',
  loadVerificationCheckList: (settlementId, prefix, signal) => ({
    type: settlementsActions.LOAD_VERIFICATION_CHECKLIST,
    payload: { settlementId, prefix, signal },
  }),

  LOAD_VERIFICATION_CHECKLIST_SUCCESS: 'LOAD_VERIFICATION_CHECKLIST_SUCCESS',
  loadVerificationCheckListSuccess: checkList => ({
    type: settlementsActions.LOAD_VERIFICATION_CHECKLIST_SUCCESS,
    payload: { checkList },
  }),

  LOAD_VERIFICATION_CHECKLIST_ERROR: 'LOAD_VERIFICATION_CHECKLIST_ERROR',
  loadVerificationCheckListError: () => ({
    type: settlementsActions.LOAD_VERIFICATION_CHECKLIST_ERROR,
  }),

  UPDATE_VERIFICATION_CHECKLIST: 'UPDATE_VERIFICATION_CHECKLIST',
  updateVerificationCheckList: (settlementId, data) => ({
    type: settlementsActions.UPDATE_VERIFICATION_CHECKLIST,
    payload: { settlementId, data },
  }),

  UPDATE_APPROVER_CHECKLIST: 'UPDATE_APPROVER_CHECKLIST',
  updateApproverCheckList: (settlementId, data) => ({
    type: settlementsActions.UPDATE_APPROVER_CHECKLIST,
    payload: { settlementId, data },
  }),

  UPDATE_APPROVER_CHECKLIST_SUCCESS: 'UPDATE_APPROVER_CHECKLIST_SUCCESS',
  updateApproverCheckListSuccess: (settlementId, data) => ({
    type: settlementsActions.UPDATE_APPROVER_CHECKLIST_SUCCESS,
    payload: { settlementId, data },
  }),

  UPDATE_APPROVER_CHECKLIST_ERROR: 'UPDATE_APPROVER_CHECKLIST_ERROR',
  updateApproverCheckListError: (settlementId, data) => ({
    type: settlementsActions.UPDATE_APPROVER_CHECKLIST_ERROR,
    payload: { settlementId, data },
  }),

  CHANGE_VERIFICATION_STATUS: 'CHANGE_VERIFICATION_STATUS',
  changeVerificationStatus: (settlementId, status, prefix) => ({
    type: settlementsActions.CHANGE_VERIFICATION_STATUS,
    payload: { settlementId, status, prefix },
  }),

  CHANGE_VERIFICATION_STATUS_SUCCESS: 'CHANGE_VERIFICATION_STATUS_SUCCESS',
  changeVerificationStatusSuccess: () => ({
    type: settlementsActions.CHANGE_VERIFICATION_STATUS_SUCCESS,
  }),

  CHANGE_VERIFICATION_STATUS_ERROR: 'CHANGE_VERIFICATION_STATUS_ERROR',
  changeVerificationStatusError: errors => ({
    type: settlementsActions.CHANGE_VERIFICATION_STATUS_ERROR,
    payload: { errors },
  }),

  LOAD_CORRECTION: 'LOAD_CORRECTION',
  loadCorrection: settlementId => ({
    type: settlementsActions.LOAD_CORRECTION,
    payload: { settlementId },
  }),

  LOAD_CORRECTION_SUCCESS: 'LOAD_CORRECTION_SUCCESS',
  loadCorrectionSuccess: correction => ({
    type: settlementsActions.LOAD_CORRECTION_SUCCESS,
    payload: { correction },
  }),

  LOAD_CORRECTION_ERROR: 'LOAD_CORRECTION_ERROR',
  loadCorrectionError: () => ({
    type: settlementsActions.LOAD_CORRECTION_ERROR,
  }),

  CHANGE_CORRECTION_VALUE_ADMIN: 'CHANGE_CORRECTION_VALUE_ADMIN',
  changeCorrectionValueAdmin: (settlementId, data) => ({
    type: settlementsActions.CHANGE_CORRECTION_VALUE_ADMIN,
    payload: { settlementId, data },
  }),

  CHANGE_CORRECTION_VALUE_ADMIN_SUCCESS: 'CHANGE_CORRECTION_VALUE_ADMIN_SUCCESS',
  changeCorrectionValueAdminSuccess: () => ({
    type: settlementsActions.CHANGE_CORRECTION_VALUE_ADMIN_SUCCESS,
  }),

  CHANGE_CORRECTION_VALUE_ADMIN_ERROR: 'CHANGE_CORRECTION_VALUE_ADMIN_ERROR',
  changeCorrectionValueAdminError: () => ({
    type: settlementsActions.CHANGE_CORRECTION_VALUE_ADMIN_ERROR,
  }),

  CHANGE_CORRECTION_VALUE_CPPC: 'CHANGE_CORRECTION_VALUE_CPPC',
  changeCorrectionValueCppc: (settlementId, data) => ({
    type: settlementsActions.CHANGE_CORRECTION_VALUE_CPPC,
    payload: { settlementId, data },
  }),

  CHANGE_CORRECTION_VALUE_CPPC_SUCCESS: 'CHANGE_CORRECTION_VALUE_CPPC_SUCCESS',
  changeCorrectionValueCppcSuccess: () => ({
    type: settlementsActions.CHANGE_CORRECTION_VALUE_CPPC_SUCCESS,
  }),

  CHANGE_CORRECTION_VALUE_CPPC_ERROR: 'CHANGE_CORRECTION_VALUE_CPPC_ERROR',
  changeCorrectionValueCppcError: () => ({
    type: settlementsActions.CHANGE_CORRECTION_VALUE_CPPC_ERROR,
  }),

  DETACH_CORRECTION_FILE: 'DETACH_CORRECTION_FILE',
  detachCorrectionFile: fileId => ({
    type: settlementsActions.DETACH_CORRECTION_FILE,
    payload: { fileId },
  }),

  LOAD_INSPECTION: 'LOAD_INSPECTION',
  loadInspection: (settlementId, prefix, user) => ({
    type: settlementsActions.LOAD_INSPECTION,
    payload: { settlementId, prefix, user },
  }),

  LOAD_INSPECTION_SUCCESS: 'LOAD_INSPECTION_SUCCESS',
  loadInspectionSuccess: inspection => ({
    type: settlementsActions.LOAD_INSPECTION_SUCCESS,
    payload: { inspection },
  }),

  LOAD_INSPECTION_ERROR: 'LOAD_INSPECTION_ERROR',
  loadInspectionError: () => ({
    type: settlementsActions.LOAD_INSPECTION_ERROR,
  }),
};
