const actions = {
  TOGGLE_COLLAPSED: 'TOGGLE_COLLAPSED',
  SET_SHOULD_LAYOUT_BE_FLEXIBLE: 'SET_SHOULD_LAYOUT_BE_FLEXIBLE',
  CHANGE_CURRENT_KEYS: 'CHANGE_CURRENT_KEYS',

  toggleCollapsed: (toggle) => ({
    type: actions.TOGGLE_COLLAPSED,
    payload: { toggle },
  }),
  setShouldLayoutBeFlexible: (flexible) => ({
    type: actions.SET_SHOULD_LAYOUT_BE_FLEXIBLE,
    payload: { flexible },
  }),
  changeCurrent: current => ({
    type: actions.CHANGE_CURRENT_KEYS,
    payload: { current }
  }),

};

export default actions;
