const actions = {
  LOAD_DATA: 'LOAD_DATA',
  loadData: (applicationId) => ({
    type: actions.LOAD_DATA,
    payload: { applicationId }
  }),

  LOAD_DATA_SUCCESS: 'LOAD_DATA_SUCCESS',
  loadDataSuccess: (data, settlement) => ({
    type: actions.LOAD_DATA_SUCCESS,
    payload: { data, settlement }
  }),

  LOAD_DATA_ERROR: 'LOAD_DATA_ERROR',
  loadDataError: error => ({
    type: actions.LOAD_DATA_ERROR,
    payload: { error }
  }),

  SAVE_RECORD: 'SAVE_RECORD',
  saveRecord: (applicationId, record) => ({
    type: actions.SAVE_RECORD,
    payload: { applicationId, record }
  }),

  SAVE_DATA_SUCCESS: 'SAVE_DATA_SUCCESS',
  saveDataSuccess: (data) => ({
    type: actions.SAVE_DATA_SUCCESS,
    payload: { data }
  }),

  REMOVE_RECORD: 'REMOVE_RECORD',
  deleteData: (applicationId, dataId) => ({
    type: actions.REMOVE_RECORD,
    payload: { applicationId, dataId }
  }),

  REMOVE_RECORD_SAVE: 'REMOVE_RECORD_SAVE',
  deleteDataSuccess: (dataId) => ({
    type: actions.REMOVE_RECORD_SAVE,
    payload: { dataId }
  }),

  ADD_DATA_SUCCESS: 'ADD_DATA_SUCCESS',
  addDataSuccess: (record) => ({
    type: actions.ADD_DATA_SUCCESS,
    payload: { record }
  }),
};

export default actions;