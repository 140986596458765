const actions = {
  LOAD_USERS: 'LOAD_USERS',
  LOAD_USERS_SUCCESS: 'LOAD_USERS_SUCCESS',
  LOAD_USERS_ERROR: 'LOAD_USERS_ERROR',

  LOAD_USER: 'LOAD_USER',
  LOAD_USER_SUCCESS: 'LOAD_USER_SUCCESS',
  LOAD_USER_ERROR: 'LOAD_USER_ERROR',

  LOAD_CURRENT_USER: 'CURRENT_USER_ROLES',
  LOAD_CURRENT_USER_SUCCESS: 'LOAD_CURRENT_USER_SUCCESS',
  LOAD_CURRENT_USER_ERROR: 'LOAD_CURRENT_USER_ERROR',

  SAVE_USER: 'SAVE_USER',
  SAVE_USER_ERROR: 'SAVE_USER_ERROR',

  TOGGLE_USER_MODAL: 'TOGGLE_USER_MODAL',

  DELETE_USER: 'DELETE_USER',
  DELETE_USER_SUCCESS: 'DELETE_USER_SUCCESS',
  DELETE_USER_ERROR: 'DELETE_USER_ERROR',

  LOAD_MODERATORS: 'LOAD_MODERATORS',
  LOAD_MODERATORS_SUCCESS: 'LOAD_MODERATORS_SUCCESS',

  loadUsers: (currentPage, teryt, email) => ({
    type: actions.LOAD_USERS,
    payload: {currentPage, teryt, email}
  }),
  loadUsersSuccess: data => ({
    type: actions.LOAD_USERS_SUCCESS,
    payload: { data }
  }),
  loadUsersError: error => ({
    type: actions.LOAD_USERS_ERROR,
    payload: { error }
  }),
  loadUser: userId => ({
    type: actions.LOAD_USER,
    payload: { userId }
  }),
  loadUserSuccess: data => ({
    type: actions.LOAD_USER_SUCCESS,
    payload: { data }
  }),
  loadUserError: error => ({
    type: actions.LOAD_USER_ERROR,
    payload: { error }
  }),
  saveUser: (data, actionName) => ({
    type: actions.SAVE_USER,
    payload: { data, actionName },
  }),
  saveUserError: error => ({
    type: actions.SAVE_USER_ERROR,
    payload: { error },
  }),
  toggleUserModal: (data = null) => ({
    type: actions.TOGGLE_USER_MODAL,
    payload: { data },
  }),
  loadModerators: () => ({
    type: actions.LOAD_MODERATORS,
    payload: {}
  }),
  loadModeratorsSuccess: data => ({
    type: actions.LOAD_MODERATORS_SUCCESS,
    payload: { data }
  }),


};

export default actions;