import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getTranslations } from '../../redux/languages/selectors';

class Tln extends Component {
  render() {
    const { translations, id } = this.props;

    return (
      <React.Fragment>
        {translations[id] ? translations[id] : id}
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => {
  return {
    translations: getTranslations(state)
  }
};

export default connect(
  mapStateToProps,
  null
)(Tln);
