import { call, all, takeEvery, put, fork } from 'redux-saga/effects';
import actions from './actions';
import authActions from '../auth/actions';
import Emails from '../../services/emails';
import { URLS } from '../../helpers/enumsUrls';
import { push } from 'connected-react-router';
import { ROLE_NAMES } from '../../helpers/enums';

export function* loadEmails() {
  yield takeEvery(actions.LOAD_EMAILS, function*({ payload }) {
    const { query, userId, mode } = payload;
    let result;
    try {
      if (mode === ROLE_NAMES.ROLE_ADMIN) {
        if(userId) {
          result = yield call(Emails.getEmailsForUser, userId, query);
        } else {
          result = yield call(Emails.getAdminEmails, query);
        }
      } else {
        result = yield call(Emails.getEmails, query);
      }
      
      yield put(actions.loadEmailsSuccess(result));
    } catch (error) {
      yield put(actions.loadEmailsError(error));
    }
  });
}

export function* loadEmail() {
  yield takeEvery(actions.LOAD_EMAIL, function*({ payload }) {
    const { emailId, mode, userId } = payload;
    let result;
    try {
      if(mode === ROLE_NAMES.ROLE_ADMIN) {
        if(userId) {
          result = yield call(Emails.getEmailForUser, userId, emailId);
        } else {
          result = yield call(Emails.getAdminEmail, emailId);
        }
      } else {
        result = userId ? yield call(Emails.getEmailById, emailId, userId) : yield call(Emails.getEmail, emailId);
      }
      yield put(authActions.getEmailsNumber());
      yield put(actions.loadEmailSuccess(result));
    } catch (error) {
      yield put(actions.loadEmailError(error));
    }
  });
}

export function* sendEmail() {
  yield takeEvery(actions.NEW_EMAIL, function*({ payload }) {
    const { status, type, title, text, mode, round } = payload;
    try {
      if( mode === ROLE_NAMES.ROLE_ADMIN) {
        yield call(Emails.sendAdminEmail, status, type, title, text, round);
        yield put(push(URLS.ADMIN_MY_EMAILS));
      } else {
        yield call(Emails.sendEmail, status, type, title, text);
        yield put(push(URLS.USER_MY_EMAILS));
      }
      yield put(actions.emailSended());

    } catch (error) {
      yield put(actions.loadEmailError(error));
      yield put(actions.emailSended());
    }
  });
}

export function* replyEmail() {
  yield takeEvery(actions.REPLY_EMAIL, function*({ payload }) {
    const { id, text, mode } = payload;
    try {
      yield call(Emails.replyEmail, id, text);
      if( mode === ROLE_NAMES.ROLE_ADMIN) {
        yield put(push(URLS.ADMIN_MY_EMAILS));
      } else {
        yield put(push(URLS.USER_MY_EMAILS));
      }

    } catch (error) {
      yield put(actions.loadEmailError(error));
    }
  });
}

export function* emailToUser() {
  yield takeEvery(actions.EMAIL_TO_USER, function*({ payload }) {
    const { userId, status, type, title, body } = payload;
    try {
      yield call(Emails.emailToUser, userId, status, type, title, body);
    } catch (error) {
      yield put(actions.loadEmailError(error));
    }
  });
}

export function* markAsRead() {
  yield takeEvery(actions.MARK_AS_READ, function*({ payload }) {
    const { id } = payload;
    try {
      yield call(Emails.markAsRead, id);
      yield put(push(URLS.ADMIN_MY_EMAILS));
    } catch (error) {
      yield put(actions.loadEmailError(error));
    }
  });
}


export default function* rootSaga() {
  yield all([
    fork(loadEmails),
    fork(loadEmail),
    fork(sendEmail),
    fork(replyEmail),
    fork(emailToUser),
    fork(markAsRead)
  ]);
}