import React from 'react';

import Tln from '../../components/Tln';

import PageWithImageStyleWrapper from '../style';

function Maintenance() {
  return (
    <PageWithImageStyleWrapper className="iso404Page">
      <div className="iso404Content maintenance">
        <h1>
          <Tln id="MAINTENANCE_TITLE" />
        </h1>
        <h3>
          <Tln id="MAINTENANCE_SUBTITLE" />
        </h3>
        <p>
          <Tln id="MAINTENANCE_DESC" />
        </p>
      </div>

      <div className="iso404Artwork"></div>
    </PageWithImageStyleWrapper>
  );
}

export default Maintenance;