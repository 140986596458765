const actions = {
  LOAD_LOGS: 'LOAD_LOGS',
  LOAD_LOGS_SUCCESS: 'LOAD_LOGS_SUCCESS',
  LOAD_LOGS_ERROR: 'LOAD_LOGS_ERROR',

  loadLogs: (currentPage, userId) => ({
    type: actions.LOAD_LOGS,
    payload: { currentPage, userId }
  }),
  loadLogsSuccess: data => ({
    type: actions.LOAD_LOGS_SUCCESS,
    payload: { data }
  }),
  loadLogsError: error => ({
    type: actions.LOAD_LOGS_ERROR,
    payload: { error }
  }),
};

export default actions;