const actions = {
  ADD_CATEGORY: 'ADD_CATEGORY',
  ADD_COST: 'ADD_COST',
  DELETE_COST: 'DELETE_COST',
  EDIT_COST: 'EDIT_COST',
  REMOVE_COSTS: 'REMOVE_COSTS',

  addCategory: (category, costs = []) => ({
    type: actions.ADD_CATEGORY,
    payload: { category, costs }
  }),

  addCost: (category, cost) => ({
    type: actions.ADD_COST,
    payload: { category, cost }
  }),

  deleteCost: (category, index) => ({
    type: actions.DELETE_COST,
    payload: { category, index }
  }),

  editCost: (category, index, cost) => ({
    type: actions.EDIT_COST,
    payload: { category, index, cost }
  }),

  removeCosts: () => ({
    type: actions.REMOVE_COSTS,
    payload: {  }
  }),
};

export default actions;