const ROUTER_URLS = {
  APP: '',
  MY_LOGS: 'my-logs',
  MY_ACCOUNT: 'my-account',
  USER_NEW_APPLICATION: 'new-application/:type',
  USER_EDIT_APPLICATION: 'edit-application/:applicationId',
  USER_MY_EMAILS: 'my-emails',
  USER_APPLICATION: 'application/:applicationId',
  USER_PGR_APPLICATION: 'application-pgr/:applicationId',
  USER_EDIT_PGR_APPLICATION: 'application-pgr-new',
  USER_APPLICATIONS: 'applications',
  USER_REGION_FORM: 'region-form',
  USER_NEW_EMAIL: 'new-email',
  ADMIN_USERS: 'admin/users',
  ADMIN_USERS_DETAILS: 'admin/users/:userId',
  ADMIN_ELIGIBLE_COSTS: 'admin/eligible-costs',
  ADMIN_MY_EMAILS: 'admin/my-emails',
  ADMIN_NEW_EMAIL: 'admin/new-email',
  ADMIN_USER_APPLICATION: 'application/:applicationId',
  ADMIN_USER_APPLICATIONS: 'applications',
  APPLICATION_BACKUP: 'admin/application/backup/:applicationId/:backupId',
  MODERATOR_APPLICATIONS_LIST: 'applications',
  MODERATOR_APPLICATION: 'moderator/application/:applicationId',
  VERIFIER_APPLICATIONS_LIST: 'verifier/applications-list',
  VERIFIER_APPLICATION: 'verifier/application/:applicationId',
  EMAIL_CHAT: 'email/:emailId',
  EMAIL_CHAT_USER: 'email/:emailId/:userId',
  MONITORING: 'application/:applicationId/monitoring',
  ADMIN_STATEMENTS: 'statements',
  USER_STATEMENTS: 'statements',
  VERIFIER_STATEMENTS: 'statements',
  APPROVER_STATEMENTS: 'statements',
  INSPECTOR_STATEMENTS: 'statements',
  VERIFIER_MY_EMAILS: 'my-emails',
  ADMIN_STATEMENT: 'statement/:statementId',
  USER_STATEMENT: 'statement/:statementId',
  VERIFIER_STATEMENT: 'statement/:statementId',
  APPROVER_STATEMENT: 'statement/:statementId',
  INSPECTOR_STATEMENT: 'statement/:statementId',
  INSPECTOR_EDIT_STATEMENT: 'edit-statement/:statementId',
  APPROVER_MY_EMAILS: 'my-emails',
  INSPECTOR_MY_EMAILS: 'my-emails',
  USER_NEW_STATEMENT: 'new-statement/:applicationId',
  USER_EDIT_STATEMENT: 'edit-statement/:statementId',
  CONTROLLER_STATEMENTS_LIST: 'statements/',
  CONTROLLER_STATEMENT: 'statement/:statementId',
  STATEMENT_BACKUP: 'admin/statement/backup/:statementId/:backupId',
};

const URLS = {
  APP: '/app',
  MY_LOGS: '/app/my-logs',
  MY_ACCOUNT: '/app/my-account',
  USER_NEW_APPLICATION: '/app/new-application/',
  USER_EDIT_APPLICATION: '/app/edit-application/',
  USER_MY_EMAILS: '/app/my-emails',
  USER_APPLICATION: '/app/application/',
  USER_APPLICATIONS: '/app/applications',
  USER_REGION_FORM: '/app/region-form',
  USER_EDIT_PGR_APPLICATION: '/app/application-pgr-new',
  USER_NEW_EMAIL: '/app/new-email',
  ADMIN_USERS: '/app/admin/users',
  ADMIN_USERS_DETAILS: '/app/admin/users/',
  ADMIN_ELIGIBLE_COSTS: '/app/admin/eligible-costs',
  ADMIN_MY_EMAILS: '/app/admin/my-emails',
  ADMIN_NEW_EMAIL: '/app/admin/new-email',
  ADMIN_USER_APPLICATION: '/app/application/',
  ADMIN_USER_APPLICATIONS: '/app/applications',
  APPLICATION_BACKUP: '/app/admin/application/backup',
  MODERATOR_APPLICATIONS_LIST: '/app/applications',
  MODERATOR_APPLICATION: '/app/moderator/application/',
  VERIFIER_MY_EMAILS: '/app/verifier/my-emails',
  APPROVER_MY_EMAILS: '/app/approver/my-emails',
  INSPECTOR_MY_EMAILS: '/app/inspector/my-emails',
  VERIFIER_APPLICATIONS_LIST: '/app/verifier/applications-list',
  VERIFIER_APPLICATION: '/app/verifier/application/',
  APPROVER_APPLICATIONS_LIST: '/app/approver/applications-list',
  APPROVER_APPLICATION: '/app/approver/application/',
  INSPECTOR_APPLICATIONS_LIST: '/app/inspector/applications-list',
  INSPECTOR_APPLICATION: '/app/inspector/application/',
  EMAIL_CHAT: '/app/email/',
  EMAIL_CHAT_USER: '/app/email/',
  ADMIN_STATEMENTS: '/app/statements',
  USER_STATEMENTS: '/app/statements',
  VERIFIER_STATEMENTS: '/app/statements',
  APPROVER_STATEMENTS: '/app/statements',
  INSPECTOR_STATEMENTS: '/app/statements',
  ADMIN_STATEMENT: '/app/statement/',
  USER_STATEMENT: '/app/statement/',
  VERIFIER_STATEMENT: '/app/statement/',
  APPROVER_STATEMENT: '/app/statement/',
  INSPECTOR_STATEMENT: '/app/statement/',
  INSPECTOR_EDIT_STATEMENT: '/app/edit-statement/',
  USER_NEW_STATEMENT: '/app/new-statement/',
  USER_EDIT_STATEMENT: '/app/edit-statement/',
  CONTROLLER_STATEMENTS_LIST: '/app/statements',
  CONTROLLER_STATEMENT: '/app/statement/',
  STATEMENT_BACKUP: '/app/admin/statement/backup',
};

const URLS_TO_ROUTER = [
  { key: URLS.APP, value: URLS.APP },
  { key: URLS.MY_LOGS, value: URLS.MY_LOGS },
  { key: URLS.MY_ACCOUNT, value: URLS.MY_ACCOUNT },
  { key: URLS.USER_EDIT_APPLICATION, value: URLS.USER_APPLICATIONS },
  { key: URLS.USER_NEW_APPLICATION, value: URLS.USER_APPLICATIONS },
  { key: URLS.USER_APPLICATION, value: URLS.USER_APPLICATIONS },
  { key: URLS.ADMIN_USER_APPLICATIONS, value: URLS.ADMIN_USER_APPLICATIONS },
  { key: URLS.USER_MY_EMAILS, value: URLS.USER_MY_EMAILS },
  { key: URLS.ADMIN_USERS, value: URLS.ADMIN_USERS },
  { key: URLS.ADMIN_USERS, value: URLS.ADMIN_USERS },
  { key: URLS.ADMIN_ELIGIBLE_COSTS, value: URLS.ADMIN_ELIGIBLE_COSTS },
  { key: URLS.ADMIN_MY_EMAILS, value: URLS.ADMIN_MY_EMAILS },
  { key: URLS.MODERATOR_APPLICATIONS_LIST, value: URLS.MODERATOR_APPLICATIONS_LIST },
  { key: URLS.VERIFIER_APPLICATIONS_LIST, value: URLS.VERIFIER_APPLICATIONS_LIST },
  { key: URLS.APPROVER_APPLICATIONS_LIST, value: URLS.APPROVER_APPLICATIONS_LIST },
  { key: URLS.ADMIN_STATEMENTS, value: URLS.ADMIN_STATEMENTS },
  { key: URLS.INSPECTOR_APPLICATIONS_LIST, value: URLS.INSPECTOR_APPLICATIONS_LIST },
];

const URLS_WITHOUT_LOGIN = {
  LOGIN: '/',
  LOGIN_WITH_PAGE: '/login',
  SIGN_UP: '/signup',
  REMIND_PASSWORD: '/remind-password',
  RESET_PASSWORD: '/reset-password',
  SET_PASSWORD: '/set-password',
  ACTIVATE_ACCOUNT: '/activate-account',
  FOUR_ZERO_FOUR: '/404',
  MAINTENANCE: '/maintenance',
};

export { ROUTER_URLS, URLS, URLS_TO_ROUTER, URLS_WITHOUT_LOGIN };
