import actions from './actions';

const initState = {
  collapsed: false,
  shouldLayoutBeFlexible: true,
  openKeys: [],
  current: []
};

export default function appReducer(state = initState, { type, payload }) {
  switch (type) {
    case actions.TOGGLE_COLLAPSED:
      return {
        ...state,
        collapsed: payload.toggle,
      };
    case actions.SET_SHOULD_LAYOUT_BE_FLEXIBLE: {
      return {
        ...state,
        shouldLayoutBeFlexible: payload.flexible,
      };
    }
    case actions.CHANGE_CURRENT_KEYS:
      return {
        ...state,
        current: payload.current
      };

    default:
      return state;
  }
}
