import React from 'react';
import { Button } from 'antd';
import { LoggedUserStyleWrapper } from './style';
import Tln from '../Tln';

import { UserAvatar } from './components';

const LoggedUser = ({user, logout}) => (
  <LoggedUserStyleWrapper>
    <UserAvatar user={user} />
    <Button type="primary" onClick={logout}><Tln id="AUTH_LOGOUT" /></Button>
  </LoggedUserStyleWrapper>
)

export default LoggedUser;
 