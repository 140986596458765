export const fieldMapperDataToDTO = data => {
  if (!data) {
    return null;
  }

  return {
    fields: Object.entries(data).map(([key, value]) => {
      if (key.endsWith('_note')) {
        return {
          field_name: key.replace('_note', ''),
          note: value,
        }
      } else {
        return {
          field_name: key,
          value: value,
        }
      }
    }),
  };
};

export const fieldMapperDataFromDTO = (dto, fieldName = "fields") => {
  if (!dto?.[fieldName]) {
    return null;
  }

  const obj = {};

  dto?.[fieldName].forEach(field => {
    const { field_name, value } = field;
    obj[field_name] = value;
  });

  return obj;
};

export const vatDeclarationMapperDataFromDTO = dto => {
  if (!dto?.declarations) {
    return null;
  }

  const obj = {};

  dto?.declarations.forEach(field => {
    const { field_name, value } = field;
    if (field.field_name === 'VAT_eligibility') {
      obj[field_name] = value;
    }
  });

  return obj;
};

export const vatDeclarationsNoteMapperDataFromDTO = dto => {
  if (!dto?.declarations) {
    return null;
  }

  const obj = {};

  dto?.declarations.forEach(field => {
    const { field_name, note, value } = field;
    if (field.field_name === 'VAT_statements_0_3' || field.field_name === 'VAT_statements_1_6') {
      obj[field_name] = value;
      obj[`${field_name}_note`] = note;
    }
  });

  return obj;
};

export const declarationsFilterMapperDataToDTO = (data) => {
  if (!data) {
    return null;
  }

  return {
    fields: Object.entries(data).map(([key, value]) => {
      if (key.endsWith('_note')) {
        return {
          field_name: key.replace('_note', ''),
          note: value,
        }
      } else {
        return {
          field_name: key,
          value: value,
        }
      }
    }),
  };
};

export const declarationMapperDataFromDTO = dto => {
  if (!dto?.declarations) {
    return null;
  }

  const obj = {};

  dto?.declarations.forEach(field => {
    const { field_name, value } = field;
    if (field.field_name.startsWith("criteria_")) {
      if (field.value === "1") {
        obj[field_name] = true;
      } else {
        obj[field_name] = false;
      }
    } else if (field.field_name !== 'VAT_eligibility') {
      obj[field_name] = value;
    }
  });

  return obj;
};
