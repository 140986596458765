import chroma from "chroma-js";

const theme = {};
const color1 = '#003977'; // dark blue
const color2 = '#1469C4'; // blue
const color3 = '#edbd32'; // yellow
//buttons
const color4 = '#FFFFFF'; // main
const color5 = '#52c41a'; // success
const color6 = '#ff4d4f'; // danger
const color7 = '#1469C4'; // info


theme.palette = {
  primary: [color2, chroma(color2).darken(), chroma(color2).brighten()],
  secondary: [color1, chroma(color1).darken(0.5), chroma(color1).brighten()],
  warning: [color3],
  success: ['#1b8400'],
  error: [color6, chroma(color6).brighten()],
  static_colors: [
    '#FFFFFF',            // 0: white
    '#000000',            // 1: black
    '#888',               // 2
    '#ddd',               // 3
    'rgba(0, 0, 0, 0.85)' // 4: text
  ],
  tableButtonsColors: [
    color4,  //0: main
    color5,  //1: success
    color6,  //2: danger
    color7,  //3: info
    '#000000',  //4: xxx
    color1 // darkBlue
  ],
  disabledButton: [
    '#d9d9d9', //0: border
    '#d9d9d9', //1: color
    '#f5f5f5', //2: background
  ]
};

theme.fonts = {
  primary: 'Noto Sans, sans-serif',
  pre: 'Consolas, Liberation Mono, Menlo, Courier, monospace',
};

export default theme;
