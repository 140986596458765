const getCollapsed = state => state.App.collapsed;

const getCurrent = state => state.App.current;

const getShouldLayoutBeFlexible = state => state.App.shouldLayoutBeFlexible;

export {
  getCollapsed,
  getCurrent,
  getShouldLayoutBeFlexible
};