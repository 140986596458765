import actions from './actions';

const initState = {
  isLoading: false,
  modalActive: false,
  users: [],
  user: {},
  roles: [],
  moderators: []
};

export default function reducer(state = initState, { type, payload }) {
  switch (type) {
    case actions.LOAD_USERS:
      return {
        ...state,
        currentPage: payload.currentPage,
        isLoading: true,
        modalActive: false,
      };
    case actions.LOAD_USERS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        users: payload.data.data,
        total: payload.data.total
      };
    case actions.LOAD_USERS_ERROR:
      return {
        ...state,
        isLoading: false,
      };

    case actions.LOAD_USER:
      return {
        ...state,
        isLoading: true,
        modalActive: false,
      };
    case actions.LOAD_USER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        user: payload.data,
        userVehicles: payload.data.user_vehicles
      };
    case actions.LOAD_USER_ERROR:
      return {
        ...state,
        isLoading: false,
      };

    case actions.TOGGLE_USER_MODAL:
      return {
        ...state,
        modalActive: !state.modalActive,
        selectedUser: payload.data == null ? initState.user : payload.data,
      };
    case actions.LOAD_MODERATORS_SUCCESS:
      return {
        ...state,
        moderators: payload.data
      };
    default:
      return state;
  }
}
