import { createSelector } from 'reselect';
import * as translations from '../../translations';
import { defaultActiveLanguage } from '../../settings';

const getActiveLanguage = state => state.Languages.activeLanguage;

const getTranslations = createSelector(
  [getActiveLanguage],
  (activeLanguage) => translations[activeLanguage] ?
    translations[activeLanguage] : translations[defaultActiveLanguage]
);

export {
  getTranslations,
  getActiveLanguage
}
