import styled from "styled-components";
import { palette } from 'styled-theme';

const DashAppStyleHolder = styled.div`
  .ant-btn-primary {
    background-color: ${palette('primary', 0)};
    border-color: ${palette('primary', 0)};
    color: ${palette('static_colors', 0)};
  }

  .ant-btn-primary:hover {
    background-color: ${palette('static_colors', 0)};
    border-color: ${palette('primary', 0)};
    color: ${palette('primary', 0)};
  }

  .ant-descriptions-item-container .ant-descriptions-item-label, .ant-descriptions-item-container .ant-descriptions-item-content {
    align-items: center;
  }

  .ant-btn-primary[disabled], .ant-btn-primary[disabled]:hover, .ant-btn-primary[disabled]:focus, .ant-btn-primary[disabled]:active, .ant-btn[disabled],
  .ant-btn[disabled]:hover, .ant-btn[disabled]:focus, .ant-btn[disabled]:active {
    background-color: ${palette('disabledButton', 2)};
    border-color: ${palette('disabledButton', 0)};
    color: ${palette('disabledButton', 1)};
  }

  .ant-checkbox-inner {
    border-color: ${palette('primary', 0)};
  }

  .ant-alert-with-description .ant-alert-message {
    font-weight: bold;
  }

  .margin-bottom {
    margin-bottom: 1rem;
  }

  .margin-top {
    margin-top: 1rem;
  }

  .no-margin {
    margin: 0;
  }

  .no-wrap {
    flex-wrap: nowrap;
  }

  .text-right {
    text-align: right;
  }
`;

const WhiteBox = styled.div`
  background-color: ${palette('static_colors', 0)};
  padding: 1rem;
  border-radius: 5px;
  box-shadow: 5px 5px 10px ${palette('static_colors', 3)};
  margin-bottom: 1rem;

  .ant-alert-warning {
    background: #FFFBE6;
    border: 1px solid rgb(255, 229, 143)
  }
`;

const WhiteBoxContainer = styled.div`
  border-radius: 8px;
  background-color: ${palette('static_colors', 0)};
  padding: 16px;
  margin-bottom: 16px;
  box-shadow: 0px 2px 8px ${palette('static_colors', 3)};
`;

export { DashAppStyleHolder, WhiteBox, WhiteBoxContainer };