import SuperFetch from '../helpers/superFetch';
import TlnString from '../components/Tln/TlnString';

const Users = {
  getUsers: (currentPage, teryt, email) =>
    SuperFetch.get(`admin/users?page=${currentPage}` +
        (teryt ? `&teryt=${teryt}&email=${email}` : '') +
        (email ? `&email=${email}` : '') )
      .then(response => response.data),

  getUser: userId =>
    SuperFetch.get(`admin/users/${userId}`)
      .then(response => response.data),

  getUserPaymentHistory: userId =>
    SuperFetch.get(`admin/users/payments-history/${userId}`)
      .then(response => response.data),

  toggleBlockUser: (userId, message) =>
    SuperFetch.put(`admin/users/${userId}/toggle-block`, {}, message)
      .then(response => response),

  saveUser: user =>
    SuperFetch.post('admin/users', user, TlnString("CRUD_ADD_RECORD_SUCCESS"))
      .then(response => response),

  updateUser: user =>
    SuperFetch.put(`admin/users/${user.id}`, user, TlnString("CRUD_EDIT_RECORD_SUCCESS"))
      .then(response => response),

  deleteUser: userId =>
    SuperFetch.delete(`admin/users/${userId}`, {}, TlnString("CRUD_REMOVE_RECORD_SUCCESS"))
      .then(response => response),

  blockUser: async userId =>
    SuperFetch.put(`admin/users/${userId}/block`, {}, TlnString("USERS_BLOCK_SUCCESS"))
      .then(response => response),

  unblockUser: async userId =>
    SuperFetch.put(`admin/users/${userId}/unblock`, {}, TlnString("USERS_UNBLOCK_SUCCESS"))
      .then(response => response),

  activateUser: async userId =>
    SuperFetch.put(`admin/users/${userId}/activate-account`, {}, TlnString("USERS_ACTIVATE_SUCCESS"))
      .then(response => response),

  loadModerators: () =>
    SuperFetch.get(`admin/users/moderators`)
      .then(response => response.data)
}
export default Users;
