import { call, all, takeEvery, put, fork } from 'redux-saga/effects';
import actions from './actions';
import EligibleCosts from '../../services/eligibleCosts';

export function* loadEligibleCosts() {
  yield takeEvery(actions.LOAD_ELIGIBLE_COSTS, function*({ payload }) {
    try {
      const result = yield call(EligibleCosts.getEligibleCosts, payload.currentPage);
      yield put(actions.loadEligibleCostsSuccess(result));
    } catch (error) {
      yield put(actions.loadEligibleCostsError(error));
    }
  });
}

export function* loadEligibleCostsCategories() {
  yield takeEvery(actions.LOAD_ELIGIBLE_COSTS_CATEGORIES, function*() {
    try {
      const result = yield call(EligibleCosts.getEligibleCostsCategories);
      yield put(actions.loadEligibleCostsCategoriesSuccess(result));
    } catch (error) {
      yield put(actions.loadEligibleCostsCategoriesError(error));
    }
  });
}

export function* loadEligibleCost() {
  yield takeEvery(actions.LOAD_ELIGIBLE_COST, function*({ payload }) {
    try {
      const result = yield call(EligibleCosts.getEligibleCost, payload);
      yield put(actions.loadEligibleCostSuccess(result));
    } catch (error) {
      yield put(actions.loadEligibleCostError(error));
    }
  });
}

export function* storeEligibleCost() {
  yield takeEvery(actions.SAVE_ELIGIBLE_COST, function*({ payload }) {
    const { data, actionName } = payload;
    try {
      switch (actionName) {
        case 'delete':
          yield call(EligibleCosts.deleteEligibleCost, data);
          break;
        case 'update':
          yield call(EligibleCosts.updateEligibleCost, data);
          break;
        default:
          yield call(EligibleCosts.saveEligibleCost, data);
          break;
      }
      yield put(actions.loadEligibleCosts(1));
    } catch (error) {
      yield put(actions.saveEligibleCostError(error));
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(loadEligibleCosts),
    fork(loadEligibleCost),
    fork(storeEligibleCost),
    fork(loadEligibleCostsCategories)
  ]);
}