import actions from './actions';

const initState = {
  isLoading: false,
  isLoadingSingleStatement: false,
  isSaving: false,
  isVerifierStatusSaving: false,
  isApproverStatusSaving: false,
  isInspectorStatusSaving: false,
  chatFile: null,
  statements: [],
  statement: {},
  verifiers: [],
  inspectors: [],
  total: null,
  filters: {},
  isLoadingFiles: false,
  infoModalActive: false,
};

export default function reducer(state = initState, { type, payload }) {
  switch (type) {
    case actions.RESET_STATE:
      return initState;
    case actions.RESET_STATEMENT:
      return {
        ...state,
        statement: initState.statement,
      };
    case actions.LOAD_STATEMENT:
      return {
        ...state,
        isLoadingSingleStatement: true,
      };
    case actions.LOAD_STATEMENT_SUCCESS:
      return {
        ...state,
        isLoadingSingleStatement: false,
        statement: { ...payload.data },
      };
    case actions.LOAD_STATEMENT_ERROR:
      return {
        ...state,
        isLoadingSingleStatement: false,
      };
    case actions.SEND_STATEMENT:
      return {
        ...state,
        isSaving: true,
      };
    case actions.SEND_STATEMENT_SUCCESS:
      return {
        ...state,
        isSaving: false,
      };
    case actions.SEND_STATEMENT_ERROR:
      return {
        ...state,
        isSaving: false,
      };
    case actions.LOAD_STATEMENTS:
      return {
        ...state,
        isLoading: true,
      };
    case actions.SAVE_STATEMENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        infoModalActive: true,
      };
    case actions.TOGGLE_INFO_MODAL:
      return {
        ...state,
        infoModalActive: payload.active,
      };
    case actions.LOAD_STATEMENTS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        statements: payload.data.data,
        total: payload.data.total,
      };
    case actions.LOAD_STATEMENTS_ERROR:
      return {
        ...state,
        isLoading: false,
        isLoadingFiles: false,
      };
    case actions.LOAD_VERIFIERS_SUCCESS:
      return {
        ...state,
        verifiers: payload.data,
      };
    case actions.LOAD_VERIFIERS_ERROR:
      return {
        ...state,
      };
    case actions.LOAD_INSPECTORS_SUCCESS:
      return {
        ...state,
        inspectors: payload.data,
      };
    case actions.LOAD_INSPECTORS_ERROR:
      return {
        ...state,
      };
    case actions.CLEAR_STATEMENT:
      return {
        ...state,
        isLoading: false,
        statement: undefined,
      };

    case actions.SAVE_CONTROL_DOCUMENTS:
      return {
        ...state,
        isLoadingFiles: true,
      };
    case actions.SAVE_CONTROL_DOCUMENTS_END:
      return {
        ...state,
        isLoadingFiles: false,
      };

    case actions.STATUS_CHANGE_VERIFIER:
      return {
        ...state,
        isVerifierStatusSaving: true,
      };
    case actions.STATUS_CHANGE_VERIFIER_SUCCESS:
      return {
        ...state,
        isVerifierStatusSaving: false,
      };
    case actions.STATUS_CHANGE_VERIFIER_ERROR:
      return {
        ...state,
        isVerifierStatusSaving: false,
      };
    case actions.STATUS_CHANGE_APPROVER:
      return {
        ...state,
        isApproverStatusSaving: true,
      };
    case actions.STATUS_CHANGE_APPROVER_SUCCESS:
      return {
        ...state,
        isApproverStatusSaving: false,
      };
    case actions.STATUS_CHANGE_APPROVER_ERROR:
      return {
        ...state,
        isApproverStatusSaving: false,
      };

    case actions.SEND_TO_INSPECTION:
      return {
        ...state,
        isInspectorStatusSaving: true,
      };
    case actions.START_INSPECTION:
      return {
        ...state,
        isInspectorStatusSaving: true,
      };
    case actions.START_INSPECTION_SUCCESS:
      return {
        ...state,
        isInspectorStatusSaving: false,
      };
    case actions.START_INSPECTION_ERROR:
      return {
        ...state,
        isInspectorStatusSaving: false,
      };
    case actions.ADD_ALL_THREAD_TO_INSPECTION:
      return {
        ...state,
        isInspectorStatusSaving: true,
      };
    case actions.SAVE_CHAT_TO_DB:
      return {
        ...state,
        isInspectorStatusSaving: false,
      };
    case actions.SAVE_CHAT_TO_DB_ERROR:
      return {
        ...state,
        isInspectorStatusSaving: false,
      };
    case actions.SEND_ALL_RESPONSES:
      return {
        ...state,
        isInspectorStatusSaving: true,
      };
    case actions.SEND_ALL_RESPONSES_TO_DRAFT:
      return {
        ...state,
        isInspectorStatusSaving: true,
      };
    case actions.ADD_ALL_THREAD_TO_DRAFT:
      return {
        ...state,
        isInspectorStatusSaving: true,
      };
    case actions.ADD_THREAD:
      return {
        ...state,
        isInspectorStatusSaving: true,
      };
    case actions.UPDATE_THREAD:
      return {
        ...state,
        isInspectorStatusSaving: true,
      };
    case actions.ADD_CHAT_RESPONSE:
      return {
        ...state,
        isInspectorStatusSaving: true,
      };
    case actions.ADD_CHAT_RESPONSE_FILE:
      return {
        ...state,
        isInspectorStatusSaving: true,
      };
    case actions.SAVE_RESPONSE_FILE_TO_DB:
      return {
        ...state,
        isInspectorStatusSaving: true,
      };
    case actions.SAVE_RESPONSE_FILE_TO_DB_SUCCESS:
      return {
        ...state,
        isInspectorStatusSaving: false,
        chatFile: payload.data.result,
      };
    case actions.SAVE_RESPONSE_FILE_TO_DB_ERROR:
      return {
        ...state,
        isInspectorStatusSaving: false,
      };
    case actions.MANAGE_INSPECTION:
      return {
        ...state,
        isInspectorStatusSaving: true,
      };
    case actions.APPROVE_INSPECTION:
      return {
        ...state,
        isInspectorStatusSaving: true,
      };

    default:
      return state;
  }
}
