export const getProjectProgress = state => state.Settlements.projectProgress;
export const getProjectProgressLoading = state => state.Settlements.projectProgressLoading;
export const getPersonalInfo = state => state.Settlements.personalInfo;
export const getPersonalInfoLoading = state => state.Settlements.personalInfoLoading;
export const getRepresentativeInfo = state => state.Settlements.representativeInfo;
export const getRepresentativeInfoLoading = state => state.Settlements.representativeInfoLoading;

export const getDeclarations = state => state.Settlements.declarations;
export const getDeclarationsLoading = state => state.Settlements.declarationsLoading;

export const getFinancialProgress = state => state.Settlements.financialProgress;
export const getFinancialProgressLoading = state => state.Settlements.financialProgressLoading;

export const getFinancialProgressCategoryDetails = state => state.Settlements.financialProgressCategoryDetails;
export const getFinancialProgressCategoryDetailsLoading = state => state.Settlements.financialProgressCategoryDetailsLoading;

export const getSummary = state => state.Settlements.summary;
export const getSummaryLoading = state => state.Settlements.summaryLoading;

export const getProcedures = state => state.Settlements.procedureDocumentation?.procurement ?? null;
export const getLegalBasis = state => state.Settlements.procedureDocumentation?.legal_basis ?? null;
export const getCostsForProcedures = state => state.Settlements.procedureDocumentation?.costs ?? null;
export const getProcedureDocumentation = state => state.Settlements.procedureDocumentation;
export const getProcedureDocumentationLoading = state => state.Settlements.procedureDocumentationLoading;

export const getSignSettlementLoading = state => state.Settlements.signSettlementLoading;

export const getSettlementDetails = state => state.Settlements.settlementDetails;
export const getSettlementDetailsLoading = state => state.Settlements.settlementDetailsLoading;

export const getVerifierNotesId = state => state.Settlements.notesId;
export const getVerifierNoteLoading = state => state.Settlements.verifierNoteLoading;
export const getBeneficiaryAnswersId = state => state.Settlements.answersId;
export const getBeneficiaryAnswerLoading = state => state.Settlements.answerLoading;

export const getVerificationCheckList = state => state.Settlements.checkList;
export const getVerificationCheckListLoading = state => state.Settlements.checkListLoading;

export const getVerificationStatusSaving = state => state.Settlements.isVerifierStatusSaving;
export const getVerificationStatusErrors = state => state.Settlements.isVerifierStatusErrors;

export const getCorrection = state => state.Settlements.correction;
export const getCorrectionLoading = state => state.Settlements.correctionLoading;
export const getCorrectionSending = state => state.Settlements.correctionSending;

export const getInspection = state => state.Settlements.inspection;
export const getInspectionLoading = state => state.Settlements.inspectionLoading;
