const actions = {
  LOAD_ELIGIBLE_COSTS: 'LOAD_ELIGIBLE_COSTS',
  LOAD_ELIGIBLE_COSTS_SUCCESS: 'LOAD_ELIGIBLE_COSTS_SUCCESS',
  LOAD_ELIGIBLE_COSTS_ERROR: 'LOAD_ELIGIBLE_COSTS_ERROR',

  LOAD_ELIGIBLE_COSTS_CATEGORIES: 'LOAD_ELIGIBLE_COSTS_CATEGORIES',
  LOAD_ELIGIBLE_COSTS_CATEGORIES_SUCCESS: 'LOAD_ELIGIBLE_COSTS_CATEGORIES_SUCCESS',
  LOAD_ELIGIBLE_COSTS_CATEGORIES_ERROR: 'LOAD_ELIGIBLE_COSTS_CATEGORIES_ERROR',

  LOAD_ELIGIBLE_COST: 'LOAD_ELIGIBLE_COST',
  LOAD_ELIGIBLE_COST_SUCCESS: 'LOAD_ELIGIBLE_COST_SUCCESS',
  LOAD_ELIGIBLE_COST_ERROR: 'LOAD_ELIGIBLE_COST_ERROR',

  SAVE_ELIGIBLE_COST: 'SAVE_ELIGIBLE_COSTS',
  SAVE_ELIGIBLE_COST_ERROR: 'SAVE_ELIGIBLE_COSTS_ERROR',

  TOGGLE_ELIGIBLE_COST_MODAL: 'TOGGLE_ELIGIBLE_COST_MODAL',

  DELETE_ELIGIBLE_COST: 'DELETE_USER',
  DELETE_ELIGIBLE_COST_SUCCESS: 'DELETE_ELIGIBLE_COST_SUCCESS',
  DELETE_ELIGIBLE_COST_ERROR: 'DELETE_ELIGIBLE_COST_ERROR',

  loadEligibleCosts: (currentPage) => ({
    type: actions.LOAD_ELIGIBLE_COSTS,
    payload: {currentPage}
  }),
  loadEligibleCostsSuccess: data => ({
    type: actions.LOAD_ELIGIBLE_COSTS_SUCCESS,
    payload: { data }
  }),
  loadEligibleCostsError: error => ({
    type: actions.LOAD_ELIGIBLE_COSTS_ERROR,
    payload: { error }
  }),
  loadEligibleCostsCategories: () => ({
    type: actions.LOAD_ELIGIBLE_COSTS_CATEGORIES,
    payload: {}
  }),
  loadEligibleCostsCategoriesSuccess: data => ({
    type: actions.LOAD_ELIGIBLE_COSTS_CATEGORIES_SUCCESS,
    payload: { data }
  }),
  loadEligibleCostsCategoriesError: error => ({
    type: actions.LOAD_ELIGIBLE_COSTS_CATEGORIES_ERROR,
    payload: { error }
  }),
  loadEligibleCost: eligibleCostId => ({
    type: actions.LOAD_ELIGIBLE_COST,
    payload: eligibleCostId
  }),
  loadEligibleCostSuccess: data => ({
    type: actions.LOAD_ELIGIBLE_COST_SUCCESS,
    payload: { data }
  }),
  loadEligibleCostError: error => ({
    type: actions.LOAD_ELIGIBLE_COST_ERROR,
    payload: { error }
  }),
  saveEligibleCost: (data, actionName) => ({
    type: actions.SAVE_ELIGIBLE_COST,
    payload: { data, actionName },
  }),
  saveEligibleCostError: error => ({
    type: actions.SAVE_ELIGIBLE_COST_ERROR,
    payload: { error },
  }),

  toggleEligibleCostModal: (data = null) => ({
    type: actions.TOGGLE_ELIGIBLE_COST_MODAL,
    payload: { data },
  }),
};

export default actions;