import actions from './actions';

const initState = {
  isLoading: false,
  isLoadingSingleEmail: false,
  isSending: false,
  emails: [],
  email: {},
  total: null,
};

export default function reducer(state = initState, { type, payload }) {
  switch (type) {
    case actions.LOAD_EMAILS:
      return {
        ...state,
        isLoading: true,
      };
    case actions.LOAD_EMAILS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        emails: payload.data.data,
        total: payload.data.total
      };
    case actions.LOAD_EMAILS_ERROR:
      return {
        ...state,
        isLoading: false,
      };

    case actions.LOAD_EMAIL:
      return {
        ...state,
        isLoadingSingleEmail: true,
      };
    case actions.LOAD_EMAIL_SUCCESS:
      return {
        ...state,
        isLoadingSingleEmail: false,
        email: payload.data,
      };
    case actions.LOAD_EMAIL_ERROR:
      return {
        ...state,
        isLoadingSingleEmail: false,
      };
    case actions.NEW_EMAIL:
      return {
        ...state,
        isSending: true
      };
    case actions.EMAIL_SENDED:
      return {
        ...state,
        isSending: false
      };

    default:
      return state;
  }
}
