import React, { useEffect, useState } from 'react';
import { Alert, Tooltip } from 'antd';
import moment from 'moment';
import 'moment/locale/pl';
import Tln from '../Tln';
import { DaysToEndWrapper } from './style';


const useDisplayDate = (pgrDate) => {
  const currentDate = moment();
  const limitedDate = moment(pgrDate);
  const pgrEndDate = limitedDate.format('YYYY-MM-DD');
  const dateInDays = limitedDate.diff(currentDate, 'days');
  let message = moment.duration(dateInDays, "days").humanize(true);

  if (currentDate.isSame(limitedDate, 'day')) {
    message = <Tln id="TODAY" />
  }

  if (currentDate.isBefore(limitedDate, 'day')) {
    message = moment.duration(dateInDays + 1, "days").humanize(true);
  }

  return { dateInDays, message, pgrEndDate };
}

const DaysToEnd = ({ pgrDate }) => {
  const [alertType, setAlertType] = useState('warning');
  const { dateInDays, message, pgrEndDate } = useDisplayDate(pgrDate);

  useEffect(() => {
    if (dateInDays <= 2) {
      setAlertType('error');
    }
  }, [dateInDays]);

  if (dateInDays > -1 && pgrDate) {
    return (
      <DaysToEndWrapper>
        <Tooltip placement="bottom" title={<><Tln id="APPLICATION_USER_DAYS_LEFT" /> {pgrEndDate} </>}>
          <Alert
            type={alertType}
            message={<> <Tln id="APPLICATION_USER_DAYS_LEFT" /> {message} </>}
            showIcon
          />
        </Tooltip>
      </DaysToEndWrapper>
    );
  }
  return <DaysToEndWrapper />;

}

export default DaysToEnd;
