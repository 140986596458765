import React from 'react';
import { Link } from 'react-router-dom';
import 'dotenv/config';

import { URLS } from '../../helpers/enumsUrls';

import logo from '../../image/cg_logo.png';
import logoDark from '../../image/cg_logo_dark.png';

import logoCp from '../../image/cp_logo.png';
import logoDarkCp from '../../image/cp_logo_dark.png';

import logoCw from '../../image/cw_logo.png';
import logoDarkCw from '../../image/cw_logo_dark.png';

import logoSmall from '../../image/cg_logo_small.png';
import { LogoStyleWrapper } from './style';
import { REACT_APP_DISTRICT_HOST, REACT_APP_VOIVODESHIP_HOST } from '../../helpers/enums';

const Logo = ({ dark, collapsed }) => {
  let logos = {
    cg: {
      dark: logoDark,
      light: logo
    },
    cp: {
      dark: logoDarkCp,
      light: logoCp
    },
    cw: {
      dark: logoDarkCw,
      light: logoCw
    }
  }

  let logoname = 'cg';
  
  if (REACT_APP_DISTRICT_HOST === window.location.hostname) {
    logoname = 'cp';
    document.title = "Cyfrowy Powiat"
  }

  if (REACT_APP_VOIVODESHIP_HOST === window.location.hostname) {
    logoname = 'cw';
    document.title = "Cyfrowe Województwo"
  }


  return (
    <LogoStyleWrapper>
      <Link to={`${URLS.APP}`} className="logo">
        {dark ? <img src={logos[logoname].dark} alt="Cyfrowa gmina" /> :
          collapsed ? <img src={logoSmall} alt="Cyfrowa gmina" className="collapsed" /> : <img src={logos[logoname].light} alt="Cyfrowa gmina" />}
      </Link>
    </LogoStyleWrapper>
  )
};
export default Logo;