const actions = {
  LOAD_STATEMENT_COSTS: 'LOAD_STATEMENT_COSTS',
  loadCosts: (statementId) => ({
    type: actions.LOAD_STATEMENT_COSTS,
    payload: { statementId }
  }),

  LOAD_STATEMENT_COSTS_SUCCESS: 'LOAD_STATEMENT_COSTS_SUCCESS',
  loadCostsSuccess: (costs) => ({
    type: actions.LOAD_STATEMENT_COSTS_SUCCESS,
    payload: { costs }
  }),

  SAVE_STATEMENT_COST: 'SAVE_STATEMENT_COST',
  saveCost: (statementId, cost) => ({
    type: actions.SAVE_STATEMENT_COST,
    payload: { cost, statementId }
  }),

  DELETE_STATEMENT_COST: 'DELETE_STATEMENT_COST',
  deleteCost: (statementId, costId) => ({
    type: actions.DELETE_STATEMENT_COST,
    payload: { statementId, costId }
  }),

  CLEAR_COSTS: 'CLEAR_COSTS',
  clearCosts: () => ({
    type: actions.CLEAR_COSTS,
    payload: { }
  }),
};

export default actions;