import { call, all, takeEvery, put, fork } from 'redux-saga/effects';
import { push } from 'connected-react-router';

import notification from '../../components/notification';

import actions from './actions';
import costsActions from '../statementCosts/actions';
import Statements from '../../services/statements';

import { ROLE_NAMES } from '../../helpers/enums';
import { URLS } from '../../helpers/enumsUrls';

export function* loadStatements() {
  yield takeEvery(actions.LOAD_STATEMENTS, function* ({ payload }) {
    const { userId, query, mode } = payload;
    yield put(actions.resetStatement());
    try {
      let result;
      switch (mode) {
        case ROLE_NAMES.ROLE_USER:
          result = yield call(Statements.getUserStatements, query.page);
          break;
        case ROLE_NAMES.ROLE_ADMIN:
          result = yield call(Statements.getAdminStatements, query);
          break;
        case ROLE_NAMES.ROLE_CONTROLLER:
          result = yield call(Statements.getControllerStatements, query);
          break;
        case ROLE_NAMES.ROLE_STATEMENT_VERIFIER:
          result = yield call(Statements.getVerifierStatements, query);
          break;
        case ROLE_NAMES.ROLE_APPROVER:
          result = yield call(Statements.getApproverStatements, query);
          break;
        case ROLE_NAMES.ROLE_INSPECTOR:
          result = yield call(Statements.getInspectorStatements, query);
          break;
        case ROLE_NAMES.ROLE_VERIFIER:
          result = yield call(Statements.getVerifierCppcStatements, query);
          break;
        default:
          if (userId) {
            result = yield call(Statements.getUserByIdStatements, userId);
          } else {
            console.error('ups, coś poszło nie tak');
          }
      }
      yield put(actions.loadStatementsSuccess(result));
    } catch (error) {
      yield put(actions.loadStatementsError(error));
    }
  });
}

export function* loadStatement() {
  yield takeEvery(actions.LOAD_STATEMENT, function* ({ payload }) {
    const { id } = payload;
    try {
      const result = yield call(Statements.getStatement, id);
      yield put(actions.loadStatementSuccess(result));
      yield put(costsActions.loadCostsSuccess(result.eligible_cost));
    } catch (error) {
      yield put(actions.loadStatementError(error));
    }
  });
}

export function* loadAdminStatement() {
  yield takeEvery(actions.LOAD_ADMIN_STATEMENT, function* ({ payload }) {
    const { id } = payload;
    try {
      const result = yield call(Statements.getAdminStatement, id);
      yield put(actions.loadStatementSuccess(result));
      yield put(costsActions.loadCostsSuccess({ data: result.eligible_cost }));
    } catch (error) {
      yield put(actions.loadStatementError(error));
    }
  });
}

export function* loadVerifierCppcStatement() {
  yield takeEvery(actions.LOAD_VERIFIER_CPPC_STATEMENT, function* ({ payload }) {
    const { id } = payload;
    try {
      const result = yield call(Statements.getVerifierCppcStatement, id);
      yield put(actions.loadStatementSuccess(result));
      yield put(costsActions.loadCostsSuccess({ data: result.eligible_cost }));
    } catch (error) {
      yield put(actions.loadStatementError(error));
    }
  });
}

export function* loadVerifierStatement() {
  yield takeEvery(actions.LOAD_VERIFIER_STATEMENT, function* ({ payload }) {
    const { id } = payload;
    try {
      const result = yield call(Statements.getVerifierStatement, id);
      yield put(actions.loadStatementSuccess(result));
      yield put(costsActions.loadCostsSuccess({ data: result.eligible_cost }));
    } catch (error) {
      yield put(actions.loadStatementError(error));
    }
  });
}

export function* loadApproverStatement() {
  yield takeEvery(actions.LOAD_APPROVER_STATEMENT, function* ({ payload }) {
    const { id } = payload;
    try {
      const result = yield call(Statements.getApproverStatement, id);
      yield put(actions.loadStatementSuccess(result));
      yield put(costsActions.loadCostsSuccess({ data: result.eligible_cost }));
    } catch (error) {
      yield put(actions.loadStatementError(error));
    }
  });
}

export function* loadInspectorStatement() {
  yield takeEvery(actions.LOAD_INSPECTOR_STATEMENT, function* ({ payload }) {
    const { id } = payload;
    try {
      const result = yield call(Statements.getInspectorStatement, id);
      yield put(actions.loadStatementSuccess(result));
      yield put(costsActions.loadCostsSuccess({ data: result.eligible_cost }));
    } catch (error) {
      yield put(actions.loadStatementError(error));
    }
  });
}

export function* createStatement() {
  yield takeEvery(actions.CREATE_STATEMENT, function* ({ payload }) {
    const { id } = payload;
    try {
      const result = yield call(Statements.createStatement, id);
      yield put(push(URLS.USER_EDIT_STATEMENT + result.id));
    } catch (error) {
      yield put(actions.loadStatementError(error));
    }
  });
}

export function* loadVerifiers() {
  yield takeEvery(actions.LOAD_VERIFIERS, function* () {
    try {
      const result = yield call(Statements.loadVerifiers);
      yield put(actions.loadVerifiersSuccess(result));
    } catch (error) {
      yield put(actions.loadVerifiersError(error));
    }
  });
}

export function* saveVerifiers() {
  yield takeEvery(actions.SAVE_VERIFIERS, function* ({ payload }) {
    const { selectedRows, primaryVerifier, secondaryVerifier, filters } = payload;
    try {
      yield call(Statements.saveVerifiers, { primary: primaryVerifier, secondary: secondaryVerifier, settlements: selectedRows });
      yield put(actions.loadStatements(undefined, filters, ROLE_NAMES.ROLE_ADMIN));
    } catch (error) {
      yield put(actions.loadStatementsError(error));
    }
  });
}

export function* loadInspectors() {
  yield takeEvery(actions.LOAD_INSPECTORS, function* () {
    try {
      const result = yield call(Statements.loadInspectors);
      yield put(actions.loadInspectorsSuccess(result));
    } catch (error) {
      yield put(actions.loadInspectorsError(error));
    }
  });
}

export function* saveInspectors() {
  yield takeEvery(actions.SAVE_INSPECTORS, function* ({ payload }) {
    const { selectedRows, manager, member, filters } = payload;
    try {
      yield call(Statements.saveInspectors, { manager: manager, member: member, settlements: selectedRows });
      yield put(actions.loadStatements(undefined, filters, ROLE_NAMES.ROLE_INSPECTOR));
    } catch (error) {
      yield put(actions.loadStatementsError(error));
    }
  });
}

export function* saveStatement() {
  yield takeEvery(actions.SAVE_STATEMENT, function* ({ payload }) {
    const { data, files } = payload;
    try {
      yield call(Statements.saveStatement, data);
      if (files) {
        yield call(Statements.saveStatementFiles, data.id, files);
      }
      yield put(actions.saveStatementSuccess(data.id));
      yield put(actions.loadStatement(data.id));
    } catch (error) {
      yield put(actions.loadStatementsError(error));
      yield put(actions.loadStatement(data.id));
    }
  });
}

export function* goToStatement() {
  yield takeEvery(actions.GO_TO_STATEMENT, function* ({ payload }) {
    const { statementId } = payload;
    yield put(push(URLS.USER_STATEMENT + statementId));
  });
}

export function* saveStatementByAdmin() {
  yield takeEvery(actions.SAVE_STATEMENT_BY_ADMIN, function* ({ payload }) {
    const { id, data } = payload;
    try {
      yield call(Statements.saveStatementByAdmin, id, data);
      yield put(actions.loadVerifierStatement(id));
    } catch (error) {
      yield put(actions.loadStatementError(error));
    }
  });
}

export function* saveStatementByApprover() {
  yield takeEvery(actions.SAVE_STATEMENT_BY_APPROVER, function* ({ payload }) {
    const { id, data } = payload;
    try {
      yield call(Statements.saveStatementByApprover, id, data);
      yield put(actions.loadApproverStatement(id));
    } catch (error) {
      yield put(actions.loadStatementError(error));
    }
  });
}

export function* sendStatement() {
  yield takeEvery(actions.SEND_STATEMENT, function* ({ payload }) {
    const { data } = payload;
    try {
      yield call(Statements.sendStatement, data);
      yield put(actions.sendStatementSuccess());
      yield put(push(URLS.USER_STATEMENTS));
    } catch (error) {
      yield put(actions.loadStatementError(error));
      yield put(actions.sendStatementError());
    }
  });
}

export function* changeAdminStatus() {
  yield takeEvery(actions.CHANGE_ADMIN_STATUS_STATEMENT, function* ({ payload }) {
    const { statementId, status } = payload;
    try {
      yield call(Statements.changeAdminStatus, { statementId, status });
      yield put(actions.loadStatement(statementId));
    } catch (error) {
      yield put(actions.loadStatementError(error));
    }
  });
}

export function* saveAdminEdit() {
  yield takeEvery(actions.SAVE_ADMIN_EDIT_STATEMENT, function* ({ payload }) {
    const { statementId, type, values } = payload;
    try {
      if (type === 'cost') {
        yield call(Statements.saveAdminCost, { values });
      } else {
        yield call(Statements.saveAdminField, { values });
      }
      yield put(actions.loadStatement(statementId));
    } catch (error) {
      yield put(actions.loadStatementError(error));
    }
  });
}

export function* loadStatementBackup() {
  yield takeEvery(actions.LOAD_STATEMENT_BACKUP, function* ({ payload }) {
    const { statementId, backupId } = payload;
    try {
      const result = yield call(Statements.loadStatementBackup, statementId, backupId);
      yield put(actions.loadStatementSuccess(result));
    } catch (error) {
      yield put(actions.loadStatementError(error));
    }
  });
}

export function* saveControllerStatement() {
  yield takeEvery(actions.SAVE_STATEMENT_CONTROLLER, function* ({ payload }) {
    const { statementId, status, data } = payload;
    try {
      yield call(Statements.saveControllerStatement, status, statementId, data);
      yield put(push(URLS.CONTROLLER_STATEMENTS_LIST));
    } catch (error) {
      yield put(actions.loadStatementError(error));
    }
  });
}

export function* removeDocumentFile() {
  yield takeEvery(actions.REMOVE_DOCUMENT_FILE, function* ({ payload }) {
    const { costId, categoryId, file } = payload;
    try {
      yield call(Statements.removeDocumentFile, costId, categoryId, file);
      notification('success', 'Usunięto plik');
    } catch (error) {
      yield put(actions.loadStatementError(error));
    }
  });
}

export function* removeDocumentFilePGR() {
  yield takeEvery(actions.REMOVE_DOCUMENT_FILE_PGR, function* ({ payload }) {
    const { statement, budgetType, category, file } = payload;
    try {
      yield call(Statements.removeDocumentFilePGR, statement, budgetType, category, file);
      notification('success', 'Usunięto plik');
    } catch (error) {
      yield put(actions.loadStatementError(error));
    }
  });
}

export function* removeDocumentFileCost() {
  yield takeEvery(actions.REMOVE_DOCUMENT_FILE_COST, function* ({ payload }) {
    const { costId, file } = payload;
    try {
      yield call(Statements.removeDocumentFileCost, costId, file);
      notification('success', 'Usunięto plik');
    } catch (error) {
      yield put(actions.loadStatementError(error));
    }
  });
}

export function* saveSingleCost() {
  yield takeEvery(actions.SAVE_SINGLE_COST, function* ({ payload }) {
    const { statementId, cost } = payload;
    try {
      yield call(Statements.saveSingleCost, statementId, cost);
    } catch (error) {
      yield put(actions.loadStatementError(error));
    }
  });
}

export function* saveSingleAdminNote() {
  yield takeEvery(actions.SAVE_SINGLE_ADMIN_NOTE, function* ({ payload }) {
    const { statementId, note } = payload;
    try {
      yield call(Statements.saveSingleAdminNote, statementId, note);
      yield put(actions.loadVerifierStatement(statementId));
    } catch (error) {
      yield put(actions.loadStatementError(error));
    }
  });
}

export function* saveVerifiersResponseNote() {
  yield takeEvery(actions.SAVE_VERIFIER_RESPONSE_NOTE, function* ({ payload }) {
    const { id, response, statementId } = payload;
    try {
      yield call(Statements.saveVerifiersResponseNote, { id, response });
      yield put(actions.loadVerifierStatement(statementId));
    } catch (error) {
      yield put(actions.loadStatementError(error));
    }
  });
}

export function* toggleAdminNoteStatus() {
  yield takeEvery(actions.TOGGLE_ADMIN_NOTE_STATUS, function* ({ payload }) {
    const { noteId, statementId } = payload;
    try {
      yield call(Statements.toggleAdminNoteStatus, noteId);
      yield put(actions.loadVerifierStatement(statementId));
    } catch (error) {
      yield put(actions.loadStatementError(error));
    }
  });
}

export function* saveResponseNote() {
  yield takeEvery(actions.SAVE_RESPONSE_NOTE, function* ({ payload }) {
    const { id, response, statementId } = payload;
    try {
      yield call(Statements.saveResponseNote, { id, response });
      yield put(actions.loadUserStatement(statementId)); //TODO: check this unused action
    } catch (error) {
      yield put(actions.loadStatementError(error));
    }
  });
}

export function* statusChangeVerifier() {
  yield takeEvery(actions.STATUS_CHANGE_VERIFIER, function* ({ payload }) {
    const { status, id } = payload;
    try {
      yield call(Statements.statusChangeVerifier, { id, status });
      yield put(actions.statusChangeVerifierSuccess());
      yield put(actions.loadVerifierStatement(id));
    } catch (error) {
      yield put(actions.loadStatementError(error));
      yield put(actions.statusChangeVerifierError());
    }
  });
}

export function* statusChangeApprover() {
  yield takeEvery(actions.STATUS_CHANGE_APPROVER, function* ({ payload }) {
    const { status, id } = payload;
    try {
      yield call(Statements.statusChangeApprover, { id, status });
      yield put(actions.statusChangeApproverSuccess());
      yield put(actions.loadApproverStatement(id));
    } catch (error) {
      yield put(actions.loadStatementError(error));
      yield put(actions.statusChangeApproverError());
    }
  });
}

export function* saveIneligibleCost() {
  yield takeEvery(actions.SAVE_INELIGIBLE_COST, function* ({ payload }) {
    const { model, fieldId, cost, sum, statementId } = payload;
    try {
      yield call(Statements.saveIneligibleCost, { model, fieldId, cost, sum });
      yield put(actions.loadVerifierStatement(statementId));
    } catch (error) {
      yield put(actions.loadStatementError(error));
    }
  });
}

export function* toggleInternalNote() {
  yield takeEvery(actions.TOGGLE_INTERNAL_NOTE, function* ({ payload }) {
    const { id } = payload;
    try {
      yield call(Statements.toggleInternalNote, { id });
      yield put(actions.loadStatements(undefined, undefined, ROLE_NAMES.ROLE_ADMIN));
    } catch (error) {
      yield put(actions.loadStatementsError(error));
    }
  });
}

export function* sendToInspection() {
  yield takeEvery(actions.SEND_TO_INSPECTION, function* ({ payload }) {
    const { id, filters } = payload;
    try {
      yield call(Statements.sendToInspection, id);
      yield put(actions.startInspectionSuccess());
      yield put(actions.loadStatements(undefined, filters, ROLE_NAMES.ROLE_INSPECTOR));
    } catch (error) {
      yield put(actions.loadStatementsError(error));
      yield put(actions.startInspectionError());
    }
  });
}

export function* startInspection() {
  yield takeEvery(actions.START_INSPECTION, function* ({ payload }) {
    const { data, filters } = payload;
    try {
      yield call(Statements.startInspection, data);
      yield put(actions.startInspectionSuccess());
      yield put(actions.loadStatements(undefined, filters, ROLE_NAMES.ROLE_INSPECTOR));
    } catch (error) {
      yield put(actions.loadStatementsError(error));
      yield put(actions.startInspectionError());
    }
  });
}
export function* manageInspection() {
  yield takeEvery(actions.MANAGE_INSPECTION, function* ({ payload }) {
    const { action, data } = payload;
    try {
      const statementId = data.get('statementId');

      yield call(Statements.manageInspection, { statementId, action, data });
      yield put(actions.saveChatToDb());
      if (action === 'preparing-the-call') {
        window.location.reload();
      } else if (
        action === 'call-for-clarification' ||
        action === 'update' ||
        action === 'sendInformationPostInspection' ||
        action === 'sendFinalInformationPostInspection'
      ) {
        yield put(push(URLS.INSPECTOR_STATEMENTS));
      }
      if (data.get('user') === ROLE_NAMES.ROLE_INSPECTOR) {
        yield put(actions.loadInspectorStatement(statementId));
      } else {
        yield put(actions.loadStatement(data.get('statementId')));
      }
    } catch (error) {
      yield put(actions.saveChatToDbError());
      yield put(actions.loadStatementsError(error));
    }
  });
}

export function* approveInspection() {
  yield takeEvery(actions.APPROVE_INSPECTION, function* ({ payload }) {
    const { status, data } = payload;
    try {
      const statementId = data.get('statementId');
      yield call(Statements.approveInspection, { statementId, status });
      yield put(actions.saveChatToDb());
      yield put(push(URLS.INSPECTOR_STATEMENTS));
    } catch (error) {
      yield put(actions.saveChatToDbError());
      yield put(actions.loadStatementsError(error));
    }
  });
}

export function* addAllThreadsToInspection() {
  yield takeEvery(actions.ADD_ALL_THREAD_TO_INSPECTION, function* ({ payload }) {
    const { statementId, action, data } = payload;
    try {
      yield call(Statements.sendAllThreads, { statementId, action, data });
      yield put(actions.saveChatToDb());
      if (action === 'reject') {
        yield put(push(URLS.INSPECTOR_STATEMENTS));
      } else if (action === 'end-inspection') {
        window.location.reload();
      }
      // yield put(actions.loadInspectorStatement(statementId));
    } catch (error) {
      yield put(actions.saveChatToDbError());
      yield put(actions.loadStatementsError(error));
    }
  });
}

export function* sendAllResponses() {
  yield takeEvery(actions.SEND_ALL_RESPONSES, function* ({ payload }) {
    const { statementId, action, data } = payload;
    try {
      yield call(Statements.sendAllThreads, { statementId, action, data });
      yield put(actions.saveChatToDb());
      if (action === 'update') {
        yield put(push(URLS.USER_STATEMENTS));
      }
    } catch (error) {
      yield put(actions.saveChatToDbError());
      yield put(actions.loadStatementsError(error));
    }
  });
}

export function* sendAllResponsesToDraft() {
  yield takeEvery(actions.SEND_ALL_RESPONSES_TO_DRAFT, function* ({ payload }) {
    const { statementId, chatId, data } = payload;
    try {
      yield call(Statements.sendAllThreadsToDraft, { chatId, data });
      yield put(actions.saveChatToDb());
      yield put(actions.loadStatement(statementId));
    } catch (error) {
      yield put(actions.saveChatToDbError());
      yield put(actions.loadStatementsError(error));
    }
  });
}

export function* addAllThreadsToDraft() {
  yield takeEvery(actions.ADD_ALL_THREAD_TO_DRAFT, function* ({ payload }) {
    const { statementId, chatId, data } = payload;
    try {
      yield call(Statements.sendAllThreadsToDraft, { chatId, data });
      yield put(actions.saveChatToDb());
      yield put(actions.loadInspectorStatement(statementId));
    } catch (error) {
      yield put(actions.saveChatToDbError());
      yield put(actions.loadStatementsError(error));
    }
  });
}

export function* addThread() {
  yield takeEvery(actions.ADD_THREAD, function* ({ payload }) {
    const { statementId, chatId, data, url } = payload;
    try {
      yield call(Statements.addThread, { chatId, data, url });
      yield put(actions.saveChatToDb());
      yield put(actions.loadInspectorStatement(statementId));
    } catch (error) {
      yield put(actions.saveChatToDbError());
      yield put(actions.loadStatementsError(error));
    }
  });
}

export function* updateThread() {
  yield takeEvery(actions.UPDATE_THREAD, function* ({ payload }) {
    const { statementId, threadId, data, url } = payload;
    try {
      yield call(Statements.updateThread, { threadId, data, url });
      yield put(actions.saveChatToDb());
      yield put(actions.loadInspectorStatement(statementId));
    } catch (error) {
      yield put(actions.saveChatToDbError());
      yield put(actions.loadStatementsError(error));
    }
  });
}

export function* deleteThread() {
  yield takeEvery(actions.DELETE_THREAD, function* ({ payload }) {
    const { statementId, threadId } = payload;
    try {
      yield call(Statements.deleteThread, { threadId });
      yield put(actions.loadInspectorStatement(statementId));
    } catch (error) {
      yield put(actions.loadStatementsError(error));
    }
  });
}

export function* removeChatDocumentFile() {
  yield takeEvery(actions.REMOVE_CHAT_DOCUMENT_FILE, function* ({ payload }) {
    const { statementId, msgId, fileId } = payload;
    try {
      yield call(Statements.removeChatDocumentFile, { msgId, fileId });
      yield put(actions.loadStatement(statementId));
    } catch (error) {
      yield put(actions.saveChatToDbError());
      yield put(actions.loadStatementError(error));
    }
  });
}

export function* addChatResponse() {
  yield takeEvery(actions.ADD_CHAT_RESPONSE, function* ({ payload }) {
    const { data } = payload;
    try {
      const threadId = data.get('threadId');
      const updateUrl = data.get('updateUrl'); //parameter to UPDATE resposne

      yield call(Statements.addChatResponse, { threadId, data, updateUrl });
      yield put(actions.saveChatToDb());
      if (data.get('user') === ROLE_NAMES.ROLE_INSPECTOR) {
        yield put(actions.loadInspectorStatement(data.get('statementId')));
      }
    } catch (error) {
      yield put(actions.saveChatToDbError());
      yield put(actions.loadStatementsError(error));
    }
  });
}

export function* addChatResponseFile() {
  yield takeEvery(actions.ADD_CHAT_RESPONSE_FILE, function* ({ payload }) {
    const { data } = payload;
    let result;
    try {
      result = yield call(Statements.addChatResponseFile, { data });
      yield put(actions.saveResponseFileToDbSuccess(result));
    } catch (error) {
      yield put(actions.saveResponseFileToDbError());
      yield put(actions.loadStatementsError(error));
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(loadStatements),
    fork(loadStatement),
    fork(loadVerifierStatement),
    fork(loadVerifiers),
    fork(saveVerifiers),
    fork(saveInspectors),
    fork(loadInspectors),
    fork(saveStatement),
    fork(createStatement),
    fork(saveStatementByAdmin),
    fork(saveStatementByApprover),
    fork(sendStatement),
    fork(changeAdminStatus),
    fork(saveAdminEdit),
    fork(loadStatementBackup),
    fork(saveControllerStatement),
    fork(removeDocumentFile),
    fork(saveSingleCost),
    fork(removeDocumentFilePGR),
    fork(removeDocumentFileCost),
    fork(loadAdminStatement),
    fork(loadVerifierCppcStatement),
    fork(loadApproverStatement),
    fork(loadInspectorStatement),
    fork(goToStatement),
    fork(saveSingleAdminNote),
    fork(saveVerifiersResponseNote),
    fork(toggleAdminNoteStatus),
    fork(saveResponseNote),
    fork(statusChangeVerifier),
    fork(statusChangeApprover),
    fork(saveIneligibleCost),
    fork(toggleInternalNote),
    fork(sendToInspection),
    fork(startInspection),
    fork(manageInspection),
    fork(approveInspection),
    fork(addThread),
    fork(addAllThreadsToInspection),
    fork(sendAllResponses),
    fork(sendAllResponsesToDraft),
    fork(addAllThreadsToDraft),
    fork(removeChatDocumentFile),
    fork(updateThread),
    fork(deleteThread),
    fork(addChatResponse),
    fork(addChatResponseFile),
  ]);
}
