import SuperFetch from '../helpers/superFetch';
import { fieldMapperDataToDTO } from '../helpers/fieldsMapper';
import TlnString from '../components/Tln/TlnString';

export const SettlementsAPI = {
  getProjectProgress: (settlementId, prefix, user, signal) =>
    SuperFetch.get(`${user ? `${user}/settlement` : prefix ?? 'settlement'}/V2/${settlementId}/project-progress`, null, null, signal).then(
      response => response.data,
    ),

  updateStatementChanges: (settlementId, statementChanges) =>
    SuperFetch.put(`settlement/V2/${settlementId}/fields/project-progress`, fieldMapperDataToDTO(statementChanges)),

  updateStatementConception: (settlementId, statementConception) =>
    SuperFetch.put(`settlement/V2/${settlementId}/fields/project-progress`, fieldMapperDataToDTO(statementConception)),

  getPersonalInfo: (id, prefix, user, signal) =>
    SuperFetch.get(`${user ? `${user}/settlement` : prefix ?? 'settlement'}/V2/${id}/details`, null, null, signal).then(response => response.data),

  updateContactInfo: ({ id, data }) => SuperFetch.put(`settlement/V2/contact-field/${id}`, fieldMapperDataToDTO(data)),

  addRepresentativeInfo: ({ id }) => SuperFetch.post(`settlement/V2/contact/${id}`),

  updateRepresentativeInfo: ({ id, data }) => SuperFetch.put(`settlement/V2/contact/${id}`, data),

  deleteRepresentativeInfo: ({ id }) => SuperFetch.delete(`settlement/V2/contact/${id}`),

  detachRepresentativeFile: (representativeId, fileId) =>
    SuperFetch.delete(`settlement/V2/contact/file/${representativeId}/${fileId}`),

  getDeclarations: (id, prefix, user, signal) =>
    SuperFetch.get(`${user ? `${user}/settlement` : prefix ?? 'settlement'}/V2/${id}/declarations`, null, null, signal).then(
      response => response.data,
    ),

  updateDeclarations: ({ id, data }) => SuperFetch.put(`settlement/V2/${id}/fields/declarations`, data),

  getFinancialProgress: (id, prefix, user, signal) =>
    SuperFetch.get(`${user ? `${user}/settlement` : prefix ?? 'settlement'}/V2/${id}/eligible-cost`, null, null, signal).then(
      response => response.data,
    ),

  getFinancialProgressCategoryDetails: (id, prefix, user, signal) =>
    SuperFetch.get(`${user ? `${user}/settlement` : prefix ?? 'settlement'}/V2/${id}/category-details`, null, null, signal).then(
      response => response.data,
    ),

  createCost: (settlementId, categoryName) =>
    SuperFetch.post(`settlement/V2/eligible-cost/${settlementId}`, { category_name: categoryName }).then(
      response => response.data,
    ),

  updateCost: cost => SuperFetch.put(`settlement/V2/eligible-cost/${cost.id}`, cost),

  deleteCost: costId => SuperFetch.delete(`settlement/V2/eligible-cost/${costId}`),

  getProcedureDocumentation: (settlementId, prefix, user, signal) =>
    SuperFetch.get(`${user ? `${user}/settlement` : prefix ?? 'settlement'}/V2/${settlementId}/public-procurement`, null, null, signal).then(
      response => response.data,
    ),

  createProcedure: settlementId =>
    SuperFetch.post(`settlement/V2/public-procurement/${settlementId}`).then(response => response.data),

  updateProcedure: procedure => SuperFetch.put(`settlement/V2/public-procurement/${procedure.id}`, procedure),

  deleteProcedure: procedureId => SuperFetch.delete(`settlement/V2/public-procurement/${procedureId}`),

  detachFile: (costId, fileId) => SuperFetch.delete(`settlement/cost/${costId}/document/${fileId}`),

  detachAttachmentFile: (attachmentId, fileId) =>
    SuperFetch.delete(`settlement/V2/public-procurement/file/${attachmentId}/${fileId}`),
  detachFieldFile: (settlementId, budgetType, category, fileId) =>
    SuperFetch.delete(`settlement/${settlementId}/field/${budgetType}/document/${category}/${fileId}`),

  getSummary: (id, prefix, user, signal) =>
    SuperFetch.get(`${user ? `${user}/settlement` : prefix ?? 'settlement'}/V2/${id}/summary`, null, null, signal).then(response => response.data),

  updateSummary: ({ id, data }) => SuperFetch.put(`settlement/V2/${id}/fields/summary`, fieldMapperDataToDTO(data)),

  updateValidationErrors: (settlementId, data) => SuperFetch.post(`settlement/V2/${settlementId}/store-fails`, data),

  signSettlement: settlementId => SuperFetch.post(`settlement/V2/${settlementId}/signing`),

  loadSettlementDetails: (settlementId, prefix, user, signal) =>
    SuperFetch.get(`${user ? `${user}/settlement` : prefix ?? 'settlement'}/V2/${settlementId}/details`, null, null, signal).then(
      response => response.data,
    ),

  getPersonalInfoAdmin: (id, prefix, user) =>
    SuperFetch.get(`${user ? `${user}/settlement` : prefix ?? 'settlement'}/V2/${id}/details`).then(response => response.data),

  addSettlementVerifierNote: (settlementId, type, id, note) =>
    SuperFetch.post(`verify/note/store/${settlementId}/${type}/${id}`, note, TlnString('CRUD_EDIT_RECORD_SUCCESS')).then(response => response.data),

  updateSettlementVerifierNote: (noteId, note) =>
    SuperFetch.put(`verify/note/update/${noteId}`, note, TlnString('CRUD_EDIT_RECORD_SUCCESS')).then(response => response.data),

  deleteSettlementVerifierNote: noteId => SuperFetch.delete(`verify/note/delete/${noteId}`, null, TlnString('CRUD_EDIT_RECORD_SUCCESS')).then(response => response.data),

  addSettlementVerifierFieldNote: (settlementId, note) =>
    SuperFetch.post(`verify/note/store/${settlementId}`, note, TlnString('CRUD_EDIT_RECORD_SUCCESS')).then(response => response.data),

  addSettlementVerifierNoteAnswer: (noteId, note) =>
    SuperFetch.post(`settlement/answers/store/${noteId}`, note, TlnString('CRUD_EDIT_RECORD_SUCCESS')).then(response => response.data),

  updateSettlementVerifierNoteAnswer: (answerId, note) =>
    SuperFetch.put(`settlement/answers/update/${answerId}`, note, TlnString('CRUD_EDIT_RECORD_SUCCESS')).then(response => response.data),

  switchSettlementVerifierNoteStatus: noteId =>
    SuperFetch.post(`verify/note/change-status/${noteId}`).then(response => response.data),

  updateSettlementVerifierIneligibleCost: (costId, sum) =>
    SuperFetch.put(`verify/ineligible-costs/store/SettlementEligibleCost/${costId}`, sum).then(response => response.data),

  loadCheckList: (settlementId, prefix, signal) =>
    SuperFetch.get(`${prefix ?? 'verify'}/V2/${settlementId}/check-list`, null, null, signal).then(response => response.data),

  updateCheckList: (settlementId, data) =>
    SuperFetch.put(`verify/V2/check-list/update/${settlementId}`, data).then(response => response.data),

  updateApproverCheckList: (settlementId, data) =>
    SuperFetch.post(`approve/fields/store/${settlementId}`, data).then(response => response.data),

  loadCorrection: settlementId => SuperFetch.get(`moderator/correct/${settlementId}`).then(response => response.data),

  changeCorrectionValueAdmin: (settlementId, values) => SuperFetch.post(`moderator/correct/admin/${settlementId}`, values),

  changeCorrectionValueCppc: (settlementId, values) => SuperFetch.post(`moderator/correct/cppc/${settlementId}`, values),

  changeVerificationStatus: (id, status, prefix) =>
    SuperFetch.post(`${prefix ?? 'verify'}/${status}/${id}`).then(response => response.data),

  detachCorrectionFile: fileId => SuperFetch.delete(`moderator/correct/file/${fileId}`),

  loadInspection: (settlementId, prefix, user) =>
    SuperFetch.get(`${user ? `${user}/settlement` : prefix ?? 'settlement'}/V2/${settlementId}/inspection`).then(
      response => response.data,
    ),
};
