import React, { Component } from 'react';
import { Layout } from 'antd';
import { compose } from 'redux';

import './global.css';
import AppRouter from './AppRouter';
import Sider from '../components/Sider';
import Nav from '../components/Nav';
import Footer from '../components/Footer';
import Messages from '../components/Messages';
import withApp from '../hoc/withApp';
import AppStyleHolder from './commonStyle';
import withUserProvider from '../hoc/withUserProvider';

export class App extends Component {

  render() {
    const { location, match, history } = this.props;
    const { pathname } = location;
    const { url } = match;
    return (
      <AppStyleHolder>
        <Layout>
          <Sider url={url} pathname={pathname} history={history} 
                 userRoles={this.props.userRoles} userDetails={this.props.userDetails} />
          <Layout className="site-layout" style={{ marginLeft: this.props.shouldLayoutBeFlexible ? this.props.collapsed ? 80 : 200 : 80 }}>
            <Nav user={this.props.user} pgrDate={this.props.pgrDate}></Nav>
            <Layout.Content style={{ padding: '25px', overflowX: 'auto' }}>
              <AppRouter url={url} userRoles={this.props.userRoles} />
              <Messages />
            </Layout.Content>
            <Footer />
          </Layout>
        </Layout>
      </AppStyleHolder>
    );
  }
}

export default compose(withApp, withUserProvider)(App);