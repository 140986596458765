import { call, all, takeEvery, put, fork, select } from 'redux-saga/effects';
import { push } from 'connected-react-router';

import { settlementsActions } from './actions';
import { SettlementsAPI } from '../../services/settlements';
import { getFinancialProgress, getProcedureDocumentation } from './selectors';
import { URLS } from '../../helpers/enumsUrls';

export function* loadProjectProgressWatcher() {
  yield takeEvery(settlementsActions.LOAD_PROJECT_PROGRESS, function* ({ payload }) {
    const { id, prefix, user, signal } = payload;
    try {
      const result = yield call(SettlementsAPI.getProjectProgress, id, prefix, user, signal);
      yield put(settlementsActions.loadProjectProgressSuccess(result));
    } catch (error) {
      yield put(settlementsActions.loadProjectProgressError());
    }
  });
}

export function* loadPersonalInfoWatcher() {
  yield takeEvery(settlementsActions.LOAD_PERSONAL_INFO, function* ({ payload }) {
    const { id, prefix, user, signal } = payload;
    try {
      const result = yield call(SettlementsAPI.getPersonalInfo, id, prefix, user, signal);
      yield put(settlementsActions.loadPersonalInfoSuccess(result));
    } catch (error) {
      yield put(settlementsActions.loadPersonalInfoError());
    }
  });
}

export function* updateStatementChangesWatcher() {
  yield takeEvery(settlementsActions.UPDATE_STATEMENT_CHANGES, function* ({ payload }) {
    const { settlementId, statementChanges } = payload;
    try {
      yield call(SettlementsAPI.updateStatementChanges, settlementId, statementChanges);
      yield put(settlementsActions.updateStatementChangesSuccess());
    } catch (error) {
      yield put(settlementsActions.updateStatementChangesError());
    }
  });
}

export function* updateContactInfo() {
  yield takeEvery(settlementsActions.UPDATE_CONTACT_INFO, function* ({ payload }) {
    const { id, data } = payload;
    try {
      yield call(SettlementsAPI.updateContactInfo, { id, data });
    } catch (error) { }
  });
}

export function* addRepresentativeInfo() {
  yield takeEvery(settlementsActions.ADD_REPRESENTATIVE_INFO, function* ({ payload }) {
    const { id } = payload;
    try {
      const result = yield call(SettlementsAPI.addRepresentativeInfo, { id });
      yield put(settlementsActions.addRepresentativeInfoSuccess(result.data[0]));
    } catch (error) {
      yield put(settlementsActions.addRepresentativeInfoError());
    }
  });
}

export function* detachRepresentativeFileWatcher() {
  yield takeEvery(settlementsActions.DETACH_REPRESENTATIVE_FILE, function* ({ payload }) {
    const { representativeId, fileId } = payload;
    try {
      yield call(SettlementsAPI.detachRepresentativeFile, representativeId, fileId);
    } catch (error) { }
  });
}

export function* updateStatementConceptionWatcher() {
  yield takeEvery(settlementsActions.UPDATE_STATEMENT_CONCEPTION, function* ({ payload }) {
    const { settlementId, statementConception } = payload;
    try {
      yield call(SettlementsAPI.updateStatementConception, settlementId, statementConception);
      yield put(settlementsActions.updateStatementConceptionSuccess());
    } catch (error) {
      yield put(settlementsActions.updateStatementConceptionError());
    }
  });
}

export function* loadFinancialProgressWatcher() {
  yield takeEvery(settlementsActions.LOAD_FINANCIAL_PROGRESS, function* ({ payload }) {
    const { id, prefix, user, signal } = payload;
    try {
      const result = yield call(SettlementsAPI.getFinancialProgress, id, prefix, user, signal);
      yield put(settlementsActions.loadFinancialProgressSuccess(result));
    } catch (error) {
      yield put(settlementsActions.loadFinancialProgressError());
    }
  });
}

export function* updateRepresentativeInfo() {
  yield takeEvery(settlementsActions.UPDATE_REPRESENTATIVE_INFO, function* ({ payload }) {
    const { id, data } = payload;
    try {
      yield call(SettlementsAPI.updateRepresentativeInfo, { id, data });
    } catch (error) { }
  });
}

export function* deleteRepresentativeInfo() {
  yield takeEvery(settlementsActions.DELETE_REPRESENTATIVE_INFO, function* ({ payload }) {
    const { id } = payload;
    try {
      yield call(SettlementsAPI.deleteRepresentativeInfo, { id });
    } catch (error) { }
  });
}

export function* loadDeclarationsWatcher() {
  yield takeEvery(settlementsActions.LOAD_DECLARATIONS, function* ({ payload }) {
    const { id, prefix, user, signal } = payload;
    try {
      const result = yield call(SettlementsAPI.getDeclarations, id, prefix, user, signal);
      yield put(settlementsActions.loadDeclarationsSuccess(result));
    } catch (error) {
      yield put(settlementsActions.loadDeclarationsError());
    }
  });
}

export function* loadFinancialProgressCategoryDetailsWatcher() {
  yield takeEvery(settlementsActions.LOAD_FINANCIAL_PROGRESS_CATEGORY_DETAILS, function* ({ payload }) {
    const { settlementId, prefix, user, signal } = payload;
    try {
      const result = yield call(SettlementsAPI.getFinancialProgressCategoryDetails, settlementId, prefix, user, signal);
      yield put(settlementsActions.loadFinancialProgressCategoryDetailsSuccess(result));
    } catch (error) {
      yield put(settlementsActions.loadFinancialProgressCategoryDetailsError());
    }
  });
}

export function* updateCostWatcher() {
  yield takeEvery(settlementsActions.UPDATE_COST_SETTLEMENT, function* ({ payload }) {
    const { cost, settlementId, needProcedureDocumentationReload } = payload;
    try {
      yield call(SettlementsAPI.updateCost, cost);
      yield put(settlementsActions.updateCostSuccess());
      yield put(settlementsActions.loadFinancialProgressCategoryDetails(settlementId));
      yield put(settlementsActions.loadSummary(settlementId));

      if (needProcedureDocumentationReload) {
        yield put(settlementsActions.loadProcedureDocumentation(settlementId));
      }
    } catch (error) {
      yield put(settlementsActions.updateCostError());
    }
  });
}

export function* deleteCostWatcher() {
  yield takeEvery(settlementsActions.DELETE_COST_SETTLEMENT, function* ({ payload }) {
    const { costId, settlementId } = payload;
    try {
      yield call(SettlementsAPI.deleteCost, costId);
      const financialProgress = yield select(getFinancialProgress);
      const obj = {};

      try {
        Object.entries(financialProgress).forEach(([key, value]) => {
          obj[key] = value.filter(cost => cost.id !== costId);
        });
      } catch (err) { }

      yield put(settlementsActions.loadFinancialProgressSuccess(obj));
      yield put(settlementsActions.deleteCostSuccess());
      yield put(settlementsActions.loadSummary(settlementId));
    } catch (error) {
      yield put(settlementsActions.deleteCostError());
    }
  });
}

export function* updateDeclarations() {
  yield takeEvery(settlementsActions.UPDATE_DECLARATIONS, function* ({ payload }) {
    const { id, data } = payload;
    try {
      yield call(SettlementsAPI.updateDeclarations, { id, data });
      yield put(settlementsActions.loadFinancialProgressCategoryDetails(id));
      yield put(settlementsActions.loadSummary(id));
    } catch (error) { }
  });
}

export function* detachFileWatcher() {
  yield takeEvery(settlementsActions.DETACH_FILE, function* ({ payload }) {
    const { costId, fileId } = payload;
    try {
      yield call(SettlementsAPI.detachFile, costId, fileId);
    } catch (error) { }
  });
}

export function* detachAttachmentFileWatcher() {
  yield takeEvery(settlementsActions.DETACH_ATTACHMENT_FILE, function* ({ payload }) {
    const { attachmentId, fileId } = payload;
    try {
      yield call(SettlementsAPI.detachAttachmentFile, attachmentId, fileId);
    } catch (error) { }
  });
}

export function* detachFieldFileWatcher() {
  yield takeEvery(settlementsActions.DETACH_FIELD_FILE, function* ({ payload }) {
    const { settlementId, budgetType, category, fileId } = payload;
    try {
      yield call(SettlementsAPI.detachFieldFile, settlementId, budgetType, category, fileId);
    } catch (error) { }
  });
}

export function* loadSummary() {
  yield takeEvery(settlementsActions.LOAD_SUMMARY, function* ({ payload }) {
    const { id, prefix, user, signal } = payload;
    try {
      const result = yield call(SettlementsAPI.getSummary, id, prefix, user, signal);
      yield put(settlementsActions.loadSummarySuccess(result));
    } catch (error) {
      yield put(settlementsActions.loadSummaryError());
    }
  });
}

export function* loadProcedureDocumentationWatcher() {
  yield takeEvery(settlementsActions.LOAD_PROCEDURE_DOCUMENTATION, function* ({ payload }) {
    const { settlementId, prefix, user, signal } = payload;
    try {
      const result = yield call(SettlementsAPI.getProcedureDocumentation, settlementId, prefix, user, signal);
      yield put(settlementsActions.loadProcedureDocumentationSuccess(result));
    } catch (error) {
      yield put(settlementsActions.loadProcedureDocumentationError());
    }
  });
}

export function* updateProcedureWatcher() {
  yield takeEvery(settlementsActions.UPDATE_PROCEDURE, function* ({ payload }) {
    const { procedure, settlementId, needProcedureDocumentationReload } = payload;
    try {
      yield call(SettlementsAPI.updateProcedure, procedure);
      yield put(settlementsActions.updateProcedureSuccess());

      if (needProcedureDocumentationReload) {
        yield put(settlementsActions.loadProcedureDocumentation(settlementId));
      }
    } catch (error) {
      yield put(settlementsActions.updateProcedureError());
    }
  });
}

export function* deleteProcedureWatcher() {
  yield takeEvery(settlementsActions.DELETE_PROCEDURE, function* ({ payload }) {
    const { procedureId } = payload;
    try {
      yield call(SettlementsAPI.deleteProcedure, procedureId);
      const procedureDocumentation = yield select(getProcedureDocumentation);

      procedureDocumentation.procurement = procedureDocumentation.procurement.filter(procedure => procedure.id !== procedureId);

      yield put(settlementsActions.loadProcedureDocumentationSuccess(procedureDocumentation));
      yield put(settlementsActions.deleteProcedureSuccess());
    } catch (error) {
      yield put(settlementsActions.deleteProcedureError());
    }
  });
}

export function* updateSummary() {
  yield takeEvery(settlementsActions.UPDATE_SUMMARY, function* ({ payload }) {
    const { id, data } = payload;
    try {
      yield call(SettlementsAPI.updateSummary, { id, data });
    } catch (error) { }
  });
}

export function* updateValidationErrorsWatcher() {
  yield takeEvery(settlementsActions.UPDATE_VALIDATION_ERRORS, function* ({ payload }) {
    const { settlementId, data } = payload;
    try {
      yield call(SettlementsAPI.updateValidationErrors, settlementId, data);
    } catch (error) { }
  });
}

export function* signSettlementWatcher() {
  yield takeEvery(settlementsActions.SIGN_SETTLEMENT, function* ({ payload }) {
    const { settlementId } = payload;
    try {
      yield call(SettlementsAPI.signSettlement, settlementId);
      yield put(settlementsActions.signSettlementSuccess());
      yield put(settlementsActions.loadSettlementDetails(settlementId));
    } catch (error) {
      yield put(settlementsActions.signSettlementError());
    }
  });
}

export function* loadSettlementDetailsWatcher() {
  yield takeEvery(settlementsActions.LOAD_SETTLEMENT_DETAILS, function* ({ payload }) {
    const { settlementId, prefix, user, signal } = payload;

    try {
      const result = yield call(SettlementsAPI.loadSettlementDetails, settlementId, prefix, user, signal);
      yield put(settlementsActions.loadSettlementDetailsSuccess(result));
    } catch (error) {
      yield put(settlementsActions.loadSettlementDetailsError());
    }
  });
}

export function* addSettlementVerifierNoteWatcher() {
  yield takeEvery(settlementsActions.ADD_SETTLEMENT_VERIFIER_NOTE, function* ({ payload }) {
    const { settlementId, type, id, note } = payload;
    try {
      const result = yield call(SettlementsAPI.addSettlementVerifierNote, settlementId, type, id, note);
      yield put(settlementsActions.addSettlementVerifierNoteSuccess({ id: id, fieldName: type, noteId: result?.at(0) }));
    } catch (error) {
      yield put(settlementsActions.addSettlementVerifierNoteError());
    }
  });
}

export function* updateSettlementVerifierNoteWatcher() {
  yield takeEvery(settlementsActions.UPDATE_SETTLEMENT_VERIFIER_NOTE, function* ({ payload }) {
    const { noteId, note } = payload;
    try {
      yield call(SettlementsAPI.updateSettlementVerifierNote, noteId, note);
      yield put(settlementsActions.updateSettlementVerifierNoteSuccess(noteId));
    } catch (error) {
      yield put(settlementsActions.updateSettlementVerifierNoteError());
    }
  });
}

export function* addSettlementVerifierFieldNoteWatcher() {
  yield takeEvery(settlementsActions.ADD_SETTLEMENT_VERIFIER_FIELD_NOTE, function* ({ payload }) {
    const { settlementId, note } = payload;
    try {
      const result = yield call(SettlementsAPI.addSettlementVerifierFieldNote, settlementId, note);
      yield put(
        settlementsActions.addSettlementVerifierNoteSuccess({
          id: note?.id ?? null,
          fieldName: note?.field_name ?? null,
          noteId: result?.at(0),
        }),
      );
    } catch (error) {
      yield put(settlementsActions.addSettlementVerifierNoteError());
    }
  });
}

export function* deleteSettlementVerifierNoteWatcher() {
  yield takeEvery(settlementsActions.DELETE_SETTLEMENT_VERIFIER_NOTE, function* ({ payload }) {
    const { noteId } = payload;
    try {
      yield call(SettlementsAPI.deleteSettlementVerifierNote, noteId);
      yield put(settlementsActions.deleteSettlementVerifierNoteSuccess(noteId));
    } catch (error) {
      yield put(settlementsActions.deleteSettlementVerifierNoteError());
    }
  });
}

export function* addSettlementVerifierNoteAnswerWatcher() {
  yield takeEvery(settlementsActions.ADD_SETTLEMENT_VERIFIER_NOTE_ANSWER, function* ({ payload }) {
    const { noteId, note } = payload;
    try {
      const result = yield call(SettlementsAPI.addSettlementVerifierNoteAnswer, noteId, note);
      yield put(settlementsActions.addSettlementVerifierNoteAnswerSuccess({ noteId: noteId, answerId: result?.at(0) }));
    } catch (error) {
      yield put(settlementsActions.addSettlementVerifierNoteAnswerError());
    }
  });
}

export function* updateSettlementVerifierNoteAnswerWatcher() {
  yield takeEvery(settlementsActions.UPDATE_SETTLEMENT_VERIFIER_NOTE_ANSWER, function* ({ payload }) {
    const { answerId, note } = payload;
    try {
      yield call(SettlementsAPI.updateSettlementVerifierNoteAnswer, answerId, note);
      yield put(settlementsActions.updateSettlementVerifierNoteAnswerSuccess(answerId));
    } catch (error) {
      yield put(settlementsActions.updateSettlementVerifierNoteAnswerError());
    }
  });
}

export function* switchSettlementVerifierNoteStatusWatcher() {
  yield takeEvery(settlementsActions.SWITCH_SETTLEMENT_VERIFIER_NOTE_STATUS, function* ({ payload }) {
    const { noteId } = payload;
    try {
      yield call(SettlementsAPI.switchSettlementVerifierNoteStatus, noteId);
      yield put(settlementsActions.updateSettlementVerifierNoteSuccess());
    } catch (error) {
      yield put(settlementsActions.updateSettlementVerifierNoteError());
    }
  });
}

export function* updateSettlementVerifierIneligibleCostWatcher() {
  yield takeEvery(settlementsActions.UPDATE_SETTLEMENT_VERIFIER_INELIGIBLE_COST, function* ({ payload }) {
    const { settlementId, costId, sum } = payload;
    try {
      yield call(SettlementsAPI.updateSettlementVerifierIneligibleCost, costId, sum);
      yield put(settlementsActions.loadVerificationCheckList(settlementId));
    } catch (error) { }
  });
}

export function* loadVerificationCheckListWatcher() {
  yield takeEvery(settlementsActions.LOAD_VERIFICATION_CHECKLIST, function* ({ payload }) {
    const { settlementId, prefix, signal } = payload;

    try {
      const result = yield call(SettlementsAPI.loadCheckList, settlementId, prefix, signal);
      yield put(settlementsActions.loadVerificationCheckListSuccess(result));
    } catch (error) {
      yield put(settlementsActions.loadVerificationCheckListError());
    }
  });
}

export function* updateVerificationCheckListWatcher() {
  yield takeEvery(settlementsActions.UPDATE_VERIFICATION_CHECKLIST, function* ({ payload }) {
    const { settlementId, data } = payload;
    try {
      yield call(SettlementsAPI.updateCheckList, settlementId, data);
      yield put(settlementsActions.loadVerificationCheckList(settlementId));
    } catch (error) {
      yield put(settlementsActions.loadVerificationCheckListError());
    }
  });
}

export function* updateApproverCheckListWatcher() {
  yield takeEvery(settlementsActions.UPDATE_APPROVER_CHECKLIST, function* ({ payload }) {
    const { settlementId, data } = payload;
    try {
      yield call(SettlementsAPI.updateApproverCheckList, settlementId, data);
      yield put(settlementsActions.updateApproverCheckListSuccess());
    } catch (error) {
      yield put(settlementsActions.updateApproverCheckListError());
    }
  });
}

export function* changeVerificationStatus() {
  yield takeEvery(settlementsActions.CHANGE_VERIFICATION_STATUS, function* ({ payload }) {
    const { settlementId, status, prefix } = payload;
    try {
      yield call(SettlementsAPI.changeVerificationStatus, settlementId, status, prefix);
      yield put(settlementsActions.changeVerificationStatusSuccess());
      yield put(push(URLS.VERIFIER_STATEMENTS));
    } catch (error) {
      yield put(settlementsActions.changeVerificationStatusError(error.errors));
    }
  });
}

export function* loadCorrectionWatcher() {
  yield takeEvery(settlementsActions.LOAD_CORRECTION, function* ({ payload }) {
    const { settlementId } = payload;
    try {
      const result = yield call(SettlementsAPI.loadCorrection, settlementId);
      yield put(settlementsActions.loadCorrectionSuccess(result));
    } catch (error) {
      yield put(settlementsActions.loadCorrectionError());
    }
  });
}

export function* changeCorrectionValueAdminWatcher() {
  yield takeEvery(settlementsActions.CHANGE_CORRECTION_VALUE_ADMIN, function* ({ payload }) {
    const { settlementId, data } = payload;
    try {
      yield call(SettlementsAPI.changeCorrectionValueAdmin, settlementId, data);
      yield put(settlementsActions.changeCorrectionValueAdminSuccess());
      yield put(settlementsActions.loadCorrection(settlementId));
    } catch (error) {
      yield put(settlementsActions.changeCorrectionValueAdminError());
    }
  });
}

export function* changeCorrectionValueCppcWatcher() {
  yield takeEvery(settlementsActions.CHANGE_CORRECTION_VALUE_CPPC, function* ({ payload }) {
    const { settlementId, data } = payload;
    try {
      yield call(SettlementsAPI.changeCorrectionValueCppc, settlementId, data);
      yield put(settlementsActions.changeCorrectionValueCppcSuccess());
      yield put(settlementsActions.loadCorrection(settlementId));
    } catch (error) {
      yield put(settlementsActions.changeCorrectionValueCppcError());
    }
  });
}

export function* detachCorrectionFileWatcher() {
  yield takeEvery(settlementsActions.DETACH_CORRECTION_FILE, function* ({ payload }) {
    const { fileId } = payload;
    try {
      yield call(SettlementsAPI.detachCorrectionFile, fileId);
    } catch (error) { }
  });
}

export function* loadInspectionWatcher() {
  yield takeEvery(settlementsActions.LOAD_INSPECTION, function* ({ payload }) {
    const { settlementId, prefix, user } = payload;
    try {
      const result = yield call(SettlementsAPI.loadInspection, settlementId, prefix, user);
      yield put(settlementsActions.loadInspectionSuccess(result));
    } catch (error) {
      yield put(settlementsActions.loadInspectionError());
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(loadProjectProgressWatcher),
    fork(loadPersonalInfoWatcher),
    fork(updateContactInfo),
    fork(addRepresentativeInfo),
    fork(updateRepresentativeInfo),
    fork(deleteRepresentativeInfo),
    fork(detachRepresentativeFileWatcher),
    fork(loadDeclarationsWatcher),
    fork(updateDeclarations),
    fork(updateStatementChangesWatcher),
    fork(updateStatementConceptionWatcher),
    fork(loadFinancialProgressWatcher),
    fork(loadFinancialProgressCategoryDetailsWatcher),
    fork(updateCostWatcher),
    fork(deleteCostWatcher),
    fork(detachFileWatcher),
    fork(detachAttachmentFileWatcher),
    fork(loadSummary),
    fork(loadProcedureDocumentationWatcher),
    fork(updateProcedureWatcher),
    fork(updateSummary),
    fork(updateValidationErrorsWatcher),
    fork(deleteProcedureWatcher),
    fork(detachFieldFileWatcher),
    fork(signSettlementWatcher),
    fork(loadSettlementDetailsWatcher),
    fork(addSettlementVerifierNoteWatcher),
    fork(updateSettlementVerifierNoteWatcher),
    fork(deleteSettlementVerifierNoteWatcher),
    fork(addSettlementVerifierFieldNoteWatcher),
    fork(switchSettlementVerifierNoteStatusWatcher),
    fork(updateSettlementVerifierIneligibleCostWatcher),
    fork(addSettlementVerifierNoteAnswerWatcher),
    fork(updateSettlementVerifierNoteAnswerWatcher),
    fork(loadVerificationCheckListWatcher),
    fork(updateVerificationCheckListWatcher),
    fork(updateApproverCheckListWatcher),
    fork(changeVerificationStatus),
    fork(loadCorrectionWatcher),
    fork(changeCorrectionValueAdminWatcher),
    fork(changeCorrectionValueCppcWatcher),
    fork(detachCorrectionFileWatcher),
    fork(loadInspectionWatcher),
  ]);
}
