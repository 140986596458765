import App from './app/reducer';
import Auth from './auth/reducer';
import Languages from './languages/reducer';
import Users from './users/reducer';
import Account from './account/reducer';
import EligibleCosts from './eligibleCosts/reducer';
import Logs from './logs/reducer';
import Emails from './emails/reducer';
import Applications from './applications/reducer';
import ApplicationCosts from './applicationCosts/reducer';
import Regions from './regions/reducer';
import Monitoring from './monitoring/reducer';
import Statements from './statements/reducer';
import StatementCosts from './statementCosts/reducer';
import Settlements from './settlements/reducer';

const reducers = {
  App,
  Auth,
  Languages,
  Users,
  Account,
  EligibleCosts,
  Logs,
  Emails,
  Applications,
  ApplicationCosts,
  Regions,
  Monitoring,
  Statements,
  StatementCosts,
  Settlements,
};
export default reducers;
