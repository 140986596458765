import React from 'react';
import { Avatar } from 'antd';
import { UserOutlined } from '@ant-design/icons';

import { UserAvatarWrapper } from './style';

const UserAvatar = ({ user }) => (
  <UserAvatarWrapper>
    <Avatar size="small" icon={<UserOutlined />} />
    <span className="userData">{user.email}</span>
  </UserAvatarWrapper>
);

export default UserAvatar;