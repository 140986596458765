import styled from "styled-components";
import { palette } from "styled-theme";


const EuStyleWrapper = styled.div`
  text-align: center;
  background-color: ${palette('static_colors', 0)};
  padding: 20px 0;
  margin-top: 50px;
  img {
      max-width: 700px;
  }
  @media(max-width: 767px) {
    width: 100%;
    img {
      width: 100%;
    }
  }
`;

export { EuStyleWrapper };