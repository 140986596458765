import actions from './actions';

const initState = {
  isLoading: false,
  logs: [],
  total: null,
};

export default function reducer(state = initState, { type, payload }) {
  switch (type) {
    case actions.LOAD_LOGS:
      return {
        ...state,
        isLoading: true,
      };
    case actions.LOAD_LOGS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        logs: payload.data.data,
        total: payload.data.total
      };
    case actions.LOAD_LOGS_ERROR:
      return {
        ...state,
        isLoading: false,
      };

    default:
      return state;
  }
}
