import { call, all, takeEvery, put, fork } from 'redux-saga/effects';
import actions from './actions';
import Monitoring from '../../services/monitoring';

export function* loadData() {
  yield takeEvery(actions.LOAD_DATA, function*({ payload }) {
    const { applicationId } = payload;
    try {
      const result = yield call(Monitoring.getData, applicationId);
      yield put(actions.loadDataSuccess(result.data, result.settlement));
    } catch (error) {
      yield put(actions.loadDataError(error));
    }
  });
}

export function* saveRecord() {
  yield takeEvery(actions.SAVE_RECORD, function*({ payload }) {
    const { applicationId, record } = payload;
    try {
      if(record.id) {
        yield call(Monitoring.saveRecord, applicationId, record);
        yield put(actions.saveDataSuccess(record));
      } else {
        const newRecord = yield call(Monitoring.addRecord, applicationId, record);
        yield put(actions.addDataSuccess(newRecord.data));
      }
    } catch (error) {
      yield put(actions.loadDataError(error));
    }
  });
}

export function* deleteRecord() {
  yield takeEvery(actions.REMOVE_RECORD, function*({ payload }) {
    const { applicationId, dataId } = payload;
    try {
      yield call(Monitoring.removeRecord, applicationId, dataId);
      yield put(actions.deleteDataSuccess(dataId));
    } catch (error) {
      yield put(actions.loadDataError(error));
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(loadData),
    fork(saveRecord),
    fork(deleteRecord)
  ]);
}