const actions = {
  LOAD_APPLICATIONS: 'LOAD_APPLICATIONS',
  LOAD_APPLICATIONS_SUCCESS: 'LOAD_APPLICATIONS_SUCCESS',
  LOAD_APPLICATIONS_ERROR: 'LOAD_APPLICATIONS_ERROR',

  LOAD_APPLICATION: 'LOAD_APPLICATION',
  LOAD_APPLICATION_SUCCESS: 'LOAD_APPLICATION_SUCCESS',
  LOAD_APPLICATION_ERROR: 'LOAD_APPLICATION_ERROR',
  DUPLICATE_APPLICATION: 'DUPLICATE_APPLICATION',

  SAVE_APPLICATION: 'SAVE_APPLICATION',
  SAVE_APPLICATION_SUCCESS: 'SAVE_APPLICATION_SUCCESS',
  SAVE_APPLICATION_ERROR: 'SAVE_APPLICATION_ERROR',
  SAVE_APPLICATION_FILE: 'SAVE_APPLICATION_FILE',
  SAVE_WITHDRAW_FILE: 'SAVE_WITHDRAW_FILE',
  SAVE_RESIGN_FILE: 'SAVE_RESIGN_FILE',
  SAVE_TERMINATION_FILE: 'SAVE_TERMINATION_FILE',
  SAVE_APPLICATION_FILE_SUCCESS: 'SAVE_APPLICATION_FILE_SUCCESS',
  SAVE_APPLICATION_FILE_ERROR: 'SAVE_APPLICATION_FILE_ERROR',
  LOAD_USER_APPLICATION: 'LOAD_USER_APPLICATION',
  LOAD_USER_APPLICATIONS: 'LOAD_USER_APPLICATIONS',
  SEND_APPLICATION: 'SEND_APPLICATION',
  SEND_APPLICATION_SUCCESS: 'SEND_APPLICATION_SUCCESS',
  SEND_APPLICATION_ERROR: 'SEND_APPLICATION_ERROR',
  SAVE_APPLICATION_BY_MODERATOR: 'SAVE_APPLICATION_BY_MODERATOR',
  SAVE_APPLICATION_FILE_VERIFIER: 'SAVE_APPLICATION_FILE_VERIFIER',
  SAVE_APPLICATION_FILE_VERIFIER_SUCCESS: 'SAVE_APPLICATION_FILE_VERIFIER_SUCCESS',
  SAVE_APPLICATION_FILE_VERIFIER_ERROR: 'SAVE_APPLICATION_FILE_VERIFIER_ERROR',

  LOAD_VERIFIER_APPLICATIONS: 'LOAD_VERIFIER_APPLICATIONS',
  LOAD_VERIFIER_APPLICATION: 'LOAD_VERIFIER_APPLICATION',

  LOAD_ADMIN_APPLICATIONS: 'LOAD_ADMIN_APPLICATIONS',

  CLEAR_APPLICATION: 'CLEAR_APPLICATION',

  SEND_STATUS: 'SEND_STATUS',
  SHOW_FILE: 'SHOW_FILE',

  ACCEPT_WITHDRAWAL: 'ACCEPT_WITHDRAWAL',
  ACCEPT_RESIGN: 'ACCEPT_RESIGN',
  ACCEPT_TERMINATION: 'ACCEPT_TERMINATION',

  SAVE_ASSIGN_MODERATOR: 'SAVE_ASSIGN_MODERATOR',
  SAVE_ASSIGN_MODERATOR_SUCCESS: 'SAVE_ASSIGN_MODERATOR_SUCCESS',
  CHANGE_ADMIN_STATUS: 'CHANGE_ADMIN_STATUS',
  REJECT_APPLICATION_FILES: 'REJECT_APPLICATION_FILES',
  REJECT_APPLICATION_FILES_SUCCESS: 'REJECT_APPLICATION_FILES_SUCCESS',
  REJECT_APPLICATION_FILES_ERROR: 'REJECT_APPLICATION_FILES_ERROR',
  CORRECT_SIGNED_FILES: 'CORRECT_SIGNED_FILES',

  MARK_AS_PAID: 'MARK_AS_PAID',
  TOGGLE_AGREEMENT: 'TOGGLE_AGREEMENT',

  SAVE_FILTERS: 'SAVE_FILTERS',
  SAVE_ADMIN_EDIT: 'SAVE_ADMIN_EDIT',
  SAVE_ADMIN_EDIT_SUCCESS: 'SAVE_ADMIN_EDIT_SUCCESS',
  SAVE_ADMIN_EDIT_ERROR: 'SAVE_ADMIN_EDIT_ERROR',
  LOAD_APPLICATION_BACKUP: 'LOAD_APPLICATION_BACKUP',

  SAVE_CONTRACTORS: 'SAVE_CONTRACTORS',
  SHOW_AMENDMENT: 'SHOW_AMENDMENT',
  SEND_AMENDMENT: 'SEND_AMENDMENT',
  VERIFY_AMENDMENT_FILES: 'VERIFY_AMENDMENT_FILES',

  loadApplications: (currentPage, userId, query) => ({
    type: actions.LOAD_APPLICATIONS,
    payload: { currentPage, userId, query }
  }),
  loadApplicationsSuccess: data => ({
    type: actions.LOAD_APPLICATIONS_SUCCESS,
    payload: { data }
  }),
  loadApplicationsError: error => ({
    type: actions.LOAD_APPLICATIONS_ERROR,
    payload: { error }
  }),
  loadApplication: (applicationId, userId) => ({
    type: actions.LOAD_APPLICATION,
    payload: { applicationId, userId }
  }),
  loadApplicationSuccess: data => ({
    type: actions.LOAD_APPLICATION_SUCCESS,
    payload: { data }
  }),
  loadApplicationError: error => ({
    type: actions.LOAD_APPLICATION_ERROR,
    payload: { error }
  }),
  duplicateApplication: id => ({
    type: actions.DUPLICATE_APPLICATION,
    payload: { id }
  }),

  saveApplication: (data, actionName) => ({ 
    type: actions.SAVE_APPLICATION,
    payload: { data, actionName },
  }),
  saveApplicationSuccess: () => ({
    type: actions.SAVE_APPLICATION_SUCCESS,
  }),
  saveApplicationError: error => ({
    type: actions.SAVE_APPLICATION_ERROR,
    payload: { error },
  }),

  saveApplicationFile: application => ({
    type: actions.SAVE_APPLICATION_FILE,
    payload: { application },
  }),
  saveWithdrawFile: application => ({
    type: actions.SAVE_WITHDRAW_FILE,
    payload: { application },
  }),
  saveTerminationFile: application => ({
    type: actions.SAVE_ACCEPT_TERMINATION_FILE,
    payload: { application },
  }),
  saveResignFile: application => ({
    type: actions.SAVE_RESIGN_FILE,
    payload: { application },
  }),
  saveApplicationFileSuccess: () => ({
    type: actions.SAVE_APPLICATION_FILE_SUCCESS
  }),
  saveApplicationFileError: error => ({
    type: actions.SAVE_APPLICATION_FILE_ERROR,
    payload: { error },
  }),
  saveApplicationFileVerifier: application => ({
    type: actions.SAVE_APPLICATION_FILE_VERIFIER,
    payload: { application },
  }),
  saveApplicationFileVerifierSuccess: () => ({
    type: actions.SAVE_APPLICATION_FILE_VERIFIER_SUCCESS
  }),
  saveApplicationFileVerifierError: error => ({
    type: actions.SAVE_APPLICATION_FILE_VERIFIER_ERROR,
    payload: { error },
  }),
  loadUserApplication: (applicationId) => ({
    type: actions.LOAD_USER_APPLICATION,
    payload: { applicationId }
  }),
  sendApplication: (applicationId, controlSum) => ({
    type: actions.SEND_APPLICATION,
    payload: { applicationId, controlSum }
  }),
  sendApplicationSuccess: () => ({
    type: actions.SEND_APPLICATION_SUCCESS,
  }),
  sendApplicationError: error => ({
    type: actions.SEND_APPLICATION_ERROR,
    payload: { error },
  }),
  saveApplicationByModerator: (applicationId, application) => ({
    type: actions.SAVE_APPLICATION_BY_MODERATOR,
    payload: { application, applicationId }
  }),
  loadUserApplications: (currentPage) => ({
    type: actions.LOAD_USER_APPLICATIONS,
    payload: { currentPage }
  }),
  loadVerifierApplications: (currentPage, userId, query) => ({
    type: actions.LOAD_VERIFIER_APPLICATIONS,
    payload: { currentPage, query }
  }),
  loadVerifierApplication: (applicationId) => ({
    type: actions.LOAD_VERIFIER_APPLICATION,
    payload: { applicationId }
  }),
  loadAdminApplications: (currentPage, userId, query) => ({
    type: actions.LOAD_ADMIN_APPLICATIONS,
    payload: { currentPage, userId, query }
  }),
  changeStatus: (applicationId, status) => ({
    type: actions.SEND_STATUS,
    payload: { applicationId, status }
  }),
  changeAdminStatus: (applicationId, status) => ({
    type: actions.CHANGE_ADMIN_STATUS,
    payload: { applicationId, status }
  }),
  showFile: (url) => ({
    type: actions.SHOW_FILE,
    payload: { url }
  }),
  clearApplication: () => ({
    type: actions.CLEAR_APPLICATION,
    payload: { }
  }),
  acceptWithdrawal: (id) => ({
    type: actions.ACCEPT_WITHDRAWAL,
    payload: { id }
  }),
  acceptResign: (id) => ({
    type: actions.ACCEPT_RESIGN,
    payload: { id }
  }),
  acceptTermination: (id) => ({
    type: actions.ACCEPT_TERMINATION,
    payload: { id }
  }),
  saveAssignModerator: (moderator, applications) => ({
    type: actions.SAVE_ASSIGN_MODERATOR,
    payload: {moderator, applications}
  }),
  rejectApplicationFiles: ({id, note_confirmation_files, note_signed_files}) => ({
    type: actions.REJECT_APPLICATION_FILES,
    payload: {id, note_confirmation_files, note_signed_files}
  }),
  rejectApplicationFilesSuccess: () => ({
    type: actions.REJECT_APPLICATION_FILES_SUCCESS,
  }),
  rejectApplicationFilesError: error => ({
    type: actions.REJECT_APPLICATION_FILES_ERROR,
    payload: { error }
  }),
  correctSignedFiles: (data) => ({
    type: actions.CORRECT_SIGNED_FILES,
    payload: data
  }),
  markAsPaid: (id, paid, state) => ({
    type: actions.MARK_AS_PAID,
    payload: {id, paid, state}
  }),
  toggleAgreement: (id, locked, state) => ({
    type: actions.TOGGLE_AGREEMENT,
    payload: {id, locked, state}
  }),
  saveFilters: (filters) => ({
    type: actions.SAVE_FILTERS,
    payload: {filters}
  }),
  saveAdminEdit: (type, applicationId, values) => ({
    type: actions.SAVE_ADMIN_EDIT,
    payload: {type, values, applicationId}
  }),
  saveAdminEditSuccess: () => ({
    type: actions.SAVE_ADMIN_EDIT_SUCCESS,
  }),
  saveAdminEditError: () => ({
    type: actions.SAVE_ADMIN_EDIT_ERROR,
  }),
  loadApplicationBackup: (applicationId, backupId) => ({
    type: actions.LOAD_APPLICATION_BACKUP,
    payload: {applicationId, backupId}
  }),
  saveContractors: (contractors) => ({
    type: actions.SAVE_CONTRACTORS,
    payload: {contractors}
  }),
  unlockAmendment: (applicationId, agreementDate, agreementExtend) => ({
    type: actions.SHOW_AMENDMENT,
    payload: {applicationId, agreementDate, agreementExtend}
  }),
  sendAmendment: (data) => ({
    type: actions.SEND_AMENDMENT,
    payload: {data}
  }),
  verifyAmendmentFiles: (id, note_signed_files, file_annex_signed) => ({
    type: 'VERIFY_AMENDMENT_FILES',
    payload: {id, note_signed_files, file_annex_signed}
  }),

};

export default actions;