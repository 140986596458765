import { Button, Modal } from 'antd';
import React from 'react';
import { REACT_APP_DISTRICT_HOST, REACT_APP_VOIVODESHIP_HOST } from '../../helpers/enums';
import { MessagesStyleWrapper } from './style';

const Messages = ({ hideModal, helloModal, videoModal }) => {
  return (
    <MessagesStyleWrapper>
      <Modal
        title='Szanowni Państwo'
        visible={helloModal}
        onCancel={() => hideModal('helloModal')}
        footer={[
          <Button key='submit' type='primary' onClick={() => hideModal('helloModal')}>
            OK
          </Button>,
        ]}
      >
        {REACT_APP_DISTRICT_HOST === window.location.hostname ? (
          <>
            <p>
              Dziękujemy za zarejestrowanie w programie „Cyfrowy Powiat”. Celami programu jest wsparcie rozwoju cyfrowego
              instytucji samorządowych, zwiększenie cyberbezpieczeństwa.
            </p>
            <p>
              Jesteśmy przekonani, że granty dla powiatów z Funduszy Europejskich pomogą Państwu oraz Państwa mieszkańcom w pełni
              korzystać z najnowszych technologii, a tym samym podniosą jakość życia w Państwa regionie.
            </p>
            <p>
              W związku z dużym zainteresowaniem projektem (zaobserwowaliśmy również rejestrację osób fizycznych), uprzejmie
              przypominamy o tym, że jedynym podmiotem, który może złożyć wniosek, jest powiat.
            </p>
            <p>
              Po więcej informacji na temat projektu zapraszamy na naszą stronę internetową:{' '}
              <a href='https://gov.pl/cppc/cyfrowy-powiat' target='_blank' rel='noreferrer'>
                https://gov.pl/cppc/cyfrowy-powiat
              </a>
            </p>
            <p>
              Biuro projektu „Cyfrowa gmina”
              <br />
              Tel.: +48 42 631 2105
              <br />
              e-mail: cyfrowypowiat@cppc.gov.pl
            </p>
          </>
        ) : REACT_APP_VOIVODESHIP_HOST === window.location.hostname ? (
          <>
            <>
              <p>
                Dziękujemy za zarejestrowanie w konkursie "Cyfrowe Województwo". W przypadku pytań dotyczących konkursu zachęcamy
                do kontaktu z Biurem Projektu.
              </p>
              <p>
                Biuro projektu "Cyfrowa Gmina"
                <br />
                Tel.: +48 42 631 2105
                <br />
                e-mail: cyfrowewojewodztwo@cppc.gov.pl
              </p>
            </>
          </>
        ) : (
          <>
            <p>
              Dziękujemy za zarejestrowanie w programie „Cyfrowa Gmina”. Celami programu jest wsparcie rozwoju cyfrowego
              instytucji samorządowych, zwiększenie cyberbezpieczeństwa oraz wyrównanie różnic terytorialnych w dostępie do
              sprzętu komputerowego. W ramach programu Cyfrowa Gmina, uruchomiony został projekt „Granty PPGR”, którego celem jest
              dostarczenie sprzętu oraz Internetu wśród dzieci i młodzieży z rodzin z terenów popegeerowskich przez JST.
            </p>
            <p>
              Jesteśmy przekonani, że granty dla gmin z Funduszy Europejskich pomogą Państwu oraz Państwa mieszkańcom w pełni
              korzystać z najnowszych technologii, a tym samym podniosą jakość życia w Państwa regionie.
            </p>
            <p>
              W związku z dużym zainteresowaniem projektem (zaobserwowaliśmy również rejestrację osób fizycznych),{' '}
              <strong>uprzejmie przypominamy o tym, że jedynym podmiotem, który może złożyć wniosek, jest gmina</strong>. W
              związku z wieloma pytaniami jakie pojawiają się w konkursie, zapraszamy do zapoznania się z sekcją{' '}
              <a href='https://www.gov.pl/attachment/39e964c3-e0d7-4103-bec8-dc797e8569f1' target='_blank' rel='noreferrer'>
                Pytania i odpowiedzi
              </a>
            </p>
            <p>
              Po więcej informacji na temat projektu zapraszamy na naszą stronę internetową:{' '}
              <a href='https://gov.pl/cppc/cyfrowa-gmina' target='_blank' rel='noreferrer'>
                https://gov.pl/cppc/cyfrowa-gmina
              </a>
            </p>
            <p>
              Biuro projektu „Cyfrowa gmina”
              <br />
              Tel.: +48 42 631 2105
              <br />
              e-mail: cyfrowagmina@cppc.gov.pl
            </p>
          </>
        )}
      </Modal>

      <Modal
        title='Szanowni Państwo'
        visible={videoModal}
        onCancel={() => hideModal('videoModal')}
        footer={[
          <Button key='submit' type='primary' onClick={() => hideModal('videoModal')}>
            OK
          </Button>,
        ]}
      >
        Wiadomość
      </Modal>
    </MessagesStyleWrapper>
  );
};

export default Messages;
