import React from "react";
import { Provider } from "react-redux";
import { ConnectedRouter } from 'connected-react-router';
import { ThemeProvider } from 'styled-components';
import themes from './settings/themes';
import { themeConfig } from './settings';
import { store, history } from "./redux/store";
import PublicRoutes from "./router";
import Boot from "./redux/boot";
import { DashAppStyleHolder } from "./DashApp.style";

const DashApp = () => 
{ 
  return (
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <ThemeProvider theme={themes[themeConfig.theme]}>
        <DashAppStyleHolder>
          <PublicRoutes />
        </DashAppStyleHolder>
      </ThemeProvider>
    </ConnectedRouter>
  </Provider>
)};

Boot()
  .then(() => DashApp())
  .catch(error => console.error(error));

export default DashApp;