import React, { useEffect } from 'react';
import { compose } from 'redux';
import { Badge, Layout, Menu } from 'antd';
import { Link } from 'react-router-dom';

import Logo from '../Logo';
import Tln from '../Tln';

import { roles } from '../../settings';
import { beneficiaryMenu, adminMenu, moderatorMenu, verifierMenu, commonMenu, controllerMenu, statementVerifierMenu } from './menus';
import withApp from '../../hoc/withApp';
import { URLS, URLS_TO_ROUTER } from '../../helpers/enumsUrls';

import { SiderStyleWrapper } from './style';

const Sider = ({ userRoles, collapsed, current, changeCurrent, toggleCollapsed, pathname, userDetails, history, formCompleted, unreadMsg }) => {

  let menus = [...beneficiaryMenu, ...commonMenu];
  if (userRoles.includes(roles.ADMIN)) menus = [...adminMenu, ...commonMenu];
  else if (userRoles.includes(roles.MODERATOR)) menus = [...moderatorMenu, ...commonMenu];
  else if (userRoles.includes(roles.VERIFIER)) menus = [...verifierMenu, ...commonMenu];
  else if (userRoles.includes(roles.STATEMENT_VERIFIER) || userRoles.includes(roles.STATEMENT_APPROVER) || userRoles.includes(roles.INSPECTOR)) menus = [...statementVerifierMenu, ...commonMenu];
  else if (userRoles.includes(roles.CONTROLLER)) menus = [...controllerMenu, ...commonMenu];

  useEffect(() => {
    if (!pathname) {
      return;
    }

    if (!userDetails && !formCompleted && userRoles.includes(roles.BENEFICIARY)) {
      history.push(URLS.USER_REGION_FORM);
    }

    URLS_TO_ROUTER.forEach(element => {
      if (pathname.startsWith(element.key)) {
        changeCurrent(element.value);
      }
    })
  }, [changeCurrent, formCompleted, history, pathname, userDetails, userRoles]);

  const getMenuItem = singleOption => {
    const { name, icon, url } = singleOption;

    if(name === 'MENU_EMAILS' && unreadMsg > 0) {
      return (
        <Menu.Item key={url} icon={icon}>
          <Link to={`${url}`}>
            <Tln id={name} /> { " " }
            <Badge
              count={unreadMsg}
              style={{ backgroundColor: '#52c41a', marginLeft: 5 }}
            />
          </Link>
        </Menu.Item>
      );
    }

    return (
      <Menu.Item key={url} icon={icon}>
        <Link to={`${url}`}>
          <Tln id={name} />
        </Link>
      </Menu.Item>
    );
  };

  return (
    <SiderStyleWrapper>
      <Layout.Sider
        collapsible
        collapsed={collapsed}
        onCollapse={toggleCollapsed.bind(null, !collapsed)}
        width={205}
      >
        <div className="logo"><Logo collapsed={collapsed} /></div>
        <Menu
          mode="inline"
          selectedKeys={current}
        >
          {menus.map(singleOption => getMenuItem(singleOption))}
        </Menu>
      </Layout.Sider>
    </SiderStyleWrapper>
  )
};

export default compose(withApp)(Sider);
