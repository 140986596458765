import { call, all, takeEvery, put, fork } from 'redux-saga/effects';
import actions from './actions';
import Logs from '../../services/logs';

export function* loadLogs() {
  yield takeEvery(actions.LOAD_LOGS, function*({ payload }) {
    const { currentPage, userId } = payload;
    try {
      const result = userId ? yield call(Logs.getLogsById, currentPage, userId) : yield call(Logs.getLogs, currentPage);
      yield put(actions.loadLogsSuccess(result));
    } catch (error) {
      yield put(actions.loadLogsError(error));
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(loadLogs)
  ]);
}