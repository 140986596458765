import TlnString from '../components/Tln/TlnString';
import SuperFetch from '../helpers/superFetch';

const Monitoring = {
  getData: (applicationId) =>
   SuperFetch.get(`application/${applicationId}/pgr-hardware-owners`)
     .then(response => response.data),
  saveRecord: (applicationId, record) => 
    SuperFetch.put(`application/${applicationId}/pgr-hardware-owners/${record.id}`, record, TlnString("CRUD_EDIT_RECORD_SUCCESS")),
  addRecord: (applicationId, record) => 
    SuperFetch.post(`application/${applicationId}/pgr-hardware-owners`, record, TlnString("CRUD_EDIT_RECORD_SUCCESS")),
  removeRecord: (applicationId, recordId) => 
    SuperFetch.delete(`application/${applicationId}/pgr-hardware-owners/${recordId}`, TlnString("CRUD_EDIT_RECORD_SUCCESS"))
}


export default Monitoring;
