import { settlementsActions } from './actions';

const initState = {
  projectProgress: null,
  projectProgressLoading: false,
  personalInfo: null,
  personalInfoLoading: false,
  representativeInfo: null,
  representativeInfoLoading: false,
  declarations: null,
  declarationsLoading: false,
  financialProgress: null,
  financialProgressLoading: false,
  financialProgressCategoryDetails: null,
  financialProgressCategoryDetailsLoading: false,
  summary: null,
  summaryLoading: false,
  procedureDocumentation: null,
  procedureDocumentationLoading: false,
  signSettlementLoading: false,
  settlementDetails: null,
  settlementDetailsLoading: false,
  notesId: [],
  verifierNoteLoading: false,
  answersId: [],
  answerLoading: false,
  checkList: null,
  checkListLoading: false,
  isVerifierStatusSaving: false,
  isVerifierStatusErrors: null,
  correction: null,
  correctionLoading: false,
  correctionSending: false,
  inspection: null,
  inspectionLoading: false,
};

export default function reducer(state = initState, { type, payload }) {
  switch (type) {
    case settlementsActions.RESET_SETTLEMENT:
      return initState;

    case settlementsActions.RESET_VERIFICATION_CHECKLIST:
      return {
        ...state,
        checkList: initState.checkList,
      };

    case settlementsActions.LOAD_PROJECT_PROGRESS:
      return {
        ...state,
        projectProgressLoading: true,
      };
    case settlementsActions.LOAD_PROJECT_PROGRESS_SUCCESS:
      return {
        ...state,
        projectProgress: payload.projectProgress,
        projectProgressLoading: false,
      };
    case settlementsActions.LOAD_PROJECT_PROGRESS_ERROR:
      return {
        ...state,
        projectProgressLoading: false,
      };
    case settlementsActions.LOAD_PERSONAL_INFO:
      return {
        ...state,
        personalInfoLoading: true,
      };
    case settlementsActions.LOAD_PERSONAL_INFO_SUCCESS:
      return {
        ...state,
        personalInfo: payload.personalInfo,
        personalInfoLoading: false,
      };
    case settlementsActions.LOAD_PERSONAL_INFO_ERROR:
      return {
        ...state,
        personalInfoLoading: false,
      };
    case settlementsActions.ADD_REPRESENTATIVE_INFO:
      return {
        ...state,
        representativeInfoLoading: true,
      };
    case settlementsActions.ADD_REPRESENTATIVE_INFO_SUCCESS:
      return {
        ...state,
        representativeInfo: payload.representativeInfo,
        representativeInfoLoading: false,
      };
    case settlementsActions.ADD_REPRESENTATIVE_INFO_ERROR:
      return {
        ...state,
        representativeInfoLoading: false,
      };
    case settlementsActions.LOAD_DECLARATIONS:
      return {
        ...state,
        declarationsLoading: true,
      };
    case settlementsActions.LOAD_DECLARATIONS_SUCCESS:
      return {
        ...state,
        declarations: payload.declarations,
        declarationsLoading: false,
      };
    case settlementsActions.LOAD_DECLARATIONS_ERROR:
      return {
        ...state,
        declarationsLoading: false,
      };
    case settlementsActions.LOAD_FINANCIAL_PROGRESS:
      return {
        ...state,
        financialProgressLoading: true,
      };
    case settlementsActions.LOAD_FINANCIAL_PROGRESS_SUCCESS:
      return {
        ...state,
        financialProgress: payload.financialProgress,
        financialProgressLoading: false,
      };
    case settlementsActions.LOAD_FINANCIAL_PROGRESS_ERROR:
      return {
        ...state,
        financialProgressLoading: false,
      };
    case settlementsActions.LOAD_FINANCIAL_PROGRESS_CATEGORY_DETAILS:
      return {
        ...state,
        financialProgressCategoryDetailsLoading: true,
      };
    case settlementsActions.LOAD_FINANCIAL_PROGRESS_CATEGORY_DETAILS_SUCCESS:
      return {
        ...state,
        financialProgressCategoryDetails: payload.financialProgressCategoryDetails,
        financialProgressCategoryDetailsLoading: false,
      };
    case settlementsActions.LOAD_FINANCIAL_PROGRESS_CATEGORY_DETAILS_ERROR:
      return {
        ...state,
        financialProgressCategoryDetailsLoading: false,
      };

    case settlementsActions.LOAD_SUMMARY:
      return {
        ...state,
        summaryLoading: true,
      };
    case settlementsActions.LOAD_SUMMARY_SUCCESS:
      return {
        ...state,
        summary: payload.summary,
        summaryLoading: false,
      };
    case settlementsActions.LOAD_SUMMARY_ERROR:
      return {
        ...state,
        summaryLoading: false,
      };

    case settlementsActions.LOAD_PROCEDURE_DOCUMENTATION:
      return {
        ...state,
        procedureDocumentationLoading: true,
      };
    case settlementsActions.LOAD_PROCEDURE_DOCUMENTATION_SUCCESS:
      return {
        ...state,
        procedureDocumentation: payload.procedureDocumentation,
        procedureDocumentationLoading: false,
      };
    case settlementsActions.LOAD_PROCEDURE_DOCUMENTATION_ERROR:
      return {
        ...state,
        procedureDocumentationLoading: false,
      };

    case settlementsActions.SIGN_SETTLEMENT:
      return {
        ...state,
        signSettlementLoading: true,
      };
    case settlementsActions.SIGN_SETTLEMENT_SUCCESS:
      return {
        ...state,
        signSettlementLoading: false,
      };
    case settlementsActions.SIGN_SETTLEMENT_ERROR:
      return {
        ...state,
        signSettlementLoading: false,
      };

    case settlementsActions.LOAD_SETTLEMENT_DETAILS:
      return {
        ...state,
        settlementDetailsLoading: true,
      };
    case settlementsActions.LOAD_SETTLEMENT_DETAILS_SUCCESS:
      return {
        ...state,
        settlementDetailsLoading: false,
        settlementDetails: payload.settlementDetails,
      };
    case settlementsActions.LOAD_SETTLEMENT_DETAILS_ERROR:
      return {
        ...state,
        settlementDetailsLoading: false,
      };

    //VERIFICATION NOTES

    case settlementsActions.ADD_SETTLEMENT_VERIFIER_NOTE:
      return {
        ...state,
        verifierNoteLoading: true,
      };
    case settlementsActions.ADD_SETTLEMENT_VERIFIER_FIELD_NOTE:
      return {
        ...state,
        verifierNoteLoading: true,
      };
    case settlementsActions.ADD_SETTLEMENT_VERIFIER_NOTE_SUCCESS:
      return {
        ...state,
        notesId: [...state.notesId, { ...payload.noteData }],
        verifierNoteLoading: false,
      };
    case settlementsActions.ADD_SETTLEMENT_VERIFIER_NOTE_ERROR:
      return {
        ...state,
        verifierNoteLoading: false,
      };

    case settlementsActions.UPDATE_SETTLEMENT_VERIFIER_NOTE:
      return {
        ...state,
        verifierNoteLoading: true,
      };
    case settlementsActions.UPDATE_SETTLEMENT_VERIFIER_NOTE_SUCCESS:
      return {
        ...state,
        verifierNoteLoading: false,
      };
    case settlementsActions.UPDATE_SETTLEMENT_VERIFIER_NOTE_ERROR:
      return {
        ...state,
        verifierNoteLoading: false,
      };

    case settlementsActions.DELETE_SETTLEMENT_VERIFIER_NOTE:
      return {
        ...state,
        verifierNoteLoading: true,
      };
    case settlementsActions.DELETE_SETTLEMENT_VERIFIER_NOTE_SUCCESS:
      return {
        ...state,
        notesId: state.notesId.filter(note => note.noteId !== payload.noteId),
        verifierNoteLoading: false,
      };
    case settlementsActions.DELETE_SETTLEMENT_VERIFIER_NOTE_ERROR:
      return {
        ...state,
        verifierNoteLoading: false,
      };

    // NOTE ANSWERS
    case settlementsActions.ADD_SETTLEMENT_VERIFIER_NOTE_ANSWER:
      return {
        ...state,
        answerLoading: true,
      };
    case settlementsActions.ADD_SETTLEMENT_VERIFIER_NOTE_ANSWER_SUCCESS:
      return {
        ...state,
        answersId: [...state.answersId, payload.answerId],
        answerLoading: false,
      };
    case settlementsActions.ADD_SETTLEMENT_VERIFIER_NOTE_ANSWER_ERROR:
      return {
        ...state,
        answerLoading: false,
      };

    case settlementsActions.UPDATE_SETTLEMENT_VERIFIER_NOTE_ANSWER:
      return {
        ...state,
        answerLoading: true,
      };
    case settlementsActions.UPDATE_SETTLEMENT_VERIFIER_NOTE_ANSWER_SUCCESS:
      return {
        ...state,
        answersId: [...state.answersId, payload.answerId],
        answerLoading: false,
      };
    case settlementsActions.UPDATE_SETTLEMENT_VERIFIER_NOTE_ANSWER_ERROR:
      return {
        ...state,
        answerLoading: false,
      };

    case settlementsActions.LOAD_VERIFICATION_CHECKLIST:
      return {
        ...state,
      };
    case settlementsActions.LOAD_VERIFICATION_CHECKLIST_SUCCESS:
      return {
        ...state,
        checkList: payload.checkList,
      };
    case settlementsActions.LOAD_VERIFICATION_CHECKLIST_ERROR:
      return {
        ...state,
      };
    case settlementsActions.CHANGE_VERIFICATION_STATUS:
      return {
        ...state,
        isVerifierStatusSaving: true,
      };
    case settlementsActions.CHANGE_VERIFICATION_STATUS_SUCCESS:
      return {
        ...state,
        isVerifierStatusSaving: false,
        isVerifierStatusErrors: null,
      };
    case settlementsActions.CHANGE_VERIFICATION_STATUS_ERROR:
      return {
        ...state,
        isVerifierStatusSaving: false,
        isVerifierStatusErrors: payload.errors,
      };

    case settlementsActions.LOAD_CORRECTION:
      return {
        ...state,
        correctionLoading: true,
      };
    case settlementsActions.LOAD_CORRECTION_SUCCESS:
      return {
        ...state,
        correction: payload.correction,
        correctionLoading: false,
      };
    case settlementsActions.LOAD_CORRECTION_ERROR:
      return {
        ...state,
        correctionLoading: false,
      };

    case settlementsActions.CHANGE_CORRECTION_VALUE_ADMIN:
    case settlementsActions.CHANGE_CORRECTION_VALUE_CPPC:
      return {
        ...state,
        correctionSending: true,
      };
    case settlementsActions.CHANGE_CORRECTION_VALUE_ADMIN_SUCCESS:
    case settlementsActions.CHANGE_CORRECTION_VALUE_CPPC_SUCCESS:
      return {
        ...state,
        correctionSending: false,
      };
    case settlementsActions.CHANGE_CORRECTION_VALUE_ADMIN_ERROR:
    case settlementsActions.CHANGE_CORRECTION_VALUE_CPPC_ERROR:
      return {
        ...state,
        correctionSending: false,
      };

    case settlementsActions.LOAD_INSPECTION:
      return {
        ...state,
        inspectionLoading: true,
      };
    case settlementsActions.LOAD_INSPECTION_SUCCESS:
      return {
        ...state,
        inspection: payload.inspection,
        inspectionLoading: false,
      };
    case settlementsActions.LOAD_INSPECTION_ERROR:
      return {
        ...state,
        inspectionLoading: false,
      };

    default:
      return state;
  }
}
