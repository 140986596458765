const actions = {
  LOAD_USER_ACCOUNT: 'LOAD_USER_ACCOUNT',
  LOAD_USER_ACCOUNT_SUCCESS: 'LOAD_USER_ACCOUNT_SUCCESS',
  LOAD_USER_ACCOUNT_ERROR: 'LOAD_USER_ACCOUNT_ERROR',

  UPDATE_USER_ACCOUNT: 'UPDATE_USER_ACCOUNT',
  UPDATE_USER_ACCOUNT_SUCCESS: 'UPDATE_USER_ACCOUNT_SUCCESS',
  UPDATE_USER_ACCOUNT_ERROR: 'UPDATE_USER_ACCOUNT_ERROR',

  UPDATE_USER_PASSWORD: 'UPDATE_USER_PASSWORD',
  UPDATE_USER_PASSWORD_SUCCESS: 'UPDATE_USER_PASSWORD_SUCCESS',
  UPDATE_USER_PASSWORD_ERROR: 'UPDATE_USER_PASSWORD_ERROR',

  REMOVE_USER_ACCOUNT: 'REMOVE_USER_ACCOUNT',

  loadUserAccount: () => ({
    type: actions.LOAD_USER_ACCOUNT
  }),
  loadUserAccountSuccess: data => ({
    type: actions.LOAD_USER_ACCOUNT_SUCCESS,
    payload: { data }
  }),
  loadUserAccountError: error => ({
    type: actions.LOAD_USER_ACCOUNT_ERROR,
    payload: { error }
  }),

  updateUserAccount: data => ({
    type: actions.UPDATE_USER_ACCOUNT,
    payload: { data } ,
  }),
  updateUserAccountSuccess: () => ({
    type: actions.UPDATE_USER_ACCOUNT_SUCCESS
  }),
  updateUserAccountError: error => ({
    type: actions.UPDATE_USER_ACCOUNT_ERROR,
    payload: { error },
  }),

  updateUserPassword: data => ({
    type: actions.UPDATE_USER_PASSWORD,
    payload: { data } ,
  }),
  updateUserPasswordSuccess: () => ({
    type: actions.UPDATE_USER_PASSWORD_SUCCESS
  }),
  updateUserPasswordError: error => ({
    type: actions.UPDATE_USER_PASSWORD_ERROR,
    payload: { error },
  }),

  removeUserAccount: () => ({
    type: actions.REMOVE_USER_ACCOUNT
  }),
};

export default actions;