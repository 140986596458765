import { all } from 'redux-saga/effects';
import authSagas from './auth/saga';
import usersSaga from './users/saga';
import accountSaga from './account/saga';
import eligibleCostsSaga from './eligibleCosts/saga';
import logsSaga from './logs/saga';
import emailsSaga from './emails/saga';
import applicationsSaga from './applications/saga';
import regionsSaga from './regions/saga';
import monitoringSaga from './monitoring/saga';
import statementSaga from './statements/saga';
import statementCostsSaga from './statementCosts/saga';
import settlementsSaga from './settlements/saga';

export default function* rootSaga(getState) {
  yield all([
    authSagas(),
    usersSaga(),
    accountSaga(),
    eligibleCostsSaga(),
    logsSaga(),
    emailsSaga(),
    applicationsSaga(),
    regionsSaga(),
    monitoringSaga(),
    statementSaga(),
    statementCostsSaga(),
    settlementsSaga(),
  ]);
}
