import { all, takeEvery, put, fork, select } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import actions from './actions';
import Auth from '../../services/auth';

import regionActions from '../regions/actions';
import { getFormCompleted } from '../regions/selectors';

import { roles } from '../../settings';
import { URLS_WITHOUT_LOGIN, URLS } from '../../helpers/enumsUrls';

export function* loginRequest() {
  yield takeEvery(actions.LOGIN_REQUEST, function* ({ payload }) {
    try {
      const { email, password } = payload;
      const response = yield Auth.login({ email, password });
      yield put({
        type: actions.LOGIN_SUCCESS,
        token: response.data.token,
      });
    } catch (error) {
      yield put({
        type: actions.LOGIN_ERROR,
        error,
      });
    }
  });
}

export function* loginSuccess() {
  yield takeEvery(actions.LOGIN_SUCCESS, function* (payload) {
    yield localStorage.setItem('id_token', payload.token);
    yield put(push(URLS.APP));
  });
}

export function* logout() {
  yield takeEvery(actions.LOGOUT, function* () {
    localStorage.removeItem('id_token');
    yield put(regionActions.clearFormCompleted());
    yield put({
      type: 'RESET_STATE',
    });
    yield put(push(URLS_WITHOUT_LOGIN.LOGIN_WITH_PAGE));
  });
}

export function* signUpRequest() {
  yield takeEvery(actions.SIGNUP_REQUEST, function* ({ payload }) {
    try {
      const { data } = payload;
      yield Auth.signUp(data);
      yield put({
        type: actions.SIGNUP_SUCCESS,
      });
      yield put(push(URLS.APP));
    } catch (error) {
      yield put({
        type: actions.SIGNUP_ERROR,
        error,
      });
    }
  });
}

export function* forgotPassword() {
  yield takeEvery(actions.FORGOT_PASSWORD_REQUEST, function* ({ payload }) {
    try {
      const { email } = payload;
      yield Auth.forgotPassword(email);
      yield put({
        type: actions.FORGOT_PASSWORD_SUCCESS,
      });
      yield put(push(URLS_WITHOUT_LOGIN.LOGIN_WITH_PAGE));
    } catch (error) {
      yield put({
        type: actions.FORGOT_PASSWORD_ERROR,
        error,
      });
    }
  });
}

export function* resetPassword() {
  yield takeEvery(actions.RESET_PASSWORD_REQUEST, function* ({ payload }) {
    try {
      yield Auth.resetPassword(payload);
      yield put({
        type: actions.RESET_PASSWORD_SUCCESS,
      });
      yield put(push(URLS_WITHOUT_LOGIN.LOGIN_WITH_PAGE));
    } catch (error) {
      yield put({
        type: actions.RESET_PASSWORD_ERROR,
        error,
      });
    }
  });
}

export function* activateAccount() {
  yield takeEvery(actions.ACTIVATE_ACCOUNT_REQUEST, function* ({ payload }) {
    try {
      const { token } = payload;
      yield Auth.activateAccount({ token });
      yield put({
        type: actions.ACTIVATE_ACCOUNT_SUCCESS,
      });
      yield put(push(URLS_WITHOUT_LOGIN.LOGIN_WITH_PAGE));
    } catch (error) {
      yield put({
        type: actions.ACTIVATE_ACCOUNT_ERROR,
        error,
      });
      yield put(push(URLS_WITHOUT_LOGIN.LOGIN_WITH_PAGE));
    }
  });
}

export function* currentUser() {
  yield takeEvery(actions.CURRENT_USER_REQUEST, function* () {
    const formCompleted = yield select(getFormCompleted);

    try {
      const response = yield Auth.currentUser();
      const currentUser = response.data.user;
      const msgcount = response.data.msgcount;
      const pgrDate = response.data.user.details ? response.data.user.details.last_date_send_settlement_application_pgr : null;
      // const cgDate = response.data.user.details ? response.data.user.details.last_date_send_settlement_application_cg : null;
      yield put({
        type: actions.CURRENT_USER_SUCCESS,
        payload: { currentUser, msgcount, pgrDate },
      });

      if (!currentUser.details && !formCompleted && currentUser.roles.includes(roles.BENEFICIARY)) {
        yield put(push(URLS.USER_REGION_FORM));
      }
    } catch (error) {
      yield put({
        type: actions.LOGOUT,
      });
      yield put({
        type: actions.CURRENT_USER_ERROR,
        error,
      });
    }
  });
}

export function* emailsNumber() {
  yield takeEvery(actions.GET_EMAILS_NUMBER, function* () {
    try {
      const response = yield Auth.currentUser();
      const msgcount = response.data.msgcount;
      yield put({
        type: actions.CURRENT_USER_SUCCESS,
        payload: { msgcount },
      });
    } catch (error) {
      yield put({
        type: actions.LOGOUT,
      });
      yield put({
        type: actions.CURRENT_USER_ERROR,
        error,
      });
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(loginRequest),
    fork(loginSuccess),
    fork(signUpRequest),
    fork(logout),
    fork(forgotPassword),
    fork(resetPassword),
    fork(activateAccount),
    fork(currentUser),
    fork(emailsNumber),
  ]);
}
