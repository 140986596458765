import actions from './actions';

const initState = {
  isLoading: false,
  terytData: '',
  formCompleted: false,
};

export default function reducer(state = initState, { type, payload }) {
  switch (type) {
    case actions.SEND_REGION_FORM:
      return {
        ...state,
        isLoading: true,
      };
    case actions.SEND_REGION_FORM_SUCCESS:
      return {
        ...state,
        isLoading: false,
        formCompleted: true,
      };
    case actions.SEND_REGION_FORM_ERROR:
      return {
        ...state,
        isLoading: false,
      };

    case actions.LOAD_TERYT_DATA:
      return {
        ...state,
        isLoading: true,
      };
    case actions.LOAD_TERYT_DATA_SUCCESS:
      return {
        ...state,
        isLoading: false,
        terytData: payload.data,
      };
    case actions.LOAD_TERYT_DATA_ERROR:
      return {
        ...state,
        isLoading: false,
      };

    case actions.CLEAR_FORM_COMPLETED:
      return {
        ...state,
        formCompleted: false
      }

    default:
      return state;
  }
}
