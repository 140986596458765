import actions from './actions';

const initState = {
};

export default function reducer(state = initState, { type, payload }) {
  switch (type) {
    case actions.ADD_CATEGORY:
      state[payload.category] = payload.costs;

      return {
        ...state
      };
    case actions.ADD_COST:

      if(state[payload.category]) {
        const costsIfExists = state[payload.category].findIndex((cost) => cost.id === payload.cost.id);
        if(payload.cost.id && costsIfExists > -1) {
          state[payload.category][costsIfExists] = payload.cost;
        } else {
          state[payload.category].push(payload.cost);
        }
      }
      return {
        ...state
      };
    case actions.DELETE_COST:
      state[payload.category].splice(payload.index, 1);
      return {
        ...state
      };
    case actions.EDIT_COST:
      state[payload.category][payload.index] = payload.cost;
      return {
        ...state
      };
    case actions.REMOVE_COSTS:
      Object.keys(state).forEach(category => {
        state[category] = [];
      })
      return {
        ...state
      };

    default:
      return state;
  }
}
