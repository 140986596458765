import styled from 'styled-components';
import { palette } from 'styled-theme';
import { transition, borderRadius } from '../settings/style-util';
import WithDirection from '../settings/withDirection';
import Image from '../image/404_bg.jpg';

const PageWithImageStyleWrapper = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  position: relative;

  @media only screen and (max-width: 767px) {
    width: 100%;
    justify-content: center;
    align-items: center;
    min-height: 300px;
  }

  .iso404Content {
    padding-right: 60px;
    padding-left: 15px;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    flex-direction: column;
    width: 50%;
    text-align: right;

    @media only screen and (max-width: 767px) {
      order: 2;
      margin: 0;
      padding: 15px;
      align-items: center;
      text-align: center;
      flex-direction: column;
    }

    h1 {
      font-size: 84px;
      font-weight: 700;
      color: ${palette('secondary', 2)};
      line-height: 1;
      margin: 0 0 25px;
    }

    h3 {
      font-size: 24px;
      font-weight: 400;
      color: ${palette('text', 1)};
      margin: 0 0 10px;
      line-height: 1.2;
    }

    p {
      font-size: 14px;
      font-weight: 400;
      color: ${palette('text', 3)};
      margin: 0 0 10px;
    }

    button {
      text-transform: uppercase;
      display: inline-block;
      margin-top: 15px;
      margin-bottom: 0;
      font-weight: 500;
      text-align: center;
      -ms-touch-action: manipulation;
      touch-action: manipulation;
      cursor: pointer;
      background-image: none;
      border: 0;
      white-space: nowrap;
      line-height: 1.5;
      padding: 0 30px;
      font-size: 13px;
      flex-shrink: 0;
      height: 36px;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      position: relative;
      color: #ffffff;
      background-color: ${palette('primary', 0)};
      ${transition()};
      ${borderRadius('18px')};

      a {
        width: 100%;
        height: 100%;
        color: #ffffff;
        text-decoration: none;
      }

      &:hover {
        a {
          text-decoration: none;
        }
      }

      &:focus {
        outline: 0;
        box-shadow: none;

        a {
          text-decoration: none;
        }
      }
    }
  }

  .maintenance.iso404Content h1 {
    font-size: 50px;
  }

  .iso404Artwork {
    width: 50%;
    background-image: url(${Image});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    display: flex;
    align-items: center;
    justify-content: center;

    @media only screen and (max-width: 767px) {
      display: none;
    }

    img {
      max-height: 100%;
    }
  }
`;

export default WithDirection(PageWithImageStyleWrapper);
