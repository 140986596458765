const actions = {
  RESET_STATE: 'RESET_STATE',
  resetState: () => ({
    type: actions.RESET_STATE,
  }),

  RESET_STATEMENT: 'RESET_STATEMENT',
  resetStatement: () => ({
    type: actions.RESET_STATEMENT,
  }),

  LOAD_STATEMENT: 'LOAD_STATEMENT',
  loadStatement: id => ({
    type: actions.LOAD_STATEMENT,
    payload: { id },
  }),

  LOAD_VERIFIER_STATEMENT: 'LOAD_VERIFIER_STATEMENT',
  loadVerifierStatement: id => ({
    type: actions.LOAD_VERIFIER_STATEMENT,
    payload: { id },
  }),

  LOAD_ADMIN_STATEMENT: 'LOAD_ADMIN_STATEMENT',
  loadAdminStatement: id => ({
    type: actions.LOAD_ADMIN_STATEMENT,
    payload: { id },
  }),

  LOAD_VERIFIER_CPPC_STATEMENT: 'LOAD_VERIFIER_CPPC_STATEMENT',
  loadVerifierCppcStatement: id => ({
    type: actions.LOAD_VERIFIER_CPPC_STATEMENT,
    payload: { id },
  }),

  LOAD_APPROVER_STATEMENT: 'LOAD_APPROVER_STATEMENT',
  loadApproverStatement: id => ({
    type: actions.LOAD_APPROVER_STATEMENT,
    payload: { id },
  }),

  LOAD_INSPECTOR_STATEMENT: 'LOAD_INSPECTOR_STATEMENT',
  loadInspectorStatement: id => ({
    type: actions.LOAD_INSPECTOR_STATEMENT,
    payload: { id },
  }),

  LOAD_STATEMENT_SUCCESS: 'LOAD_STATEMENT_SUCCESS',
  loadStatementSuccess: data => ({
    type: actions.LOAD_STATEMENT_SUCCESS,
    payload: { data },
  }),

  LOAD_STATEMENT_ERROR: 'LOAD_STATEMENT_ERROR',
  loadStatementError: error => ({
    type: actions.LOAD_STATEMENT_ERROR,
    payload: { error },
  }),

  LOAD_STATEMENTS: 'LOAD_STATEMENTS',
  loadStatements: (userId, query, mode) => ({
    type: actions.LOAD_STATEMENTS,
    payload: { userId, query, mode },
  }),

  LOAD_STATEMENTS_SUCCESS: 'LOAD_STATEMENTS_SUCCESS',
  loadStatementsSuccess: data => ({
    type: actions.LOAD_STATEMENTS_SUCCESS,
    payload: { data },
  }),

  LOAD_STATEMENTS_ERROR: 'LOAD_STATEMENTS_ERROR',
  loadStatementsError: error => ({
    type: actions.LOAD_STATEMENTS_ERROR,
    payload: { error },
  }),

  LOAD_VERIFIERS: 'LOAD_VERIFIERS',
  loadVerifiers: () => ({
    type: actions.LOAD_VERIFIERS,
    payload: {},
  }),

  LOAD_VERIFIERS_SUCCESS: 'LOAD_VERIFIERS_SUCCESS',
  loadVerifiersSuccess: data => ({
    type: actions.LOAD_VERIFIERS_SUCCESS,
    payload: { data },
  }),

  LOAD_VERIFIERS_ERROR: 'LOAD_VERIFIERS_ERROR',
  loadVerifiersError: error => ({
    type: actions.LOAD_VERIFIERS_ERROR,
    payload: { error },
  }),

  SAVE_VERIFIERS: 'SAVE_VERIFIERS',
  saveVerifiers: (selectedRows, primaryVerifier, secondaryVerifier, filters) => ({
    type: actions.SAVE_VERIFIERS,
    payload: { selectedRows, primaryVerifier, secondaryVerifier, filters },
  }),

  LOAD_INSPECTORS: 'LOAD_INSPECTORS',
  loadInspectors: () => ({
    type: actions.LOAD_INSPECTORS,
    payload: {},
  }),

  LOAD_INSPECTORS_SUCCESS: 'LOAD_INSPECTORS_SUCCESS',
  loadInspectorsSuccess: data => ({
    type: actions.LOAD_INSPECTORS_SUCCESS,
    payload: { data },
  }),

  LOAD_INSPECTORS_ERROR: 'LOAD_INSPECTORS_ERROR',
  loadInspectorsError: error => ({
    type: actions.LOAD_INSPECTORS_ERROR,
    payload: { error },
  }),

  SAVE_INSPECTORS: 'SAVE_INSPECTORS',
  saveInspectors: (selectedRows, manager, member, filters) => ({
    type: actions.SAVE_INSPECTORS,
    payload: { selectedRows, manager, member, filters },
  }),

  SAVE_STATEMENT: 'SAVE_STATEMENT',
  saveStatement: (data, files) => ({
    type: actions.SAVE_STATEMENT,
    payload: { data, files },
  }),

  TOGGLE_INFO_MODAL: 'TOGGLE_INFO_MODAL',
  toggleInfoModal: active => ({
    type: actions.TOGGLE_INFO_MODAL,
    payload: { active },
  }),

  GO_TO_STATEMENT: 'GO_TO_STATEMENT',
  goToStatement: statementId => ({
    type: actions.GO_TO_STATEMENT,
    payload: { statementId },
  }),

  SAVE_STATEMENT_SUCCESS: 'SAVE_STATEMENT_SUCCESS',
  saveStatementSuccess: () => ({
    type: actions.SAVE_STATEMENT_SUCCESS,
    payload: {},
  }),

  CLOSE_STATEMENT_MODAL: 'CLOSE_STATEMENT_MODAL',
  closeStatementModal: statementId => ({
    type: actions.CLOSE_STATEMENT_MODAL,
    payload: { statementId },
  }),

  CREATE_STATEMENT: 'CREATE_STATEMENT',
  createStatement: id => ({
    type: actions.CREATE_STATEMENT,
    payload: { id },
  }),

  CLEAR_STATEMENT: 'CLEAR_STATEMENT',
  clearStatement: () => ({
    type: actions.CLEAR_STATEMENT,
    payload: {},
  }),

  SAVE_STATEMENT_BY_ADMIN: 'SAVE_STATEMENT_BY_ADMIN',
  saveStatementByAdmin: (id, data) => ({
    type: actions.SAVE_STATEMENT_BY_ADMIN,
    payload: { id, data },
  }),

  SAVE_STATEMENT_BY_APPROVER: 'SAVE_STATEMENT_BY_APPROVER',
  saveStatementByApprover: (id, data) => ({
    type: actions.SAVE_STATEMENT_BY_APPROVER,
    payload: { id, data },
  }),

  SEND_STATEMENT: 'SEND_STATEMENT',
  sendStatement: data => ({
    type: actions.SEND_STATEMENT,
    payload: { data },
  }),

  SEND_STATEMENT_SUCCESS: 'SEND_STATEMENT_SUCCESS',
  sendStatementSuccess: () => ({
    type: actions.SEND_STATEMENT_SUCCESS,
  }),

  SEND_STATEMENT_ERROR: 'SEND_STATEMENT_ERROR',
  sendStatementError: () => ({
    type: actions.SEND_STATEMENT_ERROR,
  }),

  CHANGE_ADMIN_STATUS_STATEMENT: 'CHANGE_ADMIN_STATUS_STATEMENT',
  changeAdminStatus: (statementId, status) => ({
    type: actions.CHANGE_ADMIN_STATUS_STATEMENT,
    payload: { statementId, status },
  }),

  SAVE_ADMIN_EDIT_STATEMENT: 'SAVE_ADMIN_EDIT_STATEMENT',
  saveAdminEdit: (type, statementId, values) => ({
    type: actions.SAVE_ADMIN_EDIT_STATEMENT,
    payload: { type, values, statementId },
  }),

  SAVE_CONTROL_DOCUMENTS: 'SAVE_CONTROL_DOCUMENTS',
  saveControlDocuments: (statementId, values) => ({
    type: actions.SAVE_CONTROL_DOCUMENTS,
    payload: { statementId, values },
  }),

  SAVE_CONTROL_DOCUMENTS_END: 'SAVE_CONTROL_DOCUMENTS_END',
  saveControlDocumentsEnd: () => ({
    type: actions.SAVE_CONTROL_DOCUMENTS_END,
    payload: {},
  }),

  LOAD_STATEMENT_BACKUP: 'LOAD_STATEMENT_BACKUP',
  loadStatementBackup: (statementId, backupId) => ({
    type: actions.LOAD_STATEMENT_BACKUP,
    payload: { statementId, backupId },
  }),

  SAVE_STATEMENT_CONTROLLER: 'SAVE_STATEMENT_CONTROLLER',
  saveStatementController: (statementId, status, data) => ({
    type: actions.SAVE_STATEMENT_CONTROLLER,
    payload: { statementId, status, data },
  }),

  REMOVE_DOCUMENT_FILE: 'REMOVE_DOCUMENT_FILE',
  removeDocumentFile: (costId, categoryId, file) => ({
    type: actions.REMOVE_DOCUMENT_FILE,
    payload: { costId, categoryId, file },
  }),

  REMOVE_DOCUMENT_FILE_PGR: 'REMOVE_DOCUMENT_FILE_PGR',
  removeDocumentFilePGR: (statement, budgetType, category, file) => ({
    type: actions.REMOVE_DOCUMENT_FILE_PGR,
    payload: { statement, budgetType, category, file },
  }),

  REMOVE_DOCUMENT_FILE_COST: 'REMOVE_DOCUMENT_FILE_COST',
  removeDocumentFileCost: (costId, file) => ({
    type: actions.REMOVE_DOCUMENT_FILE_COST,
    payload: { costId, file },
  }),

  SAVE_SINGLE_COST: 'SAVE_SINGLE_COST',
  saveSingleCost: (statementId, cost) => ({
    type: actions.SAVE_SINGLE_COST,
    payload: { statementId, cost },
  }),

  SAVE_SINGLE_ADMIN_NOTE: 'SAVE_SINGLE_ADMIN_NOTE',
  saveSingleAdminNote: (statementId, note) => ({
    type: actions.SAVE_SINGLE_ADMIN_NOTE,
    payload: { statementId, note },
  }),

  SAVE_VERIFIER_RESPONSE_NOTE: 'SAVE_VERIFIER_RESPONSE_NOTE',
  saveVerifiersResponseNote: ({ id, response, statementId }) => ({
    type: actions.SAVE_VERIFIER_RESPONSE_NOTE,
    payload: { id, response, statementId },
  }),

  SAVE_INSPECTOR_RESPONSE_NOTE: 'SAVE_INSPECTOR_RESPONSE_NOTE',
  saveInspectorResponseNote: ({ id, response, statementId }) => ({
    type: actions.SAVE_INSPECTOR_RESPONSE_NOTE,
    payload: { id, response, statementId },
  }),

  TOGGLE_ADMIN_NOTE_STATUS: 'TOGGLE_ADMIN_NOTE_STATUS',
  toggleAdminNoteStatus: (noteId, statementId) => ({
    type: actions.TOGGLE_ADMIN_NOTE_STATUS,
    payload: { noteId, statementId },
  }),

  SAVE_RESPONSE_NOTE: 'SAVE_RESPONSE_NOTE',
  saveResponseNote: ({ id, response, statementId }) => ({
    type: actions.SAVE_RESPONSE_NOTE,
    payload: { id, response, statementId },
  }),

  STATUS_CHANGE_VERIFIER: 'STATUS_CHANGE_VERIFIER',
  statusChangeVerifier: (id, status) => ({
    type: actions.STATUS_CHANGE_VERIFIER,
    payload: { id, status },
  }),

  STATUS_CHANGE_VERIFIER_SUCCESS: 'STATUS_CHANGE_VERIFIER_SUCCESS',
  statusChangeVerifierSuccess: () => ({
    type: actions.STATUS_CHANGE_VERIFIER_SUCCESS,
  }),

  STATUS_CHANGE_VERIFIER_ERROR: 'STATUS_CHANGE_VERIFIER_ERROR',
  statusChangeVerifierError: () => ({
    type: actions.STATUS_CHANGE_VERIFIER_ERROR,
  }),

  STATUS_CHANGE_APPROVER: 'STATUS_CHANGE_APPROVER',
  statusChangeApprover: (id, status) => ({
    type: actions.STATUS_CHANGE_APPROVER,
    payload: { id, status },
  }),

  STATUS_CHANGE_APPROVER_SUCCESS: 'STATUS_CHANGE_APPROVER_SUCCESS',
  statusChangeApproverSuccess: () => ({
    type: actions.STATUS_CHANGE_APPROVER_SUCCESS,
  }),

  STATUS_CHANGE_APPROVER_ERROR: 'STATUS_CHANGE_APPROVER_ERROR',
  statusChangeApproverError: () => ({
    type: actions.STATUS_CHANGE_APPROVER_ERROR,
  }),

  SAVE_INELIGIBLE_COST: 'SAVE_INELIGIBLE_COST',
  saveIneligibleCost: (model, statementId, fieldId, cost, sum) => ({
    type: actions.SAVE_INELIGIBLE_COST,
    payload: { model, fieldId, cost, sum, statementId },
  }),

  TOGGLE_INTERNAL_NOTE: 'TOGGLE_INTERNAL_NOTE',
  toggleInternalNote: id => ({
    type: actions.TOGGLE_INTERNAL_NOTE,
    payload: { id },
  }),

  SEND_TO_INSPECTION: 'SEND_TO_INSPECTION',
  sendToInspection: (id, filters) => ({
    type: actions.SEND_TO_INSPECTION,
    payload: { id, filters },
  }),

  START_INSPECTION: 'START_INSPECTION',
  startInspection: (data, filters) => ({
    type: actions.START_INSPECTION,
    payload: { data, filters },
  }),

  MANAGE_INSPECTION: 'MANAGE_INSPECTION',
  manageInspection: (action, data) => ({
    type: actions.MANAGE_INSPECTION,
    payload: { action, data },
  }),

  APPROVE_INSPECTION: 'APPROVE_INSPECTION',
  approveInspection: (status, data) => ({
    type: actions.APPROVE_INSPECTION,
    payload: { status, data },
  }),

  START_INSPECTION_SUCCESS: 'START_INSPECTION_SUCCESS',
  startInspectionSuccess: () => ({
    type: actions.START_INSPECTION_SUCCESS,
  }),

  START_INSPECTION_ERROR: 'START_INSPECTION_ERROR',
  startInspectionError: () => ({
    type: actions.START_INSPECTION_ERROR,
  }),

  SAVE_CHAT_TO_DB: 'SAVE_CHAT_TO_DB',
  saveChatToDb: () => ({
    type: actions.SAVE_CHAT_TO_DB,
  }),

  SAVE_CHAT_TO_DB_ERROR: 'SAVE_CHAT_TO_DB_ERROR',
  saveChatToDbError: () => ({
    type: actions.SAVE_CHAT_TO_DB_ERROR,
  }),

  SAVE_RESPONSE_FILE_TO_DB: 'SAVE_RESPONSE_FILE_TO_DB',
  saveResponseFileToDb: () => ({
    type: actions.SAVE_RESPONSE_FILE_TO_DB,
  }),
  SAVE_RESPONSE_FILE_TO_DB_SUCCESS: 'SAVE_RESPONSE_FILE_TO_DB_SUCCESS',
  saveResponseFileToDbSuccess: result => ({
    type: actions.SAVE_RESPONSE_FILE_TO_DB_SUCCESS,
    payload: { result },
  }),
  SAVE_RESPONSE_FILE_TO_DB_ERROR: 'SAVE_RESPONSE_FILE_TO_DB_ERROR',
  saveResponseFileToDbError: () => ({
    type: actions.SAVE_RESPONSE_FILE_TO_DB_ERROR,
  }),

  ADD_ALL_THREAD_TO_INSPECTION: 'ADD_ALL_THREAD_TO_INSPECTION',
  addAllThreadsToInspection: (statementId, action, data) => ({
    type: actions.ADD_ALL_THREAD_TO_INSPECTION,
    payload: { statementId, action, data },
  }),

  SEND_ALL_RESPONSES: 'SEND_ALL_RESPONSES',
  sendAllResponses: (statementId, action, data) => ({
    type: actions.SEND_ALL_RESPONSES,
    payload: { statementId, action, data },
  }),
  SEND_ALL_RESPONSES_TO_DRAFT: 'SEND_ALL_RESPONSES_TO_DRAFT',
  sendAllResponsesToDraft: (statementId, chatId, data) => ({
    type: actions.SEND_ALL_RESPONSES_TO_DRAFT,
    payload: { statementId, chatId, data },
  }),

  ADD_ALL_THREAD_TO_DRAFT: 'ADD_ALL_THREAD_TO_DRAFT',
  addAllThreadsToDraft: (statementId, chatId, data) => ({
    type: actions.ADD_ALL_THREAD_TO_DRAFT,
    payload: { statementId, chatId, data },
  }),
  ADD_THREAD: 'ADD_THREAD',
  addThread: (statementId, chatId, data, url = '') => ({
    type: actions.ADD_THREAD,
    payload: { statementId, chatId, data, url },
  }),
  UPDATE_THREAD: 'UPDATE_THREAD',
  updateThread: (statementId, threadId, data, url = '') => ({
    type: actions.UPDATE_THREAD,
    payload: { statementId, threadId, data, url },
  }),
  DELETE_THREAD: 'DELETE_THREAD',
  deleteThread: (statementId, threadId) => ({
    type: actions.DELETE_THREAD,
    payload: { statementId, threadId },
  }),

  ADD_CHAT_RESPONSE: 'ADD_CHAT_RESPONSE',
  addChatResponse: data => ({
    type: actions.ADD_CHAT_RESPONSE,
    payload: { data },
  }),

  ADD_CHAT_RESPONSE_FILE: 'ADD_CHAT_RESPONSE_FILE',
  addChatResponseFile: data => ({
    type: actions.ADD_CHAT_RESPONSE_FILE,
    payload: { data },
  }),

  REMOVE_CHAT_DOCUMENT_FILE: 'REMOVE_CHAT_DOCUMENT_FILE',
  removeChatDocumentFile: (statementId, msgId, fileId) => ({
    type: actions.REMOVE_CHAT_DOCUMENT_FILE,
    payload: { statementId, msgId, fileId },
  }),
};

export default actions;
