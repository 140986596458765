import { call, all, takeEvery, put, fork } from 'redux-saga/effects';
import actions from './actions';
import authActions from '../auth/actions'
import Account from '../../services/account';

export function* loadUserAccount() {
  yield takeEvery(actions.LOAD_USER_ACCOUNT, function*() {
    try {
      const result = yield call(Account.getUserAccount);
      yield put(actions.loadUserAccountSuccess(result));
    } catch (error) {
      yield put(actions.loadUserAccountError(error));
    }
  });
}

export function* updateUserAccount() {
  yield takeEvery(actions.UPDATE_USER_ACCOUNT, function*({ payload }) {
    const { data } = payload;
    try {
      yield call(Account.updateUserAccount, data);
      yield put(actions.updateUserAccountSuccess());
      yield put(actions.loadUserAccount());
      yield put(authActions.getCurrentUser());
    } catch (error) {
      yield put(actions.updateUserAccountError(error));
    }
  });
}

export function* updateUserPassword() {
  yield takeEvery(actions.UPDATE_USER_PASSWORD, function*({ payload }) {
    const { data } = payload;
    try {
      yield call(Account.updateUserPassword, data);
      yield put(actions.updateUserPasswordSuccess());
      yield put(authActions.getCurrentUser());
    } catch (error) {
      yield put(actions.updateUserPasswordError(error));
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(loadUserAccount),
    fork(updateUserAccount),
    fork(updateUserPassword)
  ]);
}
