import { push } from 'react-router-redux';
import { store } from '../redux/store';
import authActions from '../redux/auth/actions';
import { apiUrl, defaultActiveLanguage } from '../settings';
import notification from '../components/notification';
import errorCodes from '../translations/errorCodes';

const customHeader = (lang, isFormData) => {
  const header = {
    Accept: 'application/json',
    Authorization: `Bearer ${localStorage.getItem('id_token')}`,
    Lang: lang
  };
  if (!isFormData) {
    header['Content-Type'] = 'application/json';
  }
  return header;
};


const base = (method, url, data = {}, message, signal) => {
  const lang = localStorage.getItem('language') || defaultActiveLanguage;

  let body = null;
  const isFormData = data instanceof FormData;

  if (isFormData) {
    body = data;
  } else if (method === 'post' || method === 'put') {
    body = JSON.stringify(data);
  }

  return fetch(`${apiUrl}${url}`, {
    method,
    headers: customHeader(lang, isFormData),
    body,
    signal
  })
    .then(response => response.json())
    .then(response => {
      if (response.status === 'success') {
        message && notification('success', message);
        return response;
      } else {
        throw response;
      }
    })
    .catch(response => {
      const { errors } = response;
      if (typeof errors === 'object' && !Array.isArray(errors) && errors !== null) {
        throw response;
      }

      if (errors.includes('401')) {
        store.dispatch(authActions.logout());
      } else if (errors.includes(404)) {
        store.dispatch(push('/404'));
      }
      const errorMsgs = errors.map(error => errorCodes[lang][error]);
      notification('error', errorMsgs.join(', '));
      throw response;
    });
};

const SuperFetch = {};
['get', 'post', 'put', 'delete'].forEach(method => {
  SuperFetch[method] = base.bind(null, method);
});

SuperFetch.getFile = (url) => {
  const lang = localStorage.getItem('language') || defaultActiveLanguage;

  return fetch(`${url}`, {
    headers: customHeader(lang)
  })
    .catch(response => {
      const { errors } = response;
      if (errors.includes('401')) {
        store.dispatch(authActions.logout());
      } else if (errors.includes(404)) {
        store.dispatch(push('/404'));
      }
      const errorMsgs = errors.map(error => errorCodes[lang][error]);
      notification('error', errorMsgs.join(', '));
      throw response;
    });
}

export default SuperFetch;
