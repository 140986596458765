import SuperFetch from '../helpers/superFetch';

const Logs = {
  getLogs: (currentPage = 1) =>
    SuperFetch.get(`my-account/logs?page=${currentPage}`)
      .then(response => response.data),

  getLogsById: (currentPage = 1, userId) =>
   SuperFetch.get(`admin/users/${userId}/logs?page=${currentPage}`)
      .then(response => response.data),

}

export default Logs;
