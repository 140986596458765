import actions from './actions';

const initState = {
  isLoading: false,
  modalActive: false,
  eligibleCosts: [],
  eligibleCostsCategories: []
};

export default function reducer(state = initState, { type, payload }) {
  switch (type) {
    case actions.LOAD_ELIGIBLE_COSTS:
      return {
        ...state,
        currentPage: payload.currentPage,
        isLoading: true,
        modalActive: false,
      };
    case actions.LOAD_ELIGIBLE_COSTS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        eligibleCosts: payload.data,
        total: payload.data.length
      };
    case actions.LOAD_ELIGIBLE_COSTS_ERROR:
      return {
        ...state,
        isLoading: false,
      };
    case actions.LOAD_ELIGIBLE_COSTS_CATEGORIES:
      return {
        ...state,
        isLoading: true,
        modalActive: false,
      };
    case actions.LOAD_ELIGIBLE_COSTS_CATEGORIES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        eligibleCostsCategories: payload.data
      };
    case actions.LOAD_ELIGIBLE_COSTS_CATEGORIES_ERROR:
      return {
        ...state,
        isLoading: false,
      };
    case actions.LOAD_ELIGIBLE_COST:
      return {
        ...state,
        isLoading: true,
        modalActive: false,
      };
    case actions.LOAD_ELIGIBLE_COST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        eligibleCost: payload.data
      };
    case actions.LOAD_ELIGIBLE_COST_ERROR:
      return {
        ...state,
        isLoading: false,
      };

    case actions.TOGGLE_ELIGIBLE_COST_MODAL:
      return {
        ...state,
        modalActive: !state.modalActive,
        selectedEligibleCost: payload.data == null ? initState.eligibleCost : payload.data,
      };
    default:
      return state;
  }
}
