import React, { Component } from 'react';
import { connect } from 'react-redux';

import appAction from '../redux/app/actions';
import { getCollapsed, getCurrent, getShouldLayoutBeFlexible } from '../redux/app/selectors';
import { getUnreadMsg } from '../redux/auth/selectors';
import { getFormCompleted } from '../redux/regions/selectors';

const { changeCurrent, toggleCollapsed, setShouldLayoutBeFlexible } = appAction;

const withApp = WrappedComponent => {
  let resize;
  class App extends Component {
    componentDidMount() {
      window.addEventListener('resize', this.resizeTimeout);
      this.resizeTimeout();
    }
    componentWillUnmount() {
      window.removeEventListener('resize', this.resizeTimeout);
    }
    resizeTimeout = () => {
      clearTimeout(resize);
      resize = setTimeout(this.resizeListener, 500);
    }
    resizeListener = () => {
      this.props.toggleCollapsed(this.getWidth() <= 767)
      this.props.setShouldLayoutBeFlexible(this.getWidth() >= 768)
    };
    getWidth = () => window.innerWidth;
    render() {
      return (
        <WrappedComponent {...this.props} />
      )
    }
  }

  return connect(mapStateToProps, mapDispatchToProps)(App);
};

const mapStateToProps = state => {
  return {
    collapsed: getCollapsed(state),
    shouldLayoutBeFlexible: getShouldLayoutBeFlexible(state),
    current: getCurrent(state),
    formCompleted: getFormCompleted(state),
    unreadMsg: getUnreadMsg(state)
  }
};

const mapDispatchToProps = { changeCurrent, toggleCollapsed, setShouldLayoutBeFlexible };

export default withApp;
