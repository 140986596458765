import actions from './actions';

const initState = {
  idToken: null,
  loading: false,
  error: null,
  currentUser: null,
  currentUserLoaded: false,
  currentUserLoading: false,
  msgcount: 0
};

export default function authReducer(state = initState, action) {
  switch (action.type) {

    //login 
    case actions.LOGIN_REQUEST:
      return { ...state, loading: true, error: null, idToken: null };

    case actions.LOGIN_SUCCESS:
      return { ...state, idToken: action.token, loading: false };

    case actions.LOGIN_ERROR:
      return { ...state, error: action.error, loading: false, idToken: null };

    case actions.LOGOUT:
      return initState;

    //signup 
    case actions.SIGNUP_REQUEST:
      return { ...state, loading: true, error: null };

    case actions.SIGNUP_SUCCESS:
      return { ...state, loading: false, error: null };

    case actions.SIGNUP_ERROR:
      return { ...state, loading: false, error: action.error };

    //forgot password 
    case actions.FORGOT_PASSWORD_REQUEST:
      return { ...state, loading: true, error: null };

    case actions.FORGOT_PASSWORD_SUCCESS:
      return { ...state, loading: false, error: null };

    case actions.FORGOT_PASSWORD_ERROR:
      return { ...state, loading: false, error: action.error };

    //reset password 
    case actions.RESET_PASSWORD_REQUEST:
      return { ...state, loading: true, error: null };

    case actions.RESET_PASSWORD_SUCCESS:
      return { ...state, loading: false, error: null };

    case actions.RESET_PASSWORD_ERROR:
      return { ...state, loading: false, error: action.error };

    //activate account 
    case actions.ACTIVATE_ACCOUNT_REQUEST:
      return { ...state, loading: true, error: null };

    case actions.ACTIVATE_ACCOUNT_SUCCESS:
      return { ...state, loading: false, error: null };

    case actions.ACTIVATE_ACCOUNT_ERROR:
      return { ...state, loading: false, error: action.error };

    //current user
    case actions.CURRENT_USER_REQUEST:
      return { ...state, error: null, currentUserLoaded: false, currentUserLoading: true, pgrDate: null };

    case actions.CURRENT_USER_SUCCESS:
      return { ...state, error: null, currentUser: action.payload.currentUser ?? state.currentUser, msgcount: action.payload.msgcount, currentUserLoaded: true, currentUserLoading: false, pgrDate: action.payload.pgrDate}; 

    case actions.CURRENT_USER_ERROR:
      return { ...state, error: action.error, currentUserLoaded: true , currentUserLoading: false, pgrDate: null};

    default:
      return state;
  }
}
