import SuperFetch from '../helpers/superFetch';
import TlnString from '../components/Tln/TlnString';

const EligibleCosts = {
  getEligibleCosts: currentPage =>
    SuperFetch.get(`eligible-costs?page=${currentPage}`)
      .then(response => response.data),

  getEligibleCostsCategories: () =>
    SuperFetch.get(`eligible-costs/categories`)
      .then(response => response.data),

  getEligibleCost: eligibleCostId =>
    SuperFetch.get(`admin/eligible-costs/${eligibleCostId}`)
      .then(response => response.data),

  saveEligibleCost: eligibleCost =>
    SuperFetch.post('admin/eligible-costs', eligibleCost,TlnString("CRUD_ADD_RECORD_SUCCESS"))
      .then(response => response.data),

  updateEligibleCost: eligibleCost =>
    SuperFetch.put(`admin/eligible-costs/${eligibleCost.id}`, eligibleCost, TlnString("CRUD_EDIT_RECORD_SUCCESS"))
      .then(response => response.data),

  deleteEligibleCost: eligibleCostId =>
    SuperFetch.delete(`admin/eligible-costs/${eligibleCostId}`, {}, TlnString("CRUD_REMOVE_RECORD_SUCCESS"))
      .then(response => response.data),

}

export default EligibleCosts;
