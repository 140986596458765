const actions = {
  LOAD_EMAILS: 'LOAD_EMAILS',
  LOAD_EMAILS_SUCCESS: 'LOAD_EMAILS_SUCCESS',
  LOAD_EMAILS_ERROR: 'LOAD_EMAILS_ERROR',

  LOAD_EMAIL: 'LOAD_EMAIL',
  LOAD_EMAIL_SUCCESS: 'LOAD_EMAIL_SUCCESS',
  LOAD_EMAIL_ERROR: 'LOAD_EMAIL_ERROR',

  NEW_EMAIL: 'NEW_EMAIL',
  REPLY_EMAIL: 'REPLY_EMAIL',
  EMAIL_TO_USER: 'EMAIL_TO_USER',
  MARK_AS_READ: 'MARK_AS_READ',
  EMAIL_SENDED: 'EMAIL_SENDED',

  loadEmails: (userId, mode, query) => ({
    type: actions.LOAD_EMAILS,
    payload: { query, userId, mode }
  }),
  loadEmailsSuccess: data => ({
    type: actions.LOAD_EMAILS_SUCCESS,
    payload: { data }
  }),
  loadEmailsError: error => ({
    type: actions.LOAD_EMAILS_ERROR,
    payload: { error }
  }),

  loadEmail: (emailId, userId, mode) => ({
    type: actions.LOAD_EMAIL,
    payload: { emailId, userId, mode }
  }),
  loadEmailSuccess: data => ({
    type: actions.LOAD_EMAIL_SUCCESS,
    payload: { data }
  }),
  loadEmailError: error => ({
    type: actions.LOAD_EMAIL_ERROR,
    payload: { error }
  }),
  newEmail: (status, type, title, round, text, mode) => ({
    type: actions.NEW_EMAIL,
    payload: {status, type, title, text, round, mode}
  }),
  replyEmail: (id, text, mode) => ({
    type: actions.REPLY_EMAIL,
    payload: {id, text, mode}
  }),
  newEmailToUser: (userId, status, type, title, body) => ({
    type: actions.EMAIL_TO_USER,
    payload: {userId, status, type, title, body}
  }),
  markAsRead: (id) => ({
    type: actions.MARK_AS_READ,
    payload: {id}
  }),
  emailSended: () => ({
    type: actions.EMAIL_SENDED,
    payload: {}
  })
};

export default actions;