import React from 'react';
import { Header } from 'antd/lib/layout/layout';
import LoggedUser from '../LoggedUser';
import { NavStyleWrapper } from './style';
import { Row } from 'antd';
import DaysToEnd from './DaysToEnd';

const Nav = ({user, pgrDate}) => (
  <NavStyleWrapper>
    <Header>
      <Row justify={pgrDate ? "space-between" : "end"}>
        {pgrDate ? <DaysToEnd pgrDate={pgrDate} /> : null}
        {user ? <LoggedUser user={user} /> : null}
      </Row>
    </Header>
  </NavStyleWrapper>
);
export default Nav;