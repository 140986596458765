import SuperFetch from '../helpers/superFetch';
import TlnString from '../components/Tln/TlnString';
import notification from '../components/notification';
import { ADMIN_PARAMS } from '../helpers/enums';

const searchParams = (currentPage, query) => {
  const qs = new URLSearchParams();

  if(currentPage) {
    qs.append('page', currentPage);
  }

  ADMIN_PARAMS.forEach(param => {
    if(query[param]) {
      qs.append(param, query[param]);
    }  
  });
  
  return qs.toString();
}

const Applications = {
  getApplications: (currentPage = 1, query) => {
    return SuperFetch.get(`moderator/applications?${searchParams(currentPage, query)}`)
      .then(response => response.data)
  },
  getApplicationsWithStatus: (currentPage = 1, query) => {
    return SuperFetch.get(`moderator/applications?${searchParams(currentPage, query)}`)
      .then(response => response.data)
  },

  saveApplicationByModerator: ({application, applicationId}) =>
    SuperFetch.post(`moderator/applications/${applicationId}`, application)
      .then(response => response.data),

  getApplication: applicationId =>
    SuperFetch.get(`moderator/applications/${applicationId}`)
      .then(response => response.data),

  getApplicationBackup: (applicationId, backupId) =>
    SuperFetch.get(`moderator/applications/${applicationId}/${backupId}`)
      .then(response => response.data),

  getApplicationsById: (currentPage = 1, userId) =>
    SuperFetch.get(`admin/users/${userId}/applications?page=${currentPage}`)
      .then(response => response.data),

  getApplicationById: (applicationId, userId) =>
    SuperFetch.get(`admin/users/${userId}/applications/${applicationId}`)
      .then(response => response.data),

  saveApplication: application =>
    SuperFetch.post('application', application, TlnString("CRUD_ADD_RECORD_SUCCESS"))
    .then(response => response.data),

  updateApplication: (id, application) => 
    SuperFetch.post(`application/${id}`, application, TlnString("CRUD_EDIT_RECORD_SUCCESS"))
      .then(response => response.data),

  saveApplicationFile: application =>
    SuperFetch.post(`application/${application.get('id')}/set-confirmation-attachment`, application, TlnString("CRUD_EDIT_RECORD_SUCCESS"))
      .then(response => response.data),

  saveWithdrawFile: application =>
    SuperFetch.post(`application/withdraw/${application.get('id')}`, application, TlnString("CRUD_EDIT_RECORD_SUCCESS"))
      .then(response => response.data),

  saveResignFile: application =>
    SuperFetch.post(`application/resign/${application.get('id')}`, application, TlnString("CRUD_EDIT_RECORD_SUCCESS"))
      .then(response => response.data),

  saveTerminationFile: application =>
    SuperFetch.post(`application/renouncement/${application.get('id')}`, application, TlnString("CRUD_EDIT_RECORD_SUCCESS"))
      .then(response => response.data),
  
  getUserApplication: applicationId => 
    SuperFetch.get(`application/${applicationId}`)
      .then(response => response.data),
    
  getUserApplications: (currentPage = 1) => 
    SuperFetch.get(`application?page=${currentPage}`)
      .then(response => response.data),

  getVerifierApplications: ({currentPage = 1, query={}}) => {
    return SuperFetch.get(`operator/applications?${searchParams(currentPage, query)}`)
      .then(response => response.data)
  },
      
  getAdminApplications: ({currentPage = 1, query={}}) => {
    return SuperFetch.get(`admin/applications?${searchParams(currentPage, query)}`)
      .then(response => response.data)
    },
  
  getVerifierApplication: applicationId => 
    SuperFetch.get(`operator/applications/${applicationId}`)
      .then(response => response.data),

  sendApplication: ({applicationId, controlSum}) => 
    SuperFetch.post(`application/${applicationId}/confirm`, {control_sum: controlSum})
      .then(response => response.data),

  duplicateApplication: ({id}) => 
    SuperFetch.put(`application/${id}/duplicate`)
      .then(response => response.data),
  setApplicationCanceled: ({applicationId}) => 
    SuperFetch.put(`application/${applicationId}/set-as-canceled`)
      .then(response => response.data),
  setApplicationVerified: ({applicationId}) => 
    SuperFetch.put(`application/${applicationId}/set-as-verified`)
      .then(response => response.data),
  changeAdminStatus: ({applicationId, status}) => 
    SuperFetch.post(`admin/applications/status/${applicationId}`, {status})
      .then(response => response.data),
  saveApplicationFileVerifier: application =>
    SuperFetch.post(`operator/applications/${application.get('id')}`, application, TlnString("CRUD_EDIT_RECORD_SUCCESS"))
      .then(response => response.data),
  acceptWithdrawal: ({id}) =>
    SuperFetch.post(`moderator/applications/withdrawn/${id}`, {}, TlnString("CRUD_EDIT_RECORD_SUCCESS"))
      .then(response => response.data),
  acceptTermination: ({id}) =>
    SuperFetch.post(`moderator/applications/renouncement/${id}`, {}, TlnString("CRUD_EDIT_RECORD_SUCCESS"))
      .then(response => response.data),
  acceptResign: ({id}) =>
    SuperFetch.post(`moderator/applications/resign/${id}`, {}, TlnString("CRUD_EDIT_RECORD_SUCCESS"))
      .then(response => response.data),
      
  saveAssignModerators: (data) => 
    SuperFetch.post(`admin/users/assign`, data, TlnString("MODERATORS_SAVE"))
      .then(response => response.data),
      
  rejectApplicationFiles: ({id, note_confirmation_files, note_signed_files}) => 
    SuperFetch.post(`operator/applications/correct/${id}`, {note_confirmation_files, note_signed_files}, TlnString("CRUD_EDIT_RECORD_SUCCESS"))
      .then(response => response.data),
      
  correctSignedFiles: (data) => 
    SuperFetch.post(`application/correct/${data.get('id')}`, data, TlnString("CRUD_EDIT_RECORD_SUCCESS"))
    .then(response => response.data),
    
  markAsPaid: ({id, paid}) =>
    SuperFetch.put(`admin/applications/paid/${id}`, {paid}, TlnString("CRUD_EDIT_RECORD_SUCCESS"))
      .then(response => response.data),
    
  toggleAgreement: ({id, agreement_locked}) =>
    SuperFetch.put(`admin/applications/toggle-agreement/${id}`, {agreement_locked}, TlnString("CRUD_EDIT_RECORD_SUCCESS"))
      .then(response => response.data),

  saveAdminField: ({values}) => 
    SuperFetch.put(`admin/applications/save-admin-field/${values.id}`, {...values}, TlnString("CRUD_EDIT_RECORD_SUCCESS"))
      .then(response => response.data),

  saveAdminCost: ({values}) => 
    SuperFetch.put(`admin/applications/save-admin-cost/${values.id}`, {...values}, TlnString("CRUD_EDIT_RECORD_SUCCESS"))
      .then(response => response.data),

  saveContractors: ({contractors}) => 
    SuperFetch.put(`admin/applications/save-contractors`, {contractors}, TlnString("CRUD_EDIT_RECORD_SUCCESS"))
      .then(response => response.data),

  showAmendment: ({applicationId, agreementDate, agreementExtend}) => 
    SuperFetch.put(`admin/applications/unblock-annex/${applicationId}?agreementDate=${agreementDate}&extended_to=${agreementExtend}`, {}, TlnString("CRUD_EDIT_RECORD_SUCCESS"))
      .then(response => response.data),

  rejectAmendmentFiles: ({id, note_signed_files}) => 
    SuperFetch.post(`operator/applications/annex/${id}`, {status: 'annex_to_correct', note_file_annex: note_signed_files}, TlnString("CRUD_EDIT_RECORD_SUCCESS"))
      .then(response => response.data),
  
  signAmendmentFiles: ({id, data}) => 
      SuperFetch.post(`operator/applications/annex/${id}`, data, TlnString("CRUD_EDIT_RECORD_SUCCESS"))
        .then(response => response.data),
      

  showFile: ({url}) => {
    url = url.replace('https://localhost', 'http://localhost');
    let filename;
    SuperFetch.getFile(url)
      .then(r => {
      const disposition = r.headers.get('Content-Disposition');
      if (disposition && disposition.indexOf('attachment') !== -1) {
        const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        const matches = filenameRegex.exec(disposition);
          if (matches != null && matches[1]) { 
            filename = matches[1].replace(/['"]/g, '');
          }
      }
        return r.blob();
      })
      .then(blob => {
          if(blob.type === 'application/json') throw new Error({errors: [404]})
          const data = window.URL.createObjectURL(blob);
          var link = document.createElement('a');
          link.href = data;
          link.target = '_blank';

          if(filename) {
            link.download = filename;
          }
          link.click();
          setTimeout(function(){
            // For Firefox it is necessary to delay revoking the ObjectURL
            window.URL.revokeObjectURL(data);
          }, 100);
      }).catch(e => {
        notification('error', "Plik nie istnieje");
      })
  }
}

export default Applications;
