import React from 'react';
import {
  UserOutlined,
  DollarOutlined,
  ReadOutlined,
  MailOutlined,
  ToolOutlined,
  FileOutlined,
  CalculatorOutlined,
} from '@ant-design/icons';

import { URLS } from '../../helpers/enumsUrls';

const beneficiaryMenu = [
  {
    url: URLS.USER_APPLICATIONS,
    name: 'MENU_APPLICATIONS',
    icon: <FileOutlined />,
  },
  {
    url: URLS.USER_STATEMENTS,
    name: 'MENU_STATEMENTS',
    icon: <CalculatorOutlined />,
  },
  {
    url: URLS.USER_MY_EMAILS,
    name: 'MENU_EMAILS',
    icon: <MailOutlined />,
  },
];

const adminMenu = [
  {
    url: URLS.ADMIN_USER_APPLICATIONS,
    name: 'MENU_APPLICATIONS',
    icon: <FileOutlined />,
  },
  {
    url: URLS.USER_STATEMENTS,
    name: 'MENU_STATEMENTS',
    icon: <CalculatorOutlined />,
  },
  {
    url: URLS.ADMIN_USERS,
    name: 'MENU_USERS',
    icon: <UserOutlined />,
  },
  {
    url: URLS.ADMIN_ELIGIBLE_COSTS,
    name: 'MENU_ELIGIBLE_COSTS',
    icon: <DollarOutlined />,
  },
  {
    url: URLS.ADMIN_MY_EMAILS,
    name: 'MENU_EMAILS',
    icon: <MailOutlined />,
  },
  {
    url: URLS.MY_LOGS,
    name: 'MENU_LOGS',
    icon: <ReadOutlined />,
  },
];

const moderatorMenu = [
  {
    url: URLS.MODERATOR_APPLICATIONS_LIST,
    name: 'MENU_APPLICATIONS',
    icon: <FileOutlined />,
  },
  {
    url: URLS.MY_LOGS,
    name: 'MENU_LOGS',
    icon: <ReadOutlined />,
  },
];

const verifierMenu = [
  {
    url: URLS.VERIFIER_APPLICATIONS_LIST,
    name: 'MENU_APPLICATIONS',
    icon: <FileOutlined />,
  },
  {
    url: URLS.VERIFIER_STATEMENTS,
    name: 'MENU_STATEMENTS',
    icon: <CalculatorOutlined />,
  },
  {
    url: URLS.MY_LOGS,
    name: 'MENU_LOGS',
    icon: <ReadOutlined />,
  },
];

const statementVerifierMenu = [
  {
    url: URLS.USER_STATEMENTS,
    name: 'MENU_STATEMENTS',
    icon: <CalculatorOutlined />,
  },
  {
    url: URLS.USER_MY_EMAILS,
    name: 'MENU_EMAILS',
    icon: <MailOutlined />,
  },
];

const controllerMenu = [
  {
    url: URLS.CONTROLLER_STATEMENTS_LIST,
    name: 'MENU_STATEMENTS',
    icon: <CalculatorOutlined />,
  },
];

const commonMenu = [
  {
    url: URLS.MY_ACCOUNT,
    name: 'MENU_ACCOUNT',
    icon: <ToolOutlined />,
  },
];

export { adminMenu, beneficiaryMenu, moderatorMenu, verifierMenu, statementVerifierMenu, controllerMenu, commonMenu };
