const actions = {

  LOGIN_REQUEST: 'LOGIN_REQUEST',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_ERROR: 'LOGIN_ERROR',
  LOGOUT: 'LOGOUT',

  SIGNUP_REQUEST: 'SIGNUP_REQUEST',
  SIGNUP_SUCCESS: 'SIGNUP_SUCCESS',
  SIGNUP_ERROR: 'SIGNUP_ERROR',

  FORGOT_PASSWORD_REQUEST: 'FORGOT_PASSWORD_REQUEST',
  FORGOT_PASSWORD_SUCCESS: 'FORGOT_PASSWORD_SUCCESS',
  FORGOT_PASSWORD_ERROR: 'FORGOT_PASSWORD_ERROR',

  RESET_PASSWORD_REQUEST: 'RESET_PASSWORD_REQUEST',
  RESET_PASSWORD_SUCCESS: 'RESET_PASSWORD_SUCCESS',
  RESET_PASSWORD_ERROR: 'RESET_PASSWORD_ERROR',

  ACTIVATE_ACCOUNT_REQUEST: 'ACTIVATE_ACCOUNT_REQUEST',
  ACTIVATE_ACCOUNT_SUCCESS: 'ACTIVATE_ACCOUNT_SUCCESS',
  ACTIVATE_ACCOUNT_ERROR: 'ACTIVATE_ACCOUNT_ERROR',

  CHECK_AUTHORIZATION: 'CHECK_AUTHORIZATION',

  CURRENT_USER_REQUEST: 'CURRENT_USER_REQUEST',
  CURRENT_USER_SUCCESS: 'CURRENT_USER_SUCCESS',
  CURRENT_USER_ERROR: 'CURRENT_USER_ERROR',
  GET_EMAILS_NUMBER: 'GET_EMAILS_NUMBER',

  GET_END_DATE: 'GET_END_DATE',

  login: ({ email, password }) => ({
    type: actions.LOGIN_REQUEST,
    payload: { email, password }
  }),

  signUp: (data) => ({
    type: actions.SIGNUP_REQUEST,
    payload: { data }
  }),

  forgotPassword: ({ email }) => ({
    type: actions.FORGOT_PASSWORD_REQUEST,
    payload: { email }
  }),

  resetPassword: data => ({
    type: actions.RESET_PASSWORD_REQUEST,
    payload: data
  }),

  activateAccount: ({ token }) => ({
    type: actions.ACTIVATE_ACCOUNT_REQUEST,
    payload: { token }
  }),

  logout: () => ({
    type: actions.LOGOUT
  }),

  checkAuthorization: () => ({
    type: actions.CHECK_AUTHORIZATION
  }),

  getCurrentUser: () => ({
    type: actions.CURRENT_USER_REQUEST
  }),
  
  getEmailsNumber: () => ({
    type: actions.GET_EMAILS_NUMBER
  }),

  date: (pgrDate) => ({
    type: actions.GET_END_DATE,
    payload: { pgrDate }
  })
};

export default actions;
