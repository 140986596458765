import SuperFetch from '../helpers/superFetch';
import TlnString from '../components/Tln/TlnString';

const Statements = {
  loadCosts: (statementId) =>
    SuperFetch.get(`settlement/cost/${statementId}`)
      .then(response => response.data),

  addCost: (statementId, cost) => 
    SuperFetch.post(`settlement/cost/${statementId}`, cost, TlnString("CRUD_EDIT_RECORD_SUCCESS"))
      .then(response => response.data),

  saveCost: (cost) => 
    SuperFetch.put(`settlement/cost/${cost.id}`, cost, TlnString("CRUD_EDIT_RECORD_SUCCESS"))
      .then(response => response.data),

  deleteCost: (costId) => 
    SuperFetch.delete(`settlement/cost/${costId}`, TlnString("CRUD_EDIT_RECORD_SUCCESS"))
      .then(response => response.data),

}

export default Statements;