import React, { Component } from 'react';
import { connect } from 'react-redux';

const withMessagesContainer = WrappedComponent => {

  class Messages extends Component {
    constructor(props) {
      super(props);

      this.state = {
        helloModal: !window.localStorage.getItem('helloModal'),
        videoModal: false
      };
    }

    hideModal = (name) => {
      const obj = {};
      obj[name] = false;
      this.setState(obj);
      window.localStorage.setItem(name, true)
    }


    render() {
      return (
          <WrappedComponent
            {...this.props}
            helloModal={this.state.helloModal}
            videoModal={this.state.videoModal}
            hideModal={this.hideModal}
          />
      )
    }
  }

  return connect(
    mapStateToProps,
    mapDispatchToProps
  )(Messages);
};

const mapDispatchToProps = {};
const mapStateToProps = state => ({
})

export default withMessagesContainer;