import React, { Component } from 'react';
import { Route, withRouter, Switch, Redirect } from 'react-router-dom';
import { compose } from 'redux';

import { adminRoutes, beneficiaryRoutes, moderatorRoutes, verifierRoutes, commonRoutes, controllerRoutes, statementVerifierRoutes, statementApproverRoutes, inspectorRoutes } from './routesList';
import { roles } from '../settings';
import { URLS_WITHOUT_LOGIN } from '../helpers/enumsUrls';

class AppRouter extends Component {
  routes = (url, routes) => (
    <Switch>
      {routes.map(singleRoute => {
        const { path, exact, ...otherProps } = singleRoute;
        return (
          <Route
            exact={exact === false ? false : true}
            key={path}
            path={`${url}/${path}`}
            {...otherProps}
          />
        );
      })}
      <Redirect to={`${URLS_WITHOUT_LOGIN.FOUR_ZERO_FOUR}`} />
    </Switch>
  );

  render() {
    const { url, userRoles } = this.props;

    if (userRoles.includes(roles.ADMIN)) return this.routes(url, [...adminRoutes, ...commonRoutes]);
    if (userRoles.includes(roles.MODERATOR)) return this.routes(url, [...moderatorRoutes, ...commonRoutes]);
    if (userRoles.includes(roles.VERIFIER)) return this.routes(url, [...verifierRoutes, ...commonRoutes]);
    if (userRoles.includes(roles.CONTROLLER)) return this.routes(url, [...controllerRoutes, ...commonRoutes]);
    if (userRoles.includes(roles.STATEMENT_VERIFIER)) return this.routes(url, [...statementVerifierRoutes, ...commonRoutes]);
    if (userRoles.includes(roles.STATEMENT_APPROVER)) return this.routes(url, [...statementApproverRoutes, ...commonRoutes]);
    if (userRoles.includes(roles.INSPECTOR)) return this.routes(url, [...inspectorRoutes, ...commonRoutes]);

    return this.routes(url, [...beneficiaryRoutes, ...commonRoutes]);
  }
}

export default compose(
  withRouter
)(AppRouter);
