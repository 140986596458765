import styled from "styled-components";


const LoggedUserStyleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  height: 64px;
  justify-content: center;
  align-items: center;
`;

export { LoggedUserStyleWrapper };