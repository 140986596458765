import { EMAILS_PARAMS } from '../helpers/enums';
import SuperFetch from '../helpers/superFetch';

const searchParams = (query) => {
  const qs = new URLSearchParams();
  EMAILS_PARAMS.forEach(param => {
    if(query[param]) {
      qs.append(param, query[param]);
    }
  });
  return qs.toString();
}

const Emails = {
  getEmails: (query) =>
    SuperFetch.get(`messages?${searchParams(query)}`)
      .then(response => response.data),

  getAdminEmails: (query) =>
    SuperFetch.get(`admin/messages?${searchParams(query)}`)
      .then(response => response.data),

  getEmailsForUser: (userId, query) =>
    SuperFetch.get(`admin/messages/user/${userId}?${searchParams(query)}`)
      .then(response => response.data),

  getEmailForUser: (userId, emailId) =>
    SuperFetch.get(`admin/messages/show/${emailId}`)
      .then(response => response.data),

  getEmail: emailId =>
    SuperFetch.get(`messages/${emailId}`)
      .then(response => response.data),

  getAdminEmail: emailId =>
    SuperFetch.get(`admin/messages/${emailId}`)
      .then(response => response.data),

  getEmailsById: (currentPage = 1, userId) =>
   SuperFetch.get(`admin/users/${userId}/emails?page=${currentPage}`)
      .then(response => response.data),

  getEmailById: (emailId, userId) =>
    SuperFetch.get(`admin/users/${userId}/emails/${emailId}`)
      .then(response => response.data),

  sendEmail: (status, type, title, body) =>
    SuperFetch.post(`messages/`,{type, title, body, status})
      .then(response => response.data),

  sendAdminEmail: (status, type, title, text, round) =>
    SuperFetch.post(`admin/messages/`,{status, type, title, text, round})
      .then(response => response.data),

  replyEmail: (id, text) =>
    SuperFetch.post(`messages/${id}`,{text})
      .then(response => response.data),
  
  emailToUser: (userId, status, type, title, body) =>
  SuperFetch.post(`admin/messages/single`,{user: userId, status, type, title, body})
    .then(response => response.data),
  
  markAsRead: (id) =>
    SuperFetch.post(`admin/messages/setAsRead/${id}`)
      .then(response => response.data),
}

export default Emails;
