const actions = {
  SEND_REGION_FORM: 'SEND_REGION_FORM',
  SEND_REGION_FORM_SUCCESS: 'SEND_REGION_FORM_SUCCESS',
  SEND_REGION_FORM_ERROR: 'SEND_REGION_FORM_ERROR',

  LOAD_TERYT_DATA: 'LOAD_TERYT_DATA',
  LOAD_TERYT_DATA_SUCCESS: 'LOAD_TERYT_DATA_SUCCESS',
  LOAD_TERYT_DATA_ERROR: 'LOAD_TERYT_DATA_ERROR',

  CLEAR_FORM_COMPLETED: 'CLEAR_FORM_COMPLETED',

  sendRegionForm: data => ({
    type: actions.SEND_REGION_FORM,
    payload: { data }
  }),
  sendRegionFormSuccess: () => ({
    type: actions.SEND_REGION_FORM_SUCCESS
  }),
  sendRegionFormError: error => ({
    type: actions.SEND_REGION_FORM_ERROR,
    payload: { error }
  }),

  loadTerytData: teryt => ({
    type: actions.LOAD_TERYT_DATA,
    payload: { teryt }
  }),
  loadTerytDataSuccess: data => ({
    type: actions.LOAD_TERYT_DATA_SUCCESS,
    payload: { data }
  }),
  loadTerytDataError: error => ({
    type: actions.LOAD_TERYT_DATA_ERROR,
    payload: { error }
  }),

  clearFormCompleted: () => ({
    type: actions.CLEAR_FORM_COMPLETED,
  }),
};

export default actions;