import React from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';
import { connect } from 'react-redux';

import App from './App/App';
import asyncComponent from './helpers/AsyncFunc';
import Maintenance from './App/Maintenance';
import { URLS_WITHOUT_LOGIN } from './helpers/enumsUrls';
import { maintenance } from './settings';

const RestrictedRoute = ({ component: Component, isLoggedIn, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      isLoggedIn ?
      (
        <Component {...props} />
      )
      :
      (
        <Redirect
          to={{
            pathname: URLS_WITHOUT_LOGIN.LOGIN_WITH_PAGE,
            state: { from: props.location }
          }}
        />
      )
    }
  />
);
const PublicRoutes = ({ isLoggedIn }) => {
  return (
    <>
      <Switch>
        <Route
          exact
          path={URLS_WITHOUT_LOGIN.LOGIN}
          component={asyncComponent(() => import('./pages/Auth/Login'))}
        />
        <Route
          exact
          path={URLS_WITHOUT_LOGIN.LOGIN_WITH_PAGE}
          component={asyncComponent(() => import('./pages/Auth/Login'))}
        />
        <Route
          exact
          path={URLS_WITHOUT_LOGIN.SIGN_UP}
          component={asyncComponent(() => import('./pages/Auth/Signup'))}
        />
        <Route
          exact
          path={URLS_WITHOUT_LOGIN.REMIND_PASSWORD}
          component={asyncComponent(() => import('./pages/Auth/RemindPassword'))}
        />
        <Route
          exact
          path={URLS_WITHOUT_LOGIN.RESET_PASSWORD}
          component={asyncComponent(() => import('./pages/Auth/ResetPassword'))}
        />
        <Route
          exact
          path={URLS_WITHOUT_LOGIN.SET_PASSWORD}
          component={asyncComponent(() => import('./pages/Auth/ResetPassword'))}
        />
        <Route
          exact
          path={URLS_WITHOUT_LOGIN.ACTIVATE_ACCOUNT}
          component={asyncComponent(() => import('./pages/Auth/ActivateAccount'))}
        />
        <Route
          exact
          path={URLS_WITHOUT_LOGIN.MAINTENANCE}
          component={Maintenance}
        />

        {/* logged user routes */}
        <RestrictedRoute path="/app" component={App} isLoggedIn={isLoggedIn} />

        <Route
          component={asyncComponent(() => import('./App/404'))}
        />
      </Switch>
      { maintenance ? <Redirect to={URLS_WITHOUT_LOGIN.MAINTENANCE} /> : null}
    </>
  );
};

export default connect(state => ({
  isLoggedIn: state.Auth.idToken !== null || localStorage.getItem('id_token') ? true : false,
}))(PublicRoutes);