import styled from "styled-components";


const NavStyleWrapper = styled.div`
  .ant-layout-header {
      background-color: white;
  }
`;

const DaysToEndWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  .ant-alert {
    background-color: transparent;
    border: none;
    padding: 0px;
  }
`;

export { NavStyleWrapper, DaysToEndWrapper };