import styled from "styled-components";
import { palette } from 'styled-theme';

const SiderStyleWrapper = styled.div`
  background-color: ${palette('secondary', 0)};
  position: fixed;
  height: 100vh;
  z-index: 2;

  .ant-layout-sider, .ant-menu  {
    background: none;
    border: 0;
  }

  .ant-menu-item, .ant-menu-item a {
    color: white;
  }

  .ant-menu-item:hover {
    color: ${palette('primary', 0)};
  }

  .ant-menu .ant-menu-item-selected {
    background-color: ${palette('secondary', 1)};
  }

  .ant-menu .ant-menu-item-selected::after {
    border-color: ${palette('secondary', 1)};
  }

  .logo {
    padding: 5px;
    display: block;
  }
  img {
      height: auto;
      width: 100%;
  }
  .ant-layout-sider-trigger {
    background-color: ${palette('secondary', 1)};
  }
`;

export { SiderStyleWrapper };