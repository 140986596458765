import actions from './actions';

const initState = {
  isLoading: false,
  isSaving: false,
  account: {}
};

export default function reducer(state = initState, { type, payload }) {
  switch (type) {
    case actions.LOAD_USER_ACCOUNT:
      return {
        ...state,
        isLoading: true,
      };
    case actions.LOAD_USER_ACCOUNT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        account: payload.data.user,
      };
    case actions.LOAD_USER_ACCOUNT_ERROR:
      return {
        ...state,
        isLoading: false,
      };

    case actions.UPDATE_USER_ACCOUNT:
      return {
        ...state,
        isSaving: true,
      };
    case actions.UPDATE_USER_ACCOUNT_SUCCESS:
      return {
        ...state,
        isSaving: false,
      };
    case actions.UPDATE_USER_ACCOUNT_ERROR:
      return {
        ...state,
        isSaving: false,
      };

    case actions.UPDATE_USER_PASSWORD:
      return {
        ...state,
        isSaving: true,
      };
    case actions.UPDATE_USER_PASSWORD_SUCCESS:
      return {
        ...state,
        isSaving: false,
      };
    case actions.UPDATE_USER_PASSWORD_ERROR:
      return {
        ...state,
        isSaving: false,
      };

    case actions.REMOVE_USER_ACCOUNT:
      return {
        ...state,
        account: {},
      };

    default:
      return state;
  }
}
